import FaviewRequestListContainer from 'containers/faview/FaviewRequestListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const FaviewRequestList = () => {
  return (
    <GlobalContainer>
      <FaviewRequestListContainer />
    </GlobalContainer>
  );
};

export default FaviewRequestList;
