import FeedbackDetailContainer from 'containers/feedback/FeedbackDetailContainer';
import GlobalContainer from "containers/GlobalContainer";

const FeedbackDetail = () => {
    return (
        <GlobalContainer>
            <FeedbackDetailContainer />
        </GlobalContainer>
    );
};

export default FeedbackDetail;