import { useMemo, useContext, useState, useEffect } from 'react';
import { useAsyncDebounce } from 'react-table';
import styled from 'styled-components';
import { icon_search } from 'assets/img/icon';
import { palette } from 'modules/defines/styles';
import { GlobalContext } from 'modules/context/GlobalContext';

const Select = styled.select`
  all: unset;
  font-size: 12px;
  padding: 4px 4px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid ${palette.borderDefault};
  border-radius: 21px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
`;

export const Filter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const globalContext = useContext(GlobalContext);
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  // rerender을 위한 counter의 선언, 해결 가능한 시점에서 리팩토링 필요
  const [renderCounter, setRenderCounter] = useState(0);
  useEffect(() => {
    if (renderCounter < 2) {
      if (globalContext.filterValue[id] !== undefined) {
        setFilter(globalContext.filterValue[id]);
      }
      setRenderCounter(renderCounter + 1);
    }
  }, [renderCounter]);

  return (
    <Select
      value={globalContext.filterValue[id]}
      onChange={(e) => {
        globalContext.setFilterValue(id, e.target.value);
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => {
        return (
          <option key={i} value={option}>
            {option.toString()}
          </option>
        );
      })}
    </Select>
  );
};

const SearchInput = styled.input`
  all: unset;
  height: 30px;
  width: stretch;
  padding-left: 40px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${palette.borderDefault};
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: ${palette.fontDefault};
  opacity: 1;
  background-image: url(${icon_search});
  background-repeat: no-repeat;
  background-position: 5px center;
`;

export const SearchFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholder,
}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((e) => {
    setGlobalFilter(e.target.value || undefined);
  }, 200);

  return (
    <>
      <SearchInput
        onChange={onChange}
        name="filter"
        placeholder={placeholder}
      />
    </>
  );
};
