// formatDate(new Date()) 와 같은 형태로 사용합니다.
export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
// getMonthBefore(new Date()) 와 같은 형태로 사용합니다.
export const getMonthBefore = (date) => {
  var startDateMonth = date;
  startDateMonth.setMonth(startDateMonth.getMonth() - 1);
  return startDateMonth;
};
export const getWeekBefore = (date) => {
  var startDate = date;
  startDate.setDate(startDate.getDate() - 7);
  return startDate;
};

export const getEightDaysBefore = (date) => {
  var startDate = date;
  startDate.setDate(startDate.getDate() - 8);
  return startDate;
};

export const getFourDaysBefore = (date) => {
  var startDate = date;
  startDate.setDate(startDate.getDate() - 4);
  return startDate;
};

export const getDaysBefore = (date, amount) => {
  var startDate = date;
  startDate.setDate(startDate.getDate() - amount);
  return startDate;
};
