import { useState, useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import styled, { createGlobalStyle } from 'styled-components';
import MenuBar from 'components/Header';
import { palette } from 'modules/defines/styles';
import MenuIcon from 'assets/svg/Menu';
import { UserAgent } from 'utils/userAgent';
import { ModalContext } from 'modules/context/ModalContext';
import FeedbackModal from 'components/modal/FeedbackModal';
import { authAPI, UserContext } from 'modules/api/user';
import PretendardBlack from 'assets/fonts/pretendard/Pretendard-Black.woff';
import PretendardExtraBold from 'assets/fonts/pretendard/Pretendard-ExtraBold.woff';
import PretendardBold from 'assets/fonts/pretendard/Pretendard-Bold.woff';
import PretendardSemiBold from 'assets/fonts/pretendard/Pretendard-SemiBold.woff';
import PretendardRegular from 'assets/fonts/pretendard/Pretendard-Regular.woff';
import PretendardMedium from 'assets/fonts/pretendard/Pretendard-Medium.woff';
import PretendardLight from 'assets/fonts/pretendard/Pretendard-Light.woff';
import PretendardExtraLight from 'assets/fonts/pretendard/Pretendard-ExtraLight.woff';
import PretendardThin from 'assets/fonts/pretendard/Pretendard-Thin.woff';

const FontStyle = createGlobalStyle`
  @font-face {
    font-family: "Pretendard-bl";
    font-style: normal;
    src: url(${PretendardBlack}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-eb";
    font-style: normal;
    src: url(${PretendardExtraBold}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-b";
    font-style: normal;
    src: url(${PretendardBold}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-sb";
    font-style: normal;
    src: url(${PretendardSemiBold}) format("woff");
}
  @font-face {
    font-family: "Pretendard-r";
    font-style: normal;
    src: url(${PretendardRegular}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-m";
    font-style: normal;
    src: url(${PretendardMedium}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-l";
    font-style: normal;
    src: url(${PretendardLight}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-el";
    font-style: normal;
    src: url(${PretendardExtraLight}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-t";
    font-style: normal;
    src: url(${PretendardThin}) format("woff");
  }
`;

const GlobalContainer = ({ children }) => {
  const { os } = UserAgent;
  const [userContext] = useContext(UserContext);
  // 팝업 모달 관련 필드
  const { openModal, closeModal } = useContext(ModalContext);
  const [showMenu, setShowMenu] = useState(
    os.isIos || os.isAndroid ? false : true
  );
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (os.isAndroid || os.isIos) {
      setShowMenu(false);
    } else {
      if (cookies?.showMenu !== undefined) {
        setShowMenu(cookies.showMenu === 'true' ? true : false);
      } else {
        setCookie('showMenu', 'true');
      }
    }
  }, []);

  const toggleShowMenu = (e) => {
    e.preventDefault();
    setCookie('showMenu', !showMenu);
    setShowMenu((prev) => !prev);
  };

  // 로그아웃 버튼
  const onClickSignout = async () => {
    try {
      authAPI.logout();
    } catch (err) {
      console.log(err);
    }
  };
  // 피드백보내기 버튼
  const sendFeedback = async (e) => {
    return openModal(
      <FeedbackModal user={userContext.user} closeModal={closeModal} />
    );
  };

  return (
    <>
      {' '}
      <FontStyle />
      <GlobalWrapper>
        {showMenu && (
          <MenuBar
            userRole={userContext.user.role}
            toggleShowMenu={toggleShowMenu}
            setShowMenu={setShowMenu}
            sendFeedback={sendFeedback}
          />
        )}
        <RightWrapper>
          <Header>
            <HideButtonWrapper onClick={toggleShowMenu}>
              <MenuIcon />
              {(os.isOthers || os.isMac) && (
                <HideButtonText>
                  {showMenu ? 'Hide' : 'Show'}
                </HideButtonText>
              )}
            </HideButtonWrapper>
            <InfoWrapper>
              <HelloText>
                {userContext.user.role} {userContext.user.name} 님
              </HelloText>
              <FeedbackButton onClick={sendFeedback}>
                개발자요청
              </FeedbackButton>
              <SignoutButton onClick={onClickSignout}>로그아웃</SignoutButton>
            </InfoWrapper>
          </Header>
          <ContentsWrapper>{children}</ContentsWrapper>
        </RightWrapper>
      </GlobalWrapper>
    </>
  );
};

const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: ${palette.background};
  .isDesktop & {
    min-width: 820px;
    // min-height: 920px;
  }
  font-family: Pretendard-r;
  overflow: hidden;
`;
const RightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  position: relative;
  .isMobile & {
    padding: 0 10px;
  }
`;

const Header = styled.div`
  height: 40px;
  background-color: ${palette.white};
  display: flex;
  flex-direction: row;
  .isMobile & {
    height: 40px;
  }
`;

const HideButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  width: 120px;
  cursor: pointer;
  .isMobile & {
    padding-left: 20px;
    width: 80px;
  }
`;
const HideButtonText = styled.div`
  margin-left: 5px;
  fonst-size: 14px;
  white-space: nowrap;
  color: ${palette.fontDefault};
  @media screen and (max-width: 400px) {
    font-size: 10px;
  }
  &:hover {
    color: ${palette.fontBlue};
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 50px;
  align-items: center;
  .isMobile & {
    margin-right: 20px;
  }
`;
const HelloText = styled.div`
  font-size: 13px;
  color: ${palette.fontDefault};
  float: right;
  white-space: nowrap;
  margin-right: 18px;
  @media screen and (max-width: 400px) {
    font-size: 11px;
  }
`;
const FeedbackButton = styled.div`
  color: ${palette.fontDefault};
  &:hover {
    color: ${palette.mainBlue};
  }
  font-size: 14px;
  margin-right: 18px;
  cursor: pointer;
  @media screen and (max-width: 820px) {
    display: none;
  }
  padding: 4px 4px;
`;
const SignoutButton = styled.div`
  color: ${palette.fontWarning};
  &:hover {
    color: ${palette.fontWarningHover};
  }
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  @media screen and (max-width: 400px) {
    font-size: 11px;
  }
  padding: 4px 4px;
`;

export default GlobalContainer;
