import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const SpaceAPI = (() => {
  class SpaceAPIConstructor {
    getSpaceAll() {
      return httpAPI(apiPaths.getSpaceAll, { method: 'GET' }).call;
    }
    editSpacePublishAt(data) {
      return httpAPI(apiPaths.editSpacePublishDate, { data }).call;
    }
    updateSpaceJson() {
      return httpAPI(apiPaths.updateSpaceJson).call;
    }
  }
  return SpaceAPIConstructor;
})();

export const spaceAPI = new SpaceAPI();
