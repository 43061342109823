import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const ProductAPI = (() => {
  class ProductAPIConstructor {
    getProductAll() {
      return httpAPI(apiPaths.getProductAll, { method: 'GET' });
    }
    getProductSingle(id) {
      return httpAPI(apiPaths.getProductSingle.replace(':id', id), {
        method: 'GET',
      }).call;
    }
    editProduct(data) {
      return httpAPI(apiPaths.editProduct, { data }).call;
    }
    editProductPublishAd(data) {
      return httpAPI(apiPaths.editProductPublishAd, { data }).call;
    }
    getProductImage(id) {
      return httpAPI(apiPaths.getProductImage + id, { method: 'GET' }).call;
    }
    uploadImage(data, headers) {
      return httpAPI(apiPaths.uploadProductImage, { data, headers }).call;
    }
    deleteImage(data) {
      return httpAPI(apiPaths.deleteProductImage, { data }).call;
    }
  }
  return ProductAPIConstructor;
})();

export const productAPI = new ProductAPI();
