import axios from 'axios';
import { apiPaths } from 'modules/defines/paths';

export const defaultOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

const api = axios.create({
  baseURL: process.env.REACT_APP_LOCAL
    ? apiPaths.devBaseUrl
    : apiPaths.awsBaseUrl,
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true,
});

// API 호출 함수 수정
export const httpAPI = function (path, callOptions) {
  const source = axios.CancelToken.source(); // axios의 취소 토큰 생성

  const options = {
    ...defaultOptions,
    ...callOptions,
    cancelToken: source.token, // 취소 토큰 추가
  };

  return {
    call: new Promise((resolve, reject) => {
      api(path, options)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            throw new Error('Error in request');
          }
        })
        .catch((error) => {
          // 에러 처리를 더 세밀하게 제어하고 싶을 때
          reject(error);
        });
    }),
    cancel: function () {
      source.cancel('Query was cancelled');
    },
  };
};
