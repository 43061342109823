import GlobalContainer from 'containers/GlobalContainer';
import DocumentListContainer from 'containers/document/DocumentListContainer';

const DocumentList = () => {
  return (
    <GlobalContainer>
      <DocumentListContainer />
    </GlobalContainer>
  );
};

export default DocumentList;
