import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const DocsAPI = (() => {
  class DocsAPIConstructor {
    getDocsAll() {
      return httpAPI(apiPaths.docsBase, { method: 'GET' });
    }
    getDocsSingle(id) {
      return httpAPI(apiPaths.docsBase + id, { method: 'GET' }).call;
    }
    registerDocs(data) {
      return httpAPI(apiPaths.registerDocs, { data }).call;
    }
    editDocs(data) {
      return httpAPI(apiPaths.editDocs, { data }).call;
    }
    deleteDocs(id) {
      return httpAPI(apiPaths.deleteDocs + id, { method: 'DELETE' }).call;
    }
  }
  return DocsAPIConstructor;
})();

export const docsAPI = new DocsAPI();
