import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

// [StoreOrderHistory] > 주문 내역 DB API
const OrderAPI = (() => {
  class OrderAPIConstructor {
    getOrderHistory() {
      return httpAPI(apiPaths.getOrderHistory, { method: 'GET' });
    }
  }
  return OrderAPIConstructor;
})();

export const orderAPI = new OrderAPI();
