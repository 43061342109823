import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Container, LayoutWrapper } from 'components/common/DataTableStyle';
import { faviewAPI } from 'modules/api/faview';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface UsedUser {
  id: number;
  name: string;
  email: string;
}

interface CouponInfo {
  issueId: string;
  issuedAt: string;
  title: string;
  usedCount: number;
  count: number;
  plan: string;
  unit: string;
  period: number;
  usedUser: UsedUser[];
  usedOpen: boolean;
  memo?: string;
}

const FaviewDataContainer = () => {
  const [couponInfo, setCouponInfo] = useState<CouponInfo[]>([]);

  const fetchList = () => {
    const { call, cancel } = faviewAPI.getFaviewCouponData();
    return { call, cancel };
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['requests'],
    queryFn: () => {
      const { call } = fetchList();
      return call;
    },
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });

  useEffect(() => {
    if (!isLoading && data) {
      setCouponInfo(data);
    }
  }, [data, isLoading]);

  return (
    <Container>
      <LayoutWrapper>
        <TitleText onClick={() => console.log(couponInfo)}>쿠폰 정보</TitleText>
        {!isLoading ? (
          <CouponWrapper>
            {couponInfo.map((coupon, index) => {
              const dateTime = coupon.issuedAt.split('+')[0];
              const date = dateTime.split('T')[0];
              const time = dateTime.split('T')[1];
              return (
                <CouponLayout>
                  <TextLayout>
                    <AttributeText>쿠폰 ID:</AttributeText>
                    <TextData>{coupon.issueId}</TextData>
                  </TextLayout>
                  <TextLayout>
                    <AttributeText>발행일자:</AttributeText>
                    <TextData>{date}</TextData>
                    <TextData>{time}</TextData>
                  </TextLayout>

                  <TextLayout>
                    <AttributeText>쿠폰명:</AttributeText>
                    <TextData>{coupon.title}</TextData>
                  </TextLayout>

                  <TextLayout>
                    <AttributeText>사용 개수:</AttributeText>
                    <TextDataHighlight>{coupon.usedCount}</TextDataHighlight>
                  </TextLayout>

                  <TextLayout>
                    <AttributeText>쿠폰정보:</AttributeText>
                    <TextData>
                      PLAN : {coupon.plan} {coupon.unit} | 기간 :{' '}
                      {coupon.period} 개월 | 개수 : {coupon.count}{' '}
                      {coupon.memo && (
                        <TextHighlight>
                          {' | ' +
                            coupon.memo.split('_')[
                              coupon.memo.split('_').length - 1
                            ]}
                        </TextHighlight>
                      )}
                    </TextData>
                  </TextLayout>
                  <TextButton
                    onClick={() =>
                      setCouponInfo((prev) =>
                        prev.map((item) =>
                          item.issueId === coupon.issueId
                            ? { ...item, usedOpen: !item.usedOpen }
                            : item
                        )
                      )
                    }
                  >
                    사용자 정보 보기
                  </TextButton>
                  {coupon.usedOpen && (
                    <CouponUserLayout>
                      {coupon.usedUser.map((user) => {
                        return (
                          <>
                            <TextLayout>
                              <TextData>{user.id}</TextData>
                              <TextData>{user.name}</TextData>
                              <TextData>{user.email}</TextData>
                            </TextLayout>
                          </>
                        );
                      })}
                    </CouponUserLayout>
                  )}
                </CouponLayout>
              );
            })}
          </CouponWrapper>
        ) : (
          <>Data loading...</>
        )}
      </LayoutWrapper>
    </Container>
  );
};

const CouponWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
`;

const CouponLayout = styled.div`
  display: inline-block;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: auto;
`;
const TextLayout = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AttributeText = styled.div`
  font-size: 14px;
  margin-right: 8px;
  font-weight: 800;
`;

const TitleText = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 700;
`;

const TextData = styled.div`
  margin-right: 8px;
  font-size: 12px;
  color: #777;
`;

const TextDataHighlight = styled.div`
  margin-right: 8px;
  font-size: 12px;
  color: #cc0000;
  font-weight: 900;
`;

const TextButton = styled.div`
  margin-top: 12px;
  color: #686dcf;
  cursor: pointer;
  font-size: 12px;
`;

const CouponUserLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  gap: 8px;
  margin-top: 4px;
`;

const TextHighlight = styled.span`
  font-weight: 900;
  color: #cc0000;
`;

export default FaviewDataContainer;
