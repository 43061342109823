import { useMemo, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import { eventAPI } from 'modules/api';
import { UserContext } from 'modules/api/user';
import { useQuery } from '@tanstack/react-query';
import { PublishButton } from 'components/appManagement/AppManagementCommon';
import {
  Container,
  Title,
  SubTitle,
  ButtonWrapper,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import { ButtonCreate } from 'components/common/DefaultButton';
import DefaultTable from 'components/table/DefaultTable';

const EventListContainer = () => {
  const [userContext] = useContext(UserContext);
  const history = useHistory();
  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }

  const fetchList = () => {
    const { call, cancel } = eventAPI.getEventAll();
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['events'],
    queryFn: () => fetchList(),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });

  const createSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      title: '-',
      subtitle: '-',
      body: '-',
      winner_count: 0,
      start_date: new Date().toLocaleDateString('ko-KR'),
      end_date: new Date().toLocaleDateString('ko-KR'),
      announce_date: new Date().toLocaleDateString('ko-KR'),
    };
    try {
      eventAPI.registerEvent(postData).then((response) => {
        console.log(response);
        alert('새로운 이벤트가 생성되었습니다.');
        history.push(pagePaths.event.detail.replace(':id', response.event.id));
      });
    } catch (e) {
      console.error(e);
      alert(
        '이벤트를 생성하는 중, 에러가 발생했습니다.\n관리자에게 문의해주세요.'
      );
    }
  };

  const publish = async (data, publish) => {
    try {
      const postData = {
        ...data,
        publish: !publish,
      };
      eventAPI.editEvent(postData).then((response) => {
        refetch();
      });
    } catch (err) {
      console.error(err);
    }
  };

  const switchBannerMode = async (data, banner) => {
    try {
      const postData = {
        ...data,
        banner: !banner,
      };
      eventAPI.editEvent(postData).then((response) => {
        refetch();
      });
    } catch (err) {
      console.error(err);
    }
  };

  const announce = async (data, announced) => {
    try {
      let postData = {
        ...data,
      };
      eventAPI.preprocessEventAnnounce(postData).then((response) => {
        if (response.success) {
          postData = {
            ...data,
            announced: !announced,
          };
          eventAPI.editEventAnnounced(postData).then((response) => {
            if (response.success) {
              refetch();
            } else {
              alert(
                '이벤트 announce 상태 변경 도중 에러가 발생하였습니다.\n관리자에게 문의해주세요.'
              );
            }
          });
        } else {
          alert(
            '응모자 상태 변경 도중 에러가 발생하였습니다.\n관리자에게 문의해주세요.'
          );
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const tableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      width: 50,
    },
    {
      accessor: 'title',
      Header: 'Title',
      Filter: false,
      width: 300,
      Cell: ({ row }) => (
        <Link to={pagePaths.event.detail.replace(':id', row.values.id)}>
          {row.values.title}
        </Link>
      ),
    },
    {
      accessor: 'subtitle',
      Header: '부제',
      Filter: false,
      width: 200,
      Cell: ({ row }) => (
        <Link to={pagePaths.event.detail.replace(':id', row.values.id)}>
          {row.values.subtitle}
        </Link>
      ),
    },
    {
      accessor: 'publish',
      Header: 'Publish',
      width: 70,
      Cell: ({ row }) => {
        return (
          <PublishButton
            published={row.values.publish}
            onClick={() => publish(row.values, row.values.publish)}
          />
        );
      },
      Filter: false,
    },
    {
      accessor: 'banner',
      Header: 'Banner',
      width: 70,
      Cell: ({ row }) => {
        return (
          <PublishButton
            published={row.values.banner}
            onClick={() => switchBannerMode(row.values, row.values.banner)}
          />
        );
      },
      Filter: false,
    },
    {
      accessor: 'announced',
      Header: '추첨여부',
      width: 70,
      Cell: ({ row }) => {
        return (
          <PublishButton
            published={row.values.announced}
            onClick={() => announce(row.values, row.values.announced)}
          />
        );
      },
      Filter: false,
    },
    {
      accessor: 'winner_count',
      Header: '당첨자수',
      Filter: false,
      width: 60,
    },
    {
      accessor: 'apply_count',
      Header: '응모자수',
      Filter: false,
      width: 60,
    },
    {
      accessor: 'start_date',
      Header: '시작날짜',
      Filter: false,
      width: 130,
    },
    {
      accessor: 'end_date',
      Header: '종료날짜',
      Filter: false,
      width: 130,
    },
    {
      accessor: 'announce_date',
      Header: '발표날짜',
      Filter: false,
      width: 130,
    },
  ]);
  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((event) => ({
      ...event,
      apply_count: event.user.length,
    }));
  }, [data]);

  return (
    <Container>
      <Title>이벤트</Title>
      <SubTitle>
        FAV 서비스 이벤트 관리 페이지입니다.
        <br />
        이벤트 시작 시간 - 09:00 / 종료 시간 - 23:59 / 발표 시간 - 13:00
      </SubTitle>
      <ButtonWrapper>
        <ButtonCreate
          text="이벤트 추가"
          active={false}
          onClick={createSubmit}
        />
      </ButtonWrapper>
      <LayoutWrapper>
        {tableData && tableColumns && (
          <DefaultTable columns={tableColumns} data={tableData} />
        )}
      </LayoutWrapper>
    </Container>
  );
};

export default EventListContainer;
