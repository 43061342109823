import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const DashboardAPI = (() => {
  class DashboardAPIConstructor {
    getDashboardData(query) {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getDashboardData + query, { method: 'GET' });
    }
    getDashboardView(query) {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getDashboardView + query, { method: 'GET' });
    }
    getClosedData(query) {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getClosedData + query, { method: 'GET' }).call;
    }
    getErrorSpaceTitleDuplicate() {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getErrorSpaceTitleDuplicate, { method: 'GET' });
    }
    getErrorSpaceMenuLineBreak() {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getErrorSpaceMenuLineBreak, { method: 'GET' });
    }
    getErrorSpaceNoKeyword() {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getErrorSpaceNoKeyword, { method: 'GET' });
    }
    getErrorSpaceNoTime() {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getErrorSpaceNoTime, { method: 'GET' });
    }
    getErrorSpaceNoMenu() {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getErrorSpaceNoMenu, { method: 'GET' });
    }
    getSpaceNum() {
      // httpAPI 호출과 함께 반환된 객체의 call 속성을 반환
      return httpAPI(apiPaths.getSpaceNum, { method: 'GET' }).call;
    }
  }
  return DashboardAPIConstructor;
})();

export const dashboardAPI = new DashboardAPI();
