import { palette } from 'modules/defines/styles';
import styled, { createGlobalStyle } from 'styled-components';
import { useState, useEffect } from 'react';
import PretendardWoffBold from 'assets/fonts/pretendard/Pretendard-Bold.woff';
import PretendardWoffRegular from 'assets/fonts/pretendard/Pretendard-Regular.woff';
import PretendardWoffLight from 'assets/fonts/pretendard/Pretendard-Light.woff';
import PretendardWoffThin from 'assets/fonts/pretendard/Pretendard-Thin.woff';
import GmarketWoffMedium from 'assets/fonts/gmarket/GmarketSansMedium.woff';
import GmarketWoffBold from 'assets/fonts/gmarket/GmarketSansBold.woff';
import SampleImgInsta from 'assets/img/insta-curation-sample.png';
import SampleImgApp1 from 'assets/img/app-curation1-sample.png';
import SampleImgApp2 from 'assets/img/app-curation2-sample.png';
import IconInstaPerson from 'assets/img/insta-icon-person.png';
import IconInstaCuration from 'assets/img/insta-icon-curation.png';

const FontStyle = createGlobalStyle`
  @font-face {
    font-family: "Pretendard-bold";
    font-style: normal;
    src: url(${PretendardWoffBold}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-regular";
    font-style: normal;
    src: url(${PretendardWoffRegular}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-light";
    font-style: normal;
    src: url(${PretendardWoffLight}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-thin";
    font-style: normal;
    src: url(${PretendardWoffThin}) format("woff");
  }
  @font-face {
    font-family: "Gmarket-bold";
    font-style: normal;
    src: url(${GmarketWoffBold}) format("truetype");
  }
  @font-face {
    font-family: "Gmarket-medium";
    font-style: normal;
    src: url(${GmarketWoffMedium}) format("truetype");
  }
`;
const MirroringCurationModal = ({
  titleProps,
  setTitleProps,
  descProps,
  setDescProps,
  closeModal,
}) => {
  const [title, setTitle] = useState(titleProps);
  const [titleFixed, setTitleFixed] = useState(titleProps);
  const [desc, setDesc] = useState(descProps);

  useEffect(() => {
    setTitleFixed(title.replaceAll('\\n', '\n'));
  }, [title]);

  const titleOnChange = (e) => {
    setTitle(e.target.value);
  };
  const descOnChange = (e) => {
    setDesc(e.target.value);
  };
  // set 'ESC' keystroke to escape ========================
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const submitBtnListener = () => {
    setTitleProps(title);
    setDescProps(desc);
    closeModal();
  };

  return (
    <ModalContainer>
      <HeaderContainer>
        <HeaderTitle>큐레이션 컨텐츠 미러링</HeaderTitle>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </HeaderContainer>
      <ContentsContainer>
        <TextContainer>
          <ItemTextInput value={title} onChange={(e) => titleOnChange(e)} />
          <ItemTextArea
            height="100px"
            value={desc}
            onChange={(e) => descOnChange(e)}
          />
          <SubmitButton onClick={submitBtnListener}>적용</SubmitButton>
        </TextContainer>
        <MirroringContents>
          <FontStyle />
          <InstaContents>
            <IconWrapperPerson />
            <InstaTitleWrapper>
              <InstaTitleIcon />
              <InstaTitle>{titleFixed}</InstaTitle>
            </InstaTitleWrapper>
            <InstaDesc>{desc}</InstaDesc>
          </InstaContents>
          <AppContentsWrapper>
            <AppContents>
              <AppTextWrapper>
                <AppTitle>{titleFixed}</AppTitle>
                <AppDesc1>{desc}</AppDesc1>
              </AppTextWrapper>
            </AppContents>
            <AppContents2>
              <AppText2Wrapper>
                <AppTitle>{titleFixed}</AppTitle>
                <AppDesc2>{desc}</AppDesc2>
              </AppText2Wrapper>
            </AppContents2>
          </AppContentsWrapper>
        </MirroringContents>
      </ContentsContainer>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  max-height: 100vh;
  max-width: calc(100vw - 120px);
`;
const HeaderContainer = styled.div`
  display: flex;
  position: relative;
`;
const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${palette.fontDefault};
`;
const CloseButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 0px;
`;
const SubmitButton = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  background-color: ${palette.mainOrangeHover};
  color: ${palette.white};
  &:hover {
    background-color: ${palette.mainOrange};
  }
  border-radius: 8px;
  cursor: pointer;
`;
const MirroringContents = styled.div`
  display: flex;
  flex-direction: row;
  width: 1508px;
  height: 100%;
`;
const AppContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const AppContents = styled.div`
  width: 428px;
  height: 400px;
  position: relative;
  background-image: url(${SampleImgApp1});
`;
const AppContents2 = styled.div`
  width: 428px;
  height: 275px;
  position: relative;
  background-image: url(${SampleImgApp2});
`;
const InstaContents = styled.div`
  width: 1080px;
  height: 675px;
  background-image: url(${SampleImgInsta});
  position: relative;
`;
const AppTextWrapper = styled.div`
  position: absolute;
  bottom: 255px;
  padding: 0 55px;
  display: flex;
  flex-direction: column;
`;
const AppText2Wrapper = styled.div`
  position: absolute;
  bottom: 83px;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
`;

const AppTitle = styled.div`
  font-family: Pretendard-light;
  font-size: 32px;
  color: #fff;
  line-height: 1.25;
  margin-bottom: 10px;
  word-break: keep-all;
  white-space: pre-wrap;
`;
const AppDesc1 = styled.div`
  font-family: Pretendard-thin;
  font-size: 23px;
  width: 318px;
  line-height: 1.22;
  text-align: left;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
`;
const AppDesc2 = styled.div`
  font-family: Pretendard-thin;
  font-size: 23px;
  width: 100%;
  line-height: 1.22;
  text-align: left;
  color: #fff;
`;
const InstaTitleWrapper = styled.div`
  position: absolute;
  left: 56px;
  bottom: 213px;
`;
const InstaTitleIcon = styled.div`
  background-image: url(${IconInstaCuration});
  width: 308px;
  height: 64px;
  margin-bottom: 35px;
`;

const InstaTitle = styled.div`
  font-family: Gmarket-medium;
  font-size: 120px;
  color: ${palette.fontWhite};
  flex-grow: 0;
  -webkit-text-stroke: 2px #fff;
  text-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: -1.2px;
  text-align: left;
  word-break: keep-all;
  white-space: pre-wrap;
`;
const InstaDesc = styled.div`
  font-family: Gmarket-medium;
  font-size: 50px;
  color: ${palette.fontWhite};
  position: absolute;
  width: 912px;
  right: 110px;
  bottom: 83px;
  flex-grow: 0;
  text-align: right;
  letter-spacing: -2px;
  text-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
`;

const ContentsContainer = styled.div`
  padding: 10px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const TextContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const ItemTextInput = styled.input`
  all: unset;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 4px;
  text-align: left;
  margin-bottom: 16px;
  font-size: 16px;
  color: ${(props) =>
    props.readOnly ? palette.mainOrange : palette.fontBlack};
  opacity: 1;
`;
const ItemTextArea = styled.textarea`
  all: unset;
  height: ${(props) => (props.height ? props.height : '60px')};
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  color: ${palette.fontBlack};
  font-size: 16px;
  line-height: 1.1;
  overflow: scroll;
`;
const IconWrapperPerson = styled.div`
  position: absolute;
  left: 39px;
  bottom: 39px;
  z-index: 99;
  width: 72px;
  height: 72px;
  background-image: url(${IconInstaPerson});
`;

export default MirroringCurationModal;
