import { useMemo, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import { notiAPI } from 'modules/api';
import { UserContext } from 'modules/api/user';
import PublishButton from 'components/common/PublishButton';
import {
  Container,
  Title,
  SubTitle,
  ButtonWrapper,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import { ButtonCreate } from 'components/common/DefaultButton';
import DefaultTable from 'components/table/DefaultTable';

const NotificationListContainer = () => {
  const [userContext] = useContext(UserContext);
  const history = useHistory();

  const fetchList = () => {
    const { call, cancel } = notiAPI.getNotiAll();
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['noti'],
    queryFn: () => fetchList(),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }

  const tableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      width: 100,
    },
    {
      accessor: 'title',
      Header: 'Title',
      Cell: ({ row }) => (
        <Link to={pagePaths.notification.detail.replace(':id', row.values.id)}>
          {row.values.title}
        </Link>
      ),
      Filter: false,
      width: 500,
    },
    {
      accessor: 'publishDate',
      Header: '게시 일자',
      Filter: false,
      width: 300,
    },
    {
      accessor: 'publish',
      Header: 'Publish',
      Cell: ({ row }) => {
        return (
          <PublishButton
            published={row.values.publish}
            data={row.values}
            refetch={() => refetch()}
            editApi={(data) => notiAPI.editNoti(data)}
          />
        );
      },
      Filter: false,
      width: 200,
    },
  ]);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((noti) => ({
      ...noti,
    }));
  }, [data]);

  return (
    <Container>
      <Title>공지사항</Title>
      <SubTitle>FAV 서비스 공지사항 관리 페이지입니다.</SubTitle>
      <ButtonWrapper>
        <ButtonCreate
          text="공지 추가"
          link={pagePaths.notification.create}
          active={true}
        />
      </ButtonWrapper>
      <LayoutWrapper>
        {isLoading
          ? null
          : tableData &&
            tableColumns && (
              <DefaultTable columns={tableColumns} data={tableData} />
            )}
      </LayoutWrapper>
    </Container>
  );
};

export default NotificationListContainer;
