import React, { useState } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

const Select = styled.select`
  all: unset;
  width: ${(props) => props.width}px;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 10px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${palette.fontBlack};
  opacity: 1;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

const Dropdown = ({
  data,
  editApi,
  refetch,
  initialValue,
  option,
  editField,
  preFunction = null,
  preFunctionField = null,
  isString = true,
}) => {
  const [result, setResult] = useState(initialValue);

  const handleChange = async (e) => {
    const postData = isString
      ? {
          ...data,
          [editField]: option[e.target.value],
        }
      : {
          ...data,
          [editField]: e.target.value,
        };

    if (preFunction) {
      let value = preFunction(parseInt(e.target.value));
      postData[preFunctionField] = value;
    }
    try {
      const response = await editApi(postData);
      if (!response.success) {
        console.log(response);
        alert(response.message);
      }
    } catch (e) {
      console.error(e);
      alert('에러가 발생했습니다.\n관리자에게 문의하세요.');
    }
    refetch();
  };

  return (
    <div style={{ display: 'flex' }}>
      <Select type="select" value={result} width={100} onChange={handleChange}>
        {option.map((item, index) => (
          <option value={index} key={item}>
            {item}
          </option>
        ))}
      </Select>
    </div>
  );
};
export default Dropdown;
