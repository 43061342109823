import React from 'react';

const Menu = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <g
          stroke="#A0A5BA"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
        >
          <path d="M2.5 10.5h15M2.5 5.5h15M9.5 15.5h8" />
        </g>
      </g>
    </svg>
  );
};

export default Menu;
