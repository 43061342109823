import { createContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { httpAPI } from 'modules/api/http';
import { apiPaths, pagePaths } from 'modules/defines/paths';

// the login context, carries current logged-in user, etc.
export const UserContext = createContext();

const defaultState = {
  loading: true,
  user: {
    user_id: '',
    name: '',
    role: '',
    email: '',
    phone: '',
  },
};

export function useUserState() {
  const userState = useState(defaultState);
  const cookieState = useCookies();
  useEffect(() => {
    authAPI.setupUserContext(userState, cookieState);
  }, []);
  return userState;
}
const AuthAPI = (() => {
  class AuthAPIConstructor {
    constructor() {
      this.user = {};
    }
    setupUserContext(userState, cookieState) {
      [this.userContext, this.setUserContext] = userState;
      [this.cookies, this.setCookie, this.removeCookie] = cookieState;
      httpAPI(apiPaths.checkAdmin, { method: 'GET' }).call.then((response) => {
        const { success, user } = response;
        if (!success) {
          this.setUserContext((prev) => ({ ...prev, loading: false }));
          if (window.location.hash.replace('#', '') !== pagePaths.auth.signIn) {
            window.location.href = '/';
          }
        } else {
          this.setUserContext((prev) => ({ ...prev, loading: false, user }));
          this.user.id = user.id;
          console.log(
            `User ${user.name} logged in via cookies, id=${user.user_id}`
          );
        }
      });

      return userState;
    }
    register(data) {
      return httpAPI(apiPaths.registerAdmin, { data: data }).call;
    }
    login(email, password) {
      const that = this;
      return new Promise((resolve, reject) => {
        const body = {
          email,
          password,
        };
        httpAPI(apiPaths.loginAdmin, { data: body })
          .call.then((response) => {
            if (response.success) {
              const user = response.user;
              that.loginSucceeded(user);
            }
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    }
    async logout() {
      try {
        const response = await httpAPI(apiPaths.logoutAdmin, { method: 'GET' })
          .call;
        if (response.success) {
          this.setUserContext((prev) => ({
            ...prev,
            ...defaultState,
            laoding: false,
          }));
          if (window.location.hash.replace('#', '') !== pagePaths.auth.signIn) {
            window.location.href = '/';
          }
        }
      } catch (err) {
        return console.log(err);
      }
    }
    loginSucceeded(user) {
      this.setUserContext((prev) => ({ ...prev, loading: false, user }));
      this.user.id = user.id;
    }
    editAdmin(data) {
      return httpAPI(apiPaths.editAdmin, { data: data }).call;
    }
  }
  return AuthAPIConstructor;
})();

const UserAPI = (() => {
  class UserAPIConstructor {
    getUserAll() {
      return httpAPI(apiPaths.getUserAll, { method: 'GET' }).call;
    }
    getUserSingle(id) {
      return httpAPI(apiPaths.getUserSingle + id, { method: 'GET' }).call;
    }
    getUserKeyword(id) {
      return httpAPI(apiPaths.getUserKeyword + id, { method: 'GET' }).call;
    }
    getUserViewLike(id) {
      return httpAPI(apiPaths.getUserViewLike + id, { method: 'GET' }).call;
    }
    getRecommendWeight() {
      return httpAPI(apiPaths.getUserWeight, { method: 'GET' }).call;
    }
    editRecommendWeight(data) {
      return httpAPI(apiPaths.editUserWeight, { data: data }).call;
    }
    getUserRecommend(id) {
      return httpAPI(apiPaths.getUserRecommend + id, { method: 'GET' }).call;
    }
  }
  return UserAPIConstructor;
})();

export const authAPI = new AuthAPI();
export const userAPI = new UserAPI();
