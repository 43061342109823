import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import {
  // AreaList,
  SignIn,
  SignUp,
  CurationList,
  CurationDetail,
  SpaceList,
  SpaceDetail,
  KeywordManagement,
  CustomKeywordList,
  CustomKeywordDetail,
  NotificationList,
  NotificationCreate,
  NotificationDetail,
  DocumentList,
  DocumentCreate,
  DocumentDetail,
  AdminList,
  UserList,
  UserDetail,
  FeedbackList,
  FeedbackDetail,
  PushNotificationList,
  PushNotificationDetail,
  EventList,
  EventDetail,
  LinkManagement,
  VersionList,
  Faview,
  FaviewSfM,
  ManagerList,
  ProductList,
  ProductDetail,
  FaviewRequestList,
} from 'pages';
import { admin_values, routes_permission_values } from 'modules/defines/values';
import { pagePaths } from 'modules/defines/paths';

import { UserContext } from 'modules/api/user';
import { useContext } from 'react';

// for temporary styling, will be moved.
import styled from 'styled-components';
import FilterManagement from 'pages/FilterManagement';
import DashboardData from 'pages/DashboardData';
import DashboardApp from 'pages/DashboardApp';
import StoreEditRequestList from 'pages/StoreEditRequestList';
import StoreOrderList from 'pages/StoreOrderList';
import FaviewData from 'pages/FaviewData';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  flex-grow: 1;
  flex-shrink: 1;
`;

const PrivateRoute = ({ component: Component, role, ...rest }) => {
  const location = useLocation();
  const permission = admin_values.role.includes(role);
  const routes_permission = permission
    ? routes_permission_values[role].includes(rest.path)
    : false;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!permission) {
          return (
            <Redirect
              to={{
                pathname: pagePaths.auth.signIn,
                state: { from: location },
              }}
            />
          );
        } else {
          if (!routes_permission) {
            alert('권한이 없습니다.');
            return <Redirect to={pagePaths.space.list} />;
          }
          return <Component {...props} />;
        }
      }}
    />
  );
};

const RouterComponent = () => {
  const [userContext] = useContext(UserContext);
  const role = userContext.user.role;
  return (
    <ContentWrapper>
      <Switch>
        <Route path={pagePaths.auth.signIn} exact component={SignIn} />
        <Route path={pagePaths.auth.signUp} exact component={SignUp} />

        <PrivateRoute
          role={role}
          path={pagePaths.user.dashboardData}
          exact
          component={DashboardData}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.user.dashboardApp}
          exact
          component={DashboardApp}
        />

        {/* 큐레이션 페이지 */}
        <PrivateRoute
          role={role}
          path={pagePaths.curation.list}
          exact
          component={CurationList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.curation.detail}
          exact
          component={CurationDetail}
        />
        {/* 공간 페이지 */}
        <PrivateRoute
          role={role}
          path={pagePaths.space.list}
          exact
          component={SpaceList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.space.detail}
          exact
          component={SpaceDetail}
        />
        {/* 필터 관리 페이지 */}
        <PrivateRoute
          role={role}
          path={pagePaths.keyword.filterList}
          exact
          component={FilterManagement}
        />
        {/* 키워드 관리 페이지 */}
        <PrivateRoute
          role={role}
          path={pagePaths.keyword.list}
          exact
          component={KeywordManagement}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.keyword.customList}
          exact
          component={CustomKeywordList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.keyword.customDetail}
          exact
          component={CustomKeywordDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.notification.list}
          exact
          component={NotificationList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.notification.create}
          exact
          component={NotificationCreate}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.notification.detail}
          exact
          component={NotificationDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.document.list}
          exact
          component={DocumentList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.document.create}
          exact
          component={DocumentCreate}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.document.detail}
          exact
          component={DocumentDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.user.adminList}
          exact
          component={AdminList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.user.list}
          exact
          component={UserList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.user.detail}
          exact
          component={UserDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.feedback.list}
          exact
          component={FeedbackList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.feedback.detail}
          exact
          component={FeedbackDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.push.list}
          exact
          component={PushNotificationList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.push.detail}
          exact
          component={PushNotificationDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.event.list}
          exact
          component={EventList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.event.detail}
          exact
          component={EventDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.managing.link}
          exact
          component={LinkManagement}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.managing.version}
          exact
          component={VersionList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.etc.threejs}
          exact
          component={Faview}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.etc.sfm}
          exact
          component={FaviewSfM}
        />

        <PrivateRoute
          role={role}
          path={pagePaths.store.managerList}
          exact
          component={ManagerList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.store.productList}
          exact
          component={ProductList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.store.productDetail}
          exact
          component={ProductDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.store.editRequestList}
          exact
          component={StoreEditRequestList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.store.editRequestDetail}
          exact
          component={FeedbackDetail}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.store.orderList}
          exact
          component={StoreOrderList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.faview.requestList}
          exact
          component={FaviewRequestList}
        />
        <PrivateRoute
          role={role}
          path={pagePaths.faview.data}
          exact
          component={FaviewData}
        />
        <Redirect from="" to={pagePaths.auth.signIn} />
      </Switch>
    </ContentWrapper>
  );
};

export default RouterComponent;
