import LinkManagementContainer from 'containers/link/LinkManagementContainer';
import GlobalContainer from 'containers/GlobalContainer';

const LinkManagement = () => {
  return (
    <GlobalContainer>
      <LinkManagementContainer />
    </GlobalContainer>
  );
};

export default LinkManagement;
