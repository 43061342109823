import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

export const LayoutWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
  row-gap: 20px;
  column-gap: 10px;
  width: 100%;
  height: calc(
    100vh - ${(props) => (props.fromBottom ? props.fromBottom : '160px')}
  );
  overflow: auto;

  .isMobile & {
    display: flex;
    padding: 0;
    flex-direction: column;
    height: 100%;
  }
`;

export const CommonContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${palette.white};
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  overflow: scroll;
  .isMobile & {
    width: 100%;
  }
`;

export const CommonWrapper = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 2px solid ${palette.borderOrange};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TitleRowWrapper = styled.div`
  // display: flex;
  // flex-direction: row;
`;

export const HeaderButtonRow = styled.div`
  .isMobile & {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .isDesktop & {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const HeaderButtonWrapper = styled.div`
  margin: 5px;
`;

export const RowLink = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
`;
