import React from 'react';
import { palette } from 'modules/defines/styles';

const Alarm = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="State=alarm">
        <g id="notification-svgrepo-com 1" clipPath="url(#clip0_1010_2213)">
          <g id="surface1">
            <path
              id="Vector"
              d="M10 2.5C9.4 2.5 8.875 3.025 8.875 3.625V4.15C6.475 4.675 4.75 6.7 4.75 9.25V12.625L3.25 14.35V15.25H16.75V14.35L15.25 12.625V9.25C15.25 6.7 13.525 4.675 11.125 4.15V3.625C11.125 3.025 10.6 2.5 10 2.5ZM8.5 16C8.5 16.825 9.175 17.5 10 17.5C10.825 17.5 11.5 16.825 11.5 16H8.5Z"
              fill="#A0A5BA"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1010_2213">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Alarm;
