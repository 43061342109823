import { useEffect, useRef, useState } from 'react';
import {
  Container as MapDiv,
  NaverMap,
  useNavermaps,
  Marker,
} from 'react-naver-maps';
import styled from 'styled-components';

function generateInfoWindowRightClick(point) {
  return `
    <div style="padding: 3px; font-size: 14px; background-color: white; 
                border-radius: 5px; border: 1px solid #ccc; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
      <p style="margin: 0;"></p>
      <div style="max-height: 200px; overflow-y: scroll;">
        <ul style="list-style-type: none; padding: 0;">
        
        <li style="margin-bottom: 5px;">
          <label 
            for="upload-image-marker" 
            style="
              display: block;
              padding: 10px; 
              background-color: #f9f9f9; 
              border: 1px solid #ccc; 
              border-radius: 5px; 
              text-align: center; 
              cursor: pointer; 
              font-size: 14px;
            "
          >
            사진 변경
            <input
              id="upload-image-marker"
              type="file"
              style="display: none;"
              onchange="changePanoramaImage(event, ${point.id})"
            />
          </label>
        </li>
        <li>
          <button
              style="
                width: 100%; 
                padding: 10px; 
                background-color: #f9f9f9; 
                border: 1px solid #ccc; 
                border-radius: 5px; 
                text-align: left; 
                cursor: pointer; 
                font-size: 14px;
                display: flex; 
                align-items: center;
                justify-content: space-between;
              "
                onclick="removePanorama(${point.id})"
            >
              <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 100%;">삭제하기</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  `;
}

function generateInfoWindowContentFromMarker(point, originalPoint) {
  if (originalPoint) {
    return `
    <div style="position: relative; padding: 15px; font-size: 14px; background-color: white; 
            border: 1px solid #ccc; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
  <button style="position: absolute; top: 5px; right: 5px; border: none; background: none; 
                 cursor: pointer; font-size: 16px;" 
          onclick="closeInfoWindow()">X</button>
  
  <!-- ID 및 방향 정보 -->
  <h3 style="margin: 0; font-size: 16px;">
    ${point.id} 
    &nbsp;&nbsp;&nbsp;&nbsp; 
    ${point.direction === 0 ? `` : `<span>설정각: ${point.direction}</span>`}
  </h3>

  <!-- 첫 번째 Row: 토글 버튼과 공간뷰어 버튼 -->
  <div style="margin-top: 10px; display: flex; align-items: center; gap: 10px;">
    <label style="display: flex; align-items: center; gap: 5px;">
      <input type="checkbox" ${point.is_on ? 'checked' : ''} 
             onchange="toggleIsOn(${point.id}, this.checked)" />
      <span>활성화</span>
    </label>
    <button onclick="showViewer('${point.id}')">공간뷰어</button>
  </div>

  <!-- 두 번째 Row: 리셋, 변경, 시작포인트 지정 버튼 -->
  <div style="margin-top: 10px; display: flex; gap: 10px;">
    <button onclick="handleResetPosition(${point.id})">리셋</button>
    <button onclick="updateMarkerPosition(${point.id}, ${point.latitude}, ${
      point.longitude
    })">변경</button>
    <button onclick="editStartingPoint('${point.id}')">시작포인트 지정</button>
  </div>
</div>
  `;
  } else {
    return `<div style="padding: 15px; font-size: 14px; background-color: white; 
                    border-radius: 0; border: 1px solid #ccc; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
          <button style="position: absolute; top: 5px; right: 5px; border: none; background: none; 
                cursor: pointer; font-size: 16px;" 
                  onclick="closeInfoWindow()">X</button>
          <h3 style="margin: 0; font-size: 16px;">${
            point.id
          } &nbsp&nbsp&nbsp&nbsp ${
      point.direction === 0 ? `` : `<span>설정각: ${point.direction}</span>`
    }</h3>
      <div style="margin-top: 10px; display: flex; align-items: center; gap: 10px;">
        <label style="display: flex; align-items: center; gap: 5px;">
          <input type="checkbox" ${point.is_on ? 'checked' : ''} 
                onchange="toggleIsOn(${point.id}, this.checked)" />
          <span>활성화</span>
        </label>
      </div>
      <div style="margin-top: 10px; display: flex; gap: 10px;">
        <button onclick="editStartingPoint('${
          point.id
        }')">시작포인트 지정</button> 
        <button onclick="showViewer('${point.id}')">공간뷰어</button>
      </div>
    </div>`;
  }
}

function generateInfoWindowContentFromMap(lat, lng, itemsWithoutLatLong) {
  // 스크롤 리스트 형태로 아이템을 표시
  let itemListContent = `
      <div style="margin-top: 5px; max-height: 200px; overflow-y: scroll;">
        <ul style="list-style-type: none; padding: 0;">
        <li style="margin: 5px 0;">
        <label 
            for="upload-image-new" 
            style="
              display: block;
              padding: 10px; 
              background-color: #f9f9f9; 
              border: 1px solid #ccc; 
              border-radius: 5px; 
              text-align: center; 
              cursor: pointer; 
              font-size: 14px;
            "
          >
            파노라마 생성
            <input
              id="upload-image-new"
              type="file"
              style="display: none;"
              onchange="uploadPanorama(event, ${lat}, ${lng})"
            />
          </label>
        </li>
          ${itemsWithoutLatLong
            .map((item) => {
              const fileName = item.img_thumbnail.split('/').pop();
              return `
                <li style="margin: 5px 0;">
                  <button 
                    style="
                      width: 100%; 
                      padding: 10px; 
                      background-color: #f9f9f9; 
                      border: 1px solid #ccc; 
                      border-radius: 5px; 
                      text-align: left; 
                      cursor: pointer; 
                      font-size: 14px;
                      display: flex; 
                      align-items: center;
                      justify-content: space-between;
                    "
                    onclick="updateMarkerPosition(${item.id}, ${lat}, ${lng})"
                  >
                    <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 70%;">${fileName}</span>
                    <span style="font-weight: bold;">ID: ${item.id}</span>
                  </button>
                </li>
              `;
            })
            .join('')}
        </ul>
      </div>
    `;

  return `
    <div style="padding: 15px; font-size: 14px; background-color: white; 
                border-radius: 5px; border: 1px solid #ccc; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
                <button style="position: absolute; top: 5px; right: 5px; border: none; background: none; 
                         cursor: pointer; font-size: 16px;" 
                  onclick="closeInfoWindow()">X</button>
      <p style="margin: 0;">파노라마 선택</p>
      ${itemListContent}
    </div>
  `;
}

const NaverMapLayout = ({
  data,
  currentPosition,
  editLatLong,
  editStartingPoint,
  showViewer,
  uploadPanorama,
  changePanoramaImage,
  removePanorama,
  toggleIsOn,
}) => {
  const navermaps = useNavermaps();
  const [loading, setLoading] = useState(true);
  const mapRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const infoWindowRef = useRef(null);
  const [defaultPosition, setDefaultPosition] = useState({
    latitude: currentPosition.lat,
    longitude: currentPosition.lng,
  });
  const [panoPoints, setPanoPoints] = useState(data);
  const [updatedPoints, setUpdatedPoints] = useState(data);

  const [clickedMarkers, setClickedMarkers] = useState();
  let dragEndTimeout;

  const findClosestPoint = (latitude, longitude) => {
    let minDistance = Number.MAX_VALUE;
    let minIndex = 0;

    panoPoints.forEach((point, index) => {
      const distance = Math.sqrt(
        Math.pow(latitude - point.latitude, 2) +
          Math.pow(longitude - point.longitude, 2)
      );
      if (distance < minDistance) {
        minDistance = distance;
        minIndex = index;
      }
    });
  };
  useEffect(() => {
    infoWindowRef.current = new navermaps.InfoWindow({
      content: '',
      borderWidth: 1,
      maxWidth: 300,
      disableAnchor: true,
    });

    window.handleResetPosition = (pointId) => {
      closeInfoWindow();
      handleResetPosition(pointId);
    };

    window.updateMarkerPosition = (pointId, latitude, longitude) => {
      closeInfoWindow();
      updateMarkerPosition(pointId, latitude, longitude);
    };
    window.showViewer = (pointId) => {
      showViewer(pointId);
    };
    window.editStartingPoint = (pointId) => {
      closeInfoWindow();
      editStartingPoint(pointId);
    };
    window.closeInfoWindow = () => {
      closeInfoWindow();
    };
    window.uploadPanorama = (e, latitude, longitude) => {
      closeInfoWindow();
      uploadPanorama(e, latitude, longitude);
    };
    window.changePanoramaImage = (e, pointId) => {
      closeInfoWindow();
      changePanoramaImage(e, pointId);
    };
    window.removePanorama = (pointId) => {
      closeInfoWindow();
      removePanorama(pointId);
    };
    window.toggleIsOn = (pointId) => {
      toggleIsOn(pointId);
    };

    return () => {
      if (infoWindowRef.current) {
        infoWindowRef.current.setMap(null);
      }
      delete window.showViewer;
      delete window.handleResetPosition;
      delete window.updateMarkerPosition;
      delete window.editStartingPoint;
      delete window.closeInfoWindow;
      delete window.uploadPanorama;
      delete window.changePanoramaImage;
      delete window.removePanorama;
      delete window.toggleIsOn;
    };
  }, []);

  // Map 정보 세팅
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setOptions({
        minZoom: 13,
        maxZoom: 20,
        boundaryOffset: 0,
      });
    }
  }, [mapRef]);

  // 현위치 트래킹
  useEffect(() => {
    if (currentPosition && mapRef.current) {
      mapRef.current.panTo(
        new navermaps.LatLng(
          currentPosition.latitude,
          currentPosition.longitude
        )
      );
      findClosestPoint(currentPosition.latitude, currentPosition.longitude);
    }
  }, [currentPosition]);

  useEffect(() => {
    setPanoPoints(data);
    setUpdatedPoints(data);
    const validData = data.filter(
      (item) => item.latitude !== null && item.longitude !== null
    );

    const total = validData.reduce(
      (acc, item) => {
        acc.latitude += parseFloat(item.latitude);
        acc.longitude += parseFloat(item.longitude);
        return acc;
      },
      { latitude: 0, longitude: 0 }
    );
    if (total.latitude !== 0 && total.longitude !== 0 && validData.length > 0) {
      setDefaultPosition({
        latitude: total.latitude / validData.length,
        longitude: total.longitude / validData.length,
      });
    }
    setLoading(false);
  }, [data]);

  const handleMapClick = (e) => {
    const itemsWithoutLatLong = data.filter(
      (item) => item.latitude === null || item.longitude === null
    );
    // if (itemsWithoutLatLong.length > 0) {
    if (e.type) {
      const lat = e.coord.lat();
      const lng = e.coord.lng();

      const infoWindow = infoWindowRef.current;
      if (infoWindow) {
        infoWindow.setPosition(new navermaps.LatLng(lat + 0.00002, lng));
        infoWindow.setContent(
          generateInfoWindowContentFromMap(lat, lng, itemsWithoutLatLong)
        );
        infoWindow.open(mapRef.current);
        setClickedMarkers({ latitude: lat, longitude: lng });
      }
    } else {
      const lat = clickedMarkers.latitude;
      const lng = clickedMarkers.longitude;

      const infoWindow = infoWindowRef.current;
      if (infoWindow) {
        infoWindow.setPosition(new navermaps.LatLng(lat + 0.00002, lng));
        infoWindow.setOptions({
          pixelOffset: new navermaps.Point(0, 0),
        });
        infoWindow.setContent(
          generateInfoWindowContentFromMap(lat, lng, itemsWithoutLatLong)
        );
        infoWindow.open(mapRef.current);
        setClickedMarkers({ latitude: lat, longitude: lng });
      }
    }
    // }
  };

  const updateMarkerPosition = async (pointId, latitude, longitude) => {
    const updatedPoint = updatedPoints.find((point) => point.id === pointId);
    if (updatedPoint) {
      await editLatLong([
        {
          id: pointId,
          latitude: latitude,
          longitude: longitude,
        },
      ]);
    }
  };

  const handleResetPosition = (pointId) => {
    const originalPoint = panoPoints.find((point) => point.id === pointId);
    setUpdatedPoints((prevPoints) => {
      return prevPoints.map((point) =>
        point.id === pointId
          ? {
              ...point,
              latitude: originalPoint.latitude,
              longitude: originalPoint.longitude,
            }
          : point
      );
    });
  };
  const handleMarkerClick = (point) => {
    const infoWindow = infoWindowRef.current;
    if (infoWindow) {
      closeInfoWindow();
      const originalPoint = panoPoints.find((item) => item.id === point.id);
      const isSamePosition =
        originalPoint.latitude === point.latitude &&
        originalPoint.longitude === point.longitude;

      if (isSamePosition) {
        infoWindow.setPosition(
          new navermaps.LatLng(point.latitude, point.longitude)
        );
        infoWindow.setOptions({
          pixelOffset: new navermaps.Point(0, 0),
        });
        infoWindow.setContent(generateInfoWindowContentFromMarker(point));
        infoWindow.open(mapRef.current);
      } else {
        infoWindow.setPosition(
          new navermaps.LatLng(point.latitude, point.longitude)
        );
        infoWindow.setOptions({
          pixelOffset: new navermaps.Point(0, 0),
        });
        infoWindow.setContent(
          generateInfoWindowContentFromMarker(point, originalPoint)
        );
        infoWindow.open(mapRef.current);
      }
    }
  };
  const handleMarkerRightClick = (e, point) => {
    const infoWindow = infoWindowRef.current;
    const marker = e.overlay;
    if (infoWindow) {
      closeInfoWindow();

      infoWindow.setContent(generateInfoWindowRightClick(point));
      infoWindow.setOptions({
        pixelOffset: new navermaps.Point(50, 100),
      });

      infoWindow.open(mapRef.current, marker);
    }
  };

  const handleMarkerDragEnd = (e, pointId) => {
    const infoWindow = infoWindowRef.current;

    const newLat = e.coord.lat();
    const newLng = e.coord.lng();

    setUpdatedPoints((prevPoints) => {
      const updatedPoints = prevPoints.map((point) =>
        point.id === pointId
          ? { ...point, latitude: newLat, longitude: newLng }
          : point
      );

      const point = updatedPoints.find((point) => point.id === pointId);
      const originalPoint = panoPoints.find((point) => point.id === pointId);
      if (point && infoWindow) {
        infoWindow.setPosition(new navermaps.LatLng(newLat, newLng));
        infoWindow.setOptions({
          pixelOffset: new navermaps.Point(0, 0),
        });
        infoWindow.setContent(
          generateInfoWindowContentFromMarker(point, originalPoint)
        );
        infoWindow.open(mapRef.current);
      }

      return updatedPoints;
    });

    if (dragEndTimeout) {
      clearTimeout(dragEndTimeout);
    }

    // 1초 후에 드래그 상태를 false로 설정
    dragEndTimeout = setTimeout(() => {
      setIsDragging(false);
    }, 500);
  };

  // 닫기 버튼의 핸들러 함수
  function closeInfoWindow() {
    const infoWindow = infoWindowRef.current;
    if (infoWindow) {
      infoWindow.close();
    }
  }
  return (
    <Container>
      <MapDiv
        style={{
          width: '100%',
          height: 'calc(100% - 20px)',
        }}
      >
        {!loading && (
          <NaverMap
            ref={mapRef}
            defaultCenter={
              new navermaps.LatLng(
                defaultPosition.latitude,
                defaultPosition.longitude
              )
            }
            defaultZoom={17}
            draggable={true}
            onClick={(e) => handleMapClick(e)}
            onCenterChanged={() => {
              if (!isDragging) {
                closeInfoWindow();
              }
            }}
          >
            {updatedPoints.map(
              (point) =>
                point.latitude &&
                point.longitude && (
                  <Marker
                    key={point.id}
                    position={
                      new navermaps.LatLng(point.latitude, point.longitude)
                    }
                    icon={{
                      content: point.is_on
                        ? `<div style="
                    width: 30px;
                    height: 30px;
                    background-image: url(${point.img_thumbnail});
                    background-size: cover;
                    background-position: center;
                    border-radius: 10px;
                    border: ${point.is_starting ? '4px solid #000000' : 'none'},
                  "></div>`
                        : `<div style="
                    width: 30px;
                    height: 30px;
                    background-color: rgba(0, 255, 255, 1);
                    border-radius: 10px;
                    border: ${
                      point.is_starting
                        ? '4px solid #000000'
                        : '2px solid #000000'
                    },
                  "></div>`,
                      anchor: new navermaps.Point(10, 10),
                    }}
                    draggable={true}
                    onDragstart={() => {
                      closeInfoWindow();
                      setIsDragging(true);
                    }}
                    onDragend={(e) => handleMarkerDragEnd(e, point.id)}
                    onClick={() => handleMarkerClick(point)}
                    onRightclick={(e) => handleMarkerRightClick(e, point)}
                  />
                )
            )}
            {clickedMarkers && (
              <Marker
                key={'clicked'}
                position={
                  new navermaps.LatLng(
                    clickedMarkers.latitude,
                    clickedMarkers.longitude
                  )
                }
                icon={{
                  content: `<div style="
                    width: 20px;
                    height: 20px;
                    background-color: rgba(0, 128, 255, 0.7);
                    border-radius: 50%;
                    border: 2px solid #0078ff;
                  "></div>`,
                  anchor: new navermaps.Point(10, 10),
                }}
                onClick={(e) => handleMapClick(e)}
              />
            )}
          </NaverMap>
        )}
      </MapDiv>
    </Container>
  );
};

const Container = styled.div`
  width: ${(props) => (props.isViewerOpen ? '1000px' : '500px')};
  height: 500px;
  display: flex;
  flex-direction: row;
  transition: width 0.3s ease; /* 부드러운 전환 효과 */
`;

export default NaverMapLayout;
