import OrderListContainer from 'containers/store/OrderListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const StoreOrderList = () => {
    return (
        <GlobalContainer>
            <OrderListContainer />
        </GlobalContainer>
    );
};

export default StoreOrderList;
