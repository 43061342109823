import DocumentDetailContainer from 'containers/document/DocumentDetailContainer';
import GlobalContainer from 'containers/GlobalContainer';

const DocumentDetail = () => {
  return (
    <GlobalContainer>
      <DocumentDetailContainer />
    </GlobalContainer>
  );
};

export default DocumentDetail;
