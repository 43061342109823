import { useModal } from 'modules/customHooks/useModal';
import { createContext } from 'react';
import ModalPortal from 'components/modal/ModalPortal';

let ModalContext;
let { Provider } = (ModalContext = createContext());

let ModalProvider = ({ children }) => {
  let { modal, openModal, closeModal, modalContent } = useModal();
  return (
    <Provider value={{ modal, openModal, closeModal, modalContent }}>
      <ModalPortal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
