import styled from 'styled-components';
import { btn_curation_off, btn_curation_on } from 'assets/img/button';

const PublishButtonWrapper = styled.div`
  width: 39px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  background-image: url(${(props) =>
    props.published ? btn_curation_on : btn_curation_off});
`;

const PublishButton = ({
  editApi,
  published,
  data,
  refetch,
  editField = null,
  editValue = null,
  blockCond = false,
  blockMessage = '상태를 변경할 수 없습니다!',
}) => {
  const onClick = async () => {
    if (blockCond) {
      alert(blockMessage);
      return;
    }
    const postData = editField
      ? {
          ...data,
          [editField]: editValue,
        }
      : {
          ...data,
          publish: !data.publish,
        };
    try {
      await editApi(postData);
    } catch (e) {
      console.error(e);
      alert('Publish하는 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.');
    }
    refetch();
  };

  return <PublishButtonWrapper published={published} onClick={onClick} />;
};

export default PublishButton;
