import VersionListContainer from 'containers/version/VersionListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const VersionList = () => {
  return (
    <GlobalContainer>
      <VersionListContainer />
    </GlobalContainer>
  );
};

export default VersionList;
