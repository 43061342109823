import { useMemo, useState, useContext, useEffect } from 'react';
import { GlobalContext } from 'modules/context/GlobalContext';
import { useHistory } from 'react-router';
import { keywordAPI } from 'modules/api';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { ButtonCreate } from 'components/common/DefaultButton';
import {
  Container,
  Title,
  SubTitle,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import {
  TitleWrapper,
  TitleRowWrapper,
  HeaderButtonRow,
  HeaderButtonWrapper,
} from 'components/curation/CurationCommon';
import DefaultTable from 'components/table/DefaultTable';
import { pagePaths } from 'modules/defines/paths';
import { UserContext } from 'modules/api/user';

const CustomKeywordListContainer = () => {
  const history = useHistory();
  const globalState = useContext(GlobalContext);
  const [userContext] = useContext(UserContext);
  const havePermission = userContext.user.role.match('admin') !== null;

  const refetchKeyword = () => {
    globalState.refetch('keyword');
  };

  const customKeywordTableData = useMemo(() => {
    if (globalState.keyword === null) {
      return [];
    }
    return globalState.keyword
      .filter((itemFilter) => {
        return itemFilter.std !== 'vibe' && itemFilter.std !== 'product';
      })
      .map((item) => {
        return {
          ...item,
          space_count: item.space.length,
          curation_count: item.curation.length,
          high_count: item.high_keywords.length,
          low_count: item.low_keywords.length,
        };
      });
  }, [globalState.keyword]);

  const customKeywordTableColumn = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      align: 'center',
      Filter: false,
      width: 80,
    },
    {
      accessor: 'title',
      Header: '키워드',
      align: 'center',
      Filter: false,
      width: 120,
    },
    {
      accessor: 'std',
      Header: 'Standard',
      align: 'center',
      Filter: false,
      width: 80,
    },
    {
      accessor: 'category',
      Header: 'Category',
      align: 'center',
      Filter: false,
      width: 80,
    },
    {
      accessor: 'high_count',
      Header: '상위 키워드',
      align: 'center',
      Filter: false,
    },
    {
      accessor: 'low_count',
      Header: '하위 키워드',
      align: 'center',
      Filter: false,
    },
    {
      accessor: 'space_count',
      Header: '공간 카운트',
      align: 'center',
      Filter: false,
    },
    {
      accessor: 'curation_count',
      Header: '큐레이션 카운트',
      align: 'center',
      Filter: false,
    },
  ]);

  const createSubmit = async (e) => {
    e.preventDefault();
    if (havePermission) {
      try {
        const res = await keywordAPI.registerKeyword({
          title: '-',
          std: 'custom',
          category: '-',
        });
        if (!res.success) {
          alert(res.message);
        } else {
          refetchKeyword();
          history.push(
            pagePaths.keyword.customDetail.replace(':id', res.data.id)
          );
        }
      } catch (err) {
        console.error(err);
        alert('키워드 추가 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
      }
    } else {
      alert('권한이 없습니다.');
    }
  };

  const onClickKeywordItem = (e, id) => {
    e.preventDefault();
    history.push(pagePaths.keyword.customDetail.replace(':id', id));
  };

  return (
    <Container>
      <TitleWrapper>
        <TitleRowWrapper>
          <Title>커스텀 키워드 관리</Title>
          <SubTitle>
            커스텀 키워드 디테일 페이지로 이동하거나 새로운 키워드를 추가하고
            편집할 수 있습니다.
          </SubTitle>
        </TitleRowWrapper>
        <HeaderButtonRow>
          <HeaderButtonWrapper>
            <ButtonCreate
              text={'커스텀 키워드 추가'}
              active={false}
              onClick={createSubmit}
            />
          </HeaderButtonWrapper>
        </HeaderButtonRow>
      </TitleWrapper>
      <LayoutWrapper>
        <KeywordWrapper>
          {customKeywordTableData && (
            <TableContainer>
              <TableWrapper>
                <DefaultTable
                  columns={customKeywordTableColumn}
                  data={customKeywordTableData}
                  onClickRow={(e, row) => onClickKeywordItem(e, row.values.id)}
                  halfSize
                  defaultWidth={100}
                />
              </TableWrapper>
            </TableContainer>
          )}
        </KeywordWrapper>
      </LayoutWrapper>
    </Container>
  );
};

const KeywordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableSubtitle = styled.div`
  font-family: Pretendard-sb;
  font-size: 18px;
  color: ${(props) => (props.color ? props.color : palette.fontDefault)};
  margin-bottom: 16px;
  margin-right: 20px;
`;
const TableSubtitleEditMode = styled.input`
  all: unset;
  border: 1px solid ${palette.borderDefault};
  text-align: left;
  padding: 2px;
  font-size: 16px;
  color: ${palette.fontBlack};
  margin-right: 8px;
  margin-bottom: 12px;
`;

const KeywordBtn = styled.div`
  background-color: ${(props) =>
    props.color ? props.color : palette.mainBlue};
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 10px;
  height: 20px;
  margin-right: 12px;
  color: ${palette.fontWhite};
  font-family: Pretendard-m;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.colorHover ? props.colorHover : palette.mainBlueHover};
  }
`;

const TableWrapper = styled.div`
  height: 100%;
  overflow: scroll;
  margin-bottom: 20px;
`;
const DetailSection = styled.div`
  margin-left: 40px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ElementWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
const ElementWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  &:hover {
    background-color: ${palette.mainBlueExtraLight};
  }
  cursor: pointer;
  padding: 4px 8px;
`;
const DetailSubtitle = styled.div`
  text-align: left;
  font-size: 15px;
  font-family: Pretendard-b;
  letter-spacing: 0px;
  color: ${palette.fontDefault};
  margin-bottom: 8px;
`;
const DetailLabel = styled.div`
  font-size: 11px;
  font-family: Pretendard-el;
  color: ${palette.fontDefault};
  width: 36px;
`;
const DetailValue = styled.div`
  font-size: 14px;
  font-family: Pretendard-l;
`;
const ListSection = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: row;
  height: 100%;
  border: 1px solid ${palette.borderDefault};
  padding: 12px;
  border-radius: 12px;
`;
const ListColumn = styled.div``;

const DescTextWrapper = styled.div`
  font-size: 12px;
  color: ${palette.fontDefault};
`;

export default CustomKeywordListContainer;
