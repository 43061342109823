/* eslint-disable no-restricted-globals */
import { useEffect, useState, useCallback, Suspense } from 'react';
import styled from 'styled-components';
import { spaceAPI } from 'api';
import { panoramaAPI } from 'modules/api';
import useInputState from 'modules/customHooks/useInputState';
import { TailSpin } from 'react-loader-spinner';
import { palette } from 'modules/defines/styles';
import { panorama_values } from 'modules/defines/values';
import { useQuery } from '@tanstack/react-query';
import { icon_imgadd, icon_trashbin, icon_plus } from 'assets/img/icon';
import { toRelativeCoordinates, calculateDistance } from 'utils/globalFunction';
import NaverMapLayout from 'components/panorama/NaverMapLayout';
import Minimap from 'components/panorama/minimap';
import EquirectangularViewer from 'components/panorama/EquirectangularViewer';

const PanoramaNewModal = ({ space, closeCurrentModal, defaultFloor }) => {
  const [showContext, setShowContext] = useState(false);
  // viewer state
  const [isViewerVisible, setIsViewerVisible] = useState(false);
  const [viewerItem, setViewerItem] = useState(null);
  const handleClick = useCallback(
    () => (showContext ? setShowContext(false) : null),
    [showContext]
  );
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.code === 'Escape') {
        let willCloseModal = true;
        if (isViewerVisible) {
          willCloseModal = false;
          setIsViewerVisible(false);
        }
        if (showContext) {
          willCloseModal = false;
          setShowContext(false);
        }
        if (willCloseModal) {
          closeCurrentModal();
        }
      }
    },
    [showContext, isViewerVisible]
  );
  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleClick);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleClick);
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  // API : Load PanoramaData
  const [dataLoading, setDataLoading] = useState(true);

  const fetchList = (id) => {
    const { call, cancel } = panoramaAPI.getPanorama(id);
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['panorama', space.id],
    queryFn: () => fetchList(space.id),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });
  useEffect(() => {
    if (!isLoading) {
      setDataLoading(false);
    }
  }, [isLoading]);

  // Global Value : Current Floor
  const [floorDropdownData, setFloorDropdownData] = useState(
    panorama_values.floor
  );

  const [viewMode, setViewMode] = useState('미니맵');

  const {
    state: floor,
    setState: setFloor,
    onChange: floorOnChange,
  } = useInputState(1);
  const [isSubFloor, setIsSubFloor] = useState(false);
  // Global Value : Current Floor List
  const [floorList, setFloorList] = useState([]);
  // Global Value : Panorama Images
  const [floorPanorama, setFloorPanorama] = useState([]);
  // Position Value : category
  const [categoryDropdownData] = useState(panorama_values.category);
  const [subCategoryDropdownData] = useState(panorama_values.subcategory);
  const [typeDropdownData] = useState(panorama_values.type);

  // 파노라마 정보 수정 패널 세팅
  const [showDataEditPanel, setShowDataEditPanel] = useState(false);
  // 우클릭 삭제를 위한 세팅
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [coordinateContext, setCoordinateContext] = useState({ x: 0, y: 0 });
  const [idSelectedContext, setIdSelectedContext] = useState(0);
  const [imageLinkContext, setImageLinkContext] = useState('');
  const [idSelected, setIdSelected] = useState(0);
  const [coordinate, setCoordinate] = useState({ x: 0, y: 0 });
  const [spotRelationBlock, setSpotRelationBlock] = useState([]);
  const [spotRelationConnect, setSpotRelationConnect] = useState([]);
  const [spotExternalConnect, setSpotExternalConnect] = useState([]);
  const [spotMediaConnect, setSpotMediaConnect] = useState([]);
  const [isHoveringHelp, setIsHoveringHelp] = useState(-1);
  const [mediaImage, setMediaImage] = useState();
  const [mediaVideo, setMediaVideo] = useState();
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const {
    state: roomCategory,
    setState: setRoomCategory,
    onChange: roomCategoryOnChange,
  } = useInputState('');
  const {
    state: type,
    setState: setType,
    onChange: typeOnChange,
  } = useInputState('');
  const {
    state: viewDistance,
    setState: setViewDistance,
    onChange: viewDistanceOnChange,
  } = useInputState('');
  const {
    state: roomName,
    setState: setRoomName,
    onChange: roomNameOnChange,
  } = useInputState('');
  const {
    state: title,
    setState: setTitle,
    onChange: titleOnChange,
  } = useInputState('');
  const {
    state: mediaSize,
    setState: setMediaSize,
    onChange: mediaSizeOnChange,
  } = useInputState('');
  const {
    state: desc,
    setState: setDesc,
    onChange: descOnChange,
  } = useInputState('');
  const {
    state: globalRoomName,
    setState: setGlobalRoomName,
    onChange: globalRoomNameOnChange,
  } = useInputState('');
  const {
    state: globalDirection,
    setState: setGlobalDirection,
    onChange: globalDirectionOnChange,
  } = useInputState('');
  const {
    state: newFloor,
    setState: setNewFloor,
    onChange: newFloorOnChange,
  } = useInputState('');
  // Position Value (Relation) : Block
  const {
    state: relationBlockAttachValue,
    setState: setRelationBlockAttachValue,
    onChange: relationBlockAttachValueOnChange,
  } = useInputState('');
  // Position Value (Relation) : Connect
  const {
    state: relationConnectValDir1,
    setState: setRelationConnectValDir1,
    onChange: relationConnectValDir1OnChange,
  } = useInputState('');
  const {
    state: relationConnectValDir2,
    setState: setRelationConnectValDir2,
    onChange: relationConnectValDir2OnChange,
  } = useInputState('');
  const {
    state: relationConnectAttachValue,
    setState: setRelationConnectAttachValue,
    onChange: relationConnectAttachValueOnChange,
  } = useInputState('');

  const {
    state: relationExternalValDir1,
    setState: setRelationExternalValDir1,
    onChange: relationExternalValDir1OnChange,
  } = useInputState('');
  const {
    state: relationExternalValDir2,
    setState: setRelationExternalValDir2,
    onChange: relationExternalValDir2OnChange,
  } = useInputState('');
  const {
    state: relationExternalAttachValue,
    setState: setRelationExternalAttachValue,
    onChange: relationExternalAttachValueOnChange,
  } = useInputState('');
  const {
    state: relationExternalSpaceIdValue,
    setState: setRelationExternalSpaceIdValue,
    onChange: relationExternalSpaceIdValueOnChange,
  } = useInputState('');
  const {
    state: latitude,
    setState: setLatitude,
    onChange: latitudeOnChange,
  } = useInputState('');
  const {
    state: longitude,
    setState: setLongitude,
    onChange: longitudeOnChange,
  } = useInputState('');
  const {
    state: relationPanoVal,
    setState: setRelationPanoVal,
    onChange: relationPanoValOnChange,
  } = useInputState('');

  const [isUploadSectionVisible, setIsUploadSectionVisible] = useState(false);

  const toggleUploadSection = () => {
    setIsUploadSectionVisible((prev) => !prev);
  };

  useEffect(() => {
    let checkImage = [];
    data?.map((item) => {
      if (item.floor == floor) {
        checkImage.push(item);
      }
    });
    setFloorPanorama(checkImage);
    setGlobalDirection(checkImage[0]?.global_direction);
    setFloorList([]);
    if (
      floorList.length === 0 &&
      defaultFloor !== null &&
      defaultFloor.length !== 0
    ) {
      setFloor(defaultFloor);
    }

    data?.forEach((item) => {
      setFloorList((prevState) => {
        if (!prevState.includes(item.floor)) {
          return [...prevState, item.floor];
        } else {
          return prevState;
        }
      });
    });
    if (Number.isInteger(Number.parseFloat(floor))) {
      setIsSubFloor(false);
    } else {
      setIsSubFloor(true);
      const roomNameForSubFloor = checkImage[0]?.room_name;
      if (roomNameForSubFloor === undefined || roomNameForSubFloor === null) {
        setGlobalRoomName('');
      } else {
        setGlobalRoomName(roomNameForSubFloor);
      }
    }
  }, [data, floor]);

  useEffect(() => {
    setRoomName(globalRoomName);
  }, [globalRoomName]);

  useEffect(() => {
    for (var floor of floorList) {
      const subFloor = Number.parseFloat(Number.parseFloat(floor).toFixed(1));
      if (!floorDropdownData.includes(subFloor)) {
        setFloorDropdownData((prev) => [...prev, subFloor]);
      }
    }
  }, [floorList]);

  const onClickFloorList = (floorClicked) => {
    setFloor(floorClicked);
  };

  const addSubFloorListener = (e) => {
    e.preventDefault();
    if (floor != 99) {
      var subFloorAdded = 0;
      if (floor > 0) {
        subFloorAdded = Number.parseFloat(
          (Number.parseFloat(floor) + 0.1).toFixed(1)
        );
      } else {
        subFloorAdded = Number.parseFloat(
          (Number.parseFloat(floor) - 0.1).toFixed(1)
        );
      }
      if (!floorDropdownData.includes(subFloorAdded)) {
        setFloorDropdownData((prev) => [...prev, subFloorAdded]);
      }
      setFloor(subFloorAdded);
    } else {
      alert('외부 층은 나눌 수 없습니다.');
    }
  };

  const uploadImageListener = async (e) => {
    let postDataWithImage = new FormData();
    postDataWithImage.append('img', e.target.files[0]);
    let headers = {
      panoid: idSelected,
      id: space.id,
    };
    try {
      let response = await spaceAPI.uploadPanonamaMediaImage(
        postDataWithImage,
        headers
      );
      setMediaImage(response.data.image);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 미디어 이미지 업로드 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    } finally {
      refetch();
    }
  };

  const deleteMediaImage = async (e) => {
    if (!window.confirm('해당 파노라마 미디어 이미지를 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: idSelected,
    };
    let error = false;
    try {
      const response = await spaceAPI.deletePanoramaMediaImage(postData);
      setMediaImage(response.data.image);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      refetch();
    }
  };

  const uploadVideoPromise = (formData, headers) => {
    return new Promise((resolve, reject) => {
      spaceAPI
        .uploadPanonamaMediaVideo(formData, headers)
        .then((response) => resolve(response))
        .catch((e) => {
          reject(e);
        });
    });
  };

  const uploadVideo = async (e) => {
    setVideoUploadLoading(true);
    let postData = new FormData();
    var temp_file = e.target.files[0];
    let blob = temp_file.slice(0, temp_file.size, temp_file.type);
    postData.append(
      'video',
      new File([blob], idSelected + '_video.' + temp_file.name.split('.').pop())
    );
    let headers = {
      panoid: idSelected,
      id: space.id,
    };
    let error = false;
    try {
      await uploadVideoPromise(postData, headers).then((response) => {
        if (response.data) {
          setMediaVideo(response.data.video);
        } else {
          alert(
            '미디어 동영상 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
      });
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '미디어 동영상을 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }

      setVideoUploadLoading(false);
    }
  };

  const deleteMediaVideo = async (e) => {
    if (!window.confirm('해당 파노라마 미디어 동영상을 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: idSelected,
    };
    let error = false;
    try {
      const response = await spaceAPI.deletePanoramaMediaVideo(postData);
      setMediaVideo(response.data.video);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '미디어 동영상을 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      refetch();
    }
  };

  // 파노라마 이미지 삭제
  const removePanoramaImage = async (e) => {
    e.preventDefault();
    let error = false;
    try {
      await spaceAPI.deletePanoramaImage(idSelectedContext);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '파노라마 이미지 삭제 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      setShowDataEditPanel(false);
      setIdSelected(0);
      setCoordinate({ x: 0, y: 0 });
      setRoomName('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    }
  };
  const removePanoramaImageFromMap = async (id) => {
    let error = false;
    try {
      await spaceAPI.deletePanoramaImage(id);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '파노라마 이미지 삭제 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      setShowDataEditPanel(false);
      setIdSelected(0);
      setCoordinate({ x: 0, y: 0 });
      setRoomName('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    }
  };

  // 파노라마 이미지 정보 수정
  const panoramaEditBtnListener = async (e) => {
    e.preventDefault();
    try {
      const postData =
        viewDistance !== ''
          ? {
              id: idSelected,
              category: roomCategory,
              room_name: roomName,
              view_distance: Math.pow(viewDistance, 2),
            }
          : {
              id: idSelected,
              category: roomCategory,
              room_name: roomName,
            };
      await spaceAPI.editPanoramaInfo(postData);
      const postDataRoomName = {
        floor: floor,
        room_name: roomName,
        space_id: space.id,
      };
      if (isSubFloor) {
        await spaceAPI.editPanoramaRoomNameAll(postDataRoomName);
      }
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    alert('파노라마 정보가 수정되었습니다.');
    setRoomName('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };
  const panoramaEditAngle = async (e, id, angle) => {
    e.preventDefault();
    try {
      const postData = {
        id: id,
        angle: angle,
      };
      await spaceAPI.editPanoramaAngle(postData);
      refetch();
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
  };
  const editStartingPoint = async (id) => {
    try {
      const postData = {
        id: id,
      };

      await spaceAPI.editPanoramaStartingPoint(postData);

      alert('파노라마 정보가 수정되었습니다.');

      setRoomName('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    }
  };

  const editPanoramaIsOn = async (id) => {
    try {
      const postData = {
        id: id,
      };

      await spaceAPI.editPanoramaIsOn(postData);

      alert('파노라마 정보가 수정되었습니다.');

      setRoomName('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    }
  };

  const editPosition = async (e, positions) => {
    e.preventDefault();
    try {
      const postData = {
        data: positions,
      };

      await spaceAPI.editPanoramaPosition(postData);

      alert('파노라마 정보가 수정되었습니다.');

      setRoomName('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    }
  };
  const editLatLong = async (latlongs) => {
    try {
      const postData = {
        data: latlongs,
      };

      await spaceAPI.editPanoramaLatLong(postData);

      alert('파노라마 정보가 수정되었습니다.');

      setRoomName('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    }
  };

  const mediaCellEditBtnListener = async (e) => {
    e.preventDefault();
    try {
      const postData = {
        id: idSelected,
        type: type,
        title: title,
        description: desc,
        size: mediaSize,
      };
      await spaceAPI.editPanoramaMediaInfo(postData);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 미디어 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    alert('파노라마 미디어 정보가 수정되었습니다.');
    setRoomName('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  // 파노라마 이미지 정보 수정
  const subFloorNameEditBtnListener = async (e) => {
    e.preventDefault();
    if (floorPanorama.length > 0) {
      try {
        const postDataRoomName = {
          floor: floor,
          room_name: roomName,
          space_id: space.id,
        };
        await spaceAPI.editPanoramaRoomNameAll(postDataRoomName);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 정보가 수정되었습니다.');
      refetch();
    }
  };

  const directionEditBtnListener = async (e) => {
    e.preventDefault();
    try {
      const postData = {
        space_id: space.id,
        angle: globalDirection,
        floor: floor,
      };
      await spaceAPI.editPanoramaAngleGlobal(postData);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    alert('파노라마 정보가 수정되었습니다.');
    refetch();
  };
  const newFloorEditBtnListener = async (e) => {
    e.preventDefault();
    if (newFloor === '') {
      alert('이동시킬 층을 입력해주세요.');
      return;
    }
    if (floorPanorama.length <= 0) {
      alert('이동시킬 파노라마가 없습니다.');
      return;
    }
    const tempPano = data.filter((item) => item.floor === newFloor);
    let proceed = false;
    if (tempPano.length > 0) {
      proceed = confirm(
        `${newFloor}층에 이미 ${tempPano.length}개의 데이터가 존재합니다. 그래도 진행하시겠습니까?`
      );
    } else {
      proceed = true;
    }
    if (proceed) {
      try {
        const postData = {
          space_id: space.id,
          floor: floor,
          new_floor: newFloor,
        };
        await spaceAPI.editPanoramaFloor(postData);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 정보가 수정되었습니다.');
      refetch();
    } else {
      alert('사용자에 의해 중단되었습니다.');
    }
  };

  const attachBlockBtnListener = async (e) => {
    e.preventDefault();
    if (idSelected !== relationBlockAttachValue * 1) {
      const postData = {
        spot_id: idSelected,
        attach_id: relationBlockAttachValue * 1,
        space_id: space.id,
      };
      try {
        await spaceAPI.attachPanoramaRelationBlock(postData);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 Relation(block)이 추가되었습니다.');
      setRelationBlockAttachValue('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } else {
      alert(
        '현재 파노라마와 입력된 id값이 동일합니다.\n다시 한번 확인해주세요.'
      );
    }
  };

  const attachMediaBtnListener = async (e) => {
    e.preventDefault();
    if (idSelected !== relationPanoVal * 1) {
      const postData = {
        pano_id: relationPanoVal * 1,
        media_id: idSelected,
        space_id: space.id,
        latitude: latitude,
        longitude: longitude,
      };
      try {
        await spaceAPI.attachPanoramaRelationMedia(postData);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 Relation(Media)이 추가되었습니다.');
      setRelationPanoVal('');
      setLatitude('');
      setLongitude('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } else {
      alert(
        '현재 파노라마와 입력된 id값이 동일합니다.\n다시 한번 확인해주세요.'
      );
    }
  };

  const detachRelationBtnListener = async (e, relation_id) => {
    e.preventDefault();
    const postData = {
      relation_id: relation_id,
      space_id: space.id,
    };
    try {
      await spaceAPI.detachPanoramaRelation(postData);
    } catch (e) {
      console.error(e);
    }
    alert('파노라마 Relation이 삭제되었습니다.');
    setRelationBlockAttachValue('');
    setRelationConnectAttachValue('');
    setRelationConnectValDir1('');
    setRelationConnectValDir2('');
    setRelationExternalAttachValue('');
    setRelationExternalValDir1('');
    setRelationExternalValDir2('');
    setRelationExternalSpaceIdValue('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  const mediaRelationFix = async (e, item) => {
    e.preventDefault();
    setRelationPanoVal(item.PanoramaSelf.s_id1);
    setLatitude(item.PanoramaSelf.target_latitude);
    setLongitude(item.PanoramaSelf.target_longitude);

    const postData = {
      relation_id: item.PanoramaSelf.id,
      space_id: space.id,
    };
    try {
      await spaceAPI.detachPanoramaRelation(postData);
    } catch (e) {
      console.error(e);
    }
    alert('파노라마 Relation이 삭제되었습니다.');
    setRelationBlockAttachValue('');
    setRelationConnectAttachValue('');
    setRelationConnectValDir1('');
    setRelationConnectValDir2('');
    setRelationExternalAttachValue('');
    setRelationExternalValDir1('');
    setRelationExternalValDir2('');
    setRelationExternalSpaceIdValue('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  const attachConnectBtnListener = async (e) => {
    e.preventDefault();
    if (idSelected !== relationConnectAttachValue * 1) {
      const postData = {
        spot_id: idSelected,
        attach_id: relationConnectAttachValue,
        space_id: space.id,
        dir_1: relationConnectValDir1,
        dir_2: relationConnectValDir2,
      };
      try {
        await spaceAPI.attachPanoramaRelationConnect(postData);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 Relation(connect)이 추가되었습니다.');
      setRelationConnectAttachValue('');
      setRelationConnectValDir1('');
      setRelationConnectValDir2('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } else {
      alert('id1, id2가 동일합니다.\n다시 한번 확인해주세요.');
    }
  };

  const attachExternalBtnListener = async (e) => {
    e.preventDefault();
    const postData = {
      spot_id: idSelected,
      attach_id: relationExternalAttachValue,
      space_id: space.id,
      dir_1: relationExternalValDir1,
      dir_2: relationExternalValDir2,
      external_id: relationExternalSpaceIdValue,
    };
    try {
      await spaceAPI.attachPanoramaRelationExternal(postData);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    alert('파노라마 Relation(external)이 추가되었습니다.');
    setRelationExternalAttachValue('');
    setRelationExternalValDir1('');
    setRelationExternalValDir2('');
    setRelationExternalSpaceIdValue('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  const [jsonFileName, setJsonFileName] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [jsonFileData, setJsonFileData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [progressList, setProgressList] = useState({});
  const [uploadMode, setUploadMode] = useState('sfm');

  const handleJsonUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setJsonFileName(file.name);

      // JSON 파일 읽기
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const data = JSON.parse(reader.result);
          setJsonFileData(data.frames);
        } catch (error) {
          console.error('JSON 파일 파싱 오류:', error);
        }
      };
      reader.readAsText(file);
    }
  };
  const uploadMultiplePanoramaImage = (e) => {
    const files = Array.from(e.target.files);
    setUploadedImages((prev) => [...prev, ...files]);
  };
  const isUploadEnabled =
    uploadMode === 'json' || uploadMode === 'image'
      ? jsonFileName || uploadedImages.length > 0
      : jsonFileName && uploadedImages.length > 0;

  const resetInputs = () => {
    setJsonFileName(''); // JSON 파일명 초기화
    setJsonFileData([]); // JSON 데이터 초기화
    setUploadedImages([]); // 업로드된 이미지 초기화
    setProgress(0); // 진행률 초기화
  };

  const handleServerUpload = async () => {
    if (isUploadEnabled) {
      if (uploadMode === 'sfm' || uploadMode === 'gps') {
        if (jsonFileData.length !== uploadedImages.length) {
          const proceed = confirm(
            `JSON 데이터 (${jsonFileData.length}개)와 업로드된 이미지 파일 (${uploadedImages.length}개)의 개수가 일치하지 않습니다. 그래도 진행하시겠습니까?`
          );

          if (!proceed) {
            alert('업로드가 취소되었습니다.');
            return;
          }
        }

        setIsUploading(true);
        setProgress(0);

        const matchedData = jsonFileData.map((jsonItem) => {
          const matchedImage = uploadedImages.find((image) => {
            // image.name과 jsonItem.file_name에서 확장자를 제거
            const imageNameWithoutExtension = image.name.replace(
              /\.[^/.]+$/,
              ''
            );
            const jsonFileNameWithoutExtension = jsonItem.file_name.replace(
              /\.[^/.]+$/,
              ''
            );

            // 확장자를 제외한 이름이 같은지 비교
            return imageNameWithoutExtension === jsonFileNameWithoutExtension;
          });

          if (!matchedImage) {
            console.warn(`이미지 파일이 누락되었습니다: ${jsonItem.file_name}`);
            return null;
          }

          return {
            ...jsonItem,
            imageFile: matchedImage,
          };
        });

        const missingData = matchedData.filter((item) => item === null);
        let allUploadsCompleted = true;
        for (let i = 0; i < matchedData.length; i++) {
          const tempData = matchedData[i];
          if (!tempData) {
            continue;
          }
          if (uploadMode === 'sfm') {
            const postDataWithImage = new FormData();
            const headers = {
              id: space.id,
              x: tempData.position[0],
              y: tempData.position[1],
              floor: floor,
              category: 'default',
            };

            postDataWithImage.append('room_name', roomName ?? '');
            postDataWithImage.append('img', tempData.imageFile);

            try {
              await uploadImagePromise(postDataWithImage, headers);
              setProgress((prev) => prev + 1);
            } catch (error) {
              allUploadsCompleted = false;
              console.error('이미지 업로드 실패:', error);
            }
          } else {
            const postDataWithImage = new FormData();

            const firstItem = matchedData.find((item) => item !== null);
            const relativePosition =
              floorPanorama.length === 0
                ? i === 0
                  ? { x: 0, y: 0 }
                  : toRelativeCoordinates(
                      firstItem.position_gps[0],
                      firstItem.position_gps[1],
                      tempData.position_gps[0],
                      tempData.position_gps[1]
                    )
                : toRelativeCoordinates(
                    data[0].latitude,
                    data[0].longitude,
                    tempData.position_gps[0],
                    tempData.position_gps[1]
                  );
            const headers = {
              id: space.id,
              x: relativePosition.x,
              y: relativePosition.y,
              latitude: tempData.position_gps[0],
              longitude: tempData.position_gps[1],
              altitude: tempData.position_gps[2],
              floor: floor,
              category: 'default',
            };
            postDataWithImage.append('room_name', roomName ?? '');
            postDataWithImage.append('img', tempData.imageFile);

            try {
              await uploadImagePromise(postDataWithImage, headers);
              setProgress((prev) => prev + 1);
            } catch (error) {
              allUploadsCompleted = false;
              console.error('이미지 업로드 실패:', error);
            }
          }
        }
        setIsUploading(false);
        if (missingData.length > 0) {
          alert(
            `경고: 일부 JSON 데이터에 해당하는 이미지 파일이 누락되었습니다. (${missingData.length}개)`
          );
        }
        if (allUploadsCompleted) {
          alert('파일이 성공적으로 전송되었습니다!');
        } else {
          alert(
            '이미지를 업로드하는 중 에러가 발생했습니다. 관리자에게 문의하세요.'
          );
        }
        setIsUploadSectionVisible(false);
        resetInputs();
        refetch();
      } else if (uploadMode === 'image') {
        setIsUploading(true);
        setProgress(0);
        const temp_list = uploadedImages;
        for (let i = 0; i < temp_list.length; i++) {
          let file = temp_list[i];
          let fileName = file.name.split('.')[0];
          let tempData = data.filter((item) =>
            item.img_original?.includes(fileName)
          );
          if (tempData && tempData.length > 0) {
            let postDataWithImage = new FormData();
            let headers = {
              id: space.id,
              panoid: tempData[0].id,
            };
            postDataWithImage.append('img', file);

            await uploadImagePromise(postDataWithImage, headers).then(
              (response) => {
                if (response.data) {
                  refetch();
                } else {
                  alert(
                    '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
                  );
                }
              }
            );
            setProgress((prev) => prev + 1);
          }
        }
        setIsUploading(false);

        setIsUploadSectionVisible(false);
        resetInputs();
        refetch();
      } else if (uploadMode === 'json') {
        setIsUploading(true);
        setProgress(0);
        const temp_list = jsonFileData;

        const minValue = Math.min(
          ...jsonFileData.map((item) => item.position_gps[2])
        );
        const maxValue = Math.max(
          ...jsonFileData.map((item) => item.position_gps[2])
        );
        const range = maxValue - minValue;

        for (let i = 0; i < temp_list.length; i++) {
          let fileData = temp_list[i];
          let fileName = fileData.file_name.split('.')[0];
          let tempData = data.filter((item) =>
            item.img_original?.includes(fileName)
          );
          if (tempData && tempData.length > 0) {
            const alt_value = (fileData.position_gps[2] - minValue) / range;
            const postData = {
              id: tempData[0].id,
              latitude: fileData.position_gps[0],
              longitude: fileData.position_gps[1],
              altitude: alt_value,
            };

            await spaceAPI.editPanoramaGPS(postData);

            setProgress((prev) => prev + 1);
          }
        }
        setIsUploading(false);

        setIsUploadSectionVisible(false);
        resetInputs();
        refetch();
      }
    }
  };

  const handleDownloadImages = async () => {
    if (isDownloading) return;

    try {
      setIsDownloading(true);

      // 1. 원본 URL 리스트 가져오기
      const response = await spaceAPI.downloadPanoramaImage(space.id); // data.data가 URL 리스트
      const urls = response.data.data;
      // 2. 진행률 초기화 (각 URL에 대해 0%로 설정)
      const initialProgress = urls.reduce((acc, url) => {
        acc[url] = 0; // 초기값 0
        return acc;
      }, {});
      setProgressList(initialProgress); // 진행률 초기화

      // 3. 각 파일 다운로드 및 진행률 업데이트
      const downloadFile = async (url) => {
        const response = await fetch(url);
        const reader = response.body?.getReader();

        if (!reader) throw new Error(`Failed to download file: ${url}`);

        const contentLength = response.headers.get('Content-Length') || 1;
        let receivedLength = 0; // 각 반복마다 새로운 변수 생성
        const chunks = [];
        const updateProgress = (value) => {
          chunks.push(value);
          receivedLength += value.length;

          // 진행률 업데이트
          setProgressList((prev) => ({
            ...prev,
            [url]: Math.min((receivedLength / +contentLength) * 100, 100),
          }));
        };

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          if (value) {
            updateProgress(value);
          }
        }

        // Blob 생성 및 다운로드
        const blob = new Blob(chunks);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = url.substring(url.lastIndexOf('/') + 1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      await Promise.all(urls.map((url) => downloadFile(url)));

      setIsDownloading(false);
    } catch (err) {
      console.error('Error downloading images:', err);
      setIsDownloading(false);
    }
  };

  const uploadImagePromise = (formData, headers) => {
    return new Promise((resolve, reject) => {
      spaceAPI
        .uploadPanoramaImage(formData, headers)
        .then((response) => resolve(response))
        .catch((e) => {
          reject(e);
        });
    });
  };

  const resetCoordinateFromLatLng = async (e) => {
    if (
      window.confirm(
        '파노라마 데이터 전부의 좌표를 위경도에 맞춰 재설정 하시겠습니까?'
      )
    ) {
      const firstItem = data.find(
        (item) => item.latitude !== null && item.longitude !== null
      );
      if (!firstItem) {
        alert(
          '해당 기능을 사용하기 위해선 하나의 셀이라도 위경도가 설정되어야 합니다.'
        );
        return;
      }
      const body = [];
      for (let i = 0; i < data.length; i++) {
        const tempData = data[i];
        const relativePosition =
          i === 0
            ? { x: 0, y: 0 }
            : toRelativeCoordinates(
                firstItem.latitude,
                firstItem.longitude,
                tempData.latitude,
                tempData.longitude
              );
        body.push({
          id: tempData.id,
          x: relativePosition.x,
          y: relativePosition.y,
        });
      }
      await editPosition(e, body);
    } else {
      alert('취소되었습니다.');
    }
  };

  const calculateButtonViewDistance = async (e) => {
    e.preventDefault();
    const panoWithImage = data.filter(
      (item) => item.img_original && item.img_original !== ''
    );
    if (panoWithImage.length < 2) {
      alert(
        '버튼 거리 설정을 위해서는 이미지가 2개 이상 등록된 셀이 필요합니다.'
      );
      return;
    }

    const temp = floorList.map((floor) => {
      const tempPano = panoWithImage.filter((item) => item.floor === floor);
      if (tempPano.length === 0) {
        return -1;
      }

      const panoWithMinDistance = tempPano.map((point, index) => {
        let minDistance = 1000;

        tempPano.forEach((otherPoint, otherIndex) => {
          if (index !== otherIndex) {
            const distance = calculateDistance(point, otherPoint);
            if (distance < minDistance) {
              minDistance = distance;
            }
          }
        });

        return minDistance;
      });
      return panoWithMinDistance.length > 0
        ? Math.max(...panoWithMinDistance)
        : -1;
    });
    const tempDistance = Math.max(...temp);
    if (tempDistance === -1) {
      alert('셀이 2개 이상 등록된 층이 필요합니다.');
    }
    const body = [];
    const distance = Math.pow(tempDistance, 2);
    panoWithImage.forEach((item) => {
      const roundedSpaceDistance =
        Math.round(space.view_distance * 1000) / 1000;
      const roundedItemDistance = Math.round(item.view_distance * 1000) / 1000;
      if (roundedSpaceDistance === roundedItemDistance) {
        body.push({
          id: item.id,
          view_distance: distance,
        });
      }
    });
    try {
      const postData = {
        id: space.id,
        distance: distance,
      };

      await spaceAPI.editSpaceViewDistance(postData);
      await spaceAPI.editPanoramaViewDistance({ data: body });

      alert('파노라마 버튼 거리 정보가 수정되었습니다.');

      setRoomName('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    }
  };

  const changePanoramaImage = async (e, id) => {
    // panorama image upload
    let postDataWithImage = new FormData();
    postDataWithImage.append('img', e.target.files[0]);
    let headers = {
      id: space.id,
      panoid: id,
    };
    try {
      await spaceAPI.uploadPanoramaImage(postDataWithImage, headers);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 이미지 업로드 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    } finally {
      refetch();
    }
  };

  const uploadPanoramaImage = async (e, x, y) => {
    let error = false;
    let postDataWithImage = new FormData();
    let headers = {
      id: space.id,
      x: x,
      y: y,
      floor: floor,
      category: 'default',
      global_direction: globalDirection,
    };
    postDataWithImage.append('room_name', roomName ?? '');
    postDataWithImage.append('img', e.target.files[0]);
    try {
      await spaceAPI.uploadPanoramaImage(postDataWithImage, headers);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '파노라마 이미지 업로드 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      refetch();
      setDataLoading(false);
    }
  };
  const uploadPanoramaImageFromMap = async (e, latitude, longitude) => {
    let error = false;
    let postDataWithImage = new FormData();
    const relativePosition = toRelativeCoordinates(
      data[0].latitude,
      data[0].longitude,
      latitude,
      longitude
    );
    let headers = {
      id: space.id,
      x: relativePosition.x,
      y: relativePosition.y,
      latitude: latitude,
      longitude: longitude,
      floor: floor,
      category: 'default',
      global_direction: globalDirection,
    };

    postDataWithImage.append('room_name', roomName ?? '');
    postDataWithImage.append('img', e.target.files[0]);

    try {
      await spaceAPI.uploadPanoramaImage(postDataWithImage, headers);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '파노라마 이미지 업로드 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      refetch();
      setDataLoading(false);
    }
  };
  const removeAllBtnListener = async (e) => {
    e.preventDefault();
    let error = false;
    if (
      window.confirm(
        '파노라마 데이터 전부를 삭제합니다. 다시 한번 확인해주세요.'
      )
    ) {
      try {
        await spaceAPI.deletePanoramaImageAll(space.id);
      } catch (e) {
        console.error(e);
      } finally {
        if (error) {
          alert(
            '파노라마 전체 삭제 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        } else {
          alert('전체 삭제되었습니다.');
        }
        setShowDataEditPanel(false);
        setIdSelected(0);
        setCoordinate({ x: 0, y: 0 });
        setRoomName('');
        setImageLinkContext('');
        setIdSelectedContext(0);
        setCoordinateContext({ x: 0, y: 0 });
        refetch();
      }
    } else {
      alert('취소되었습니다.');
    }
  };

  const contextOpenFunction = (e, panorama, x, y) => {
    e.preventDefault();
    setAnchorPoint({ x: e.pageX, y: e.pageY });

    if (!panorama) {
      setShowContext(false);
      setCoordinateContext({});
      setIdSelectedContext();
      setImageLinkContext();
    } else {
      setCoordinateContext({
        x: panorama.x,
        y: panorama.y,
      });
      setIdSelectedContext(panorama.id);
      setImageLinkContext(panorama.img_original ?? '');
      setShowContext(true);
    }

    if (panorama === null) {
      setCoordinateContext({
        x: x,
        y: y,
      });
      setIdSelectedContext();
      setImageLinkContext();
      setShowContext(true);
    }
  };

  const showViewer = (pointId) => {
    setIsViewerVisible(false);
    const originalPoint = floorPanorama.find(
      (point) => point.id === parseInt(pointId)
    );
    setViewerItem(originalPoint);
    setIsViewerVisible(true);
  };

  const clickPanoramaItem = (e, id) => {
    e.preventDefault();
    setShowDataEditPanel(true);
    const panorama = floorPanorama.find((item) => item.id === id);
    setIdSelected(panorama.id);
    setCoordinate({
      x: panorama.x,
      y: panorama.y,
    });
    setRoomCategory(panorama.category ?? '');
    setViewDistance(Math.sqrt(panorama.view_distance) ?? '');
    setType(panorama.type ?? '');
    setTitle(panorama.title ?? '');
    setDesc(panorama.description ?? '');
    setMediaSize(panorama.size ?? '');
    setMediaImage(panorama.image ?? '');
    setMediaVideo(panorama.video ?? '');
    setRoomName(panorama.room_name ?? '');
    setSpotRelationBlock(
      panorama.panoramaself1
        .concat(panorama.panoramaself2)
        .filter((item) => item.PanoramaSelf.type === 'block') ?? []
    );
    setSpotRelationConnect(
      panorama.panoramaself1
        .concat(panorama.panoramaself2)
        .filter((item) => item.PanoramaSelf.type === 'connect') ?? []
    );
    setSpotExternalConnect(
      panorama.panoramaself1
        .concat(panorama.panoramaself2)
        .filter((item) => item.PanoramaSelf.type === 'external') ?? []
    );
    setSpotMediaConnect(
      panorama.panoramaself1
        .concat(panorama.panoramaself2)
        .filter((item) => item.PanoramaSelf.type === 'media') ?? []
    );
  };

  return (
    <ModalContainer>
      {/* 우클릭  */}
      <CustomContextMenu
        style={{
          display: showContext ? 'block' : 'none',
          top: anchorPoint.y,
          left: anchorPoint.x,
        }}
      >
        <CustomContextMenuItem>
          {`( x, y ) = ( ${coordinateContext.x}, ${coordinateContext.y} )`}
        </CustomContextMenuItem>

        <CustomContextMenuItem
          onClick={(e) => setRelationBlockAttachValue(idSelectedContext)}
        >
          블락 id로 추가
        </CustomContextMenuItem>
        <CustomContextMenuItem
          onClick={(e) => setRelationConnectAttachValue(idSelectedContext)}
        >
          커넥트 id로 추가
        </CustomContextMenuItem>

        {imageLinkContext && (
          <CustomContextMenuItem>
            <a href={imageLinkContext} download>
              사진 다운로드
            </a>
          </CustomContextMenuItem>
        )}
        <CustomContextMenuItem>
          <label htmlFor="change-panorama-image">
            {idSelectedContext ? '사진 변경' : '사진 업로드'}
            <ImageUploadInput
              type="file"
              id="change-panorama-image"
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={async (e) => {
                if (idSelectedContext) {
                  await changePanoramaImage(e, idSelectedContext);
                } else {
                  await uploadPanoramaImage(
                    e,
                    coordinateContext.x,
                    coordinateContext.y
                  );
                }
              }}
            />
          </label>
        </CustomContextMenuItem>
        <CustomContextMenuItem onClick={(e) => removePanoramaImage(e)}>
          {'삭제'}
        </CustomContextMenuItem>
      </CustomContextMenu>
      <HeaderContainer>
        <HeaderRow space_between>
          <GlobalSettingWrapper>
            <ContainerTitle>FAVIEW 데이터 관리</ContainerTitle>
            <ToggleWrapper style={{ marginLeft: 10 }}>
              <ToggleButton
                active={viewMode === '미니맵'}
                onClick={() => {
                  setViewMode('미니맵');
                }}
              >
                미니맵
              </ToggleButton>
              <ToggleButton
                active={viewMode === '지도'}
                onClick={() => {
                  setViewMode('지도');
                }}
              >
                지도
              </ToggleButton>
            </ToggleWrapper>
          </GlobalSettingWrapper>
          <GlobalSettingWrapper>
            <LinkButton onClick={toggleUploadSection}>
              {isUploadSectionVisible ? '팝업 닫기' : '이미지 관리'}
            </LinkButton>
            <LinkButton
              color={palette.fontWarning}
              fontColor={palette.fontWarning}
              onClick={(e) => removeAllBtnListener(e)}
            >
              Delete All
            </LinkButton>
            <CloseButton onClick={() => closeCurrentModal()}>✕</CloseButton>
          </GlobalSettingWrapper>
        </HeaderRow>
        <HeaderRow>
          {/* 층 선택 */}
          <GlobalSettingWrapper>
            <button
              onClick={() => setDrawerOpen(!drawerOpen)}
              style={{
                width: '25px',
                height: '25px',
                border: '1px solid #ccc',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                marginRight: '5px',
              }}
            >
              ↓
            </button>
            <Dropdown value={floor} onChange={floorOnChange}>
              {floorDropdownData.map((data, idx) => (
                <option value={data} key={'floor_' + idx}>
                  {data !== 99
                    ? String(data).replace('-', '지하 ') + '층'
                    : '외부'}
                </option>
              ))}
            </Dropdown>
            <FloorListWrapper>
              {floorList.map((floor, idx) => {
                var floorString = floor;
                if (floor == 99) floorString = '외부';
                return (
                  <FloorList
                    onClick={() => onClickFloorList(floor)}
                    key={'floorList_' + idx}
                  >
                    {floorString}
                  </FloorList>
                );
              })}
            </FloorListWrapper>
            <AddSubFloorBtn onClick={(e) => addSubFloorListener(e)}>
              +
            </AddSubFloorBtn>
          </GlobalSettingWrapper>

          <GlobalSettingWrapper margin_left={'20px'}>
            <GlobalSettingTitle>기본 방향</GlobalSettingTitle>
            <DetailTextarea
              width="80px"
              height="14px"
              font_size="13px"
              value={globalDirection}
              onChange={globalDirectionOnChange}
            />
            <Button onClick={directionEditBtnListener}>수정</Button>
            <LinkButton
              fontColor={palette.black}
              color={palette.naver}
              onClick={resetCoordinateFromLatLng}
            >
              좌표 재설정
            </LinkButton>
            <LinkButton
              fontColor={palette.black}
              color={palette.mint}
              onClick={calculateButtonViewDistance}
            >
              버튼 거리 설정
            </LinkButton>
          </GlobalSettingWrapper>
        </HeaderRow>
        {drawerOpen && (
          <HeaderRow>
            <GlobalSettingWrapper margin_left={'20px'}>
              <GlobalSettingTitle>이동할 층</GlobalSettingTitle>
              <DetailTextarea
                width="80px"
                height="14px"
                font_size="13px"
                value={newFloor}
                onChange={newFloorOnChange}
              />
              <Button onClick={newFloorEditBtnListener}>수정</Button>
            </GlobalSettingWrapper>
          </HeaderRow>
        )}
        {isUploadSectionVisible && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // 배경 어둡게
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                position: 'relative',
                width: '100%', // 원하는 크기로 조정
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              <PopupCloseButton
                onClick={() => setIsUploadSectionVisible(false)}
              >
                ✕
              </PopupCloseButton>
              <PopupContent>
                <ToggleWrapper>
                  <ToggleButton
                    active={uploadMode === 'sfm'}
                    onClick={() => {
                      setUploadMode('sfm');
                      resetInputs();
                    }}
                  >
                    SFM
                  </ToggleButton>
                  <ToggleButton
                    active={uploadMode === 'gps'}
                    onClick={() => {
                      setUploadMode('gps');
                      resetInputs();
                    }}
                  >
                    GPS
                  </ToggleButton>
                  <ToggleButton
                    active={uploadMode === 'image'}
                    onClick={() => {
                      setUploadMode('image');
                      resetInputs();
                    }}
                  >
                    사진 업로드
                  </ToggleButton>
                  <ToggleButton
                    active={uploadMode === 'json'}
                    onClick={() => {
                      setUploadMode('json');
                      resetInputs();
                    }}
                  >
                    좌표 수정(JSON)
                  </ToggleButton>

                  <ToggleButton
                    active={uploadMode === 'download'}
                    onClick={() => {
                      setUploadMode('download');
                      resetInputs();
                    }}
                  >
                    원본 다운로드
                  </ToggleButton>
                </ToggleWrapper>
                {uploadMode !== 'image' && uploadMode !== 'download' && (
                  <ActionGroup>
                    <StyledUploadButton>
                      <input
                        type="file"
                        accept=".json"
                        onChange={handleJsonUpload}
                        style={{ display: 'none' }}
                      />
                      좌표 JSON 업로드
                    </StyledUploadButton>
                    {jsonFileName && <FileName>{jsonFileName}</FileName>}
                  </ActionGroup>
                )}
                {uploadMode !== 'json' && uploadMode !== 'download' && (
                  <ActionGroup>
                    <StyledUploadButton
                      variant="secondary"
                      htmlFor="multiple-panorama-image-change"
                    >
                      이미지 파일 업로드
                    </StyledUploadButton>
                    <MultipleImageUploadInput
                      type="file"
                      id="multiple-panorama-image-change"
                      multiple
                      onChange={uploadMultiplePanoramaImage}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />
                    {uploadedImages.length > 0 && (
                      <FileCount>{uploadedImages.length}장 선택됨</FileCount>
                    )}
                  </ActionGroup>
                )}
                {uploadMode !== 'download' ? (
                  <ServerUploadButton
                    disabled={!isUploadEnabled}
                    onClick={handleServerUpload}
                  >
                    {isUploading ? '파일 전송 중' : '서버로 파일 전송'}
                  </ServerUploadButton>
                ) : (
                  <div>
                    <ServerUploadButton
                      disabled={isDownloading}
                      onClick={handleDownloadImages}
                    >
                      {isDownloading ? '다운로드 중' : '원본 이미지 다운로드'}
                    </ServerUploadButton>
                    <div style={{ marginTop: '20px' }}>
                      {Object.keys(progressList).map((url) => (
                        <div key={url} style={{ marginBottom: '10px' }}>
                          <div>{url.substring(url.lastIndexOf('/') + 1)}</div>
                          <div
                            style={{
                              width: '100%',
                              height: '10px',
                              backgroundColor: '#ddd',
                              borderRadius: '5px',
                              overflow: 'hidden',
                            }}
                          >
                            <div
                              style={{
                                width: `${progressList[url]}%`,
                                height: '100%',
                                backgroundColor: '#4caf50',
                                transition: 'width 0.2s ease',
                              }}
                            ></div>
                          </div>
                          <div>{progressList[url].toFixed(2)}%</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {isUploading && (
                  <ProgressWrapper>
                    <progress
                      value={progress}
                      max={Math.min(jsonFileData.length, uploadedImages.length)}
                      style={{ width: '100%', height: '24px' }}
                    />
                    <span>
                      {progress} /{' '}
                      {Math.min(jsonFileData.length, uploadedImages.length)}{' '}
                      완료
                    </span>
                  </ProgressWrapper>
                )}
              </PopupContent>
            </div>
          </div>
        )}

        {/* Data Loading... */}
        {dataLoading && (
          <LoadingWrapper>
            <TailSpin color={palette.mainBlue} width={50} height={50} />
          </LoadingWrapper>
        )}
      </HeaderContainer>

      <PanoramaContainer>
        <Suspense fallback={<div>Loading...</div>}>
          {viewMode === '미니맵' ? (
            <Minimap
              data={floorPanorama}
              showContext={showContext}
              contextOpenFunction={contextOpenFunction}
              editPosition={editPosition}
              editStartingPoint={editStartingPoint}
              showViewer={showViewer}
              onClickItem={clickPanoramaItem}
              showDataEditPanel={showDataEditPanel}
            />
          ) : (
            <NaverMapLayout
              currentPosition={{
                lat:
                  space.address_lat && space.address_lat !== 0
                    ? space.address_lat
                    : 37.162076,
                lng:
                  space.address_long && space.address_long !== 0
                    ? space.address_long
                    : 128.042981,
              }}
              data={floorPanorama}
              editLatLong={editLatLong}
              editStartingPoint={editStartingPoint}
              showViewer={showViewer}
              uploadPanorama={uploadPanoramaImageFromMap}
              changePanoramaImage={changePanoramaImage}
              removePanorama={removePanoramaImageFromMap}
              toggleIsOn={editPanoramaIsOn}
            />
          )}
          {isViewerVisible && viewerItem && (
            <ViewerContainer>
              <EquirectangularViewer
                viewerKey="viewer"
                panoId={viewerItem.id}
                imageUrl={viewerItem.img_original}
                position={[0, 0, 0]}
                initialAngle={
                  viewerItem.direction !== 0
                    ? viewerItem.direction
                    : viewerItem.global_direction
                }
                onSaveAngle={async (e, id, angle) => {
                  await panoramaEditAngle(e, id, angle);
                  setIsViewerVisible(false);
                  showViewer(id);
                }}
                onCloseViewer={() => setIsViewerVisible(false)}
              />
            </ViewerContainer>
          )}
        </Suspense>

        {showDataEditPanel && (
          <EditWrapper>
            <EditTitleWrapper>
              <DetailTitle center>촬영본 정보</DetailTitle>
              <DetailCloseButton onClick={() => setShowDataEditPanel(false)}>
                &#60;
              </DetailCloseButton>
            </EditTitleWrapper>
            <EditDataWrapper>
              <RowWrapper>
                {/* 촬영본 정보 */}
                <ElementWrapper>
                  <DetailSection>
                    <DetailLabel>Panorama ID</DetailLabel>
                    <DetailValue>{idSelected}</DetailValue>
                  </DetailSection>
                  <DetailSection>
                    <DetailLabel>Coordinate(x,y)</DetailLabel>
                    <DetailValue>
                      ({coordinate.x}, {coordinate.y})
                    </DetailValue>
                  </DetailSection>
                  <DetailSection>
                    <DetailLabel>층 (floor)</DetailLabel>
                    <DetailValue>{floor}</DetailValue>
                  </DetailSection>
                </ElementWrapper>

                <ElementWrapper>
                  <DetailTitle>타입 (type)</DetailTitle>
                  <Dropdown
                    value={type}
                    onChange={typeOnChange}
                    margin_top={'10px'}
                  >
                    {typeDropdownData.map((data, idx) => (
                      <option value={data} key={'type_' + idx}>
                        {data}
                      </option>
                    ))}
                  </Dropdown>
                </ElementWrapper>

                {/* 카테고리 */}
                {type === 'normal' && (
                  <ElementWrapper>
                    <DetailTitle>카테고리 (category)</DetailTitle>
                    <HelpButton
                      onMouseOver={() => setIsHoveringHelp(0)}
                      onMouseOut={() => setIsHoveringHelp(-1)}
                    >
                      ?
                    </HelpButton>
                    <Dropdown
                      value={roomCategory}
                      onChange={roomCategoryOnChange}
                      margin_top={'10px'}
                    >
                      {coordinate.x % 1 === 0 && coordinate.y % 1 === 0
                        ? categoryDropdownData.map((data, idx) => (
                            <option value={data} key={'category_' + idx}>
                              {data}
                            </option>
                          ))
                        : subCategoryDropdownData.map((data, idx) => (
                            <option value={data} key={'category_' + idx}>
                              {data}
                            </option>
                          ))}
                    </Dropdown>
                    {isHoveringHelp === 0 && (
                      <HelpWrapper>
                        <HelpDescription>
                          구분되지 않고 이어지는 내부 매장의 경우 default로 모두
                          통일합니다.
                          <br />
                          <br />
                          entrance : 방을 포함한 문을 열고 들어간 위치의 모든
                          cell에 해당합니다.
                          <br />
                          exterior : 공간 밖의 외부에 위치한 cell을 표시합니다.
                          <br />
                          room : 한 층에서 방이 구분되어져야 하는 경우 room으로
                          표기하고 room_name에 표기할 방 이름을 작성합니다.
                        </HelpDescription>
                      </HelpWrapper>
                    )}
                  </ElementWrapper>
                )}
                <ElementWrapper>
                  <DetailSubTitle>
                    &#62; 버튼 가시 거리 (view_distance)
                  </DetailSubTitle>
                  <EditButtonWrapper>
                    <DetailTextarea
                      value={viewDistance}
                      onChange={viewDistanceOnChange}
                    />
                  </EditButtonWrapper>
                </ElementWrapper>
                {type === 'normal' ? (
                  <ElementWrapper>
                    <DetailSubTitle>&#62; 방 이름 (room_name)</DetailSubTitle>
                    <EditButtonWrapper>
                      {!isSubFloor ? (
                        <DetailTextarea
                          value={roomName}
                          onChange={roomNameOnChange}
                        />
                      ) : (
                        <DetailTextarea
                          value={roomName}
                          onChange={roomNameOnChange}
                          readOnly
                          font_color={palette.fontBlue}
                        />
                      )}
                      <Button onClick={panoramaEditBtnListener}>수정</Button>
                    </EditButtonWrapper>
                  </ElementWrapper>
                ) : (
                  <>
                    <ElementWrapper>
                      <DetailSubTitle>&#62; 제목 (title)</DetailSubTitle>
                      <EditButtonWrapper>
                        <DetailTextarea
                          value={title}
                          onChange={titleOnChange}
                        />
                      </EditButtonWrapper>
                    </ElementWrapper>
                    <ElementWrapper>
                      <DetailSubTitle>&#62; 사이즈 (size)</DetailSubTitle>
                      <EditButtonWrapper>
                        <DetailTextarea
                          value={mediaSize}
                          onChange={mediaSizeOnChange}
                        />
                      </EditButtonWrapper>
                    </ElementWrapper>
                    <ElementWrapper>
                      <DetailSubTitle>&#62; 설명 (description)</DetailSubTitle>
                      <EditButtonWrapper>
                        <MediaDescTextarea
                          value={desc}
                          onChange={descOnChange}
                        />
                        <Button onClick={mediaCellEditBtnListener}>수정</Button>
                      </EditButtonWrapper>
                    </ElementWrapper>
                    {type === 'image' && (
                      <ImageWrapper>
                        <ImageContents
                          uploadedImg={mediaImage}
                          key={mediaImage}
                        />
                        <ImageUploadButton
                          htmlFor="upload-media-image"
                          icon={icon_imgadd}
                        />

                        <ImageDeleteButton
                          onClick={(e) => deleteMediaImage(e)}
                        />

                        <ImageUploadInput
                          type="file"
                          id="upload-media-image"
                          multiple={true}
                          onChange={(e) => uploadImageListener(e)}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                        />
                      </ImageWrapper>
                    )}
                    {type === 'video' && (
                      <>
                        {videoUploadLoading && (
                          <ContentsLoadingWrapper>
                            <TailSpin
                              color={palette.mainBlue}
                              width={16}
                              height={16}
                            />
                            영상 업로드 중...
                          </ContentsLoadingWrapper>
                        )}

                        <ImageWrapper>
                          <VideoContents key={mediaVideo} controls>
                            <source src={mediaVideo} />
                          </VideoContents>

                          <VideoUploadButton
                            htmlFor="upload-media-video"
                            icon={icon_imgadd}
                          />

                          <VideoDeleteButton
                            size="30"
                            onClick={(e) => deleteMediaVideo(e)}
                          />

                          <VideoUploadInput
                            type="file"
                            id="upload-media-video"
                            accept="video/mp4,video/mkv, video/x-m4v, video/*"
                            onChange={(e) => uploadVideo(e)}
                            onClick={(e) => {
                              // e.target.value = null;
                            }}
                          />
                        </ImageWrapper>
                      </>
                    )}
                  </>
                )}

                {/* Block Relation */}
                {type === 'normal' && (
                  <ElementWrapper>
                    <DetailTitle>BLOCK RELATION</DetailTitle>
                    <AttachWrapper>
                      <DetailDescription>
                        Block할 위치의 spot_id를 입력해주세요.
                      </DetailDescription>
                      <EditButtonWrapper direction={'row'}>
                        <DetailTextarea
                          value={relationBlockAttachValue}
                          onChange={relationBlockAttachValueOnChange}
                        />
                        <Button onClick={attachBlockBtnListener}>추가</Button>
                      </EditButtonWrapper>
                    </AttachWrapper>
                    <RelationWrapper>
                      {spotRelationBlock &&
                        spotRelationBlock.map((item, idx) => {
                          return (
                            <RelationItemWrapper key={'relation_' + idx}>
                              <RelationItem>
                                {item.id} ({item.x}, {item.y})
                              </RelationItem>
                              <DetachButton
                                onClick={(e) =>
                                  detachRelationBtnListener(
                                    e,
                                    item.PanoramaSelf.id
                                  )
                                }
                              >
                                -
                              </DetachButton>
                            </RelationItemWrapper>
                          );
                        })}
                    </RelationWrapper>
                  </ElementWrapper>
                )}
              </RowWrapper>
              <div style={{ width: '20px' }}></div>
              {/* Connect Relation */}

              <RowWrapper>
                {type === 'normal' ? (
                  <>
                    <ElementWrapper>
                      <DetailTitle>CONNECT RELATION</DetailTitle>
                      <HelpButton
                        onMouseOver={() => setIsHoveringHelp(1)}
                        onMouseOut={() => setIsHoveringHelp(-1)}
                      >
                        ?
                      </HelpButton>

                      {isHoveringHelp === 1 && (
                        <HelpWrapper>
                          <HelpDescription>
                            Connect dir 에 들어가는 방향은 <br />
                            아래와 같습니다.
                            <br />
                            | 8 1 2 |<br />| 7 _ 3 |<br />| 6 5 4 |
                          </HelpDescription>
                        </HelpWrapper>
                      )}
                      <AttachWrapper>
                        {/* <DetailDescription>
                          Connect할 위치의 panorama id를 입력해주세요.
                          <br />
                          id2: 커넥트할 위치의 pano id
                          <br />
                          dir2: id2 파노에서 버튼 위치
                        </DetailDescription> */}
                        <EditButtonWrapper direction={'row'}>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              id1
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              font_color={palette.fontBlue}
                              readOnly={true}
                              value={idSelected}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              id2
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationConnectAttachValue}
                              onChange={relationConnectAttachValueOnChange}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              dir1
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationConnectValDir1}
                              onChange={relationConnectValDir1OnChange}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              dir2
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationConnectValDir2}
                              onChange={relationConnectValDir2OnChange}
                            />
                          </DetailTextareaWrapper>
                        </EditButtonWrapper>
                        <Button
                          style={{
                            width: '40px',
                            height: '20px',
                            marginTop: '3px',
                          }}
                          onClick={attachConnectBtnListener}
                        >
                          추가
                        </Button>
                      </AttachWrapper>
                      <RelationWrapper>
                        {spotRelationConnect &&
                          spotRelationConnect.map((item, idx) => {
                            return (
                              <RelationItemWrapper
                                key={'relation_connect_' + idx}
                              >
                                <RelationItem>
                                  {item.id === item.PanoramaSelf.s_id1
                                    ? `${item.PanoramaSelf.s_id2} / ${item.PanoramaSelf.dir_2} : ${item.PanoramaSelf.s_id1} / ${item.PanoramaSelf.dir_1}`
                                    : `${item.PanoramaSelf.s_id1} / ${item.PanoramaSelf.dir_1} : ${item.PanoramaSelf.s_id2} / ${item.PanoramaSelf.dir_2}`}
                                </RelationItem>
                                <DetachButton
                                  onClick={(e) =>
                                    detachRelationBtnListener(
                                      e,
                                      item.PanoramaSelf.id
                                    )
                                  }
                                >
                                  -
                                </DetachButton>
                              </RelationItemWrapper>
                            );
                          })}
                      </RelationWrapper>
                    </ElementWrapper>
                    {/* External Relation */}
                    <ElementWrapper>
                      <DetailTitle>External RELATION</DetailTitle>
                      <HelpButton
                        onMouseOver={() => setIsHoveringHelp(2)}
                        onMouseOut={() => setIsHoveringHelp(-1)}
                      >
                        ?
                      </HelpButton>

                      {isHoveringHelp === 2 && (
                        <HelpWrapper>
                          <HelpDescription>
                            Connect dir 에 들어가는 방향은 <br />
                            아래와 같습니다.
                            <br />
                            | 8 1 2 |<br />| 7 _ 3 |<br />| 6 5 4 |
                          </HelpDescription>
                        </HelpWrapper>
                      )}
                      <AttachWrapper>
                        {/* <DetailDescription>
                          Connect할 외부 공간의 space id와
                          <br />
                          해당 위치의 panorama id를 입력해주세요.
                          <br />
                          sid: 외부 공간 id
                          <br />
                          id2: 커넥트할 위치의 pano id
                          <br />
                          dir2: id2 파노에서 버튼 위치
                        </DetailDescription> */}
                        <EditButtonWrapper direction={'row'}>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              id1
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              font_color={palette.fontBlue}
                              readOnly={true}
                              value={idSelected}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              id2
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationExternalAttachValue}
                              onChange={relationExternalAttachValueOnChange}
                            />
                          </DetailTextareaWrapper>

                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              dir1
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationExternalValDir1}
                              onChange={relationExternalValDir1OnChange}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              dir2
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationExternalValDir2}
                              onChange={relationExternalValDir2OnChange}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              sid
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationExternalSpaceIdValue}
                              onChange={relationExternalSpaceIdValueOnChange}
                            />
                          </DetailTextareaWrapper>
                        </EditButtonWrapper>
                        <Button
                          style={{
                            width: '40px',
                            height: '20px',
                            marginTop: '3px',
                          }}
                          onClick={attachExternalBtnListener}
                        >
                          추가
                        </Button>
                      </AttachWrapper>
                      <RelationWrapper>
                        {spotExternalConnect &&
                          spotExternalConnect.map((item, idx) => {
                            return (
                              <RelationItemWrapper
                                key={'relation_external_' + idx}
                              >
                                <RelationItem>
                                  {item.id === item.PanoramaSelf.s_id1
                                    ? `${item.PanoramaSelf.external_space_id} / ${item.PanoramaSelf.s_id2} / ${item.PanoramaSelf.dir_2} : ${item.PanoramaSelf.space_id} / ${item.PanoramaSelf.s_id1} / ${item.PanoramaSelf.dir_1}`
                                    : `${item.PanoramaSelf.space_id} / ${item.PanoramaSelf.s_id1} / ${item.PanoramaSelf.dir_1} : ${item.PanoramaSelf.external_space_id} / ${item.PanoramaSelf.s_id2} / ${item.PanoramaSelf.dir_2}`}
                                </RelationItem>
                                <DetachButton
                                  onClick={(e) =>
                                    detachRelationBtnListener(
                                      e,
                                      item.PanoramaSelf.id
                                    )
                                  }
                                >
                                  -
                                </DetachButton>
                              </RelationItemWrapper>
                            );
                          })}
                      </RelationWrapper>
                    </ElementWrapper>
                  </>
                ) : (
                  <ElementWrapper>
                    <DetailTitle>MEDIA RELATION</DetailTitle>
                    <AttachWrapper>
                      <DetailDescription>
                        Connect할 위치의 panorama id를 입력해주세요.
                        <br />
                        id2: 커넥트할 위치의 pano id
                        <br />
                        dir2: id2 파노에서 버튼 위치
                      </DetailDescription>
                      <EditButtonWrapper direction={'row'}>
                        <DetailTextareaWrapper>
                          <DetailTextareaDescConnect>
                            media
                          </DetailTextareaDescConnect>
                          <DetailTextarea
                            font_size="11px"
                            font_color={palette.fontBlue}
                            readOnly={true}
                            value={idSelected}
                          />
                        </DetailTextareaWrapper>
                        <DetailTextareaWrapper>
                          <DetailTextareaDescConnect>
                            pano
                          </DetailTextareaDescConnect>
                          <DetailTextarea
                            font_size="11px"
                            value={relationPanoVal}
                            onChange={relationPanoValOnChange}
                          />
                        </DetailTextareaWrapper>
                        <DetailTextareaWrapper>
                          <DetailTextareaDescConnect>
                            latitude
                          </DetailTextareaDescConnect>
                          <DetailTextarea
                            font_size="11px"
                            value={latitude}
                            onChange={latitudeOnChange}
                          />
                        </DetailTextareaWrapper>
                        <DetailTextareaWrapper>
                          <DetailTextareaDescConnect>
                            longitude
                          </DetailTextareaDescConnect>
                          <DetailTextarea
                            font_size="11px"
                            value={longitude}
                            onChange={longitudeOnChange}
                          />
                        </DetailTextareaWrapper>
                      </EditButtonWrapper>
                      <Button
                        style={{
                          width: '40px',
                          height: '20px',
                          marginTop: '3px',
                        }}
                        onClick={attachMediaBtnListener}
                      >
                        추가
                      </Button>
                    </AttachWrapper>
                    <RelationWrapper>
                      {spotMediaConnect &&
                        spotMediaConnect.map((item, idx) => {
                          return (
                            <RelationItemWrapper key={'relation_media_' + idx}>
                              <RelationItem>
                                {`${item.PanoramaSelf.s_id1} / LatLng(${item.PanoramaSelf.target_latitude}, ${item.PanoramaSelf.target_longitude})`}
                              </RelationItem>
                              <DetachButton
                                onClick={(e) =>
                                  detachRelationBtnListener(
                                    e,
                                    item.PanoramaSelf.id
                                  )
                                }
                              >
                                -
                              </DetachButton>
                              <DetachButton
                                backgroundColor={'blue'}
                                fontSize={'10px'}
                                onClick={(e) => mediaRelationFix(e, item)}
                              >
                                수정
                              </DetachButton>
                            </RelationItemWrapper>
                          );
                        })}
                    </RelationWrapper>
                  </ElementWrapper>
                )}
              </RowWrapper>
            </EditDataWrapper>
          </EditWrapper>
        )}
      </PanoramaContainer>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  height: 100%;
`;
//// HEADER Container
const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
`;
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  margin-bottom: 12px;
  justify-content: ${(props) => (props.space_between ? 'space-between' : '')};
`;
const CloseButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  margin-left: 8px;
`;
const ContainerTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${palette.fontDefault};
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;
const LoadingWrapper = styled.div`
  margin-left: 10px;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 180px;
  top: -10px;
`;
const GlobalSettingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-left: ${(props) => (props.margin_left ? props.margin_left : '0px')};
  margin-right: ${(props) => (props.margin_right ? props.margin_right : '0px')};
`;
const GlobalSettingTitle = styled.div`
  font-size: 14px;
  color: ${palette.fontDefault};
  font-weight: 800;
  display: flex;
  align-items: center;
`;
const FloorListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const FloorList = styled.div`
  padding: 3px 8px;
  border-radius: 20px;
  background-color: ${palette.mainBlue};
  color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
`;
const AddSubFloorBtn = styled.div`
  display: flex;
  align-items: center;
  background-color: ${palette.grey_lighter};
  &:hover {
    background-color: ${palette.mainBlueLight};
  }
  color: ${palette.fontWhite};
  font-size: 14px;
  padding: 0 2px;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 12px;
`;

const LinkButton = styled.div`
  color: ${(props) => (props.fontColor ? props.fontColor : palette.mainOrange)};
  background-color: ${palette.white};
  border: 1px solid
    ${(props) => (props.color ? props.color : palette.mainOrange)};
  &:hover {
    background-color: ${(props) =>
      props.color ? props.color : palette.mainOrangeHover};
    color: ${palette.white};
  }
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 12px;
`;
//// Panorama Container
const PanoramaContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

//// Setting Panel
const EditWrapper = styled.div`
  height: 100%;
  margin: 10px 0 0 16px;
  letter-spacing: 0px;
  opacity: 1;
`;
const EditTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const EditDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const RowWrapper = styled.div`
  width: 200px;
`;
const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
const DetailTitle = styled.div`
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  color: ${palette.fontDefault};
`;
const DetailSubTitle = styled.div`
  margin-top: 12px;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0px;
  color: ${palette.fontDefault};
`;
const DetailCloseButton = styled.div`
  cursor: pointer;
  font-size: 16px;
  color: ${palette.fontWhite};
  background-color: ${palette.grey_lighter};
  &:hover {
    background-color: ${palette.mainBlueLight};
  }
  padding: 0 12px;
  border-radius: 20px;
`;
const DetailSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DetailDescription = styled.div`
  width: 100%;
  display: flex;
  font-size: 11px;
  color: ${palette.fontDefault};
  line-height: 1.4;
  margin-top: 4px;
`;
const DetailLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.fontBlack};
`;
const DetailValue = styled.div`
  text-align: right;
  font-size: 14px;
  color: ${palette.fontBlack};
`;
const DetailTextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  width: 40px;
`;
const DetailTextareaDescConnect = styled.div``;
const DetailTextarea = styled.textarea`
  all: unset;
  text-align: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '20px')};
  border-bottom: 1px solid #cccccc;
  letter-spacing: 0px;
  opacity: 1;
  margin: 4px 0;
  font-size: ${(props) => (props.font_size ? props.font_size : '14px')};
  color: ${(props) =>
    props.font_color ? props.font_color : palette.fontBlack};
  padding-top: 4px;
`;

const MediaDescTextarea = styled.textarea`
  all: unset;
  text-align: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '300px')};
  border: 1px solid #cccccc;
  letter-spacing: 0px;
  opacity: 1;
  margin: 4px 0;
  font-size: ${(props) => (props.font_size ? props.font_size : '14px')};
  color: ${(props) =>
    props.font_color ? props.font_color : palette.fontBlack};
  padding-top: 4px;
`;
////// Relation
const AttachWrapper = styled.div`
  margin-bottom: 20px;
`;
const RelationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  overflow: scroll;
  border: solid 1px ${palette.borderLightGrey};
  padding: 8px;
`;
const RelationItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;
`;
const RelationItem = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-left: 0px;
  line-height: 24px;
`;
const DetachButton = styled.div`
  height: 24px;
  width: 24px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  font-weight: 800;
  line-height: 24px;
  cursor: pointer;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : palette.buttonDelete};
  color: ${palette.white};
  border-radius: 20px;
  text-align: center;
  &:hover {
    background-color: ${palette.buttonDeleteHover};
  }
`;
const EditButtonWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  margin-top: 10px;
  align-items: end;
  justify-content: center;
  width: 100%;
`;
const HelpButton = styled.div`
  color: ${palette.mainBlue};
  font-size: 16px;
  font-weight: 800;
  border: 2px solid;
  border-radius: 10px;
  width: 20px;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
`;
const HelpWrapper = styled.div`
  position: absolute;
  padding: 10px;
  background-color: ${palette.background};
  width: 200px;
  opacity: 1;
  right: 16px;
  top: 16px;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 0 40px 0 #777777;
`;
const HelpDescription = styled.div`
  width: 100%;
  display: flex;
  font-size: 10px;
  color: ${palette.fontDefault};
  line-height: 1.4;
  margin-top: 4px;
`;
const Button = styled.div`
  background-color: ${palette.mainBlue};
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
  padding: 3px 8px;
  cursor: pointer;
  color: white;
  margin-left: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  white-space: nowrap;
`;

//// COMMON
const Dropdown = styled.select`
  font-size: 12px;
  margin-top: ${(props) => (props.margin_top ? props.margin_top : '0px')};
  margin-left: ${(props) => (props.margin_left ? props.margin_left : '0px')};
  margin-right: ${(props) => (props.margin_right ? props.margin_right : '0px')};
  padding: 3px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid ${palette.mainBlue};
  color: ${palette.fontDefault};
  background-color: ${palette.background};
`;
const CustomContextMenu = styled.ul`
  z-index: 15;
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  width: 150px;
  height: auto;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
`;
const CustomContextMenuItem = styled.li`
  padding: 10px 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
  cursor: pointer;
`;
const ImageUploadInput = styled.input`
  display: none;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const ImageContents = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) =>
    props.uploadedImg === '' ? `${props.initialImg}` : `${props.uploadedImg}`});
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  z-index: 0;
  cursor: pointer;
`;

const ImageUploadButton = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => (props.icon ? props.icon : icon_plus)});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
  cursor: pointer;
`;

const ImageDeleteButton = styled.div`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: url(${icon_trashbin}) 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 8px;
  color: white;
  :hover {
    background-color: ${palette.red};
  }
  cursor: pointer;
`;

const VideoContents = styled.video`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 200px;
  height: 200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  z-index: 0;
`;

const VideoUploadButton = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => (props.icon ? props.icon : icon_plus)});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

const VideoDeleteButton = styled.div`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: url(${icon_trashbin}) 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 8px;
  color: white;
  :hover {
    background-color: ${palette.red};
  }
`;

const VideoUploadInput = styled.input`
  display: none;
`;
const ContentsLoadingWrapper = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
  font-family: Pretendard-r;
  font-size: 10px;
  color: ${palette.fontBlue};
  font-weight: 400;
  gap: 8px;
`;

const FileName = styled.span`
  font-size: 10px;
  color: ${palette.darkGray};
`;

const MultipleImageUploadInput = styled.input`
  display: none;
`;
const FileCount = styled.span`
  font-size: 10px;
  color: ${palette.darkGray};
`;

const ServerUploadButton = styled.button`
  width: 50%;
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  color: ${(props) => (props.disabled ? '#aaa' : '#fff')};
  background-color: ${(props) => (props.disabled ? '#ddd' : '#4caf50')};
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: center;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ddd' : '#388e3c')};
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 좌측 정렬 */
  gap: 16px;
  padding: 16px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-self: center; /* 토글 버튼 중앙 정렬 */
`;

const ToggleButton = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid ${({ active }) => (active ? '#007BFF' : '#ccc')};
  background-color: ${({ active }) => (active ? '#007BFF' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: ${({ active }) => (active ? '#0056b3' : '#f2f2f2')};
  }
`;

const StyledUploadButton = styled.label`
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: ${({ variant }) =>
    variant === 'secondary' ? '#6c757d' : '#007bff'};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${({ variant }) =>
      variant === 'secondary' ? '#5a6268' : '#0056b3'};
  }

  input {
    display: none;
  }
`;

const ActionGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;
const PopupCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const ViewerContainer = styled.div`
  width: 500px;
  height: 500px;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default PanoramaNewModal;
