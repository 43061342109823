import { useEffect, useMemo, useState, useContext } from 'react';
import { GlobalContext } from 'modules/context/GlobalContext';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from 'modules/api/user';
import { spaceAPI } from 'api';
import { useCookies } from 'react-cookie';
import { TailSpin } from 'react-loader-spinner';
import { UserAgent } from 'utils/userAgent';
import SpacePublishSwitchButton from 'components/space/SpacePublishSwitchButton';
import SpacePaidSwitchButton from 'components/space/SpacePaidSwitchButton';
import SpaceUseAppSwitchButton from 'components/space/SpaceUseAppSwitchButton';
import DefaultTable from 'components/table/DefaultTable';
import { ButtonCreate } from 'components/common/DefaultButton';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { pagePaths } from 'modules/defines/paths';
import moment from 'moment-timezone';
import {
  Container,
  TableWrapper,
  LayoutWrapper,
  LoadingText,
  ContainerHeader,
  ContainerHeaderWrapper,
  ContainerHeaderWrapperWrapper,
  ContainerHeaderSubtext,
  PageContainer,
  PageWrapper,
  TableToolBarContainer,
  TableToolBarWrapper,
  SearchWrapper,
  SearchTitle,
  SearchInput,
  MobileToolbarContainer,
  MobileToolbarWrapper,
  MobileToolbarOpenButton,
  MobileAddSpaceBtn,
} from 'components/common/DataTableStyle';
import { palette } from 'modules/defines/styles';
import {
  TableFilterBtn,
  TableFilterBtnDivider,
  TableFilterBtnDesc,
  ExtendBtn,
  RowLink,
  SelectDropdown,
  TableFilterItemBtn,
} from 'components/space/SpaceCommon';
import { formatDate, getDaysBefore } from 'utils/date';
import NewWindow from 'assets/svg/NewWindow';
import Expand from 'assets/svg/Expand';

const SpaceListContainer = () => {
  const { os } = UserAgent;
  const globalState = useContext(GlobalContext);
  const [userContext] = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get('page');
  const [cookies, setCookie] = useCookies();

  const [spaces, setSpaces] = useState(null);
  const refetch = () => {
    globalState.refetch('space');
  };
  const [spaceCount, setSpaceCount] = useState(0);
  const [search, setSearch] = useState('');
  // For FAVIEW AI Data
  const [viewFaviewAI, setViewFaviewAI] = useState(false);
  // (임시) 호반 데이터 관리
  const [viewHoban, setViewHoban] = useState(false);

  // For only FAVIEW Data
  const [viewFaview, setViewFaview] = useState(false);
  // For View excluded type zero
  const [viewExcludedTZ, setViewExcludedTZ] = useState(false);
  // For 펼쳐보기
  const [viewTotal, setViewTotal] = useState(false);
  // For Pagination
  const [pageTotalNum, setPageTotalNum] = useState(0);
  const [pageList, setPageList] = useState([]);
  // 새 창에서 열기
  const [openNewTab, setOpenNewTab] = useState(false);

  const [useEffectTrigger, setUseEffectTrigger] = useState(false);
  // manually sorting
  const [sortManual, setSortManual] = useState({ column: '', dir: '' }); // dir : ASC || DESC
  // manually filtering
  const optionsType = ['• 전체보기', '0', '1', '2', '3', '4', '5'];
  const [filterType, setFilterType] = useState('• 전체보기'); // 필터 : TYPE
  const [filterWriter, setFilterWriter] = useState('• 전체보기'); // 필터 : 작성자
  const [optionsWriter, setOptionsWriter] = useState(['• 전체보기']);
  const [numElementInPage, setNumElementInPage] = useState(
    os.isAndroid || os.ios ? 10 : 15
  );

  // mobile Toolbar
  const [openedToolbarM, setOpenedToolbarM] = useState(false);
  const [toolbarBtnText, setToolbarBtnText] = useState('<');

  // Super-Admin Permission
  const haveSuperAdminPermission = userContext.user.role === 'super-admin';

  // (Data Initialize) Table COLUMN
  const tableColumns = useMemo(() => {
    const columnData = [
      // table columns
      {
        accessor: 'extend_btn',
        Header: '연장',
        Cell: ({ row }) => (
          <ExtendBtn onClick={(e) => onClickExtendBtn(e, row.values.id)}>
            연장
          </ExtendBtn>
        ),
        Filter: false,
        Sort: false,
        Resize: false,
        width: 50,
        align: 'center',
      },
      {
        accessor: 'id',
        Header: 'ID',
        Cell: ({ row }) => (
          <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
            {row.values.id}
          </RowLink>
        ),
        Filter: false,
        Sort: true,
        Resize: false,
        width: 50,
        align: 'center',
      },
      {
        accessor: 'title',
        Header: '공간 이름',
        Cell: ({ row }) => (
          <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
            {row.values.title}
          </RowLink>
        ),
        Filter: false,
        Sort: true,
        Resize: true,
        maxWidth: 200,
        minWidth: 50,
        width: 150,
        align: 'center',
      },
      {
        accessor: 'publish',
        Header: 'PUBLISH FAV앱',
        Cell: ({ row }) => (
          <SpacePublishSwitchButton
            publishMode="app"
            published={row.values.publish}
            id={row.values.id}
            refetchList={refetch}
            havePermission={haveSuperAdminPermission}
          />
        ),
        Filter: false,
        Sort: false,
        Resize: false,
        width: 70,
        align: 'center',
      },
      {
        accessor: 'publishAt',
        Header: '퍼블리시 일자',
        Cell: ({ row }) =>
          row.values.publishAt !== null ? (
            <DatePicker
              className="datePicker"
              dateFormat="yyyy.MM.dd"
              locale={ko}
              shouldCloseOnSelect
              showPopperArrow={false}
              selected={moment(row.values.publishAt, 'YYYY-MM-DD').toDate()}
              onChange={(date) => changePublishDate(row.values.id, date)}
              placeholderText="최초 pubish 필요"
              disabled={!haveSuperAdminPermission}
            />
          ) : null,
        Filter: false,
        width: 90,
        align: 'center',
      },
      {
        accessor: 'publish_instagram',
        Header: 'PUBLISH Insta',
        Cell: ({ row }) => (
          <SpacePublishSwitchButton
            publishMode="insta"
            published={row.values.publish_instagram}
            id={row.values.id}
            refetchList={refetch}
            havePermission={haveSuperAdminPermission}
          />
        ),
        Filter: false,
        Sort: false,
        Resize: false,
        width: 70,
        align: 'center',
      },
      {
        accessor: 'publish_faview',
        Header: 'PUBLISH FAVIEW',
        Cell: ({ row }) =>
          row.original.pano_count !== 0 ? (
            <SpacePublishSwitchButton
              publishMode="faview"
              published={row.values.publish_faview}
              id={row.values.id}
              refetchList={refetch}
            />
          ) : null,
        Filter: false,
        Sort: false,
        Resize: false,
        width: 70,
        align: 'center',
      },
      {
        accessor: 'type',
        Header: 'TYPE',
        Filter: false,
        Sort: false,
        Resize: false,
        maxWidth: 100,
        minWidth: 30,
        width: 40,
        align: 'center',
      },
      {
        accessor: 'use_app',
        Header: '앱 사용예정',
        Cell: ({ row }) => (
          <SpaceUseAppSwitchButton
            use_app={row.values.use_app}
            id={row.values.id}
            refetchList={refetch}
          />
        ),
        Filter: false,
        Sort: false,
        Resize: false,
        width: 60,
        align: 'center',
      },
      {
        accessor: 'description',
        Header: '설명',
        Cell: ({ row }) => (
          <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
            {row.values.description}
          </RowLink>
        ),
        Filter: false,
        Resize: true,
        Sort: false,
        maxWidth: 350,
        minWidth: 150,
        width: 250,
        align: 'left',
      },
      {
        accessor: 'phone',
        Header: '전화번호',
        Filter: false,
        Cell: ({ row }) => (
          <RowLink onClick={(e) => onClickRow(e, row.values.id)} align={'left'}>
            {row.values.phone}
          </RowLink>
        ),
        Resize: true,
        Sort: false,
        maxWidth: 250,
        minWidth: 80,
        width: 140,
        align: 'left',
      },
      {
        accessor: 'address',
        Header: '주소',
        Filter: false,
        Cell: ({ row }) => (
          <RowLink onClick={(e) => onClickRow(e, row.values.id)} align={'left'}>
            {row.values.address}
          </RowLink>
        ),
        Resize: true,
        Sort: false,
        maxWidth: 350,
        minWidth: 150,
        width: 250,
        align: 'left',
      },
      {
        accessor: 'writer',
        Header: '작성자',
        Filter: false,
        Sort: false,
        width: 90,
        align: 'center',
      },
      {
        accessor: 'editAt',
        Header: '작성 일자',
        Filter: false,
        width: 90,
        align: 'center',
      },
      {
        accessor: 'paid',
        Header: '집행완료',
        Cell: ({ row }) =>
          row.original.owner !== 1 ? (
            <SpacePaidSwitchButton
              paid={row.values.paid}
              id={row.values.id}
              refetchList={refetch}
            />
          ) : null,
        Filter: false,
        Sort: true,
        width: 70,
        align: 'center',
      },
      {
        accessor: 'paid_updated_at',
        Header: '집행수정일자',
        Filter: false,
        Sort: true,
        width: 70,
        align: 'center',
      },
      {
        accessor: 'pano_count',
        Header: '파노',
        Filter: false,
        width: 40,
        align: 'center',
      },
      {
        accessor: 'image_main_count',
        Header: '이미지',
        Filter: false,
        width: 40,
        align: 'center',
      },
      {
        accessor: 'keyword_count',
        Header: '키워드',
        Filter: false,
        width: 40,
        align: 'center',
      },
      {
        accessor: 'keyword_vibe_count',
        Header: 'Vibe',
        Filter: false,
        width: 40,
        align: 'center',
      },
      {
        accessor: 'owner',
        Header: '작성자원본',
        Filter: false,
        Sort: false,
        width: 90,
        align: 'center',
      },
      {
        accessor: 'owner_faview',
        Header: 'Faview 공간주',
        Filter: false,
        width: 80,
        align: 'center',
      },
      {
        accessor: 'subdomain',
        Header: '도메인',
        Filter: false,
        width: 120,
        align: 'center',
      },
    ];
    // 권한 : super-admin 일 때
    if (userContext.user.role === 'super-admin') {
      // 진욱님, 찬일님만 집행완료 접근 가능.

      if (
        userContext.user.user_id === 349 ||
        userContext.user.user_id === 184 ||
        userContext.user.user_id === 187
      ) {
        if (viewExcludedTZ) {
          return columnData;
        } else {
          if (!viewFaviewAI && !viewHoban) {
            return columnData.filter(
              (item) =>
                item.accessor !== 'extend_btn'
            )
          } else {
            // faviewAI 혹은 Hoban 데이터 확인 시 (필요한 데이터만 보여주기)
            return columnData.filter(
              (item) =>
                item.accessor !== 'extend_btn' &&
                item.accessor !== 'paid' &&
                item.accessor !== 'paid_updated_at' &&
                item.accessor !== 'use_app' &&
                item.accessor !== 'type' &&
                item.accessor !== 'description' &&
                item.accessor !== 'phone' &&
                item.accessor !== 'address' &&
                item.accessor !== 'writer' &&
                item.accessor !== 'publish' &&
                item.accessor !== 'publishAt' &&
                item.accessor !== 'publish_instagram' &&
                item.accessor !== 'keyword_count' &&
                item.accessor !== 'keyword_vibe_count' &&
                item.accessor !== 'editAt'
            );
          }
        }
      } else {
        if (viewExcludedTZ) {
          return columnData.filter(
            (item) =>
              item.accessor !== 'paid' && item.accessor !== 'paid_updated_at'
          );
        } else {
          if (!viewFaviewAI && !viewHoban) {
            return columnData.filter(
              (item) =>
                item.accessor !== 'extend_btn' &&
                item.accessor !== 'paid' &&
                item.accessor !== 'paid_updated_at'
            );
          } else {
            // faview AI 테스트 데이터 확인용
            return columnData.filter(
              (item) =>
                item.accessor !== 'extend_btn' &&
                item.accessor !== 'paid' &&
                item.accessor !== 'paid_updated_at' &&
                item.accessor !== 'use_app' &&
                item.accessor !== 'type' &&
                item.accessor !== 'description' &&
                item.accessor !== 'phone' &&
                item.accessor !== 'address' &&
                item.accessor !== 'writer' &&
                item.accessor !== 'publish' &&
                item.accessor !== 'publishAt' &&
                item.accessor !== 'publish_instagram' &&
                item.accessor !== 'keyword_count' &&
                item.accessor !== 'keyword_vibe_count' &&
                item.accessor !== 'editAt'
            );
          }
        }
      }
    } else if (userContext.user.role === 'admin') {
      // 권한 : admin 일 때
      return columnData.filter(
        (item) =>
          item.accessor !== 'extend_btn' &&
          item.accessor !== 'paid' &&
          item.accessor !== 'paid_updated_at' &&
          item.accessor !== 'owner' &&
          item.accessor !== 'owner_faview' &&
          item.accessor !== 'subdomain'
      );
    } else {
      // 권한 : curator, editor, marketer
      return columnData.filter(
        (item) =>
          item.accessor !== 'extend_btn' &&
          item.accessor !== 'paid' &&
          item.accessor !== 'paid_updated_at' &&
          item.accessor !== 'publish_faview' &&
          item.accessor !== 'use_app' &&
          item.accessor !== 'owner' &&
          item.accessor !== 'owner_faview' &&
          item.accessor !== 'subdomain'
      );
    }
  }, [
    spaces,
    viewExcludedTZ,
    viewTotal,
    openNewTab,
    viewFaview,
    currentPage,
    useEffectTrigger,
    filterType,
    filterWriter,
    viewFaviewAI,
    viewHoban
  ]);

  // (Data Initialize) TABLE DATA
  const tableData = useMemo(() => {
    if (!spaces) {
      return [];
    }
    if (!viewFaviewAI && !viewHoban) {
      console.log(spaces[0])
      var spacesArr = spaces
        .filter((item) => {
          for (let value of Object.values(item)) {
            if (
              value &&
              String(value).toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        })
        .filter((item) => {
          if (viewFaview) return item.pano_count !== 0;
          else return item;
        })
        .filter((item) => {
          const createdDate = item.createdAt.split('T')[0];
          const dateWeekAgo = formatDate(getDaysBefore(new Date(), 15));
          const exBool =
            createdDate <= dateWeekAgo &&
            item.pano_count === 0 &&
            (item.image_main_count === 0 || item.description.length <= 5);
          if (viewExcludedTZ) return exBool;
          else {
            return !exBool;
          }
        })
        .filter((item) => {
          // faview AI 데이터와 TEST 데이터 제외
          return (item.vid === null || item.vid === undefined) && (!item.type || (!item.type.includes('resom') && !item.type.includes('hoban') && !item.type.includes('avnfr')));
        })
        .map((item) => {
          const descComplete = item.description.length > 5;
          const imageComplete = item.image_main_count > 0;
          const panoComplete = item.pano_count > 0;
          var typeOfData = 0;
          if (item.writer == 'FAV_refer') {
            typeOfData = 4;
          } else {
            if (!panoComplete) {
              typeOfData = 0;
              if (descComplete && imageComplete) typeOfData = 1;
            } else {
              if (!imageComplete) {
                typeOfData = 2;
              } else {
                typeOfData = 3;
                if (descComplete && imageComplete) typeOfData = 5;
              }
            }
          }
          return {
            ...item,
            editAt: moment
              .tz(item.createdAt, 'Asia/Seoul')
              .format('YYYY-MM-DD  HH:mm'),
            type: typeOfData,
          };
        });
      const filteredSpaces = spacesArr.filter((item) => {
        if (filterType === '• 전체보기') return item;
        else return item.type === parseInt(filterType);
      });
      const filteredSpacesWriter = filteredSpaces.filter((item) => {
        if (filterWriter === '• 전체보기') return item;
        else {
          return item.writer === filterWriter;
        }
      });
      return filteredSpacesWriter;
    } else if (viewFaviewAI) {
      // faview AI 데이터만 보기 (FAVIEW 데이터 테스트용)
      var spacesArr = spaces
        .filter((item) => {
          for (let value of Object.values(item)) {
            if (
              value &&
              String(value).toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        })
        .filter((item) => {
          return item.vid !== null;   // vid가 null이 아닌 데이터만 불러오기
        })
        .map((item) => {
          return {
            ...item,
            owner_faview: item.owner_faview,
          }
        });
      return spacesArr;
    } else if (viewHoban) {
      // 호반 데이터만 보기 (FAVIEW 데이터 테스트용)
      var spacesArr = spaces
        .filter((item) => {
          for (let value of Object.values(item)) {
            if (
              value &&
              String(value).toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        })
        .filter((item) => {
          return (item.type && (item.type.includes('hoban') || item.type.includes('resom') || item.type.includes('avnfr')));  // type 에 'resom'을 포함한 데이터만 불러오기
        })
        .map((item) => {
          return {
            ...item,
          }
        });
      return spacesArr;
    }
  }, [
    spaces,
    search,
    viewFaview,
    viewExcludedTZ,
    currentPage,
    useEffectTrigger,
    filterType,
    filterWriter,
    numElementInPage,
    viewFaviewAI,
    viewHoban
  ]);

  // 필터:새창열기 쿠키 사용하여 값 저장되도록
  useEffect(() => {
    if (cookies?.openNewTab) {
      setOpenNewTab(cookies.openNewTab === 'true' ? true : false);
    } else {
      setCookie('openNewTab', 'true');
    }
  }, []);

  // (Data Initialize) 공간 데이터 Initialize
  useEffect(() => {
    setSpaces(globalState.space);
    setSpaceCount(globalState.space.length);
    // 작성한지 오래된 데이터 처리 /////////////////////
    const spaceOldDatas = [];
    const spacesTemp = globalState.space;
    // 작성자 리스트 가져오기
    const writersSoFar = [];
    spacesTemp.map((space, idx) => {
      if (!writersSoFar.includes(space.writer)) {
        writersSoFar.push(space.writer);
      }
      const createdDate = space.createdAt.split('T')[0];
      if (
        createdDate <= formatDate(getDaysBefore(new Date(), 11)) &&
        formatDate(getDaysBefore(new Date(), 15)) < createdDate &&
        space.pano_count === 0 &&
        space.owner === userContext.user.user_id &&
        space.owner !== 1
      ) {
        if (space.image_main_count === 0 || space.description.length <= 5) {
          spaceOldDatas.push(space);
        }
      }
    });

    if (spaceOldDatas.length !== 0) {
      var spaceOldDataStr = '';
      spaceOldDatas.map((item) => (spaceOldDataStr += item.title + ','));
      alert(
        `작성한지 11일 이상 된 완성되지 않은 데이터가 있습니다.\n${spaceOldDataStr}\n\n해당 데이터는 14일 이후 리스트에서 자동 제외됩니다.`
      );
    }
    setOptionsWriter(['• 전체보기'].concat(writersSoFar));
  }, [globalState.space]);

  // (Pagination) 펼쳐보기에 따른 보기 설정
  useEffect(() => {
    if (currentPage === null) history.push(`${pagePaths.space.list}?page=1`);
    if (currentPage === '0') setViewTotal(true);
    else setViewTotal(false);
  }, [currentPage]);

  // 검색 시 펼쳐보기 자동 비활성화 -> page 1로 자동으로 이동
  useEffect(() => {
    if (currentPage !== '1') {
      history.push(`${pagePaths.space.list}?page=1`);
    }
  }, [search]);

  // 공간 데이터 개수와 총 페이지 수 계산 및 적용
  useEffect(() => {
    if (!tableData) {
      return 0;
    } else if (tableData === null) {
      return 0;
    } else {
      setSpaceCount(tableData?.length);
    }
    setPageTotalNum(Math.ceil(tableData?.length / numElementInPage));
  }, [tableData?.length, numElementInPage]);

  // (Pagination) Intialize
  useEffect(() => {
    setPageList(getRange(currentPage, pageTotalNum));
  }, [pageTotalNum, currentPage]);

  // (Pagination) 띄워줄 페이지 넘버 리스트들을 불러오는 함수
  const getRange = (currentPage, pageTotalNum) => {
    var pageOrderNum = Math.floor((currentPage - 1) / 10);
    var array = [];
    if (pageOrderNum >= Math.floor((pageTotalNum - 1) / 10)) {
      for (var i = pageOrderNum * 10 + 1; i <= pageTotalNum; i++) {
        array.push(i);
      }
    } else {
      for (var i = pageOrderNum * 10 + 1; i < pageOrderNum * 10 + 11; i++) {
        array.push(i);
      }
    }
    // (Exception) array가 줄어들어서 current가 array에 벗어날 경우
    if (!array.includes(Number(currentPage))) {
      history.push(`${pagePaths.space.list}?page=1`);
    }
    return array;
  };

  useEffect(() => {
    if (openedToolbarM) setToolbarBtnText('>');
    else setToolbarBtnText('<');
  }, [openedToolbarM]);

  // 공간 새로 추가
  const onClickCreateSpace = async (e) => {
    e.preventDefault();
    let postData = {
      title: '-',
      description: '-',
      description2: '-',
      address: '-',
      phone: '-',
      time: '-',
      menu: '-',
      owner: userContext.user.user_id,
      type: viewHoban ? 'hoban' : null,
    };
    console.log(postData);

    if (Object.values(postData).slice(2, 4).includes('')) {
      alert('제목과 설명을 입력하세요.');
      return;
    }
    try {
      let response = await spaceAPI.spaceRegister(postData);
      refetch();
      alert('공간정보가 생성되었습니다.');
      history.push(
        pagePaths.space.detail.replace(':id', response.data.space.id)
      );
    } catch (e) {
      console.error(e);
      alert(
        '공간정보를 생성하는 중, 에러가 발생했습니다.\n관리자에게 문의해주세요.'
      );
    }
  };

  // (필터) 제외 데이터 보기
  const onClickViewExcluded = (e) => {
    e.preventDefault();
    setViewExcludedTZ((prev) => !prev);
    setViewFaviewAI(false);
    setViewHoban(false);
    setViewFaview(false);
  };

  // (필터) FAVIEW 공간만 보기
  const onClickViewFaview = (e) => {
    e.preventDefault();
    setViewFaview((prev) => !prev);
    setViewFaviewAI(false);
    setViewExcludedTZ(false);
  };

  // (필터) FAVIEW AI 공간 보기 (곤리자 테스트용)
  const onClickViewFaviewAI = (e) => {
    e.preventDefault();
    setViewFaviewAI((prev) => !prev);
    setViewHoban(false);
    setViewFaview(false);
    setViewExcludedTZ(false);
  };

  const onClickViewHoban = (e) => {
    e.preventDefault();
    setViewHoban((prev) => !prev);
    setViewFaviewAI(false);
    setViewFaview(false);
    setViewExcludedTZ(false);
  }

  // (보기 설정) 공간 전체보기 (펼쳐보기)
  const onClickTotalView = (e) => {
    e.preventDefault();
    setViewTotal((prev) => !prev);
    if (viewTotal) history.push(`${pagePaths.space.list}?page=1`);
    else history.push(`${pagePaths.space.list}?page=0`);
  };

  // (보기 설정) 새창 열기 (새 탭에서 열리도록 설정)
  const onClickNewTab = (e) => {
    e.preventDefault();
    setCookie('openNewTab', !openNewTab);
    setOpenNewTab((prev) => !prev);
  };

  // 제외데이터 '연장' 버튼
  const onClickExtendBtn = async (e, id) => {
    e.preventDefault();
    let postData = {
      sid: id,
    };
    await spaceAPI.extendExpired(postData).then((response) => {
      alert('작성일자가 오늘로 수정되었습니다.');
      // refetch(); 240425 찬일님 요청으로 refetch 삭제
    });
  };

  const changePublishDate = async (id, date) => {
    let postData = {
      id: id,
      publishAt: date,
    };
    await spaceAPI.editSpacePublishAt(postData).then((response) => {
      alert('퍼블리시 일자가 수정되었습니다.');
      refetch();
    });
  };

  // 공간 디테일 페이지로 이동 (Click Row)
  const onClickRow = (e, spaceId) => {
    e.preventDefault();
    if (openNewTab)
      window.open(
        pagePaths.base + pagePaths.space.detail.replace(':id', spaceId),
        '_blank'
      );
    else history.push(pagePaths.space.detail.replace(':id', spaceId));
  };

  // (Pagination) page 이동
  const movePageListener = (e, clickedPageNum) => {
    e.preventDefault();
    history.push(`${pagePaths.space.list}?page=${clickedPageNum}`);
  };

  // (Pagination) page 한 번에 이동
  const movePageChunkListener = (e, direction, endward) => {
    e.preventDefault();
    var movePage = 0;
    if (!endward) {
      if (direction === 'forward') {
        movePage = (Math.floor((currentPage - 1) / 10) + 1) * 10 + 1;
      } else if (direction === 'backward') {
        movePage = (Math.floor((currentPage - 1) / 10) - 1) * 10 + 10;
      }
    } else {
      if (direction === 'forward') {
        movePage = pageTotalNum;
      } else if (direction === 'backward') {
        movePage = 1;
      }
    }
    if (movePage > 0 && movePage <= pageTotalNum) {
      history.push(`${pagePaths.space.list}?page=${movePage}`);
    }
  };

  // (Sorting) sort 값에 따라 space 데이터 정렬 변경
  useEffect(() => {
    if (sortManual.column !== '') {
      if (sortManual.dir === 'ASC') {
        if (typeof spaces[0][sortManual.column] === 'number') {
          setSpaces(
            spaces.sort((a, b) => a[sortManual.column] - b[sortManual.column])
          );
        } else if (typeof spaces[0][sortManual.column] === 'string') {
          setSpaces(
            spaces.sort((a, b) =>
              a[sortManual.column].localeCompare(b[sortManual.column])
            )
          );
        } else {
          setSpaces(
            spaces.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          );
        }
      } else if (sortManual.dir === 'DESC') {
        if (typeof spaces[0][sortManual.column] === 'number') {
          setSpaces(
            spaces.sort((a, b) => b[sortManual.column] - a[sortManual.column])
          );
        } else if (typeof spaces[0][sortManual.column] === 'string') {
          setSpaces(
            spaces.sort((a, b) =>
              b[sortManual.column].localeCompare(a[sortManual.column])
            )
          );
        } else {
          setSpaces(
            spaces.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          );
        }
      } else {
        setSpaces(spaces.sort((a, b) => b.id - a.id));
      }
      setUseEffectTrigger(!useEffectTrigger);
    }
  }, [sortManual]);

  // Table Header 클릭 시 Sorting
  const tableHeaderSortListener = (e, column_id) => {
    e.preventDefault();
    if (sortManual.dir === 'ASC') {
      setSortManual((prev) => ({ ...prev, column: 'id', dir: '' }));
    } else if (sortManual.dir === 'DESC') {
      setSortManual((prev) => ({ ...prev, column: column_id, dir: 'ASC' }));
    } else {
      setSortManual((prev) => ({ ...prev, column: column_id, dir: 'DESC' }));
    }
  };

  const handleSelectListener = (e, filterType) => {
    if (filterType === 'type') setFilterType(e.target.value);
    else if (filterType === 'writer') setFilterWriter(e.target.value);
  };

  // Pagination Resp
  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 540) {
        setNumElementInPage(5);
      } else if (window.innerHeight < 490) {
        setNumElementInPage(6);
      } else if (window.innerHeight < 580) {
        setNumElementInPage(7);
      } else if (window.innerHeight < 680) {
        setNumElementInPage(8);
      } else if (window.innerHeight < 750) {
        setNumElementInPage(10);
      } else if (window.innerHeight < 880) {
        setNumElementInPage(12);
      } else if (window.innerHeight < 1000) {
        setNumElementInPage(15);
      } else if (window.innerHeight < 1100) {
        setNumElementInPage(18);
      } else if (window.innerHeight < 1200) {
        setNumElementInPage(20);
      } else if (window.innerHeight < 1300) {
        setNumElementInPage(22);
      } else if (window.innerHeight < 1400) {
        setNumElementInPage(25);
      } else if (window.innerHeight < 1600) {
        setNumElementInPage(27);
      } else {
        setNumElementInPage(30);
      }
    };
    // 이벤트 리스너 등록
    window.addEventListener('resize', handleResize);
    // 초기 렌더링에서 한 번 실행
    handleResize();
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      {(os.isAndroid || os.isIos) && (
        <>
          <ContainerHeaderWrapper>
            <ContainerHeaderWrapperWrapper>
              <ContainerHeader>공간 관리</ContainerHeader>
              <ContainerHeaderSubtext>{spaceCount}곳</ContainerHeaderSubtext>
            </ContainerHeaderWrapperWrapper>
            <MobileToolbarContainer isClicked={openedToolbarM}>
              {openedToolbarM ? (
                <MobileToolbarWrapper>
                  {/* Filter Type */}
                  <SelectDropdown
                    type="select"
                    onChange={(e) => handleSelectListener(e, 'type')}
                  >
                    <option value="" disabled selected={true}>
                      TYPE
                    </option>
                    {optionsType.map((opt, idx) => {
                      return (
                        <option value={opt} key={idx + '_option_type'}>
                          {opt}
                        </option>
                      );
                    })}
                  </SelectDropdown>
                  {/* Filter 작성자 */}
                  <SelectDropdown
                    value={filterWriter}
                    type="select"
                    onChange={(e) => handleSelectListener(e, 'writer')}
                  >
                    <option value="" disabled selected={true}>
                      작성자
                    </option>
                    {optionsWriter.map((opt, idx) => {
                      return (
                        <option value={opt} key={idx + '_option_writer'}>
                          {opt}
                        </option>
                      );
                    })}
                  </SelectDropdown>
                  {/* Filter FAVIEW */}
                  <TableFilterBtn
                    enabled={viewFaview}
                    onClick={onClickViewFaview}
                  >
                    FAVIEW
                  </TableFilterBtn>
                  {/* 제외데이터 보기 */}
                  {haveSuperAdminPermission && (
                    <TableFilterBtn
                      enabled={viewExcludedTZ}
                      onClick={onClickViewExcluded}
                    >
                      제외
                    </TableFilterBtn>
                  )}
                  <MobileAddSpaceBtn onClick={onClickCreateSpace}>
                    +
                  </MobileAddSpaceBtn>
                </MobileToolbarWrapper>
              ) : (
                <SearchInput
                  width="250px"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
              <MobileToolbarOpenButton
                onClick={() => setOpenedToolbarM((prev) => !prev)}
              >
                {toolbarBtnText}
              </MobileToolbarOpenButton>
            </MobileToolbarContainer>
          </ContainerHeaderWrapper>
        </>
      )}
      {(os.isOthers || os.isMac) && (
        <ContainerHeaderWrapper>
          <ContainerHeaderWrapperWrapper>
            <ContainerHeader>
              공간 관리
            </ContainerHeader>
            <ContainerHeaderSubtext>{spaceCount}곳</ContainerHeaderSubtext>
          </ContainerHeaderWrapperWrapper>

          <TableToolBarContainer>
            <TableToolBarWrapper>
              <TableFilterBtnDesc>보기 설정</TableFilterBtnDesc>
              {/* 펼쳐보기 */}
              {/* <TableFilterBtn enabled={viewTotal} onClick={onClickTotalView}>
                펼쳐보기
              </TableFilterBtn> */}
              {/* View New Tab */}
              {/* <TableFilterBtn enabled={openNewTab} onClick={onClickNewTab}>
                새창열기
              </TableFilterBtn> */}
              {/* 펼쳐보기 */}
              <TableFilterItemBtn enabled={viewTotal} onClick={onClickTotalView} >
                <Expand color={"#ffffff"} size={"20px"} />
              </TableFilterItemBtn>
              {/* View New Tab */}
              <TableFilterItemBtn enabled={openNewTab} onClick={onClickNewTab} src={NewWindow}>
                <NewWindow color={"#ffffff"} size={"24px"} />
              </TableFilterItemBtn>
              <TableFilterBtnDivider />
              <TableFilterBtnDesc>필터</TableFilterBtnDesc>
              {/* Filter Type */}
              <SelectDropdown
                type="select"
                onChange={(e) => handleSelectListener(e, 'type')}
              >
                <option value="" disabled selected={true}>
                  TYPE
                </option>
                {optionsType.map((opt, idx) => {
                  return (
                    <option value={opt} key={idx + '_option_type'}>
                      {opt}
                    </option>
                  );
                })}
              </SelectDropdown>
              {/* Filter 작성자 */}
              <SelectDropdown
                value={filterWriter}
                type="select"
                onChange={(e) => handleSelectListener(e, 'writer')}
              >
                <option value="" disabled selected={true}>
                  작성자
                </option>
                {optionsWriter.map((opt, idx) => {
                  return (
                    <option value={opt} key={idx + '_option_writer'}>
                      {opt}
                    </option>
                  );
                })}
              </SelectDropdown>
              {/* Filter FAVIEW */}
              <TableFilterBtn enabled={viewFaview} onClick={onClickViewFaview}>
                FAVIEW
              </TableFilterBtn>
              {/* 제외데이터 보기 */}
              {haveSuperAdminPermission && (
                <TableFilterBtn
                  enabled={viewExcludedTZ}
                  onClick={onClickViewExcluded}
                >
                  제외
                </TableFilterBtn>
              )}
              {/* 파뷰 AI 데이터만 보기 */}
              {haveSuperAdminPermission && (
                <TableFilterBtn
                  enabled={viewFaviewAI}
                  onClick={onClickViewFaviewAI}
                >
                  파뷰AI
                </TableFilterBtn>
              )}
              {haveSuperAdminPermission && (
                <TableFilterBtn
                  enabled={viewHoban}
                  onClick={onClickViewHoban}
                >
                  호반
                </TableFilterBtn>
              )}
            </TableToolBarWrapper>
            <TableToolBarWrapper>
              {/* 검색 */}
              <SearchWrapper>
                <SearchTitle>검색 : </SearchTitle>
                <SearchInput
                  width="160px"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </SearchWrapper>
              {/* 공간 추가 버튼 */}
              <ButtonCreate
                text={'공간 추가'}
                active={false}
                onClick={onClickCreateSpace}
              />
            </TableToolBarWrapper>
          </TableToolBarContainer>
        </ContainerHeaderWrapper>
      )}
      <LayoutWrapper viewTotal={viewTotal} isPaginated={true}>
        {globalState.loadingSpace ? (
          <LoadingText>
            Loading...
            <br />
            데이터 로딩 중입니다...
            <TailSpin color={palette.mainBlue} width={50} height={50} />
          </LoadingText>
        ) : (
          tableData &&
          tableColumns && (
            <TableWrapper>
              <DefaultTable
                columns={tableColumns}
                data={tableData}
                numElementInPage={numElementInPage}
                currentPage={currentPage}
                tableHeaderSortListener={tableHeaderSortListener}
                sortManual={sortManual}
              />
              {currentPage !== '0' && (
                <PageContainer>
                  <PageWrapper
                    onClick={(e) => movePageChunkListener(e, 'backward', true)}
                  >
                    &lt;&lt;
                  </PageWrapper>
                  <PageWrapper
                    onClick={(e) => movePageChunkListener(e, 'backward', false)}
                  >
                    &lt;
                  </PageWrapper>
                  {pageList.map((page, idx) => {
                    if (page == currentPage)
                      return (
                        <PageWrapper
                          key={idx + '_page_wrapper_1'}
                          current={true}
                        >
                          {page}
                        </PageWrapper>
                      );
                    else
                      return (
                        <PageWrapper
                          key={idx + '_page_wrapper_2'}
                          onClick={(e) => movePageListener(e, page)}
                          current={false}
                        >
                          {page}
                        </PageWrapper>
                      );
                  })}

                  <PageWrapper
                    onClick={(e) => movePageChunkListener(e, 'forward', false)}
                  >
                    &gt;
                  </PageWrapper>

                  <PageWrapper
                    onClick={(e) => movePageChunkListener(e, 'forward', true)}
                  >
                    &gt;&gt;
                  </PageWrapper>
                </PageContainer>
              )}
            </TableWrapper>
          )
        )}
      </LayoutWrapper>
    </Container>
  );
};

export default SpaceListContainer;
