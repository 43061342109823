import { createContext, useState, useEffect } from 'react';
import { theme } from 'modules/defines/names';
import {
  keywordAPI,
  categoryAPI,
  filterAPI,
  spaceAPI,
  userAPI,
} from 'modules/api';

export const GlobalContext = createContext();

const defaultState = {
  keyword: [],
  loadingKeyword: true,
  category: [],
  loadingCategory: true,
  filter: [],
  loadingFilter: true,
  space: [],
  loadingSpace: true,
  user: [],
  loadingUser: true,
  filterValue: {},
  theme: localStorage.getItem('theme') || theme.light,
};

export const useGlobalContext = () => {
  const [globalState, setGlobalState] = useState(defaultState);

  const setFilterValue = (key, value) => {
    setGlobalState((prev) => ({
      ...prev,
      filterValue: { ...prev.filterValue, ...{ [key]: value } },
    }));
  };

  const updateFilter = (value) => {
    setGlobalState((prev) => ({
      ...prev,
      filter: value,
      loadingFilter: false,
    }));
  };

  const updateKeyword = (value) => {
    setGlobalState((prev) => ({
      ...prev,
      keyword: value,
      loadingKeyword: false,
    }));
  };

  const updateCategory = (value) => {
    setGlobalState((prev) => ({
      ...prev,
      category: value,
      loadingCategory: false,
    }));
  };

  const updateSpace = (value) => {
    setGlobalState((prev) => ({
      ...prev,
      space: value,
      loadingSpace: false,
    }));
  };

  const updateUser = (value) => {
    setGlobalState((prev) => ({
      ...prev,
      user: value,
      loadingUser: false,
    }));
  };

  const refetch = (key) => {
    switch (key) {
      case 'space': {
        setGlobalState((prev) => ({
          ...prev,
          loadingSpace: true,
        }));
        spaceAPI.getSpaceAll().then((space) => {
          updateSpace(space);
        });
        break;
      }
      case 'keyword': {
        setGlobalState((prev) => ({
          ...prev,
          loadingKeyword: true,
        }));
        keywordAPI.getKeywordAll().then((keyword) => {
          updateKeyword(keyword);
        });
        break;
      }
      case 'filter': {
        setGlobalState((prev) => ({
          ...prev,
          loadingFilter: true,
        }));
        filterAPI.getFilterAll().then((filter) => {
          updateFilter(filter);
        });
        break;
      }
      case 'category': {
        setGlobalState((prev) => ({
          ...prev,
          loadingCategory: true,
        }));
        categoryAPI.getCategoryAll().then((category) => {
          updateCategory(category);
        });
        break;
      }
      case 'user': {
        setGlobalState((prev) => ({
          ...prev,
          loadingUser: true,
        }));
        userAPI.getUserAll().then((user) => {
          updateUser(user);
        });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    spaceAPI.getSpaceAll().then((space) => {
      updateSpace(space);
    });
    keywordAPI.getKeywordAll().then((keyword) => {
      updateKeyword(keyword);
    });

    categoryAPI.getCategoryAll().then((category) => {
      updateCategory(category);
    });

    filterAPI.getFilterAll().then((filter) => {
      updateFilter(filter);
    });

    userAPI.getUserAll().then((user) => {
      updateUser(user);
    });
  }, []);

  return {
    ...globalState,
    setGlobalState,
    setFilterValue,
    updateFilter,
    updateKeyword,
    updateCategory,
    refetch,
  };
};
