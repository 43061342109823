import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { TextureLoader, BackSide, RepeatWrapping } from 'three';
import CameraControlDirectionDrag from 'components/panorama/CameraControlDirectionDrag';
import { arrow_minimap } from 'assets/img/icon';
import { palette } from 'modules/defines/styles';

const normalizeAngle = (angle) => {
  const normalized = ((angle + 180) % 360) - 180;
  return normalized < -180 ? normalized + 360 : normalized;
};

const EquirectangularViewer = ({
  panoId,
  imageUrl,
  position,
  initialAngle = 0,
  onSaveAngle,
  onCloseViewer,
}) => {
  const texture = useLoader(TextureLoader, imageUrl);

  const [currentAngle, setCurrentAngle] = useState(0);

  useEffect(() => {
    if (texture) {
      texture.wrapS = RepeatWrapping;
      texture.repeat.x = -1;

      const offsetX = normalizeAngle(initialAngle) / 360;
      texture.offset.x = offsetX;
    }
  }, [texture, initialAngle]);
  const combinedAngle = normalizeAngle(initialAngle + currentAngle);
  const isSaveButtonActive =
    Math.abs(normalizeAngle(initialAngle - combinedAngle)) > 0.01;

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          zIndex: 10,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: '15px',
          borderRadius: '10px',
          color: 'white',
          fontSize: '14px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          opacity: 0.9,
        }}
      >
        {/* 각도 정보 */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <p>설정 각도: {normalizeAngle(initialAngle).toFixed(1)}°</p>
            <p>변화량: {currentAngle.toFixed(1)}°</p>
            <p>현재 각도: {combinedAngle.toFixed(1)}°</p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              height: '40px',
              backgroundColor: palette.mainBlue,
              borderRadius: '10px',
            }}
          >
            <img
              src={arrow_minimap}
              alt="Arrow Icon"
              style={{
                width: '20px',
                height: '20px',
                filter: 'invert(1)',
                transform: `rotate(${combinedAngle}deg)`,
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </div>
        </div>

        {/* 버튼들 */}
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            style={{
              backgroundColor: isSaveButtonActive ? '#007bff' : '#a9a9a9',
              color: 'white',
              border: 'none',
              padding: '10px 15px',
              borderRadius: '5px',
              cursor: isSaveButtonActive ? 'pointer' : 'not-allowed',
              fontSize: '14px',
            }}
            onClick={(e) =>
              onSaveAngle(e, panoId, (initialAngle + currentAngle).toFixed(1))
            }
            disabled={!isSaveButtonActive}
          >
            각도 저장
          </button>
          <button
            style={{
              backgroundColor: '#dc3545',
              color: 'white',
              border: 'none',
              padding: '10px 15px',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '14px',
            }}
            onClick={onCloseViewer}
          >
            닫기
          </button>
        </div>
      </div>

      <Canvas
        camera={{
          position: position,
          fov: 90,
          rotation: new THREE.Euler(0, -Math.PI / 2, 0, 'YXZ'),
        }}
        style={{ height: '500px', width: '500px', margin: 0, padding: 0 }}
      >
        <mesh position={position} name="EquirectangularViewer" visible={true}>
          <sphereGeometry args={[1, 30, 20]} />
          <meshBasicMaterial
            map={texture}
            side={BackSide}
            transparent={true}
            opacity={1}
            depthTest={false}
            depthWrite={false}
          />
        </mesh>
        <CameraControlDirectionDrag setCurrentAngle={setCurrentAngle} />
      </Canvas>
    </div>
  );
};

export default EquirectangularViewer;
