import { palette } from 'modules/defines/styles';
import { useState, useEffect } from 'react';
import useInputState from 'modules/customHooks/useInputState';
import styled from 'styled-components';
import { spaceAPI } from 'api';

const ImageDetailModal = ({ closeModal, url, imgId = 0, imageDataRefetch = null }) => {
  // set 'ESC' keystroke to escape
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);


  const deleteImageListener = async () => {
    if (!window.confirm('해당 이미지를 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: imgId,
    };
    let error = false;
    try {
      const response = await spaceAPI.deleteImage(postData);
      if (response.status === 200) {
        closeModal();
      }
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageDataRefetch();
    }
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <TitleContainer>
          <Title>이미지 자세히보기</Title>
        </TitleContainer>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </TitleWrapper>
      <ImageContainer src={url} />
      <ButtonWrapper>
        <ButtonConfirm>
          <a href={url} download>
            다운로드
          </a>
        </ButtonConfirm>
        {imgId === 0 ?
          <ButtonCancel onClick={() => closeModal()}>닫기</ButtonCancel>
          : (
            <ButtonCancel onClick={deleteImageListener}>삭제</ButtonCancel>
          )}


      </ButtonWrapper>
    </Wrapper>
  );
};
export default ImageDetailModal;

const Wrapper = styled.div`
  max-width: 80wh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
`;
// =====  T I T L E  =====
const TitleWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  color: ${palette.fontBlack};
  font-size: 20px;
  font-weight: 700;
`;
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;
const ButtonCancel = styled.div`
  background-color: ${palette.buttonCancel};
  color: ${palette.fontWhite};
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 80px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.buttonCancelHover};
  }
`;
const ButtonConfirm = styled.div`
  background-color: ${palette.mainBlue};
  color: ${palette.fontWhite};
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 80px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
`;
const ImageContainer = styled.img`
  margin-top: 20px;
  height: calc(100vh - 320px);
`;
