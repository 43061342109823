import PushNotificationListContainer from 'containers/push/PushNotificationListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const PushNotificationList = () => {
  return (
    <GlobalContainer>
      <PushNotificationListContainer />
    </GlobalContainer>
  );
};

export default PushNotificationList;
