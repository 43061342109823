import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const FaviewAPI = (() => {
    class FaviewAPIConstructor {
        getFaviewCouponData() {
            return httpAPI(apiPaths.getFaviewCouponData, { method: 'GET' });
        }
    }
    return FaviewAPIConstructor;
})();

export const faviewAPI = new FaviewAPI();
