import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'modules/api/user';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { pagePaths } from 'modules/defines/paths';
import { palette } from 'modules/defines/styles';
import { eventAPI } from 'modules/api';
import { ButtonCancel, ButtonConfirm } from 'components/common/DefaultButton';
import { icon_imgadd, icon_plus } from 'assets/img/icon';
import EditorComponent from 'components/common/EditorComponent';
import SmallTable from 'components/table/SmallTable';
import { Filter } from 'components/common/Filter';
import { Container, Title, SubTitle } from 'components/common/DataTableStyle';
import {
  LayoutWrapper,
  CommonContainer,
} from 'components/appManagement/AppManagementCommon';
import { CSVLink } from 'react-csv';

const EventDetailContainer = () => {
  const history = useHistory();
  const [convertedText, setConvertedText] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [announceDate, setAnnounceDate] = useState(new Date());
  const [titleText, setTitleText] = useState('');
  const [subtitleText, setSubtitleText] = useState('');
  const [winnerCount, setWinnerCount] = useState(0);
  const [bannerImage, setBannerImage] = useState();
  const [detailImage, setDetailImage] = useState();
  const [appliedUsers, setAppliedUsers] = useState(null);
  const [userContext] = useContext(UserContext);
  const { id } = useParams();

  // 우클릭 세팅
  const [show, setShow] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [choosedPicId, setChoosedPicId] = useState(0);
  const [imageLink, setImageLink] = useState('');
  const [imageType, setImageType] = useState('');
  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }
  const havePermission = userContext.user.role.match('admin') !== null;

  const goBack = () => {
    history.goBack();
  };
  const onInputTitle = (e) => {
    setTitleText(e.target.value);
  };
  const onInputSubtitle = (e) => {
    setSubtitleText(e.target.value);
  };
  const onInputCount = (e) => {
    setWinnerCount(e.target.value);
  };

  const uploadImageListener = async (e, type) => {
    var temp_list = [...e.target.files].sort((a, b) => {
      if (type === 'event') {
        if (Number(a.name.split('.')[0]) > Number(b.name.split('.')[0]))
          return 1;
        else if (Number(a.name.split('.')[0]) === Number(b.name.split('.')[0]))
          return 0;
        else return -1;
      } else {
        if (
          Number(a.name.split('.')[0].split('_')[1]) >
          Number(b.name.split('.')[0].split('_')[1])
        )
          return 1;
        else if (
          Number(a.name.split('.')[0].split('_')[1]) ===
          Number(b.name.split('.')[0].split('_')[1])
        )
          return 0;
        else return -1;
      }
    });
    for (let i = 0; i < temp_list.length; i++) {
      let headers = {
        id: id,
        type: type,
      };
      let file = temp_list[i];
      let blob = file.slice(0, file.size, file.type);
      let postData = new FormData();
      postData.append('img', new File([blob], id + '_' + temp_list[i].name));

      await eventAPI.uploadImage(postData, headers).then((response) => {
        if (response.url) {
          imageRefetch();
        } else {
          alert(
            '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
      });
    }
  };

  const uploadBannerImageListener = async (e, type) => {
    let file = e.target.files[0];
    let blob = file.slice(0, file.size, file.type);
    let postData = new FormData();
    let headers = {
      id: id,
      type: type,
    };
    postData.append(
      'img',
      new File([blob], id + '_banner_' + e.target.files[0].name)
    );
    let error = false;
    try {
      await eventAPI.uploadImage(postData, headers);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageRefetch();
    }
  };

  const deleteImageListener = async (e, choosedPicId) => {
    if (!window.confirm('해당 이미지를 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: choosedPicId,
    };
    let error = false;
    try {
      const response = await eventAPI.deleteImage(postData);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageRefetch();
    }
  };

  const imageRefetch = async () => {
    eventAPI.getEventImage(id).then((response) => {
      setBannerImage(response.filter((data) => data.type === 'banner'));
      setDetailImage(response.filter((data) => data.type === 'event'));
    });
  };

  useEffect(() => {
    eventAPI.getEventSingle(id).then((response) => {
      setConvertedText(response.body);
      if (response.start_date !== null || response.start_date !== '') {
        setStartDate(new Date(response.start_date));
      }
      if (response.end_date !== null || response.end_date !== '') {
        setEndDate(new Date(response.end_date));
      }
      if (response.announce_date !== null || response.announce_date !== '') {
        setAnnounceDate(new Date(response.announce_date));
      }
      setTitleText(response.title);
      setSubtitleText(response.subtitle);
      setWinnerCount(response.winner_count);
      setAppliedUsers(response.user);
      imageRefetch();
    });
  }, []);

  const userTableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      width: 70,
    },
    {
      accessor: 'name',
      Header: '이름',
      Filter: false,
      width: 120,
    },
    {
      accessor: 'createdAt',
      Header: '회원가입',
      Filter: false,
      width: 150,
    },
    {
      accessor: 'instagram',
      Header: '인스타ID',
      Filter: false,
      width: 150,
    },
    {
      accessor: 'appliedTime',
      Header: '응모날짜',
      Filter: false,
      width: 150,
    },
    {
      accessor: 'result',
      Header: '상태',
      Filter: Filter,
      width: 100,
    },
  ]);

  const onClickRow = async (e, row) => {
    e.preventDefault();
    let postData = {
      id: id,
      user_id: row.original.id,
    };
    if (
      window.confirm(
        `${row.original.id} ${row.original.instagram}님의 상태를 당첨으로 변경합니다.`
      )
    ) {
      try {
        eventAPI.pickEventWinner(postData).then((response) => {
          if (response.success) {
            alert('사용자 이벤트 당첨상태를 변경하였습니다.');
          } else {
            alert(response.message);
          }
        });
      } catch (e) {
        alert(
          '사용자 이벤트 당첨상태를 변경하는 도중, 에러가 발생했습니다.\n관리자에게 문의해주세요.'
        );
      }
    } else {
      alert('취소되었습니다.');
    }
  };

  const userTableData = useMemo(() => {
    if (appliedUsers === null) {
      return [];
    }
    return appliedUsers.map((item) => ({
      ...item,
      result: item.EventUser?.result,
      createdAt: item.createdAt.split('T')[0],
      appliedTime:
        item.EventUser?.createdAt.split('T')[0] +
        ' ' +
        item.EventUser?.createdAt.split('T')[1].split('.')[0],
      instagram: item.EventUser?.instagram,
    }));
  }, [appliedUsers]);

  const submit = async (e) => {
    e.preventDefault();
    let postData = {
      id: id,
      title: titleText,
      subtitle: subtitleText,
      body: convertedText,
      winner_count: winnerCount,
      start_date: new Date(startDate).toLocaleDateString('ko-KR'),
      end_date: new Date(endDate).toLocaleDateString('ko-KR'),
      announce_date: new Date(announceDate).toLocaleDateString('ko-KR'),
    };
    try {
      eventAPI.editEvent(postData).then((response) => {
        alert('이벤트 정보가 수정되었습니다.');
      });
    } catch (e) {
      alert(
        '이벤트를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의해주세요.'
      );
    }
  };

  const csvData = [
    ['id', 'name', 'createdAt', 'instagram', 'appliedTime', 'result'],
    ...userTableData.map(
      ({ id, name, createdAt, instagram, appliedTime, result }) => [
        id,
        name,
        createdAt,
        instagram,
        appliedTime,
        result,
      ]
    ),
  ];

  return (
    <Container>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>이벤트 추가</Title>
          <SubTitle>이벤트 추가 페이지입니다.</SubTitle>
        </TitleWrapper>
        <ButtonWrapper>
          <ButtonCancel onClick={goBack} text="취소" />
          <CSVLink
            className="downloadbtn"
            data={csvData}
            filename={`${titleText}_응모자.csv`}
          >
            명단저장
          </CSVLink>
          <ButtonConfirm onClick={submit} text="이벤트 저장" />
        </ButtonWrapper>
      </HeaderWrapper>
      <LayoutWrapper fromBottom="100px">
        <CommonContainer>
          <TextComponents>
            <TextLabel>제목</TextLabel>
            <TextInput value={titleText} onChange={onInputTitle} />
          </TextComponents>
          <TextComponents>
            <TextLabel>부제</TextLabel>
            <TextInput value={subtitleText} onChange={onInputSubtitle} />
          </TextComponents>
          <TextComponents>
            <TextLabel>당첨인원</TextLabel>
            <TextInput onChange={onInputCount} value={winnerCount} />
          </TextComponents>
          <TextComponents>
            <TextLabel>시작날짜</TextLabel>
            <DatePicker
              className="datePicker"
              dateFormat="yyyy.MM.dd"
              locale={ko}
              shouldCloseOnSelect
              showPopperArrow={false}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="이벤트 시작 날짜를 선택해 주세요."
            />
            <TextLabel>종료날짜</TextLabel>
            <DatePicker
              dateFormat="yyyy.MM.dd"
              locale={ko}
              shouldCloseOnSelect
              showPopperArrow={false}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="이벤트 종료 날짜를 선택해 주세요."
            />
          </TextComponents>
          <TextComponents>
            <TextLabel>발표 날짜</TextLabel>
            <DatePicker
              dateFormat="yyyy.MM.dd"
              locale={ko}
              shouldCloseOnSelect
              showPopperArrow={false}
              selected={announceDate}
              onChange={(date) => setAnnounceDate(date)}
              placeholderText="이벤트 발표 날짜를 선택해 주세요."
            />
          </TextComponents>
          <BodyWrapper>
            <EditorComponent
              convertedText={convertedText}
              setConvertedText={setConvertedText}
            />
          </BodyWrapper>
          <ContentsContainer>
            <ContentsWrapper>
              <ContentsTitle>배너</ContentsTitle>
              <ImageWrapper>
                {bannerImage?.map((img) => (
                  <ImageContents
                    uploadedImg={img?.url}
                    key={img?.url}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorPoint({ x: e.pageX, y: e.pageY });
                      setShow(true);
                      setChoosedPicId(img?.id);
                      setImageLink(img?.url);
                      setImageType('banner');
                    }}
                  />
                ))}
                <ImageUploadButton
                  htmlFor="upload-banner-image"
                  icon={icon_imgadd}
                />
                {havePermission && (
                  <ImageUploadInput
                    type="file"
                    id="upload-banner-image"
                    onChange={(e) => uploadBannerImageListener(e, 'banner')}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                )}
              </ImageWrapper>
            </ContentsWrapper>
            <ContentsWrapper>
              <ContentsTitle>상세 이미지</ContentsTitle>
              <ImageWrapper>
                {detailImage?.map((img) => (
                  <ImageContents
                    uploadedImg={img?.url}
                    key={img?.url}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorPoint({ x: e.pageX, y: e.pageY });
                      setShow(true);
                      setChoosedPicId(img?.id);
                      setImageLink(img?.url);
                      setImageType('event');
                    }}
                  />
                ))}
                <ImageUploadButton
                  htmlFor="upload-detail-image"
                  icon={icon_imgadd}
                />
                {havePermission && (
                  <ImageUploadInput
                    type="file"
                    id="upload-detail-image"
                    multiple={true}
                    onChange={(e) => uploadImageListener(e, 'event')}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                )}
              </ImageWrapper>
            </ContentsWrapper>
            {havePermission && (
              <CustomContextMenu
                style={{
                  display: show ? 'block' : 'none',
                  top: anchorPoint.y,
                  left: anchorPoint.x,
                }}
              >
                <CustomContextMenuItem
                  onClick={(e) => deleteImageListener(e, choosedPicId)}
                >
                  삭제
                </CustomContextMenuItem>
                <CustomContextMenuItem>
                  <a href={imageLink} download>
                    사진 다운로드
                  </a>
                </CustomContextMenuItem>
              </CustomContextMenu>
            )}
          </ContentsContainer>
        </CommonContainer>
        <CommonContainer>
          {userTableColumns && userTableData && (
            <SmallTable
              columns={userTableColumns}
              data={userTableData}
              onClickRow={onClickRow}
            />
          )}
        </CommonContainer>
      </LayoutWrapper>
    </Container>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleWrapper = styled.div``;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 350px;
  justify-content: space-around;

  .downloadbtn {
    width: 80px;
    height: 35px;
    padding: 9px 10px;
    border-radius: 5px;
    background-color: ${palette.mint};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${palette.white};
    font-size: 12px;
    font-weight: 700;
`;
const TextComponents = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;

  .react-datepicker-wrapper {
    width: 200px;
  }
  .react-datepicker__input-container {
    height: 36px;
    input {
      height: 100%;
      text-align: center;
    }
  }
`;
const TextLabel = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  width: 80px;
  color: ${palette.fontDefault};
  height: 100%;
  align-items: center;
`;
const TextInput = styled.input`
  height: 36px;
  width: 400px;
  font-size: 14px;
  padding: 0 10px;
`;
const BodyWrapper = styled.div`
  .ql-editor {
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .ql-editor strong {
    font-weight: bold;
  }
  .ql-editor em {
    font-style: italic;
  }
`;
const ContentsContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;
const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;
const ContentsTitle = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: ${palette.fontDefault};
`;
const ImageWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;
const ImageUploadButton = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => (props.icon ? props.icon : icon_plus)});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;
const ImageUploadInput = styled.input`
  display: none;
`;
const ImageContents = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) =>
    props.uploadedImg === '' ? `${props.initialImg}` : `${props.uploadedImg}`});
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  z-index: 0;
`;

// 우클릭 메뉴
const CustomContextMenu = styled.ul`
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  width: 150px;
  height: auto;
  margin: 0;
  position: fixed;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 99;
`;

const CustomContextMenuItem = styled.li`
  padding: 10px 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

export default EventDetailContainer;
