import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'modules/api/user';
import 'react-datepicker/dist/react-datepicker.css';
import { pagePaths } from 'modules/defines/paths';
import { palette } from 'modules/defines/styles';
import { productAPI } from 'modules/api';
import { ButtonCancel, ButtonConfirm } from 'components/common/DefaultButton';
import { icon_imgadd, icon_plus } from 'assets/img/icon';
import { Container, Title, SubTitle } from 'components/common/DataTableStyle';
import {
  LayoutWrapper,
  CommonContainer,
} from 'components/appManagement/AppManagementCommon';

const ProductDetailContainer = () => {
  const history = useHistory();
  const [titleText, setTitleText] = useState('');
  const [subtitleText, setSubtitleText] = useState('');
  const [ad60Image, setAd60Image] = useState();
  const [ad90Image, setAd90Image] = useState();
  const [ad120Image, setAd120Image] = useState();
  const [ad172Image, setAd172Image] = useState();
  const [userContext] = useContext(UserContext);
  const { id } = useParams();

  // 우클릭 세팅
  const [show, setShow] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [choosedPicId, setChoosedPicId] = useState(0);
  const [imageLink, setImageLink] = useState('');
  const [imageType, setImageType] = useState('');
  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }
  const havePermission = userContext.user.role.match('admin') !== null;

  const goBack = () => {
    history.goBack();
  };
  // const onInputTitle = (e) => {
  //   setTitleText(e.target.value);
  // };
  // const onInputSubtitle = (e) => {
  //   setSubtitleText(e.target.value);
  // };

  const uploadImageListener = async (e, type) => {
    let file = e.target.files[0];
    let blob = file.slice(0, file.size, file.type);
    let postData = new FormData();
    let headers = {
      id: id,
      type: type,
    };
    postData.append(
      'img',
      new File(
        [blob],
        id + `_${type}.` + e.target.files[0].name.split('.').pop()
      )
    );
    let error = false;
    try {
      await productAPI.uploadImage(postData, headers);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageRefetch();
    }
  };

  const deleteImageListener = async (e, choosedPicId) => {
    if (!window.confirm('해당 이미지를 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: choosedPicId,
    };
    let error = false;
    try {
      const response = await productAPI.deleteImage(postData);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageRefetch();
    }
  };

  const imageRefetch = async () => {
    productAPI.getProductImage(id).then((response) => {
      setAd60Image(response.filter((data) => data.type === 'ad60'));
      setAd90Image(response.filter((data) => data.type === 'ad90'));
      setAd120Image(response.filter((data) => data.type === 'ad120'));
      setAd172Image(response.filter((data) => data.type === 'ad172'));
    });
  };

  useEffect(() => {
    productAPI.getProductSingle(id).then((response) => {
      setTitleText(response.title);
      setSubtitleText(response.subtitle);
      imageRefetch();
    });
  }, []);

  return (
    <Container>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>스토어 상품 관리 페이지</Title>
          <SubTitle>
            스토어 상품 관리 페이지입니다. 현재는 광고용 사진 업로드만
            가능합니다.
          </SubTitle>
        </TitleWrapper>
        <ButtonWrapper>
          <ButtonCancel onClick={goBack} text="취소" />
          <ButtonConfirm onClick={goBack} text="완료" />
        </ButtonWrapper>
      </HeaderWrapper>
      <LayoutWrapper fromBottom="100px">
        <CommonContainer>
          {/* <TextComponents>
            <TextLabel>제목</TextLabel>
            <TextInput value={titleText} onChange={onInputTitle} />
          </TextComponents>
          <TextComponents>
            <TextLabel>부제</TextLabel>
            <TextInput value={subtitleText} onChange={onInputSubtitle} />
          </TextComponents> */}
          <ContentsContainer>
            <ContentsWrapper>
              <ContentsTitle>광고 60 X 378</ContentsTitle>
              <ImageWrapper>
                {ad60Image?.map((img) => (
                  <ImageContents
                    uploadedImg={img?.url}
                    key={img?.url}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorPoint({ x: e.pageX, y: e.pageY });
                      setShow(true);
                      setChoosedPicId(img?.id);
                      setImageLink(img?.url);
                      setImageType('ad60');
                    }}
                  />
                ))}
                <ImageUploadButton
                  htmlFor="upload-ad60-image"
                  icon={icon_imgadd}
                />
                {havePermission && (
                  <ImageUploadInput
                    type="file"
                    id="upload-ad60-image"
                    onChange={(e) => uploadImageListener(e, 'ad60')}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                )}
              </ImageWrapper>
            </ContentsWrapper>
            <ContentsWrapper>
              <ContentsTitle>광고 90 X 428</ContentsTitle>
              <ImageWrapper>
                {ad90Image?.map((img) => (
                  <ImageContents
                    uploadedImg={img?.url}
                    key={img?.url}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorPoint({ x: e.pageX, y: e.pageY });
                      setShow(true);
                      setChoosedPicId(img?.id);
                      setImageLink(img?.url);
                      setImageType('ad90');
                    }}
                  />
                ))}
                <ImageUploadButton
                  htmlFor="upload-ad90-image"
                  icon={icon_imgadd}
                />
                {havePermission && (
                  <ImageUploadInput
                    type="file"
                    id="upload-ad90-image"
                    multiple={true}
                    onChange={(e) => uploadImageListener(e, 'ad90')}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                )}
              </ImageWrapper>
            </ContentsWrapper>
            <ContentsWrapper>
              <ContentsTitle>광고 120 X 378</ContentsTitle>
              <ImageWrapper>
                {ad120Image?.map((img) => (
                  <ImageContents
                    uploadedImg={img?.url}
                    key={img?.url}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorPoint({ x: e.pageX, y: e.pageY });
                      setShow(true);
                      setChoosedPicId(img?.id);
                      setImageLink(img?.url);
                      setImageType('ad120');
                    }}
                  />
                ))}
                <ImageUploadButton
                  htmlFor="upload-ad120-image"
                  icon={icon_imgadd}
                />
                {havePermission && (
                  <ImageUploadInput
                    type="file"
                    id="upload-ad120-image"
                    onChange={(e) => uploadImageListener(e, 'ad120')}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                )}
              </ImageWrapper>
            </ContentsWrapper>
            <ContentsWrapper>
              <ContentsTitle>광고 172 X 378</ContentsTitle>
              <ImageWrapper>
                {ad172Image?.map((img) => (
                  <ImageContents
                    uploadedImg={img?.url}
                    key={img?.url}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorPoint({ x: e.pageX, y: e.pageY });
                      setShow(true);
                      setChoosedPicId(img?.id);
                      setImageLink(img?.url);
                      setImageType('ad172');
                    }}
                  />
                ))}
                <ImageUploadButton
                  htmlFor="upload-ad172-image"
                  icon={icon_imgadd}
                />
                {havePermission && (
                  <ImageUploadInput
                    type="file"
                    id="upload-ad172-image"
                    multiple={true}
                    onChange={(e) => uploadImageListener(e, 'ad172')}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                )}
              </ImageWrapper>
            </ContentsWrapper>
            {havePermission && (
              <CustomContextMenu
                style={{
                  display: show ? 'block' : 'none',
                  top: anchorPoint.y,
                  left: anchorPoint.x,
                }}
              >
                <CustomContextMenuItem
                  onClick={(e) => deleteImageListener(e, choosedPicId)}
                >
                  삭제
                </CustomContextMenuItem>
                <CustomContextMenuItem>
                  <a href={imageLink} download>
                    사진 다운로드
                  </a>
                </CustomContextMenuItem>
              </CustomContextMenu>
            )}
          </ContentsContainer>
        </CommonContainer>
      </LayoutWrapper>
    </Container>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleWrapper = styled.div``;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 350px;
  justify-content: space-around;

  .downloadbtn {
    width: 80px;
    height: 35px;
    padding: 9px 10px;
    border-radius: 5px;
    background-color: ${palette.mint};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${palette.white};
    font-size: 12px;
    font-weight: 700;
`;
const TextComponents = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;

  .react-datepicker-wrapper {
    width: 200px;
  }
  .react-datepicker__input-container {
    height: 36px;
    input {
      height: 100%;
      text-align: center;
    }
  }
`;
const TextLabel = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  width: 80px;
  color: ${palette.fontDefault};
  height: 100%;
  align-items: center;
`;
const TextInput = styled.input`
  height: 36px;
  width: 400px;
  font-size: 14px;
  padding: 0 10px;
`;
const ContentsContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;
const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;
const ContentsTitle = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: ${palette.fontDefault};
`;
const ImageWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;
const ImageUploadButton = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => (props.icon ? props.icon : icon_plus)});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;
const ImageUploadInput = styled.input`
  display: none;
`;
const ImageContents = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) =>
    props.uploadedImg === '' ? `${props.initialImg}` : `${props.uploadedImg}`});
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  z-index: 0;
`;

// 우클릭 메뉴
const CustomContextMenu = styled.ul`
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  width: 150px;
  height: auto;
  margin: 0;
  position: fixed;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 99;
`;

const CustomContextMenuItem = styled.li`
  padding: 10px 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

export default ProductDetailContainer;
