import styled from 'styled-components';
import AddIcon from 'assets/svg/Add';
import { palette } from 'modules/defines/styles';
import { Link } from 'react-router-dom';

const ButtonWrapper = styled.div`
  width: 80px;
  height: 35px;
  padding: 9px 10px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.color ? props.color : palette.mainBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Text = styled.div`
  color: ${palette.white};
  font-size: 12px;
  font-weight: 700;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
`;

const StyledLink = styled(Link)`
  width: auto;
  padding: 9px 10px;
  border-radius: 5px;
  background-color: ${palette.mainOrange};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .isMobile & {
    width: 130px;
    justify-content: center;
    padding: 9px 0;
  }
`;

const StyledButton = styled.div`
  width: auto;
  padding: 8px 12px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.color ? props.color : palette.mainOrange};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .isMobile & {
    width: 130px;
    justify-content: center;
    padding: 9px 0;
  }

  &:hover {
    background-color: ${palette.mainOrangeHover};
  }
`;

export const DefaultButton = ({ text = '', color, onClick }) => {
  return (
    <StyledButton onClick={onClick} color={color}>
      <Text>{text}</Text>
    </StyledButton>
  );
};

export const ButtonSend = ({ text, onClick }) => {
  return (
    <ButtonWrapper onClick={onClick} color={palette.mint}>
      <Text>{text}</Text>
    </ButtonWrapper>
  );
};

export const ButtonConfirm = ({ text, onClick, color }) => {
  return (
    <ButtonWrapper onClick={onClick} color={color ?? palette.mainOrange}>
      <Text>{text}</Text>
    </ButtonWrapper>
  );
};

export const ButtonCancel = ({ text, onClick }) => {
  return (
    <ButtonWrapper onClick={onClick} color={palette.buttonCancel}>
      <Text>{text}</Text>
    </ButtonWrapper>
  );
};

export const ButtonCreate = ({ text = '', link = '', onClick, active }) => {
  return active ? (
    <StyledLink to={active ? link : `#`}>
      <AddIcon />
      <Text marginLeft={'8px'}>{text}</Text>
    </StyledLink>
  ) : (
    <StyledButton onClick={onClick}>
      <AddIcon />
      <Text marginLeft={'8px'}>{text}</Text>
    </StyledButton>
  );
};

export const ButtonDelete = ({ text, onClick }) => {
  return (
    <ButtonWrapper onClick={onClick} color={palette.buttonDelete}>
      <Text>{text}</Text>
    </ButtonWrapper>
  );
};
