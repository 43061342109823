import FaviewDataContainer from 'containers/faview/FaviewDataContainer';
import GlobalContainer from 'containers/GlobalContainer';

const FaviewData = () => {
  return (
    <GlobalContainer>
      <FaviewDataContainer />
    </GlobalContainer>
  );
};

export default FaviewData;
