import ManagerListContainer from 'containers/store/ManagerListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const ManagerList = () => {
  return (
    <GlobalContainer>
      <ManagerListContainer />
    </GlobalContainer>
  );
};

export default ManagerList;
