import { useMemo, useContext, useState } from 'react';
import { UserContext } from 'modules/api/user';
import { faviewRequestAPI } from 'modules/api';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Dropdown from 'components/common/DropdownButton';
import { useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import {
  TableFilterBtn,
  TableFilterBtnDivider,
  TableFilterBtnDesc,
  SelectDropdown,
} from 'components/space/SpaceCommon';
import {
  Container,
  LayoutWrapper,
  ContainerHeader,
  ContainerHeaderWrapper,
  ContainerHeaderWrapperWrapper,
  ContainerHeaderSubtext,
  TableToolBarContainer,
  TableToolBarWrapper,
} from 'components/common/DataTableStyle';
import DefaultTable from 'components/table/DefaultTable';
import { request_values } from 'modules/defines/values';

const FaviewRequestListContainer = () => {
  const history = useHistory();
  const [userContext] = useContext(UserContext);
  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }
  const [viewAll, setViewAll] = useState(false);
  const [filterStatus, setFilterStatus] = useState('• 전체보기');
  const optionsType = ['• 전체보기', '0', '1', '2', '3', '4', '5'];
  const queryClient = useQueryClient();
  const fetchList = () => {
    const { call, cancel } = faviewRequestAPI.getFaviewRequestAll();
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['requests'],
    queryFn: () => fetchList(),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });

  const faviewRequestMutation = useMutation({
    mutationFn: (data) => faviewRequestAPI.editFaviewRequest(data),
    onSuccess: (response) => {
      alert('파뷰 제작 요청의 퍼블리시 상태가 변경되었습니다.');
      queryClient.invalidateQueries(['requests']);
    },
    onError: (error) => {
      console.error(error);
      alert('파뷰 제작 요청의 퍼블리시 상태 변경도중 에러가 발생하였습니다.');
    },
  });

  const inputCancelationReason = (status) => {
    if (status === 4) {
      let reason = prompt('취소 사유를 입력해주세요.');
      return reason;
    } else {
      return null;
    }
  };
  const onClickViewAll = (e) => {
    e.preventDefault();
    setViewAll((prev) => !prev);
  };

  const handleSelectListener = (e) => {
    setFilterStatus(e.target.value);
  };

  const tableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      Resize: false,
      width: 50,
    },
    {
      accessor: 'type',
      Header: '요청 타입',
      Filter: false,
      Resize: false,
      width: 50,
    },
    {
      accessor: 'status',
      Header: '요청 상태',
      Cell: ({ row }) => {
        return (
          <Dropdown
            data={row.values}
            editApi={(data) => faviewRequestAPI.editFaviewRequest(data)}
            refetch={() => queryClient.invalidateQueries(['requests'])}
            initialValue={row.values.status}
            option={request_values.status}
            editField="status"
            isString={false}
            preFunction={(status) => inputCancelationReason(status)}
            preFunctionField="reason"
          />
        );
      },
      Filter: false,
      width: 150,
    },
    {
      accessor: 'title',
      Header: '매장명',
      Filter: false,
      Resize: false,
      width: 120,
    },
    {
      accessor: 'address',
      Header: '매장주소',
      Filter: false,
      Resize: true,
      width: 220,
    },
    {
      accessor: 'description',
      Header: '매장설명',
      Filter: false,
      Sort: false,
      Resize: true,
      width: 220,
    },
    {
      accessor: 'phoneManager',
      Header: '담당자 전화번호',
      Cell: ({ row }) => `${row.values.phone}`,
      Filter: false,
      Sort: false,
      Resize: false,
      width: 120,
    },
    {
      accessor: 'phone',
      Header: '전화번호',
      Filter: false,
      Resize: false,
      width: 120,
    },
    {
      accessor: 'time',
      Header: '가능 시간',
      Filter: false,
      Sort: false,
      Resize: false,
      width: 120,
      align: 'center',
    },
    {
      accessor: 'createdAt',
      Cell: ({ row }) => row.values.createdAt.slice(0, 10),
      Header: '등록일자',
      Filter: false,
    },
  ]);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    const requests = data.filter((item) => {
      if (viewAll) {
        return item;
      } else {
        return item.status === 1 || item.status === 2 || item.status === 3;
      }
    });
    const filteredRequests = requests.filter((item) => {
      if (filterStatus === '• 전체보기') return item;
      else return item.status === parseInt(filterStatus);
    });
    return filteredRequests;
  }, [data, viewAll, filterStatus]);

  return (
    <Container>
      {!isLoading ? (<>
        <ContainerHeaderWrapper>
          <ContainerHeaderWrapperWrapper>
            <ContainerHeader>파뷰 제작 요청 관리</ContainerHeader>
            <ContainerHeaderSubtext>
              FAVIEW 제작 요청 관리 페이지입니다.
            </ContainerHeaderSubtext>
          </ContainerHeaderWrapperWrapper>
          <TableToolBarContainer>
            <TableToolBarWrapper>
              <TableFilterBtnDivider />
              <TableFilterBtnDesc>필터</TableFilterBtnDesc>
              <SelectDropdown
                type="select"
                onChange={(e) => handleSelectListener(e)}
              >
                <option value="" disabled selected={true}>
                  STATUS
                </option>
                {optionsType.map((opt, idx) => {
                  return (
                    <option value={opt} key={idx + '_option_type'}>
                      {opt}
                    </option>
                  );
                })}
              </SelectDropdown>
              <TableFilterBtn enabled={viewAll} onClick={onClickViewAll}>
                ALL
              </TableFilterBtn>
            </TableToolBarWrapper>
          </TableToolBarContainer>
        </ContainerHeaderWrapper>
        <LayoutWrapper>
          {tableData.length !== 0 ? (
            <>{tableData && tableColumns && (
              <DefaultTable columns={tableColumns} data={tableData} />
            )}</>
          ) : (<>Status : {filterStatus} {")"} 데이터가 없습니다.</>)}
        </LayoutWrapper></>
      ) : (
        <>데이터를 불러오는 중입니다..</>
      )}

    </Container>
  );
};

export default FaviewRequestListContainer;
