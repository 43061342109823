import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const FeedbackAPI = (() => {
  class FeedbackAPIConstructor {
    getFeedbackAll() {
      return httpAPI(apiPaths.feedbackBase, { method: 'GET' });
    }
    getFeedbackSingle(id) {
      return httpAPI(apiPaths.feedbackBase + id, { method: 'GET' }).call;
    }
    sendFeedback(data) {
      return httpAPI(apiPaths.registerFeedback, { data }).call;
    }
    deleteFeedback(data) {
      return httpAPI(apiPaths.deleteFeedback, { data }).call;
    }
    getStoreEditRequest() {
      return httpAPI(apiPaths.getStoreEditRequest, { method: 'GET' });
    }
  }
  return FeedbackAPIConstructor;
})();

export const feedbackAPI = new FeedbackAPI();
