import ProductDetailContainer from 'containers/store/ProductDetailContainer';
import GlobalContainer from 'containers/GlobalContainer';

const ProductDetail = () => {
  return (
    <GlobalContainer>
      <ProductDetailContainer />
    </GlobalContainer>
  );
};

export default ProductDetail;
