import SpaceDetailContainer from 'containers/space/SpaceDetailContainer.js';
import GlobalContainer from 'containers/GlobalContainer';

const SpaceDetail = () => {
  return (
    <GlobalContainer>
      <SpaceDetailContainer />
    </GlobalContainer>
  );
};
export default SpaceDetail;
