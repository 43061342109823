import React from 'react';
import { palette } from 'modules/defines/styles';

const Link = ({ color }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="link-4-svgrepo-com"
          transform="translate(2.000000, 2.000000)"
          fill={palette.iconDefault}
          fillRule="nonzero"
        >
          <path
            d="M7.10412518,11.8365329 L6.19085179,12.7890155 C5.97570444,13.0155535 5.73396302,13.1794701 5.47252811,13.2909635 C5.08111622,13.4574215 4.64590603,13.5022208 4.23331972,13.4212888 C3.81877981,13.3403568 3.42884887,13.1402748 3.10341803,12.8098675 C2.88729388,12.5863922 2.72924188,12.3359395 2.61993517,12.0671436 C2.45895278,11.6614083 2.41512297,11.2118837 2.49339274,10.7832437 C2.57166251,10.3556137 2.76566694,9.95189849 3.08665493,9.61846117 L5.38735774,7.20946283 C5.60250509,6.98647621 5.84475067,6.8200182 6.10618557,6.71054493 C6.49857426,6.5425556 6.932335,6.49671367 7.34637076,6.57767827 C7.76091067,6.65913159 8.15084161,6.85970226 8.47482301,7.19212956 C8.65502625,7.37386822 8.79335326,7.57750153 8.89724032,7.79488416 C8.90659866,7.79693678 8.91447605,7.79996684 8.92184929,7.80149817 C9.16261392,7.85037016 9.41960598,7.82440284 9.64508846,7.72871148 C9.80263632,7.66354882 9.94442939,7.56681485 10.0670331,7.43854216 L11,6.47433029 C10.7907449,6.08130172 10.5243945,5.71326302 10.2023982,5.38386578 C9.74847137,4.92264448 9.2211587,4.57135258 8.66139118,4.34126324 C7.81948696,3.99508661 6.90523677,3.91262327 6.02886109,4.0923093 C5.15497466,4.27303793 4.31354308,4.72002119 3.64251527,5.42459244 L1.34033151,7.83160332 C0.891320133,8.29995993 0.552592249,8.84416587 0.329567508,9.42502581 C-0.00471752584,10.2950451 -0.0844997586,11.2404249 0.0893070328,12.1455668 C0.263586468,13.0507087 0.69637042,13.9202067 1.37823753,14.6146125 C1.83021081,15.0783752 2.35846876,15.4286245 2.91877194,15.6587464 C3.76017201,16.0064544 4.67445371,16.0868977 5.54931693,15.9071791 C6.42468431,15.7274604 7.26561173,15.2789784 7.93764785,14.5759385 L10.2398316,12.1685041 C10.2521519,12.1557648 10.2619828,12.1430581 10.2737989,12.1303188 C10.2565632,12.1328601 10.2398316,12.1379428 10.2225959,12.1405167 C9.1734217,12.3507315 8.09569986,12.2453309 7.10412518,11.8365329 Z"
            id="Path"
          ></path>
          <path
            d="M15.9147705,3.5619799 C15.7470287,2.7303822 15.3180719,1.93011928 14.6421619,1.28868306 C14.1930245,0.861686808 13.6671628,0.536528608 13.1088421,0.322537138 C12.2696294,-0.000767287076 11.3570926,-0.0801806369 10.4809818,0.0805297379 C9.60534324,0.240761719 8.76269892,0.647695338 8.0877649,1.28865316 L5.7742631,3.4862741 C5.75754559,3.50262918 5.74032435,3.52083804 5.72162341,3.53767152 C5.7285182,3.53767152 5.73490926,3.53578785 5.74130033,3.53578785 C6.79648685,3.3348924 7.88460466,3.42832867 8.88419208,3.80769485 C8.88910344,3.80817324 8.89404628,3.8119107 8.89993361,3.81331598 L9.82377277,2.93734726 C10.0392429,2.73226587 10.2827329,2.58228946 10.543948,2.48137829 C10.9355344,2.3295481 11.3688988,2.29262209 11.7835938,2.36784949 C12.1963053,2.44400378 12.5844601,2.62994942 12.9076338,2.93734726 C13.1231039,3.14198015 13.2805192,3.37229681 13.3862709,3.62082232 C13.5461419,3.99364049 13.5864717,4.40568694 13.506788,4.79859765 C13.4256247,5.19055156 13.2298315,5.55963228 12.9076338,5.86610323 L10.5936282,8.06231889 C10.3786618,8.26740028 10.1346681,8.41737669 9.87345305,8.51876625 C9.48186665,8.66966956 9.04702251,8.70704406 8.63431096,8.63181666 C8.22011972,8.55473548 7.83297242,8.36926823 7.50976724,8.06231889 C7.33610664,7.89646578 7.19887194,7.7138091 7.0970557,7.5171295 C7.08622553,7.51479733 7.0749231,7.51150837 7.06557263,7.51058148 C6.82403453,7.46713736 6.56628265,7.49096734 6.34294176,7.57973927 C6.18552642,7.64187065 6.04482859,7.73156946 5.92333543,7.84835732 L5,8.73505999 C5.20463994,9.08590195 5.46289555,9.41434911 5.77429458,9.7100562 C6.22491174,10.1384577 6.7502697,10.462689 7.30859044,10.6780559 C8.14682713,11.0008819 9.06081209,11.0802953 9.93645067,10.9191364 C10.8110818,10.7589044 11.6532539,10.3528977 12.3301398,9.7100562 L14.6421934,7.51384054 C15.0928106,7.08636589 15.4346852,6.58743119 15.6599781,6.0571617 C16.000373,5.26066614 16.084968,4.3935776 15.9147705,3.5619799 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Link;
