import Logo from 'assets/icon/fav_logo.png';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 151, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
`;

const LogoImage = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 120px;
  height: 120px;
`;

const LoadingText = styled.div`
  margin-top: 20px;
  color: #ffffff;
  font-size: 20px;
`;

// 로딩 화면 컴포넌트
const LoadingScreen = () => (
  <LoadingContainer>
    <LogoImage />
    <LoadingText>로딩중. 잠시만 기다려주세요.</LoadingText>
  </LoadingContainer>
);

export { LoadingScreen };
