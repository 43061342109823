import { useState } from 'react';
import styled from 'styled-components';
import { btn_curation_off, btn_curation_on } from 'assets/img/button';
import { curationAPI } from 'modules/api';

const PublishButton = styled.div`
  width: 39px;
  height: 24px;
  object-fit: contain;
  background-image: url(${(props) =>
    props.paid ? btn_curation_on : btn_curation_off});
`;
const CurationPaidSwitchButton = (props) => {
  const [paid, setPaid] = useState(props.paid);
  const id = props.id;
  const onClick = async () => {
    setPaid(!paid);
    let error = false;
    const postData = {
      id,
      paid: !paid,
    };
    try {
      await curationAPI.editCuration(postData);
    } catch (e) {
      error = true;
      console.error(e);
      alert('Paid 상태변경 중, 에러가 발생했습니다.\n관리자에게 문의하세요.');
    } finally {
      if (error) {
        return;
      }
    }
  };

  return paid === undefined ? null : (
    <PublishButton paid={paid} onClick={onClick} />
  );
};

export default CurationPaidSwitchButton;
