import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { spaceAPI } from 'api';
import { keywordAPI, curationAPI } from 'modules/api';

const KeywordVibeSelectModal = ({
  id,
  typeData,
  keywordData,
  keywordAllRefetch,
  keywordConnectedRefetch,
  closeModal,
}) => {
  const [vibeCategory, setVibeCategory] = useState([]);
  const [keywordSelected, setKeywordSelected] = useState([]);
  // 키워드 추가
  const [addKeywordCategorySelected, setAddKeywordCategorySelected] =
    useState('');
  const [addKeywordText, setAddKeywordText] = useState('');

  // INITIALIZE 초기화 (불러오기)
  useEffect(() => {
    // 키워드의 카테고리 구조 불러오기 (vibeCategory)
    keywordData.vibe.totalKeyword.map(async (item, index) => {
      if (!vibeCategory.includes(item.category)) {
        setVibeCategory((prevState) => {
          if (!prevState.includes(item.category)) {
            return [...prevState, item.category];
          } else {
            return [...prevState];
          }
        });
      }
    });

    // 선택되어있던 Vibe 키워드 불러오기
    keywordData.vibe.selectedKeyword.map(async (item, index) => {
      setKeywordSelected((prev) => {
        return [...prev, item.id];
      });
    });
  }, [keywordData]);

  // Keyword Item 클릭리스너
  const KeywordItemClickListener = (e, keywordId) => {
    e.preventDefault();
    setKeywordSelected((prev) => {
      if (prev.includes(keywordId)) {
        return keywordSelected.filter((el) => el !== keywordId);
      } else {
        return [...prev, keywordId];
      }
    });
  };

  // Keyword 추가 Select Listener
  const addKeywordCategorySelectedOnChange = (e) => {
    setAddKeywordCategorySelected(e.target.value);
  };

  // Keyword 추가 TextInput Listener
  const AddKeywordInputOnChange = (e) => {
    setAddKeywordText(e.target.value.replace(' ', ''));
  };

  // Keyword 추가 Button Listener
  const AddKeywordButtonListener = async (e) => {
    // e.preventDefault();
    if (addKeywordText === '') {
      alert('추가하려는 키워드가 비어있습니다.');
    } else if (addKeywordCategorySelected === '') {
      alert('카테고리를 선택해주세요');
    } else if (addKeywordText.length > 20) {
      alert('키워드는 20자 이내로 입력해주세요');
    } else {
      try {
        const postData = {
          title: addKeywordText,
          category: addKeywordCategorySelected,
        };
        const response = await keywordAPI.registerKeyword(postData);
        if (response.success) {
          keywordAllRefetch();
          setAddKeywordText('');
        }
      } catch (err) {
        console.error(err);
        alert('키워드가 중복되었습니다.');
        return;
      }
    }
  };
  // 최종 적용 Button Listener
  const SubmitListener = async (e) => {
    e.preventDefault();
    // 겹치는 요소 제거
    const keywordSelectedUniq = [...new Set(keywordSelected)];
    let keywordSelectedString = '';
    keywordSelectedUniq.map((keywordItem) => {
      keywordSelectedString += keywordItem + '/';
    });
    keywordSelectedString = keywordSelectedString.slice(0, -1);
    if (keywordSelectedString === '') {
      alert('선택된 Vibe 키워드가 없습니다.');
    } else {
      try {
        if (typeData === 'space') {
          const postData = {
            keyword_ids: keywordSelectedString,
            s_id: id,
          };
          const res = await spaceAPI.updateVibeKeyword(postData);
        } else if (typeData === 'curation') {
          const postData = {
            keyword_ids: keywordSelectedString,
            c_id: id,
          };
          await curationAPI.updateVibeKeyword(postData);
        }
      } catch (err) {
        console.error(err);
        alert(
          '필터 업데이트 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.'
        );
        return;
      } finally {
        keywordConnectedRefetch();
        closeModal();
      }
    }
  };

  // set 'ESC' keystroke to escape ========================
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <ModalContainer>
      <HeaderContainer>
        <ContainerTitle>Vibe Keyword 모두보기</ContainerTitle>
        <SubmitButton onClick={(e) => SubmitListener(e)}>적용</SubmitButton>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </HeaderContainer>
      <ContentsContainer>
        {vibeCategory?.map((categoryTitle) => {
          return (
            <KeywordCategoryContainer>
              <KeywordCategoryTitle>
                {categoryTitle ? categoryTitle : 'etc.'}
              </KeywordCategoryTitle>
              <KeywordCategoryWrapper>
                {keywordData.vibe.totalKeyword
                  .filter((data) => data.category === categoryTitle)
                  .map((keywordItem) => {
                    return !keywordSelected.includes(keywordItem.id) ? (
                      <KeywordItem
                        isSelected={false}
                        onClick={(e) =>
                          KeywordItemClickListener(e, keywordItem.id)
                        }
                      >
                        #{keywordItem.title}
                      </KeywordItem>
                    ) : (
                      <KeywordItem
                        isSelected={true}
                        onClick={(e) =>
                          KeywordItemClickListener(e, keywordItem.id)
                        }
                      >
                        #{keywordItem.title}
                      </KeywordItem>
                    );
                  })}
              </KeywordCategoryWrapper>
            </KeywordCategoryContainer>
          );
        })}
        <AddKeywordContainer>
          <KeywordCategoryTitle>키워드 새로 추가</KeywordCategoryTitle>
          <SelectWrapper>
            <AddKeywordSelect
              value={addKeywordCategorySelected}
              onChange={(e) => addKeywordCategorySelectedOnChange(e)}
            >
              <option value="" disabled selected={true}>
                카테고리 선택
              </option>
              {vibeCategory.map((item) => {
                return <option>{item}</option>;
              })}
            </AddKeywordSelect>
          </SelectWrapper>

          <AddKeywordTextContainer>
            <AddKeywordInputText
              value={addKeywordText}
              onChange={(e) => AddKeywordInputOnChange(e)}
            />
            <AddKeywordButton onClick={AddKeywordButtonListener}>
              추가
            </AddKeywordButton>
          </AddKeywordTextContainer>
        </AddKeywordContainer>
      </ContentsContainer>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  max-height: 90vh;
  max-width: 80vw;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;
const CloseButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 0px;
`;
const SubmitButton = styled.div`
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  top: -4px;
  right: 48px;
  @media screen and (max-width: 400px) {
    right: auto;
    left: 0px;
  }
  padding: 8px 16px;
  background-color: ${palette.mainOrangeHover};
  color: ${palette.white};
  &:hover {
    background-color: ${palette.mainOrange};
  }
  border-radius: 8px;
  cursor: pointer;
`;
const ContainerTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${palette.fontDefault};
  @media screen and (max-width: 400px) {
    margin-top: 48px;
    font-size: 14px;
  }
`;
const ContentsContainer = styled.div`
  padding: 10px 20px 60px 20px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  margin-top: 12px;
`;
const KeywordCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const KeywordCategoryTitle = styled.div`
  font-size: 22px;
  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
  color: ${palette.fontDefault};
  font-weight: 500;
`;

const KeywordCategoryWrapper = styled.div`
  margin-top: 10px;
  overflow-y: scroll;
`;

const KeywordItem = styled.div`
  color: ${(props) =>
    props.isSelected ? palette.fontWhite : palette.fontDefault};
  background-color: ${(props) =>
    props.isSelected ? palette.mainOrange : palette.grey_lighter};
  display: inline-block;
  padding: 3px 8px 3px 6px;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 6px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainOrangeHover};
    color: ${palette.white};
  }
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

const AddKeywordContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const AddKeywordTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AddKeywordInputText = styled.input`
  width: 100%;
  border: 1px solid ${palette.mint};
  border-radius: 5px;
  padding-left: 10px;
  height: 30px;
`;
const AddKeywordButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100px;
  margin-left: 6px;

  background-color: ${palette.buttonCancel};
  color: ${palette.white};
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  &:hover {
    background-color: ${palette.mainOrangeHover};
  }
  cursor: pointer;
`;

const AddKeywordSelect = styled.select`
  width: 140px;
  padding-left: 4px;
  height: 24px;
  margin-bottom: 8px;
  border: 1px solid ${palette.mint};
  border-radius: 4px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${palette.fontBlack};
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;
const SelectWrapper = styled.div`
  margin-top: 12px;
`;

export default KeywordVibeSelectModal;
