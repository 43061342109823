import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const FilterAPI = (() => {
  class FilterAPIConstructor {
    getFilterAll() {
      return httpAPI(apiPaths.getFilterAll, { method: 'GET' }).call;
    }
    registerFilter(data) {
      return httpAPI(apiPaths.registerFilter, data).call;
    }
    deleteFilter(id) {
      return httpAPI(apiPaths.deleteFilter + id, { method: 'DELETE' }).call;
    }
  }
  return FilterAPIConstructor;
})();

export const filterAPI = new FilterAPI();
