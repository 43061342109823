import { palette } from 'modules/defines/styles';
import styled, { createGlobalStyle } from 'styled-components';
import { useState, useEffect } from 'react';
import PretendardWoffBold from 'assets/fonts/pretendard/Pretendard-Bold.woff';
import PretendardWoffRegular from 'assets/fonts/pretendard/Pretendard-Regular.woff';
import PretendardWoffLight from 'assets/fonts/pretendard/Pretendard-Light.woff';
import PretendardWoffThin from 'assets/fonts/pretendard/Pretendard-Thin.woff';
import GmarketWoffMedium from 'assets/fonts/gmarket/GmarketSansMedium.woff';
import GmarketWoffBold from 'assets/fonts/gmarket/GmarketSansBold.woff';
import SampleImgInsta from 'assets/img/insta-curated-sample.png';
import SampleImgApp from 'assets/img/app-curated-sample.png';

const FontStyle = createGlobalStyle`
  @font-face {
    font-family: "Pretendard-bold";
    font-style: normal;
    src: url(${PretendardWoffBold}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-regular";
    font-style: normal;
    src: url(${PretendardWoffRegular}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-light";
    font-style: normal;
    src: url(${PretendardWoffLight}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-thin";
    font-style: normal;
    src: url(${PretendardWoffThin}) format("woff");
  }
  @font-face {
    font-family: "Gmarket-bold";
    font-style: normal;
    src: url(${GmarketWoffBold}) format("truetype");
  }
  @font-face {
    font-family: "Gmarket-medium";
    font-style: normal;
    src: url(${GmarketWoffMedium}) format("truetype");
  }
`;
const MirroringCuratedModal = ({ reasonProps, setReasonProps, closeModal }) => {
  const [reason, setReason] = useState(reasonProps);

  const reasonOnChange = (e) => {
    setReason(e.target.value);
  };
  // set 'ESC' keystroke to escape ========================
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const submitBtnListener = () => {
    setReasonProps(reason);
    closeModal();
  };

  return (
    <ModalContainer>
      <HeaderContainer>
        <HeaderTitle>큐레이션 포함공간 컨텐츠 미러링</HeaderTitle>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </HeaderContainer>
      <ContentsContainer>
        <TextContainer>
          <ItemTextArea
            height="100px"
            value={reason}
            onChange={(e) => reasonOnChange(e)}
          />
          <SubmitButton onClick={submitBtnListener}>적용</SubmitButton>
        </TextContainer>
        <MirroringContents>
          <FontStyle />
          <InstaContents>
            <InstaReasonWrapper>
              <InstaReason>{reason}</InstaReason>
            </InstaReasonWrapper>
          </InstaContents>
          <AppContents>
            <AppTextWrapper>
              <AppReason>{reason}</AppReason>
              <AppKeyword>#큐레이션 #파인다이닝 #카페 #디저트</AppKeyword>
            </AppTextWrapper>
          </AppContents>
        </MirroringContents>
      </ContentsContainer>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  max-height: 100vh;
  max-width: calc(100vw - 120px);
`;
const HeaderContainer = styled.div`
  display: flex;
  position: relative;
`;
const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${palette.fontDefault};
`;
const CloseButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 0px;
`;
const SubmitButton = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  background-color: ${palette.mainOrangeHover};
  color: ${palette.white};
  &:hover {
    background-color: ${palette.mainOrange};
  }
  border-radius: 8px;
  cursor: pointer;
`;
const MirroringContents = styled.div`
  display: flex;
  flex-direction: row;
  width: 1508px;
  height: 100%;
`;
const AppContents = styled.div`
  width: 428px;
  height: 675px;
  position: relative;
  background-image: url(${SampleImgApp});
`;
const InstaContents = styled.div`
  width: 1080px;
  height: 675px;
  background-image: url(${SampleImgInsta});
  position: relative;
`;
const AppTextWrapper = styled.div`
  position: absolute;
  top: 555px;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
`;

const AppReason = styled.div`
  font-family: Pretendard-Regular;
  font-size: 18px;
  line-height: 1.56;
  text-align: left;
  color: #717171;
`;

const AppKeyword = styled.div`
  font-size: 16px;
  font-family: Pretendard-Medium;
  color: #1e1e1e;
  margin-top: 10px;
`;

const InstaReasonWrapper = styled.div`
  position: absolute;
  top: 454px;
  padding: 0 70px;
`;
const InstaReason = styled.div`
  font-family: Gmarket-medium;
  font-size: 32px;
  color: ${palette.fontWhite};
  flex-grow: 0;
  text-align: left;
  letter-spacing: -1.28px;
  line-height: 1.6;
`;

const ContentsContainer = styled.div`
  padding: 10px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const TextContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;
const ItemTextArea = styled.textarea`
  all: unset;
  height: ${(props) => (props.height ? props.height : '60px')};
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  color: ${palette.fontBlack};
  font-size: 16px;
  line-height: 1.1;
  overflow: scroll;
`;
export default MirroringCuratedModal;
