import React from 'react';
import { palette } from 'modules/defines/styles';

const Dashboard = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="State=dashboard">
        <g id="dashboard-svgrepo-com 1">
          <path
            id="Vector"
            d="M7.99998 3.33337H4.66665C3.93027 3.33337 3.33331 3.93033 3.33331 4.66671V9.33337C3.33331 10.0698 3.93027 10.6667 4.66665 10.6667H7.99998C8.73636 10.6667 9.33331 10.0698 9.33331 9.33337V4.66671C9.33331 3.93033 8.73636 3.33337 7.99998 3.33337Z"
            fill="#A0A5BA"
          />
          <path
            id="Vector_2"
            d="M15.3334 3.33337H12C11.2636 3.33337 10.6667 3.93033 10.6667 4.66671V6.66671C10.6667 7.40309 11.2636 8.00004 12 8.00004H15.3334C16.0697 8.00004 16.6667 7.40309 16.6667 6.66671V4.66671C16.6667 3.93033 16.0697 3.33337 15.3334 3.33337Z"
            fill="#A0A5BA"
          />
          <path
            id="Vector_3"
            d="M7.99998 12H4.66665C3.93027 12 3.33331 12.597 3.33331 13.3333V15.3333C3.33331 16.0697 3.93027 16.6667 4.66665 16.6667H7.99998C8.73636 16.6667 9.33331 16.0697 9.33331 15.3333V13.3333C9.33331 12.597 8.73636 12 7.99998 12Z"
            fill="#A0A5BA"
          />
          <path
            id="Vector_4"
            d="M15.3334 9.33337H12C11.2636 9.33337 10.6667 9.93033 10.6667 10.6667V15.3334C10.6667 16.0698 11.2636 16.6667 12 16.6667H15.3334C16.0697 16.6667 16.6667 16.0698 16.6667 15.3334V10.6667C16.6667 9.93033 16.0697 9.33337 15.3334 9.33337Z"
            fill="#A0A5BA"
          />
        </g>
      </g>
    </svg>
  );
};

export default Dashboard;
