import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { btn_curation_off, btn_curation_on } from 'assets/img/button';
import { curationAPI } from 'modules/api';

const PublishButton = styled.div`
  width: 39px;
  height: 24px;
  object-fit: contain;
  background-size: cover;
  background-image: url(${(props) =>
    props.published ? btn_curation_on : btn_curation_off});
`;
const CurationPublishInstaSwitchButton = (props) => {
  const [published, setPublished] = useState(props.published);
  const id = props.id;

  useEffect(() => {
    setPublished(props.published);
  }, [props.published]);

  const onClick = async () => {
    if (!published) {
      setPublished(!published);
      const postData = {
        id,
      };
      try {
        await curationAPI.switchPublishInsta(postData);
      } catch (e) {
        console.error(e);
        alert(
          '큐레이션을 Publish하는 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
    } else {
      setPublished(!published);
      const postData = {
        id,
      };
      try {
        await curationAPI.switchPublishInsta(postData);
      } catch (e) {
        console.error(e);
        alert(
          '큐레이션을 Publish하는 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
    }
  };

  const onClickNoPermission = () => {
    alert('Super Admin만 가능한 기능입니다.');
  };

  return published === undefined ? null : (
    <PublishButton
      published={published}
      onClick={props.havePermission ? onClick : onClickNoPermission}
    />
  );
};

export default CurationPublishInstaSwitchButton;
