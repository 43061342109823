import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const PushAPI = (() => {
  class PushAPIConstructor {
    getPushAll() {
      return httpAPI(apiPaths.getPushAll, { method: 'GET' }).call;
    }
    getPushDetail(id) {
      return httpAPI(apiPaths.getPushSingle + id, { method: 'GET' }).call;
    }
    registerPush(data) {
      return httpAPI(apiPaths.registerPush, { data }).call;
    }
    editPush(data) {
      return httpAPI(apiPaths.editPush, { data }).call;
    }
  }
  return PushAPIConstructor;
})();

export const pushAPI = new PushAPI();
