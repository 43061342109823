import { useMemo, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import { feedbackAPI } from 'modules/api';
import { UserContext } from 'modules/api/user';
import { Filter } from 'components/common/Filter';
import {
  Container,
  Title,
  SubTitle,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import DefaultTable from 'components/table/DefaultTable';

const EditRequestListContainer = () => {
  const history = useHistory();
  const [userContext] = useContext(UserContext);

  const fetchList = () => {
    const { call, cancel } = feedbackAPI.getStoreEditRequest();
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['feedback'],
    queryFn: () => fetchList(),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }

  const tableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      width: 50,
    },
    {
      accessor: 'type',
      Header: '피드백 타입',
      Filter: Filter,
      width: 100,
    },
    {
      accessor: 'user_nickname',
      Header: '사용자',
      Filter: false,
      width: 100,
    },
    {
      accessor: 'user_contact',
      Header: '전화번호',
      Filter: false,
      width: 200,
    },
    {
      accessor: 'space_id',
      Header: '매장 id',
      Filter: false,
      width: 50,
    },
    {
      accessor: 'text',
      Header: '텍스트',
      // Cell: ({ row }) => (
      //   <Link to={pagePaths.feedback.detail.replace(':id', row.values.id)}>
      //     {row.values.text}
      //   </Link>
      // ),
      Filter: false,
      width: 300,
    },
    {
      accessor: 'status',
      Header: '상태',
      Filter: false,
      width: 50,
    },
    {
      accessor: 'editAt',
      Header: '작성 일자',
      Filter: false,
      width: 100,
    },
  ]);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((feedback) => ({
      ...feedback,
      text:
        feedback.text.length > 50
          ? feedback.text.substring(0, 50) + '...'
          : feedback.text,
      editAt: feedback.createdAt.split('T')[0].substring(2),
    }));
  }, [data]);

  return (
    <Container>
      <Title>스토어 공간 요청 관리</Title>
      <SubTitle>FAV 스토어 매니저의 공간 수정 요청 관리 페이지입니다.</SubTitle>
      <LayoutWrapper>
        {tableData && tableColumns && (
          <DefaultTable columns={tableColumns} data={tableData} />
        )}
      </LayoutWrapper>
    </Container>
  );
};

export default EditRequestListContainer;
