import React from 'react';
import { palette } from 'modules/defines/styles';

const NewWindow = ({ color, size }) => {
    return (
        <svg
            fill={color}
            width={size}
            height={size}
            viewBox="-2 0 19 19"
            xmlns="http://www.w3.org/2000/svg"
            class="cf-icon-svg">
            <path d="M14.267 3.793v7.996a.477.477 0 0 1-.475.475h-2.356v2.472a.476.476 0 0 1-.475.475H1.208a.476.476 0 0 1-.475-.475V6.74a.476.476 0 0 1 .475-.475h2.356V3.793a.476.476 0 0 1 .475-.475h9.753a.476.476 0 0 1 .475.475zm-3.94 8.471H4.04a.477.477 0 0 1-.475-.475V8.626H1.84v5.476h8.487zm2.832-6.585H4.672v5.476h8.487z" />
        </svg>
    );
};

export default NewWindow;
