import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const CurationAPI = (() => {
  class CurationAPIConstructor {
    getCurationAll() {
      return httpAPI(apiPaths.getCurationAll, { method: 'GET' });
    }
    getCurationAllForPush() {
      return httpAPI(apiPaths.getCurationAllForPush, { method: 'GET' }).call;
    }
    getCurationDetail(id) {
      return httpAPI(apiPaths.getCurationSingle + id, { method: 'GET' });
    }
    registerCuration(data) {
      return httpAPI(apiPaths.registerCuration, { data }).call;
    }
    editCuration(data) {
      return httpAPI(apiPaths.editCuration, { data }).call;
    }
    getSpaceCurated(id) {
      return httpAPI(apiPaths.getSpaceCurated + id, { method: 'GET' });
    }
    attachSpaceCurated(data) {
      return httpAPI(apiPaths.attachSpaceCurated, { data }).call;
    }
    detachSpaceCurated(data) {
      return httpAPI(apiPaths.detachSpaceCurated, { data }).call;
    }
    setSpaceCuratedOrder(data) {
      return httpAPI(apiPaths.setSpaceCuratedOrder, { data }).call;
    }
    switchPublish(data) {
      return httpAPI(apiPaths.editCurationPublish, { data }).call;
    }
    editCurationUploadedAt(data) {
      return httpAPI(apiPaths.editCurationUploadDate, { data }).call;
    }
    switchPublishInsta(data) {
      return httpAPI(apiPaths.editCurationPublishInsta, { data }).call;
    }
    deleteCuration(id) {
      return httpAPI(apiPaths.deleteCuration + id, { method: 'DELETE' });
    }
    getKeyword(id) {
      return httpAPI(apiPaths.getCurationKeyword + id, { method: 'GET' });
    }
    addKeyword(data) {
      return httpAPI(apiPaths.addCurationKeyword, { data }).call;
    }
    removeKeyword(data) {
      return httpAPI(apiPaths.removeCurationKeyword, { data }).call;
    }
    updateVibeKeyword(data) {
      return httpAPI(apiPaths.updateVibeCurationKeyword, { data }).call;
    }
    uploadImage(data, headers) {
      return httpAPI(apiPaths.uploadCurationImage, { data, headers }).call;
    }
  }
  return CurationAPIConstructor;
})();

export const curationAPI = new CurationAPI();
