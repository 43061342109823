import {
  useTable,
  useFilters,
  useSortBy,
  useBlockLayout,
  useResizeColumns,
  usePagination,
} from 'react-table';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

const DefaultHeader = styled.th`
  background-color: ${palette.background};
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const FilterButton = styled.div`
  margin-top: 5px;
`;

const Styles = styled.div`
  overflow: scroll;
  table {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    th {
      padding: 5px 0;
      font-size: 12px;
      font-weight: 500
      letter-spacing: 0px;
      color: ${palette.fontDefault};
      opacity: 1;
      border-top: 1px solid ${palette.borderDefault};
      border-bottom: 1px solid ${palette.borderDefault};
      border-left: 1px solid rgb(112, 112, 112, 0.1);
      border-right: 1px solid rgb(112, 112, 112, 0.1);
    }
    td {
      padding: 7px 0;
      font-size: 14px;
      letter-spacing: 0px;
      color: ${palette.fontDefault};
      opacity: 1;
      height: auto;
      border-left: 1px solid rgb(112, 112, 112, 0.1);
      border-right: 1px solid rgb(112, 112, 112, 0.1);
      word-break: break-word;
    }

    tbody tr:nth-child(odd) {
      background-color: #fbfbfb;
      &:hover{
        background-color: ${palette.mainBlueExtraLight};
      }
      cursor:pointer;
    }
    tbody tr:nth-child(even) {
      background-color: #ffffff;
      &:hover{
        background-color: ${palette.mainBlueExtraLight};
      }
      cursor:pointer;
    }
    .resizer {
      width: 10px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      touch-action: none;
    }
    .datePicker {
      width: 80px;
      text-align: center;
    }
  }
`;

const headerStyle = {
  style: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const cellStyle = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  left: {
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
};

const DefaultTable = ({
  columns,
  data,
  onClickRow,
  halfSize,
  defaultWidth = 150,
  // customize to space
  numElementInPage = null,
  currentPage = null,
  tableHeaderSortListener = null,
  sortManual = null,
}) => {
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(0);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: defaultWidth,
      maxWidth: 400,
    }),
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        autoResetGlobalFilter: false,
      },
      useFilters,
      useSortBy,
      useBlockLayout,
      useResizeColumns,
      usePagination
    );
  useEffect(() => {
    if (
      numElementInPage === null ||
      currentPage === null ||
      currentPage === '0'
    ) {
      setPageStart(0);
      setPageEnd(rows?.length);
    } else {
      setPageStart((currentPage - 1) * numElementInPage);
      setPageEnd(currentPage * numElementInPage);
    }
  }, [rows]);

  const onClickRowListener = (e, row) => {
    if (onClickRow) {
      onClickRow(e, row);
    }
  };

  return (
    <Styles>
      {data ? (
        <table {...getTableProps()} style={{ width: halfSize ? '' : '100%' }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return column.Sort === false ? (
                    <th {...column.getHeaderProps(headerStyle)}>
                      <FilterWrapper>
                        {column.render('Header')}
                        {column.Resize === true && (
                          <div
                            {...column.getResizerProps()}
                            className="resizer"
                            onClick={(event) => event.stopPropagation()}
                          />
                        )}
                        <FilterButton>{column.render('Filter')}</FilterButton>
                      </FilterWrapper>
                    </th>
                  ) : (
                    <DefaultHeader
                      {...column.getHeaderProps(
                        column.getSortByToggleProps(headerStyle)
                      )}
                    >
                      {tableHeaderSortListener ? (
                        <FilterWrapper
                          onClick={(e) =>
                            tableHeaderSortListener(e, column.render('id'))
                          }
                        >
                          {column.render('Header')}
                          {/* table resize */}
                          {column.Resize === true && (
                            <div
                              {...column.getResizerProps()}
                              className="resizer"
                              onClick={(event) => event.stopPropagation()}
                            />
                          )}
                          {sortManual.column === column.render('id') && (
                            <>
                              {sortManual.dir === 'DESC' && <>▼</>}
                              {sortManual.dir === 'ASC' && <>▲</>}
                              {sortManual.dir === '' && <></>}
                            </>
                          )}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                          <FilterButton>{column.render('Filter')}</FilterButton>
                        </FilterWrapper>
                      ) : (
                        <FilterWrapper>
                          {column.render('Header')}
                          {/* table resize */}
                          {column.Resize === true && (
                            <div
                              {...column.getResizerProps()}
                              className="resizer"
                              onClick={(event) => event.stopPropagation()}
                            />
                          )}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                          <FilterButton>{column.render('Filter')}</FilterButton>
                        </FilterWrapper>
                      )}
                    </DefaultHeader>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.slice(pageStart, pageEnd).map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i + '_tr'}
                  {...row.getRowProps()}
                  onClick={(e) => onClickRowListener(e, row)}
                >
                  {row.cells.map((cell, idx) => {
                    return (
                      <td
                        key={idx + '_td'}
                        {...cell.getCellProps([
                          {
                            style: cell.column.align
                              ? cellStyle[cell.column.align]
                              : cellStyle['center'],
                          },
                        ])}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </Styles>
  );
};

export default DefaultTable;
