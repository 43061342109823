import EventDetailContainer from 'containers/event/EventDetailContainer';
import GlobalContainer from 'containers/GlobalContainer';

const EventDetail = () => {
  return (
    <GlobalContainer>
      <EventDetailContainer />
    </GlobalContainer>
  );
};

export default EventDetail;
