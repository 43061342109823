import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from 'modules/defines/styles';

export const LayoutWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
  row-gap: 20px;
  column-gap: 10px;
  width: 100%;
  height: calc(
    100vh - ${(props) => (props.fromBottom ? props.fromBottom : '160px')}
  );
  overflow: auto;

  .isMobile & {
    display: flex;
    padding: 0;
    flex-direction: column;
    height: 100%;
  }
`;

export const LayoutNoneWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 20px;
`;

export const NoneTitle = styled.div`
  font-size: 22px;
  color: ${palette.fontDefault};
`;
export const NoneDesc = styled.div`
  margin-top: 12px;
  font-size: 13px;
  color: ${palette.fontDefault};
`;

export const CommonContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${palette.white};
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  overflow: scroll;
  .isMobile & {
    width: 100%;
  }
`;
export const CommonWrapper = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 2px solid ${palette.borderOrange};
`;

export const TableFilterBtnDesc = styled.div`
  font-size: 10px;
  font-family: Pretendard-sl;
  color: ${palette.fontDefault};
  margin-left: 10px;
  margin-right: 4px;
  @media screen and (max-width: 1200px){
    display:none;
  }
  height: 100%;
  display:flex;
  align-items:flex-start;
`;

export const TableFilterBtnDivider = styled.div`
  width: 1px;
  height: 31px;
  border-left: 1px solid #707070;
  margin-left: 4px;
  margin-right: 10px;
  opacity: 0.2;
  @media screen and (min-width: 1199px){
    display:none;
  }
`;

export const TableFilterBtn = styled.div`
  padding: 8px 12px;
  background-color: ${(props) =>
    props.enabled ? palette.mainBlue : palette.mainBlueLight};
  color: ${palette.fontWhite};
  font-size: 12px;
  font-family: Pretendard-sb;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
  margin-right: 8px;
  display:flex;
  align-items:center;
  justify-content: center;
  @media screen and (max-width: 1350px){
    padding: 10px 6px;
    font-size: 10px;
  }
  .isMobile & {
    margin-right: 4px;
  }
`;

export const TableFilterItemBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: ${(props) =>
    props.enabled ? palette.mainBlue : palette.mainBlueLight};
    border-radius: 100%;
    cursor:pointer;
    margin-right: 8px;
    &:hover{
      background-color: ${palette.mainBlueHover};
    }
    .isMobile & {
    margin-right: 4px;
    }
`;
export const SelectDropdown = styled.select`
  all: unset;
  font-size: 12px;
  font-family: Pretendard-sb;
  color: ${palette.fontDefault};
  border: 1px solid ${palette.mainBlue};
  border-radius: 16px;
  padding: 6px;
  display: flex;
  margin-right: 8px;
  cursor: pointer;
  text-align: center;
  @media screen and (max-width: 1350px){
    padding: 10px 6px;
    font-size: 10px;
  }
  .isMobile & {
    margin-right: 4px;
  }
`;

export const ExtendBtn = styled.div`
  font-size: 12px;
  color: ${palette.fontWhite};
  background-color: ${palette.mainBlue};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover{
    background-color: ${palette.mainBlueHover};
  }
`;
export const RowLink = styled.div`
  width: 100%;
  height: 100%;
  display:flex;
  align-items:center;
  justify-content: ${(props) => props.align ? props.align : 'center'}
`
export const FloatingBtn = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  background-color: ${palette.mainBlue};
  &:hover{
    background-color: ${palette.mainBlueHover};
  }
  color: ${palette.fontWhite};
  border-radius: 30px;
  top: 8px;
  right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: Pretendard-sb;
  cursor: pointer;
  z-index: 99;
`;

