import { useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { radiansToDegrees } from 'utils/globalFunction';

const CameraControlDirectionDrag = ({ setCurrentAngle }) => {
  const { camera, gl } = useThree();

  useEffect(() => {
    let startX = 0;
    let startY = 0;
    const startQuaternion = new THREE.Quaternion().copy(camera.quaternion);

    const handleMouseMove = (event) => {
      const deltaX = event.clientX - startX;
      const deltaY = event.clientY - startY;
      rotateCamera(deltaX, deltaY);
    };

    const handleMouseDown = (event) => {
      startX = event.clientX;
      startY = event.clientY;
      startQuaternion.copy(camera.quaternion);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseUp = () => {
      const rotation = camera.rotation;
      const adjustedYDegree = -(
        ((radiansToDegrees(rotation.y) + 270) % 360) -
        180
      );
      setCurrentAngle(adjustedYDegree);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleTouchStart = (event) => {
      if (event.touches.length === 1) {
        startX = event.touches[0].clientX;
        startY = event.touches[0].clientY;
        startQuaternion.copy(camera.quaternion);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
      }
    };

    const handleTouchMove = (event) => {
      if (event.touches.length === 1) {
        const deltaX = event.touches[0].clientX - startX;
        const deltaY = event.touches[0].clientY - startY;
        rotateCamera(deltaX, deltaY);
      }
    };

    const handleTouchEnd = () => {
      const rotation = camera.rotation;
      const adjustedYDegree = -(
        ((radiansToDegrees(rotation.y) + 270) % 360) -
        180
      );
      setCurrentAngle(adjustedYDegree);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    const rotateCamera = (deltaX, deltaY) => {
      const sensitivity = 0.005;

      const euler = new THREE.Euler(0, 0, 0, 'YXZ');
      euler.setFromQuaternion(startQuaternion);

      euler.y += deltaX * sensitivity;
      euler.x += deltaY * sensitivity;
      euler.x = Math.max(Math.min(euler.x, Math.PI / 2), -Math.PI / 2);

      camera.quaternion.setFromEuler(euler);
    };

    gl.domElement.addEventListener('mousedown', handleMouseDown);
    gl.domElement.addEventListener('touchstart', handleTouchStart);

    return () => {
      gl.domElement.removeEventListener('mousedown', handleMouseDown);
      gl.domElement.removeEventListener('touchstart', handleTouchStart);
    };
  }, [camera, gl]);

  return null;
};

export default CameraControlDirectionDrag;
