import { useEffect, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import useInputState from 'modules/customHooks/useInputState';
import { ModalContext } from 'modules/context/ModalContext';
import AddressPopup from 'components/modal/AddressPopup';
import { useCookies } from 'react-cookie';
import TimeSelectModal from 'components/modal/TimeSelectModal';
import { spaceAPI } from 'api';
import SpaceDetailCategory from './SpaceDetailCategory';
import SpacePublishSwitchButton from './SpacePublishSwitchButton';
import MirroringSpaceModal from 'components/modal/MirroringSpaceModal';
import { GlobalContext } from 'modules/context/GlobalContext';
import { UserAgent } from 'utils/userAgent';
import { pagePaths } from 'modules/defines/paths';
import moment from 'moment-timezone';

const SpaceDetailInfo = ({
  havePermission,
  detailLoading,
  detailData,
  detailRefetch,
  titleList,
  categoryData,
  isSuperAdmin,
}) => {
  const history = useHistory();
  const globalState = useContext(GlobalContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [cookies, setCookie] = useCookies();
  const { os } = UserAgent;

  // USE STATE Data Value
  const { state: id, setState: setId } = useInputState('');
  const [categoryState, setCategoryState] = useState([0, 0, 0, 0, 0, 0, 0]); // FIXME 임시방편
  const {
    state: title,
    setState: setTitle,
    onChange: titleOnChange,
  } = useInputState('');
  const {
    state: phone,
    setState: setPhone,
    onChange: phoneOnChange,
  } = useInputState('');
  const {
    state: address,
    setState: setAddress,
    onChange: addressOnChange,
  } = useInputState('');
  const {
    state: addressOld,
    setState: setAddressOld,
    onChange: AddressOldOnChange,
  } = useInputState('');
  const [latLong, setLatLong] = useState('');
  const {
    state: floorDefault,
    setState: setFloorDefault,
    onChange: floorDefaultOnChange,
  } = useInputState('');
  const {
    state: time,
    setState: setTime,
    onChange: timeOnChange,
  } = useInputState('');
  const {
    state: desc,
    setState: setDesc,
    onChange: descOnChange,
  } = useInputState('');
  const {
    state: desc2,
    setState: setDesc2,
    onChange: desc2OnChange,
  } = useInputState('');
  const {
    state: menu,
    setState: setMenu,
    onChange: menuOnChange,
  } = useInputState('');
  const {
    state: link,
    setState: setLink,
    onChange: linkOnChange,
  } = useInputState('');
  const {
    state: reservationLink,
    setState: setReservationLink,
    onChange: reservationLinkOnChange,
  } = useInputState('');
  const {
    state: subdomain,
    setState: setSubdomain,
    onChange: subdomainOnChange,
  } = useInputState('');
  const [imgRep, setImgRep] = useState('');
  const [publishApp, setPublishApp] = useState(false);
  const [publishInsta, setPublishInsta] = useState(false);
  const [publishFaview, setPublishFaview] = useState(false);
  const [curation, setCuration] = useState([]);
  const {
    state: curatorsMemo,
    setState: setCuratorsMemo,
    onChange: curatorsMemoOnChange,
  } = useInputState('');

  // title on Focus (겹치는 공간 방지)
  const [isFocusTitle, setIsFocusTitle] = useState(false);
  const onFocusTitle = () => {
    setIsFocusTitle(true);
  };
  const onBlurTitle = () => {
    setIsFocusTitle(false);
  };
  const spaceTitleList = useMemo(() => {
    if (titleList === null) {
      return [];
    }
    if (title === '') {
      return [];
    } else {
      return titleList.filter((item) => {
        return item.includes(title);
      });
    }
  }, [title, titleList]);

  // INITIALIZE Data
  useEffect(() => {
    setId(detailData?.data?.id);
    setTitle(detailData?.data?.title);
    setPhone(detailData?.data?.phone);
    setAddress(detailData?.data?.address);
    setAddressOld(detailData?.data?.address_old);
    setLatLong(
      detailData?.data?.address_lat + ', ' + detailData?.data?.address_long
    );
    setFloorDefault(detailData?.data.floor_default);
    setTime(detailData?.data?.time);
    setDesc(detailData?.data?.description);
    setDesc2(detailData?.data?.description2);
    setMenu(detailData?.data?.menu);
    setLink(detailData?.data?.link);
    setReservationLink(detailData?.data?.reservation_link);
    setSubdomain(detailData?.data?.subdomain);
    setPublishApp(detailData?.data?.publish);
    setPublishInsta(detailData?.data?.publish_instagram);
    setPublishFaview(detailData?.data?.publish_faview);
    setCuratorsMemo(detailData?.data?.curators_memo);
    setCuration(detailData?.data?.curation);
    setImgRep(detailData?.data?.img);
  }, [detailData]);

  // Func for 카테고리
  const getCategoryString = (arr) => {
    let firstClickIdx = null;
    let restIdx = '';
    arr.map((item, idx) => {
      if (item === 2) {
        firstClickIdx = idx;
      }
      if (item === 1) {
        restIdx += '/' + idx;
      }
    });
    if (firstClickIdx + restIdx === 'null') {
      return '';
    } else {
      return firstClickIdx + restIdx;
    }
  };

  // Modal : 영업시간
  const ModalSelectTime = async (e) => {
    e.preventDefault();
    return openModal(
      <TimeSelectModal closeModal={closeModal} setTime={setTime} />
    );
  };

  // 주소 불러오기 (from Naver)
  let column_data = useMemo(() => {
    return [];
  }, []);
  const searchAdressListener = async (e) => {
    e.preventDefault();
    let response;
    column_data = [];
    try {
      const postData = {
        place_name: title,
      };
      response = await spaceAPI.searchAddressByPlace(postData);
      response.data['items'].map((item) =>
        column_data.push({
          title: item.title,
          roadAddress: item.roadAddress
            .replace('특별시', '시')
            .replace('광역시', '시')
            .replace('특별자치도', '도'),
          address: item.address
            .replace('특별시', '시')
            .replace('광역시', '시')
            .replace('특별자치도', '도'),
          category: item.category,
        })
      );
      return openModal(
        <AddressPopup
          title={title}
          data={column_data}
          closeModal={closeModal}
          setAddressBasedOnNaver={setAddressBasedOnNaver}
        />
      );
    } catch (e) {
      console.error(e);
      alert('네이버 api 호출 에러가 발생했습니다.\n관리자에게 문의하세요.');
    }
  };
  const setAddressBasedOnNaver = (title, address, addressOld) => {
    setTitle(title);
    setAddress(address);
    setAddressOld(addressOld);
  };

  const checkFaviewSubdomain = (e) => {
    window.open(
      pagePaths.faview_subdomain.replace(':sub', subdomain),
      '_blank'
    );
  };

  // 위경도 불러오기 (from Naver)
  const convertWithNaver = async (e) => {
    e.preventDefault();
    let response;
    try {
      const postData = {
        address: address,
        address_old: addressOld,
      };
      response = await spaceAPI.convertAddressWithNaver(postData);
      setLatLong(response.data['y'] + ', ' + response.data['x']);
    } catch (e) {
      console.error(e);
      alert('네이버 api 호출 에러가 발생했습니다.\n관리자에게 문의하세요.');
    }
  };

  // 위경도 불러오기 (from Google)
  const convertWithGoogle = async (e) => {
    e.preventDefault();
    let response;
    try {
      const postData = {
        address: address,
        address_old: addressOld,
      };
      response = await spaceAPI.convertAddressWithGoogle(postData);
      setLatLong(response.data['y'] + ', ' + response.data['x']);
    } catch (e) {
      console.error(e);
      alert('네이버 api 호출 에러가 발생했습니다.\n관리자에게 문의하세요.');
    }
  };

  // BUTTON : 삭제
  const deleteBtnClickListener = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    }
    const postData = {
      id: id,
    };
    let response;
    try {
      response = await spaceAPI.spaceDelete(postData);
    } catch (e) {
      console.error(e);
      alert(
        '공간 정보를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    globalState.refetch('space');
    history.push(pagePaths.space.list);
  };
  // BUTTON : 저장
  const saveBtnClickListener = async (e, isTempSave = false) => {
    e.preventDefault();
    const categoryString = getCategoryString(categoryState);
    const descFixed = desc.trimEnd().trimStart();
    const desc2Fixed =
      desc !== null ? desc2.replaceAll('\n', ' ').trimEnd().trimStart() : '';
    const timeFixed = time.trimEnd().trimStart();
    const menuFixed =
      menu !== null ? menu.replaceAll('\n\n', '\n').trimEnd().trimStart() : '';

    let duplicated = false;
    let duplicatedItems = [];
    await Promise.all(
      globalState.space.map((item) => {
        if (
          item.title.replace(/ /g, '') === title.replace(/ /g, '') &&
          item.id !== id
        ) {
          duplicatedItems.push({ id: item.id, title: item.title });
        }
      })
    );
    if (duplicatedItems.length !== 0) {
      if (
        window.confirm(
          `이름이 비슷한 공간이 있습니다.\n${duplicatedItems
            .map((item) => `> id:${item.id} | ${item.title}`)
            .join('\n')}\n\n그래도 등록하시겠습니까?`
        )
      ) {
        // 확인
        duplicated = false;
      } else {
        // 취소
        duplicated = true;
      }
    }

    if (categoryString === '') {
      alert('오류 : 공간 카테고리를 선택해주세요.');
    } else if (title === '') {
      alert('오류 : 제목을 입력해주세요.');
    } else if (duplicated) {
      //
    } else {
      const postData = {
        id: id,
        category: categoryString,
        title: title,
        description: descFixed,
        description2: desc2Fixed,
        address: address,
        address_old: addressOld,
        floor_default: floorDefault,
        phone: phone,
        time: timeFixed,
        menu: menuFixed,
        link: link,
        reservation_link: reservationLink,
        subdomain: subdomain,
        address_latlong: latLong,
        curators_memo: curatorsMemo,
      };
      try {
        await spaceAPI.spaceEdit(postData);
      } catch (e) {
        console.error(e);
        alert(
          '공간 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('공간정보가 수정되었습니다.');
      // 저장
      if (!isTempSave) {
        globalState.refetch('space');
        if (cookies?.openNewTab) {
          history.push(pagePaths.space.list);
          // window.close();
        } else {
          history.goBack();
        }
      }
      // 임시저장
      else {
        detailRefetch();
        globalState.refetch('space');
      }
    }
  };
  // Modal Mirroring
  const ModalMirroring = async (e) => {
    e.preventDefault();
    return openModal(
      <MirroringSpaceModal
        titleProps={title}
        setTitleProps={setTitle}
        descProps={desc}
        setDescProps={setDesc}
        imgRep={imgRep}
        closeModal={closeModal}
      />
    );
  };
  const curationClickListener = (e, id) => {
    e.preventDefault();
    history.push(pagePaths.curation.detail.replace(':id', id));
  };

  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle>공간 정보</HeaderTitle>
        {havePermission && (
          <HeaderBtnContainer>
            {(os.isOthers || os.isMac) && (
              <HeaderBtn
                color={palette.fontGray}
                onClick={(e) => ModalMirroring(e)}
              >
                미러링
              </HeaderBtn>
            )}
            <HeaderBtn
              onClick={deleteBtnClickListener}
              color={palette.mainOrange}
              hoverColor={palette.mainOrangeHover}
            >
              삭제
            </HeaderBtn>
            <HeaderBtn onClick={(e) => saveBtnClickListener(e, true)}>
              임시저장
            </HeaderBtn>
            <HeaderBtn onClick={(e) => saveBtnClickListener(e, false)}>
              저장
            </HeaderBtn>
          </HeaderBtnContainer>
        )}
      </HeaderContainer>
      <ContentsContainer>
        {!detailLoading && (
          <ContentsWrapper>
            {/* 공간 ID */}
            <ItemContainer>
              <ItemTitle>공간 ID</ItemTitle>
              <ItemTextInput readOnly value={id} />
            </ItemContainer>
            {/* 공간 카테고리 */}
            <ItemContainer>
              <ItemTitle>카테고리</ItemTitle>
              <SpaceDetailCategory
                categoryData={categoryData}
                categoryState={categoryState}
                setCategoryState={setCategoryState}
                detailData={detailData}
              />
            </ItemContainer>
            {/* 공간이름 */}
            <ItemContainer>
              <ItemTitle>공간 이름</ItemTitle>
              <ItemTextInput
                value={title || ''}
                onChange={titleOnChange}
                onFocus={onFocusTitle}
                onBlur={onBlurTitle}
                disabled={!havePermission}
              />
              {title !== '' && isFocusTitle && (
                <DropDownContainer>
                  {spaceTitleList.map((option, index) => {
                    return <DropDownItem key={index}>{option}</DropDownItem>;
                  })}
                </DropDownContainer>
              )}
              {havePermission && (
                <InlineBtn
                  onClick={searchAdressListener}
                  color={palette.grey_darker}
                >
                  주소검색
                </InlineBtn>
              )}
            </ItemContainer>
            {/* 전화번호 */}
            <ItemContainer>
              <ItemTitle>전화번호</ItemTitle>
              <ItemTextInput
                value={phone || ''}
                onChange={phoneOnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            {/* 주소 */}
            <ItemContainer>
              <ItemTitle>주소</ItemTitle>
              <ItemTextInput
                value={address || ''}
                onChange={addressOnChange}
                disabled={!havePermission}
              />
              {havePermission && (
                <InlineBtn onClick={convertWithNaver} color={palette.naver}>
                  네이버변환
                </InlineBtn>
              )}
            </ItemContainer>
            {/* 지번주소 */}
            <ItemContainer>
              <ItemTitle>지번주소</ItemTitle>
              <ItemTextInput
                value={addressOld || ''}
                onChange={AddressOldOnChange}
                disabled={!havePermission}
              />
              {havePermission && (
                <InlineBtn onClick={convertWithGoogle} color={palette.google}>
                  구글변환
                </InlineBtn>
              )}
            </ItemContainer>
            {/* 위∙경도 & 층수 */}
            <ItemContainersRow grid={2}>
              <ItemContainer>
                <ItemTitle>위∙경도</ItemTitle>
                <ItemTextInput value={latLong || ''} disabled={true} />
              </ItemContainer>
              {isSuperAdmin && (
                <ItemContainer>
                  <ItemTitle>층(Floor)</ItemTitle>
                  <ItemTextInput
                    value={floorDefault || ''}
                    onChange={floorDefaultOnChange}
                  />
                </ItemContainer>
              )}
            </ItemContainersRow>

            {/* 영업시간 */}
            <ItemContainer>
              <ItemTitle>영업시간</ItemTitle>
              <ItemTextArea
                height="100px"
                value={time || ''}
                onChange={timeOnChange}
                disabled={!havePermission}
              />
              {havePermission && (
                <InlineBtn
                  onClick={ModalSelectTime}
                  color={palette.grey_darker}
                >
                  영업시간
                </InlineBtn>
              )}
            </ItemContainer>
            {/* 설명 */}
            <ItemContainer>
              <ItemTitleContainer>
                <ItemTitle>설명</ItemTitle>
                <ItemDesc>
                  공백 포함
                  <br />
                  최대 21자
                </ItemDesc>
                {desc != null && (
                  <ItemDesc2 warning={desc.length > 21}>
                    {desc.length} / 21
                  </ItemDesc2>
                )}
              </ItemTitleContainer>
              <ItemTextArea
                value={desc || ''}
                onChange={descOnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            {/* 추가설명 */}
            <ItemContainer>
              <ItemTitleContainer>
                <ItemTitle>추가설명</ItemTitle>
                <ItemDesc>
                  공백 포함
                  <br />
                  최소 80자
                </ItemDesc>
                {desc2 != null && (
                  <ItemDesc2 warning={desc2.length < 80}>
                    {desc2.length} 자
                  </ItemDesc2>
                )}
              </ItemTitleContainer>
              <ItemTextArea
                height="100px"
                value={desc2 || ''}
                onChange={desc2OnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            {/* 메뉴 */}
            <ItemContainer>
              <ItemTitle>메뉴</ItemTitle>
              <ItemTextArea
                value={menu || ''}
                onChange={menuOnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            {/* 링크 */}
            <ItemContainer>
              <ItemTitle>링크</ItemTitle>
              <ItemTextInput
                value={link || ''}
                onChange={linkOnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            {/* 예약링크 */}
            <ItemContainer>
              <ItemTitle>예약링크</ItemTitle>
              <ItemTextInput
                value={reservationLink || ''}
                onChange={reservationLinkOnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            {/* 예약링크 */}
            <ItemContainer>
              <ItemTitle>파뷰도메인</ItemTitle>
              <ItemTextInput
                value={subdomain || ''}
                onChange={subdomainOnChange}
                disabled={!havePermission}
              />
              <InlineBtn
                onClick={checkFaviewSubdomain}
                color={palette.grey_darker}
              >
                도메인확인
              </InlineBtn>
            </ItemContainer>
            {/* 작성일자 & 작성자 */}
            <ItemContainersRow grid={2}>
              <ItemContainer>
                <ItemTitle>작성 일자</ItemTitle>
                <ItemTextInput
                  readOnly
                  value={moment
                    .tz(detailData.data.createdAt, 'Asia/Seoul')
                    .format('YYYY-MM-DD  HH:mm')}
                />
              </ItemContainer>
              <ItemContainer>
                <ItemTitle>작성자</ItemTitle>
                <ItemTextInput readOnly value={detailData?.data?.writer} />
              </ItemContainer>
            </ItemContainersRow>
            {/* PUBLISH */}
            {havePermission && (
              <ItemContainersRow grid={3}>
                <ItemContainer>
                  <ItemTitle>Publish</ItemTitle>
                  <SpacePublishSwitchButton
                    publishMode="app"
                    published={publishApp}
                    id={id}
                  />
                </ItemContainer>
                <ItemContainer>
                  <ItemTitle>Insta</ItemTitle>
                  <SpacePublishSwitchButton
                    publishMode="insta"
                    published={publishInsta}
                    id={id}
                  />
                </ItemContainer>
                {detailData?.data?.panorama.length !== 0 && (
                  <ItemContainer>
                    <ItemTitle>FAVIEW</ItemTitle>
                    <SpacePublishSwitchButton
                      publishMode="faview"
                      published={publishFaview}
                      id={id}
                    />
                  </ItemContainer>
                )}
              </ItemContainersRow>
            )}

            {/* 담벼락 */}
            <ItemContainer>
              <ItemTitleContainer>
                <ItemTitle>담벼락</ItemTitle>
                <ItemDesc>
                  에디터들
                  <br />
                  떠드는 공간
                </ItemDesc>
              </ItemTitleContainer>
              <ItemTextArea
                value={curatorsMemo || ''}
                onChange={curatorsMemoOnChange}
              />
            </ItemContainer>
            <ItemContainer>
              <ItemTitle>
                포함
                <br />
                큐레이션
              </ItemTitle>
              <CurationItemWrapper>
                {curation.length !== 0 &&
                  curation.map((cur) => {
                    return (
                      <CurationItem
                        onClick={(e) => curationClickListener(e, cur.id)}
                      >
                        {cur.id}
                        <br />
                        {cur.title}
                      </CurationItem>
                    );
                  })}
              </CurationItemWrapper>
            </ItemContainer>
          </ContentsWrapper>
        )}
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
// HEADER
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.div`
  font-family: Pretendard-b;
  font-size: 18px;
  color: ${palette.fontDefault};
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;
const HeaderBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const HeaderBtn = styled.div`
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : palette.mainBlue)};
  background-color: ${palette.white};
  border: 1px solid ${(props) => (props.color ? props.color : palette.mainBlue)};
  border-radius: 16px;
  padding: 4px 16px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.color : palette.mainBlueHover};
    color: ${palette.white};
  }
  margin-left: 16px;
  @media screen and (max-width: 1200px) {
    font-size: 13px;
    margin-left: 12px;
    padding: 4px 8px;
  }
`;
const InlineBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 12px;
  color: ${(props) => (props.color ? props.color : palette.mainOrange)};
  background-color: ${palette.white};
  border: 1px solid
    ${(props) => (props.color ? props.color : palette.mainOrange)};
  border-radius: 16px;
  padding: 4px 8px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.color ? props.color : palette.mainOrangeHover};
    color: ${palette.white};
  }
  margin-left: 16px;
  @media screen and (max-width: 1200px) {
    font-size: 10px;
  }
`;
// CONTENTS
const ContentsContainer = styled.div`
  margin-top: 12px;
`;
const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
// Space Items
const ItemContainersRow = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.grid ? props.grid : 2)},
    1fr
  );
  grid-column-gap: 20px;
`;
const ItemContainer = styled.div`
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
`;
// Space Title
const ItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemTitle = styled.div`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0'}px;
  min-width: 70px;
  text-align: left;
  font-size: 14px;
  color: ${palette.fontDefault};
  line-height: 16px;
`;
const ItemDesc = styled.div`
  text-size: 6px;
  display: flex;
  margin-top: 4px;
  color: ${palette.fontGray};
  line-height: 12px;
`;
const ItemDesc2 = styled.div`
  color: ${(props) => (props.warning ? palette.red : palette.black)};
`;
// Space Item Value
const ItemTextInput = styled.input`
  all: unset;
  width: 100%;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  font-size: 16px;
  color: ${(props) =>
    props.readOnly ? palette.mainOrange : palette.fontBlack};
  opacity: 1;
`;
const ItemTextArea = styled.textarea`
  all: unset;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '60px')};
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  color: ${palette.fontBlack};
  font-size: 16px;
  line-height: 1.1;
`;
const DropDownContainer = styled.div`
  border: 1px solid rgb(223, 225, 229);
  font-size: 14px;
  background-color: ${palette.white};
  border-radius: 2px;
  width: 140px;
  position: absolute;
  box-shadow: 0 0 20px 0 #ccc;
  z-index: 99;
  opacity: 1;
  overflow: scroll;
  top: 20px;
  left: 70px;
`;
const DropDownItem = styled.div`
  color: ${palette.fontDefault};
  font-size: 14px;
  padding: 10px 8px;
  &:hover {
    color: ${palette.mainOrangeHover};
  }
`;
// Value Set Button
const ValueSetButton = styled.button`
  all: unset;
  width: 90px;
  height: 30px;
  cursor: pointer;
`;
const CurationItemWrapper = styled.div`
  width: 100%;
`;
const CurationItem = styled.div`
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-top: 8px;
  width: 140px;
  height: 40px;
  background-color: ${palette.mainOrangeHover};
  border-radius: 20px;
  vertical-align: middle;

  color: ${palette.white};
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

export default SpaceDetailInfo;
