import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { TailSpin } from 'react-loader-spinner';

const ErrorDashboard = ({
  closedData,
  closedDataLoadingMode,
  closedDataSyncBtnListener,
  closedDataBtnText,
  elementClickListener,
  spaceTitleDuplicateLoading,
  spaceTitleDuplicate,
  spaceMenuNoLineBreakLoading,
  spaceMenuNoLineBreak,
  spaceNoKeywordLoading,
  spaceNoKeyword,
  spaceNoMenuLoading,
  spaceNoMenu,
  spaceNoTimeLoading,
  spaceNoTime,
}) => {
  return !spaceTitleDuplicateLoading &&
    !spaceMenuNoLineBreakLoading &&
    !spaceNoKeywordLoading &&
    !spaceNoMenuLoading &&
    !spaceNoTimeLoading ? (
    <DataErrorHandlerContainer>
      {/* 폐업 의심 공간 */}
      <DataErrorHandlerWrapper>
        <DataErrorHandlerTitle>폐업 의심 공간</DataErrorHandlerTitle>
        <LayoutSpaceBetween>
          <DataErrorHandlerDesc>
            폐업이 의심되는 공간입니다. 검색 후 처리해주세요. 우측 버튼을 통해
            동기화할 수 있습니다.
          </DataErrorHandlerDesc>
          <DataSyncButton onClick={closedDataSyncBtnListener}>
            {closedDataBtnText}
          </DataSyncButton>
        </LayoutSpaceBetween>
        <DataErrorHandlerTableWrapper height={'200px'}>
          {closedDataLoadingMode === 0 && (
            <DataErrorHandlerNoDataWrapper>
              우측상단 동기화버튼을 통해 동기화해주세요
            </DataErrorHandlerNoDataWrapper>
          )}
          {closedDataLoadingMode === 1 && (
            <DataErrorHandlerLoadingWrapperWrapper>
              <DataErrorHandlerLoadingWrapper>
                로딩중입니다... 잠시만 기다려주세요.
              </DataErrorHandlerLoadingWrapper>
              <DataErrorHandlerLoadingSpinWrapper>
                <TailSpin
                  color={palette.mainOrangeHover}
                  width={24}
                  height={24}
                />
              </DataErrorHandlerLoadingSpinWrapper>
            </DataErrorHandlerLoadingWrapperWrapper>
          )}
          {closedDataLoadingMode === 2 && (
            <DataErrorHandlerLoadingWrapperWrapper>
              <DataErrorHandlerLoadingWrapper>
                로딩 완료
              </DataErrorHandlerLoadingWrapper>
            </DataErrorHandlerLoadingWrapperWrapper>
          )}
          <DataErrorHandlerClosedListWrapper>
            {closedData.map((item, idx) => {
              return (
                <DataErrorHandlerClosedListItemWrapper
                  key={idx + '_'}
                  onClick={(e) =>
                    elementClickListener(e, 'space', item.id, true)
                  }
                >
                  <DataErrorHandlerClosedId>{item.id}</DataErrorHandlerClosedId>
                  <DataErrorHandlerClosedTitle>
                    {item.title}
                  </DataErrorHandlerClosedTitle>
                </DataErrorHandlerClosedListItemWrapper>
              );
            })}
          </DataErrorHandlerClosedListWrapper>
        </DataErrorHandlerTableWrapper>
      </DataErrorHandlerWrapper>
      {/* Title 겹치는 공간들 */}
      <DataErrorHandlerWrapper>
        <DataErrorHandlerTitle>Title 겹치는 공간</DataErrorHandlerTitle>
        <DataErrorHandlerDesc>
          공간 title 정보가 겹치는 공간들입니다. 확인해보시고 수정 혹은 삭제
          해주세요.
        </DataErrorHandlerDesc>
        <DataErrorHandlerTableWrapper>
          {spaceTitleDuplicate?.length !== 0 ? (
            spaceTitleDuplicate?.map((spaceItem, idx) => {
              return (
                <DataErrorHandlerTableItem
                  key={idx + spaceItem.id}
                  onClick={(e) =>
                    elementClickListener(e, 'space', spaceItem.id)
                  }
                >
                  <DataErrorHandlerTableTitle>
                    ID : {spaceItem.id}
                  </DataErrorHandlerTableTitle>
                  <DataErrorHandlerTableDesc>
                    Title : {spaceItem.title}
                  </DataErrorHandlerTableDesc>
                  <DataErrorHandlerTableDesc>
                    Writer : {spaceItem.writer}
                  </DataErrorHandlerTableDesc>
                </DataErrorHandlerTableItem>
              );
            })
          ) : (
            <DataErrorHandlerNoDataWrapper>
              해당하는 데이터가 없습니다.
            </DataErrorHandlerNoDataWrapper>
          )}
        </DataErrorHandlerTableWrapper>
      </DataErrorHandlerWrapper>
      {/* 메뉴 줄바꿈 필요한 공간 */}
      <DataErrorHandlerWrapper>
        <DataErrorHandlerTitle>Menu 줄바꿈 필요</DataErrorHandlerTitle>
        <DataErrorHandlerDesc>
          공간의 메뉴들은 메뉴 하나를 입력하고 줄바꿈을 해야합니다. 아래
          데이터는 줄바꿈이 안 되어있는 데이터들입니다. 확인해보시고
          줄바꿈해주세요.
        </DataErrorHandlerDesc>
        <DataErrorHandlerTableWrapper>
          {spaceMenuNoLineBreak?.length !== 0 ? (
            spaceMenuNoLineBreak?.map((spaceItem, idx) => {
              return (
                <DataErrorHandlerTableItem
                  key={idx + spaceItem.id}
                  onClick={(e) =>
                    elementClickListener(e, 'space', spaceItem.id)
                  }
                >
                  <DataErrorHandlerTableTitle>
                    ID : {spaceItem.id}
                  </DataErrorHandlerTableTitle>
                  <DataErrorHandlerTableDesc>
                    Title : {spaceItem.title}
                  </DataErrorHandlerTableDesc>
                  <DataErrorHandlerTableDesc>
                    Writer : {spaceItem.writer}
                  </DataErrorHandlerTableDesc>
                </DataErrorHandlerTableItem>
              );
            })
          ) : (
            <DataErrorHandlerNoDataWrapper>
              해당하는 데이터가 없습니다.
            </DataErrorHandlerNoDataWrapper>
          )}
        </DataErrorHandlerTableWrapper>
      </DataErrorHandlerWrapper>
      {/* 키워드가 없는 공간 */}
      <DataErrorHandlerWrapper>
        <DataErrorHandlerTitle>Keyword 없는 공간</DataErrorHandlerTitle>
        <DataErrorHandlerDesc>
          공간이 앱 내에서 제대로 추천되기 위해서는 적어도 4개의 Vibe 키워드가
          필요합니다. 해당 공간들은 4개 미만의 Vibe 키워드를 가지고 있습니다.
        </DataErrorHandlerDesc>
        <DataErrorHandlerTableWrapper>
          {spaceNoKeyword?.length !== 0 ? (
            spaceNoKeyword?.map((spaceItem, idx) => {
              return (
                <DataErrorHandlerTableItem
                  key={idx + spaceItem.id}
                  onClick={(e) =>
                    elementClickListener(e, 'space', spaceItem.id)
                  }
                >
                  <DataErrorHandlerTableTitle>
                    ID : {spaceItem.id}
                  </DataErrorHandlerTableTitle>
                  <DataErrorHandlerTableDesc>
                    Title : {spaceItem.title}
                  </DataErrorHandlerTableDesc>
                  <DataErrorHandlerTableDesc>
                    Writer : {spaceItem.writer}
                  </DataErrorHandlerTableDesc>
                </DataErrorHandlerTableItem>
              );
            })
          ) : (
            <DataErrorHandlerNoDataWrapper>
              해당하는 데이터가 없습니다.
            </DataErrorHandlerNoDataWrapper>
          )}
        </DataErrorHandlerTableWrapper>
      </DataErrorHandlerWrapper>
      {/* 영업시간 없는 공간 */}
      <DataErrorHandlerWrapper>
        <DataErrorHandlerTitle>영업시간 없는 공간</DataErrorHandlerTitle>
        <DataErrorHandlerDesc>영업시간 없는 공간들입니다.</DataErrorHandlerDesc>
        <DataErrorHandlerTableWrapper>
          {spaceNoTime?.length !== 0 ? (
            spaceNoTime?.map((spaceItem, idx) => {
              return (
                <DataErrorHandlerTableItem
                  key={idx + spaceItem.id}
                  onClick={(e) =>
                    elementClickListener(e, 'space', spaceItem.id)
                  }
                >
                  <DataErrorHandlerTableTitle>
                    ID : {spaceItem.id}
                  </DataErrorHandlerTableTitle>
                  <DataErrorHandlerTableDesc>
                    Title : {spaceItem.title}
                  </DataErrorHandlerTableDesc>
                  <DataErrorHandlerTableDesc>
                    Writer : {spaceItem.writer}
                  </DataErrorHandlerTableDesc>
                </DataErrorHandlerTableItem>
              );
            })
          ) : (
            <DataErrorHandlerNoDataWrapper>
              해당하는 데이터가 없습니다.
            </DataErrorHandlerNoDataWrapper>
          )}
        </DataErrorHandlerTableWrapper>
      </DataErrorHandlerWrapper>
      {/* 메뉴 없는 공간 */}
      <DataErrorHandlerWrapper>
        <DataErrorHandlerTitle>Menu 없는 공간</DataErrorHandlerTitle>
        <DataErrorHandlerDesc>
          메뉴가 비어있는 공간들입니다.
        </DataErrorHandlerDesc>
        <DataErrorHandlerTableWrapper>
          {spaceNoMenu?.length !== 0 ? (
            spaceNoMenu?.map((spaceItem, idx) => {
              return (
                <DataErrorHandlerTableItem
                  key={idx + spaceItem.id}
                  onClick={(e) =>
                    elementClickListener(e, 'space', spaceItem.id)
                  }
                >
                  <DataErrorHandlerTableTitle>
                    ID : {spaceItem.id}
                  </DataErrorHandlerTableTitle>
                  <DataErrorHandlerTableDesc>
                    Title : {spaceItem.title}
                  </DataErrorHandlerTableDesc>
                  <DataErrorHandlerTableDesc>
                    Writer : {spaceItem.writer}
                  </DataErrorHandlerTableDesc>
                </DataErrorHandlerTableItem>
              );
            })
          ) : (
            <DataErrorHandlerNoDataWrapper>
              해당하는 데이터가 없습니다.
            </DataErrorHandlerNoDataWrapper>
          )}
        </DataErrorHandlerTableWrapper>
      </DataErrorHandlerWrapper>
    </DataErrorHandlerContainer>
  ) : (
    <></>
  );
};
////
const DataErrorHandlerContainer = styled.div``;
const DataErrorHandlerWrapper = styled.div`
  margin-bottom: 32px;
`;
const DataErrorHandlerTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${palette.fontDefault};
`;
const DataErrorHandlerDesc = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: ${palette.fontDefault};
`;
const DataErrorHandlerTableWrapper = styled.div`
  padding: 10px;
  margin-top: 12px;
  border: 1px solid ${palette.borderDefault};
  height: ${(props) => (props.height ? props.height : '110px')};
  border-radius: 10px;
  overflow: scroll;
`;
const DataErrorHandlerNoDataWrapper = styled.div`
  font-size: 14px;
  color: ${palette.grey_lighter};
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
const DataErrorHandlerLoadingWrapperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px ${palette.borderLightGrey};
`;
const DataErrorHandlerLoadingWrapper = styled.div`
  font-size: 14px;
  font-family: Pretendard-sb;
  color: ${palette.fontDefault};
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 0px;
  margin-bottom: 4px;
`;
const DataErrorHandlerLoadingSpinWrapper = styled.div`
  height: 100%;
  margin-left: 8px;
  display: flex;
  align-items: center;
`;
const DataErrorHandlerClosedListWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const DataErrorHandlerClosedListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px ${palette.borderLightGrey};
  padding: 4px 2px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainBlueLight};
  }
`;

const DataErrorHandlerClosedId = styled.div`
  font-family: Pretendard-l;
  color: ${palette.fontGray};
  font-size: 11px;
`;
const DataErrorHandlerClosedTitle = styled.div`
  font-family: Pretendard-r;
  color: ${palette.fontBlack};
  font-size: 13px;
  margin-left: 6px;
`;

const DataErrorHandlerTableItem = styled.div`
  display: flex;
  padding: 12px;
  border-bottom: 1px solid ${palette.borderDefault};
  cursor: pointer;
  &:hover {
    background-color: ${palette.background};
  }
`;

const DataErrorHandlerTableTitle = styled.div`
  font-size: 16px;
  color: ${palette.fontDefault};
`;
const DataErrorHandlerTableDesc = styled.div`
  margin-left: 32px;
  font-size: 16px;
  color: ${palette.fontDefault};
`;

const LayoutSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DataSyncButton = styled.div`
  font-family: Pretendard-sb;
  font-size: 14px;

  background-color: ${palette.mainBlue};
  color: ${palette.white};
  padding: 8px 8px;
  margin-right: 8px;
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
  cursor: pointer;
  border-radius: 6px;
`;

export default ErrorDashboard;
