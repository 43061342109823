import NotificationListContainer from 'containers/notification/NotificationListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const NotificationList = () => {
  return (
    <GlobalContainer>
      <NotificationListContainer />
    </GlobalContainer>
  );
};

export default NotificationList;
