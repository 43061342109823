
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

export const FormWrapper = styled.div`
  width: 360px;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormLayout = styled.form`
  border: solid 1px #cccccc;
  border-radius: 8px;
  width: 500px;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${palette.mainOrange};
  width: 360px;
  margin-bottom: 20px;
`;

export const TextForm = styled.input`
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  width: 360px;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 16px;
`;
export const WarningText = styled.div`
  color: ${palette.fontWarning};
  font-size: 12px;
  margin-bottom: 10px;
  width: 360px;
`;
export const SubmitButton = styled.button`
  width: 360px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: ${palette.mainOrange};
  &:hover {
    background-color: ${palette.mainOrangeHover};
  }
  cursor: pointer;
  font-size: 14px;
  font-weight: 900;
  color: ${palette.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SubButtonWrapper = styled.div`
  margin-top: 16px;
  width: 360px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SubButtonElement = styled.div`
  color: ${palette.mainOrange};
  font-size: 12px;
  cursor: pointer;
`;
