import { useState } from "react";

export const useModal = () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("0");

  const closeModal = (content = false) => {
    setModal(false);
    setModalContent(content);
  };
  const openModal = (content = false) => {
    setModal(true);
    if (content) {
      setModalContent(content);
    }
  };

  return { modal, closeModal, openModal, modalContent };
};
