import React from 'react';
import { HashRouter } from 'react-router-dom';
import Router from 'routes';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import GlobalStyling from 'modules/defines/styles';
import { GlobalContext, useGlobalContext } from 'modules/context/GlobalContext';
import { ModalProvider } from 'modules/context/ModalContext';
import { CookiesProvider } from 'react-cookie';
import { theme } from 'modules/defines/styles';
import { useEffect } from 'react';
import { UserAgent } from 'utils/userAgent';
import { useUserState, UserContext } from 'modules/api/user';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LoadingScreen } from 'components/common/Common';
import { NavermapsProvider } from 'react-naver-maps';

function App() {
  const queryClient = new QueryClient();
  const [userContext, setUserContext] = useUserState();
  const globalState = useGlobalContext();
  const history = createBrowserHistory();
  const { os } = UserAgent;

  useEffect(() => {
    if (os.isIos || os.isAndroid) {
      document.body.classList.add('isMobile');
    } else {
      document.body.classList.add('isDesktop');
    }
  }, []);
  return (
    <NavermapsProvider ncpClientId="wwyebba9sr">
      <HashRouter history={history}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyling />
            <UserContext.Provider value={[userContext, setUserContext]}>
              <QueryClientProvider client={queryClient}>
                <GlobalContext.Provider value={globalState}>
                  <ModalProvider>
                    {userContext.loading ? <LoadingScreen /> : <Router />}
                  </ModalProvider>
                </GlobalContext.Provider>
              </QueryClientProvider>
            </UserContext.Provider>
          </ThemeProvider>
        </CookiesProvider>
      </HashRouter>
    </NavermapsProvider>
  );
}

export default App;
