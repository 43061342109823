import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { btn_curation_off, btn_curation_on } from 'assets/img/button';
import { curationAPI } from 'modules/api';

const PublishButton = styled.div`
  width: 39px;
  height: 24px;
  object-fit: contain;
  background-size: cover;
  background-image: url(${(props) =>
    props.published ? btn_curation_on : btn_curation_off});
`;
const CurationPublishSwitchButton = (props) => {
  const [published, setPublished] = useState(props.published);
  const [needOrder, setNeedOrder] = useState(false);
  const id = props.id;
  const unpublishedSpace = [];

  useEffect(() => {
    setPublished(props.published);
  }, [props.published]);
  useEffect(() => {
    props.belongSpace?.map((space) => {
      if (space.CurationSpace.order === null) {
        setNeedOrder(true);
      }
    });
  }, [props.belongSpace]);

  const onClick = async () => {
    if (!published) {
      props.belongSpace.forEach((item) => {
        if (item.publish === false) {
          unpublishedSpace.push(item);
        }
      });
      if (props.belongSpace.length === 0) {
        alert('포함 공간이 비어있습니다. 공간을 넣고 Publish 해주세요.');
      } else if (unpublishedSpace.length !== 0) {
        alert(
          `큐레이션에 포함된 매장 중 [${unpublishedSpace.map(
            (item) => item.title
          )}] 이(가) publish 상태가 아닙니다.\n매장 publish 상태를 확인해 주세요.`
        );
      } else if (needOrder) {
        alert('포함 공간의 순서가 필요합니다.\n"순서"를 클릭하여 정해주세요.');
      } else {
        setPublished(!published);
        const postData = {
          id,
        };
        try {
          await curationAPI.switchPublish(postData);
        } catch (e) {
          console.error(e);
          alert(
            '큐레이션을 Publish하는 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
      }
    } else {
      setPublished(!published);
      const postData = {
        id,
      };
      try {
        await curationAPI.switchPublish(postData);
      } catch (e) {
        console.error(e);
        alert(
          '큐레이션을 Publish하는 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
    }
  };

  const onClickNoPermission = () => {
    alert('Super Admin만 가능한 기능입니다.');
  };

  return published === undefined ? null : (
    <PublishButton
      published={published}
      onClick={props.havePermission ? onClick : onClickNoPermission}
    />
  );
};

export default CurationPublishSwitchButton;
