import { useState } from 'react';

const useInputState = (defaultValue = '') => {
  const [state, setState] = useState(defaultValue);
  const onChange = (e) => {
    setState(e.target.value);
  };
  return { state, setState, onChange };
};

export default useInputState;
