import React from 'react';
import { palette } from 'modules/defines/styles';

const Hashtag = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="m17.738 7.361.255-1.429a.429.429 0 0 0-.422-.503H14.9l.522-2.925A.429.429 0 0 0 15 2h-1.451a.429.429 0 0 0-.422.353l-.55 3.076H9.056l.522-2.925A.429.429 0 0 0 9.155 2h-1.45a.429.429 0 0 0-.423.353L6.733 5.43H3.908a.429.429 0 0 0-.422.353L3.231 7.21a.429.429 0 0 0 .422.504h2.672l-.816 4.572H2.684a.429.429 0 0 0-.422.353l-.255 1.429a.429.429 0 0 0 .422.503H5.1l-.522 2.925A.429.429 0 0 0 5 18h1.451a.429.429 0 0 0 .422-.353l.55-3.076h3.522l-.522 2.925a.429.429 0 0 0 .422.504h1.45a.429.429 0 0 0 .423-.353l.549-3.076h2.825a.429.429 0 0 0 .422-.353l.255-1.428a.429.429 0 0 0-.422-.504h-2.672l.816-4.572h2.825a.429.429 0 0 0 .422-.353zm-6.385 4.925H7.831l.816-4.572h3.522l-.816 4.572z"
          fill={palette.iconDefault}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default Hashtag;
