import { useRef, useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { FirstPersonControls as FirstPersonControlsImpl } from 'three/examples/jsm/controls/FirstPersonControls';

const FirstPersonControls = () => {
  const { camera, gl } = useThree();
  const controlsRef = useRef(null);

  useEffect(() => {
    const controls = new FirstPersonControlsImpl(camera, gl.domElement);
    controlsRef.current = controls;
    controls.movementSpeed = 1.0;
    controls.lookSpeed = 0.1;
    controls.autoForward = false;

    gl.domElement.style.touchAction = 'none'; // Disable touch action to avoid interfering with controls
    gl.domElement.tabIndex = '0'; // Ensure the canvas can be focused
    gl.domElement.focus(); // Focus the canvas to immediately enable keyboard controls

    return () => controls.dispose();
  }, [camera, gl]);

  useFrame((state, delta) => controlsRef.current?.update(delta));

  return null;
};

export default FirstPersonControls;
