import React from 'react';
import { palette } from 'modules/defines/styles';

const Curation = ({ color = palette.iconDefault, size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="m14.344 7.9 2.063-1.889a.286.286 0 0 0 0-.421L14.345 3.7H9.923v-.467A.231.231 0 0 0 9.693 3a.231.231 0 0 0-.229.233V3.7H5.426a.787.787 0 0 0-.779.793v2.614c0 .437.35.793.78.793h4.037v.467H5.656l-2.064 1.89a.286.286 0 0 0 0 .42l2.064 1.89h3.808v4.2c0 .129.103.233.23.233.126 0 .229-.104.229-.233v-4.2h4.726c.43 0 .78-.356.78-.793V9.16a.787.787 0 0 0-.78-.792H9.923V7.9h4.421z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default Curation;
