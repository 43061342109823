import { useState, useEffect } from 'react';
import { palette } from 'modules/defines/styles';
import styled, { css } from 'styled-components';
import { curationAPI } from 'modules/api';

const SetOrderModal = ({ closeModal, curatedData }) => {
  const [curationId, setCurationId] = useState();
  const [curatedItem, setCuratedItem] = useState([]);
  const [orderState, setOrderState] = useState([]);
  const [imageState, setImageState] = useState([]);

  useEffect(() => {
    setCurationId(curatedData[0].CurationSpace.c_id);
    setCuratedItem(curatedData);
    setOrderState([]);
    curatedData.map((item) => {
      var orderData = item.CurationSpace.order + '';
      if (orderData === 'null') {
        orderData = '';
      }
      setOrderState((prev) => [...prev, orderData]);

      var imageData = item.CurationSpace.image_number + '';
      if (imageData === 'null') {
        imageData = '';
      }
      setImageState((prev) => [...prev, imageData]);
    });
  }, [curatedData]);

  const orderStateOnChange = (e, idx) => {
    orderState.splice(idx, 1, e.target.value);
    setOrderState((orderState) => [...orderState]);
  };

  const imageStateOnChange = (e, idx) => {
    imageState.splice(idx, 1, e.target.value);
    setImageState((imageState) => [...imageState]);
  }

  const chooseImage = (e, idx, index, item) => {
    imageState.splice(idx, 1, index.toString());
    setImageState((imageState) => [...imageState]);
  }

  const SubmitButtonListener = async () => {
    var pass = true;
    var sumItem = 0;
    orderState.map((item, idx) => {
      if (item > orderState.length) {
        pass = false;
      }
      for (var i = 0; i < orderState.length; i++) {
        if (i !== idx && orderState[idx] === orderState[i]) {
          pass = false;
        }
      }
      sumItem += item * 1;
    });
    var sumBuff = 0;
    for (var i = 0; i < orderState.length; i++) {
      sumBuff += i + 1;
    }
    if (sumItem !== sumBuff) {
      pass = false;
    }
    if (pass) {
      try {
        const postData = {
          c_id: curationId,
          order: orderState,
          image_number: imageState,
        };
        await curationAPI.setSpaceCuratedOrder(postData);
        alert('순서가 수정되었습니다.');
        closeModal();
      } catch (err) {
        console.error(err);
      }
    } else {
      alert(
        'Order에 오류가 있습니다.\n ex)매장이 5개면 서로 다른 숫자인 1~5로 작성해주세요.'
      );
    }
  };

  return (
    <ModalWrapper>
      <TitleWrapper>
        <TitleContainer>
          <Title>큐레이션 내 매장 순서</Title>
        </TitleContainer>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </TitleWrapper>
      <ContentWrapper>
        <CuratedListContainer>
          <TableHeaderWrapper>
            <TableHeader width={"70px"} align={"center"} fontSize={"11px"}>ID</TableHeader>
            <TableHeader width={"180px"} align={"center"} fontSize={"12px"}>공간명</TableHeader>
            <TableHeader width={"70px"} align={"center"} fontSize={"12px"}>순서</TableHeader>
            <TableHeader width={"70px"} align={"center"} fontSize={"11px"}>사용이미지</TableHeader>
          </TableHeaderWrapper>
          {orderState &&
            orderState.map((val, idx) => {
              return (
                <CuratedListItem key={idx + 'ff'}>
                  <RowWrapper>
                    <TableValueText width={"70px"} align={"center"} fontSize={"12px"}>{curatedItem[idx]?.id}</TableValueText>
                    <TableValueText width={"180px"} align={"center"} fontSize={"13px"}>{curatedItem[idx]?.title}</TableValueText>
                    <TableValueInput
                      width={"70px"}
                      fontSize={"14px"}
                      value={val}
                      onChange={(e) => orderStateOnChange(e, idx)}
                    />
                    <TableValueInput
                      width={"70px"}
                      fontSize={"14px"}
                      value={imageState[idx]}
                      onChange={(e) => imageStateOnChange(e, idx)}
                    />
                  </RowWrapper>
                  <RowWrapper>
                    <ImageListItem key={idx + 'dd'}>
                      {
                        curatedItem[idx]?.image.map((item, i) => {
                          if (imageState[idx] === i.toString()) {
                            return (<a href={item.url} download><ImageContents selected={imageState[idx] === i.toString()} uploadedImg={item.url} /></a>);
                          } else {
                            return (<ImageContents selected={imageState[idx] === i.toString()} onClick={(e) => chooseImage(e, idx, i, item)} uploadedImg={item.url} />);
                          }
                        })
                      }
                    </ImageListItem>
                  </RowWrapper>
                </CuratedListItem>
              );
            })}
        </CuratedListContainer>
        <ButtonContainer>
          <SubmitButton onClick={SubmitButtonListener}>완료</SubmitButton>
        </ButtonContainer>
      </ContentWrapper>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  max-height: 100vh;
  max-width: 80wh;
  padding: 24px 10px;
  @media screen and (max-width: 1000px) {
    padding: 10px 5px 0px 5px;
  }
  @media screen and (max-width: 750px) {
    padding: 10px 0px 0px 0px;
  }
`;
// Title
const TitleWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  color: ${palette.fontBlack};
  font-size: 20px;
  font-weight: 700;
`;
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
`;
// Contents
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

`;

const RowWrapper = styled.div`
  display:flex;

`;

const CuratedListContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const TableHeaderWrapper = styled.div`
  background-color: ${palette.borderLightGrey};
  height: 30px;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  font-weight: 400;
  align-items: center;
`;
const TableHeader = styled.div`
  width: ${(props) => props.width ? props.width : '100px'};
  text-align: ${(props) => props.align ? props.align : 'center'};
  font-size: ${(props) => props.fontSize ? props.fontSize : '13px'};
  border-right: 1px solid ${palette.borderDefault};
  
  @media screen and (max-width: 750px) {
    :nth-child(1) {
      width: 50px;
    }
    :nth-child(2) {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      justify-content: start;
      padding-left: 4px;
      scroll-x: auto;
    }
  }
`;
const TableValueText = styled.div`
  width: ${(props) => props.width ? props.width : '100px'};
  text-align: ${(props) => props.align ? props.align : 'center'};
  font-size: ${(props) => props.fontSize ? props.fontSize : '13px'};
  border-right: 1px solid ${palette.borderLightGrey};
  height: 40px;
  display:flex;
  align-items:center;
  justify-content:center;
  @media screen and (max-width: 750px) {
    :nth-child(1) {
      width: 50px;
    }
    :nth-child(2) {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      justify-content: start;
      padding-left: 4px;
      scroll-x: auto;
    }
  }
`;
const TableValueInput = styled.input`
  all: unset;
  width: ${(props) => props.width ? props.width : '100px'};
  font-size: ${(props) => props.fontSize ? props.fontSize : '13px'};
  text-align: center;
  border-left: 1px solid ${palette.borderDefault};
  type: number;`;

const CuratedListItem = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${palette.borderLightGrey};
  align-items:center;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    align-items: start;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const SubmitButton = styled.div`
  float: right;
  height: 30px;
  background-color: ${palette.mainBlue};
  border-radius: 8px;
  color: ${palette.fontWhite};
  line-height: 30px;
  font-size: 15px;
  font-weight: 600;
  padding: 0 15px;
  cursor: pointer;
  &:hover{
    background-color: ${palette.mainBlueHover};
  }
`;
const ImageListItem = styled.div`
  font-size: 15px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  width: 40vw;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  @media screen and (max-width: 750px) {
    width: 90vw;
    margin-left: 16px;
  }
`;
const ImageContents = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => `${props.uploadedImg}`});
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  z-index: 0;
  cursor: pointer;

  ${(props) =>
    props.selected
      ? css`
        opacity: 1;
          `
      : css`
        opacity: 0.5;
        &:hover{
          opacity: 0.8;
        }
      `
  };
`;

export default SetOrderModal;
