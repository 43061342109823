import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { emailRegex, phoneRegex } from 'utils/regex';
import { pagePaths } from 'modules/defines/paths';
import { authAPI } from 'modules/api/user';
import { SelectRadioBtn } from 'components/button/RadioButton';
import { Base64Encode } from 'utils/base64';
import {
  FormWrapper,
  Wrapper,
  FormLayout,
  Title,
  TextForm,
  WarningText,
  SubmitButton,
} from 'components/auth/AuthCommon';

const SignUpContainer = () => {
  const history = useHistory();
  const [cookies] = useCookies();
  const [warningText, setWarningText] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputPW, setInputPW] = useState('');
  const [inputPW2, setInputPW2] = useState('');
  const [inputRole, setInputRole] = useState('editor');
  const [inputName, setInputName] = useState('');
  const [inputPhone, setInputPhone] = useState('');

  useEffect(() => {
    if (cookies.user) {
      history.push(pagePaths.space.list);
    }
  });

  useEffect(() => {
    console.log(inputRole);
  }, [inputRole]);

  useEffect(() => {
    if (inputPhone.length === 10) {
      setInputPhone((current) =>
        current.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      );
    }
    if (inputPhone.length === 13) {
      setInputPhone((current) =>
        current.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
      );
    }
  }, [inputPhone]);

  const onInputEmail = (e) => {
    setInputEmail(e.target.value);
  };
  const onInputPW = (e) => {
    setInputPW(e.target.value);
  };
  const onInputPW2 = (e) => {
    setInputPW2(e.target.value);
  };
  const onInputName = (e) => {
    setInputName(e.target.value);
  };
  const onInputPhone = (e) => {
    if (phoneRegex.test(e.target.value)) {
      setInputPhone(e.target.value);
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    if (!emailRegex.test(inputEmail)) {
      setWarningText('올바른 이메일을 입력해주세요.');
    } else if (inputPW === '') {
      setWarningText('비밀번호를 입력해주세요');
    } else if (inputPW !== inputPW2) {
      setWarningText('비밀번호가 일치하지 않습니다');
    } else if (inputName === '') {
      setWarningText('이름을 입력해주세요');
    } else if (inputPhone === '') {
      setWarningText('전화번호를 입력해주세요');
    } else {
      const data = {
        email: inputEmail,
        password: Base64Encode(inputPW),
        name: inputName,
        phone: inputPhone,
      };
      if (inputRole === 'curator') {
        data['role'] = 'curator';
      } else if (inputRole === 'admin') {
        data['role'] = 'admin';
      } else if (inputRole === 'editor') {
        data['role'] = 'editor';
      }
      try {
        await authAPI.register(data).then((response) => {
          if (response) {
            alert(
              '회원가입이 완료되었습니다.\n관리자 승인 후 이용 가능합니다.'
            );
            history.push(pagePaths.auth.signIn);
          }
        });
      } catch (e) {
        if (e.response.status === 400 || e.response.status === 401) {
          alert('계정이 이미 있습니다.\n다른 계정으로 가입해주세요.');
        }
      }
    }
  };

  return (
    <Wrapper>
      <FormLayout onSubmit={formSubmit}>
        <Title>회원가입</Title>
        <TextForm onChange={onInputEmail} placeholder="Email" />
        <TextForm type="password" onChange={onInputPW} placeholder="Password" />
        <TextForm
          type="password"
          onChange={onInputPW2}
          placeholder="Password Again"
        />
        <FormWrapper>
          <SelectRadioBtn
            select={inputRole === 'admin'}
            onClick={() => setInputRole('admin')}
            label="관리자 Admin"
          />
          <SelectRadioBtn
            select={inputRole === 'curator'}
            onClick={() => setInputRole('curator')}
            label="큐레이터 Curator"
          />

          <SelectRadioBtn
            select={inputRole === 'editor'}
            onClick={() => setInputRole('editor')}
            label="에디터 Editor"
          />
        </FormWrapper>
        <TextForm onChange={onInputName} placeholder="Name" />
        <TextForm
          onChange={onInputPhone || null}
          value={inputPhone}
          placeholder="Phone Number"
        />
        <WarningText>{warningText}</WarningText>
        <SubmitButton type="submit">회원가입</SubmitButton>
      </FormLayout>
    </Wrapper>
  );
};

export default SignUpContainer;
