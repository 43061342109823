import { useMemo, useContext } from 'react';
import { UserContext } from 'modules/api/user';
import { managerAPI } from 'modules/api';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import PublishButton from 'components/common/PublishButton';
import { useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import {
  Container,
  Title,
  SubTitle,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import DefaultTable from 'components/table/DefaultTable';

const ManagerListContainer = () => {
  const history = useHistory();
  const [userContext] = useContext(UserContext);
  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }
  const queryClient = useQueryClient();
  const fetchList = () => {
    const { call, cancel } = managerAPI.getManagerAll();
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['managers'],
    queryFn: () => fetchList(),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });

  const managerMutation = useMutation({
    mutationFn: (data) => managerAPI.editManager(data),
    onSuccess: (response) => {
      alert('스토어 매니저의 상태가 변경되었습니다.');
      queryClient.invalidateQueries(['managers']);
    },
    onError: (error) => {
      console.error(error);
      alert('스토어 매니저의 상태가 변경도중 에러가 발생하였습니다.');
    },
  });

  const tableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      Resize: false,
      width: 50,
    },
    {
      accessor: 'corpName',
      Header: '회사명',
      Filter: false,
      Resize: false,
      width: 120,
    },
    {
      accessor: 'status',
      Header: '상태',
      Cell: ({ row }) => {
        return (
          <PublishButton
            published={row.values.status}
            data={row.values}
            refetch={() => queryClient.invalidateQueries(['managers'])}
            editApi={(data) => managerAPI.editManagerStatus(data)}
            editField="status"
            editValue={row.values.status ? false : true}
          />
        );
      },
      Filter: false,
      width: 80,
    },
    {
      accessor: 'corpType',
      Header: '회사 타입',
      Filter: false,
      Resize: false,
      width: 50,
    },
    {
      accessor: 'corpAddr',
      Header: '회사 주소',
      Filter: false,
      Resize: true,
      width: 250,
    },
    {
      accessor: 'email',
      Header: 'Email',
      Filter: false,
      Sort: false,
      Resize: false,
      width: 200,
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      Cell: ({ row }) => `${row.values.phone}`,
      Filter: false,
      Sort: false,
      Resize: false,
      width: 200,
    },
    {
      accessor: 'managerName',
      Header: 'Name',
      Filter: false,
      Resize: false,
      width: 100,
    },
    {
      accessor: 'role',
      Header: 'Role',
      Filter: false,
      Sort: false,
      Resize: false,
      width: 100,
      align: 'center',
    },
    {
      accessor: 'createdAt',
      Cell: ({ row }) => row.values.createdAt.slice(0, 10),
      Header: '가입일자',
      Filter: false,
    },
  ]);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((item) => ({
      ...item,
    }));
  }, [data]);

  return (
    <Container>
      <Title>스토어 매니저 설정</Title>
      <SubTitle>FAV 스토어 매니저 관리 페이지입니다.</SubTitle>
      <LayoutWrapper>
        {tableData && tableColumns && (
          <DefaultTable columns={tableColumns} data={tableData} />
        )}
      </LayoutWrapper>
    </Container>
  );
};

export default ManagerListContainer;
