import { Suspense, useState } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import { Canvas, extend } from '@react-three/fiber';
import { Splat } from '@react-three/drei';
import { TAARenderPass } from 'three/examples/jsm/postprocessing/TAARenderPass';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass';
import { useControls } from 'leva';
import FirstPersonControls from './FirstPersonControls';

extend({ TAARenderPass, OutputPass });

const FaviewContainer = () => {
  const [ready, setReady] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setSelectedFile(fileURL);
      setReady(true);
    }
  };

  const { root } = useControls({
    root: { value: 'faview', options: ['faview'] },
  });

  return ready ? (
    <Suspense fallback={<div>Loading...</div>}>
      <Canvas
        dpr={1.5}
        gl={{ antialias: true }}
        camera={{ position: [0, 0, 0], fov: 50 }}
      >
        <FirstPersonControls />
        <Splat
          src={selectedFile}
          scale={1}
          position={[0, 0, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </Canvas>
    </Suspense>
  ) : (
    <Wrapper>
      <FileInputLabel htmlFor="file-upload">파일 선택</FileInputLabel>
      <HiddenFileInput id="file-upload" onChange={handleFileChange} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #0056b3;
  }
`;

export default FaviewContainer;
