import React from 'react';
import { palette } from 'modules/defines/styles';

const Feedback = ({ color }) => {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <title>icon/feedback/_20</title>
            <g id="icon/feedback/_20" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
                <g id="speech-bubble-8-svgrepo-com" transform="translate(1.000000, 3.000000)" fill="#A0A5BA" fillRule="nonzero">
                    <path d="M12,3.0925799 L12,1.96138102 C12,0.878115188 11.1338139,0 10.0645307,0 L1.93543454,0 C0.866186109,0 0,0.878115188 0,1.96138102 L0,8.10297492 C0,9.18624075 0.866186109,10.0643559 1.93543454,10.0643559 L2.64829,10.0643559 L2.06881692,13 L6.03303282,10.0643559 L6.77615822,10.0643559 L6.77615822,6.15473059 C6.77615822,4.46640243 8.13152888,3.0925799 9.79787021,3.0925799 L12,3.0925799 Z" id="Path"></path>
                    <path d="M16.0511541,4 L9.94888089,4 C8.87222323,4 8,4.89780413 8,6.00535874 L8,10.5106913 C8,11.6182459 8.87222323,12.51605 9.94888089,12.51605 L12.9725648,12.51605 L16.27578,15 L15.792969,12.51605 L16.0511541,12.51605 C17.1274968,12.51605 18,11.6182459 18,10.5106913 L18,6.00532274 C18,4.89776812 17.1274968,4 16.0511541,4 Z" id="Path"></path>
                </g>
            </g>
        </svg>
      );
};

export default Feedback;
