import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { emailRegex } from 'utils/regex';
import { pagePaths } from 'modules/defines/paths';
import { Base64Encode } from 'utils/base64';
import { authAPI, UserContext } from 'modules/api/user';
import {
  FormLayout,
  Title,
  TextForm,
  WarningText,
  SubmitButton,
  SubButtonWrapper,
  SubButtonElement,
  Wrapper,
} from 'components/auth/AuthCommon';

const SignInContainer = () => {
  const history = useHistory();
  const [inputEmail, setInputEmail] = useState('');
  const [inputPW, setInputPW] = useState('');
  const [warningText, setWarningText] = useState('');
  const [userContext] = useContext(UserContext);
  const location = useLocation();
  const { from } = location.state || {
    from: { pathname: pagePaths.space.list },
  };

  const onInputEmail = (e) => {
    setInputEmail(e.target.value);
  };
  const onInputPW = (e) => {
    setInputPW(e.target.value);
  };
  const signUpClickListener = () => {
    history.push(pagePaths.auth.signUp);
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    if (!emailRegex.test(inputEmail)) {
      setWarningText('올바른 이메일을 입력해주세요.');
    } else if (inputPW === '') {
      setWarningText('비밀번호를 입력해주세요');
    } else {
      authAPI.login(inputEmail, Base64Encode(inputPW)).catch((error) => {
        setWarningText(
          '로그인 정보가 틀렸거나 승인이 안 된 계정입니다.\n관리자에게 문의해주세요.'
        );
      });
    }
  };

  useEffect(() => {
    if (
      userContext.user.role.match('super-admin|admin|curator|editor|marketer')
    ) {
      history.push(from);
    }
  }, [userContext.user]);

  return (
    <Wrapper>
      <FormLayout onSubmit={formSubmit}>
        <Title>FAV ADMIN</Title>
        <TextForm
          id="textform-email"
          type="text"
          onChange={onInputEmail}
          placeholder="Email"
        />
        <TextForm
          id="textform-password"
          type="password"
          onChange={onInputPW}
          placeholder="Password"
        />
        <WarningText>{warningText}</WarningText>
        <SubmitButton type="submit">로그인</SubmitButton>

        <SubButtonWrapper>
          <SubButtonElement onClick={signUpClickListener}>
            회원가입
          </SubButtonElement>
          <SubButtonElement>비밀번호 찾기</SubButtonElement>
        </SubButtonWrapper>
      </FormLayout>
    </Wrapper>
  );
};

export default SignInContainer;
