import { palette } from 'modules/defines/styles';
import React from 'react';

const RadioButton = ({ onClick, select }) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <title>btn/radiobtn/enabled</title>
      <g
        id="Admin-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Sign-Up" transform="translate(-563.000000, -445.000000)">
          <g id="Group-2" transform="translate(459.000000, 186.000000)">
            <g id="select" transform="translate(81.000000, 247.000000)">
              <g id="Group" transform="translate(23.000000, 0.000000)">
                <g
                  id="btn/radiobtn/enabled"
                  transform="translate(0.000000, 12.000000)"
                >
                  {select ? (
                    <>
                      <circle
                        id="Oval"
                        fill={palette.mainBlue}
                        cx="8"
                        cy="8"
                        r="8"
                      ></circle>
                      <polyline
                        id="Line"
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="3 8.29935953 6.40629569 12 12 4"
                      ></polyline>
                    </>
                  ) : (
                    <circle
                      id="Oval"
                      cx="8"
                      cy="8"
                      r="7.5"
                      stroke="#A0A5BA"
                    ></circle>
                  )}
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RadioButton;
