import { useEffect, useState, useMemo } from 'react';
import useInputState from 'modules/customHooks/useInputState';
import { filterAPI } from 'modules/api';
import styled from 'styled-components';
import SearchTableWithAdd from 'components/table/SearchTableWithAdd';
import KeywordAddButton from 'components/button/KeywordAddButton';
import {
  Container,
  Title,
  SubTitle,
  LayoutWrapper,
} from 'components/common/DataTableStyle';

const FilterManagementContainer = () => {
  // 1) GET Data from API
  const [filterAllData, setFilterAllData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      filterAPI.getFilterAll().then((response) => {
        setFilterAllData(response);
      });
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [filterAllData]);

  // 2) Use InputState
  const {
    state: addFilterState,
    setState: setAddFilterState,
    onChange: addFilterStateOnChange,
  } = useInputState();

  // 3) Table Data & Column

  const filterAllTableData = useMemo(() => {
    if (filterAllData === null) {
      return [];
    }
    return filterAllData.map((item) => ({
      ...item,
      delete: item.id,
    }));
  }, [filterAllData]);

  const filterAllTableColumn = useMemo(() => [
    { accessor: 'title', Header: '필터', filter: 'includes' },
    { accessor: 'id', Header: 'ID', filter: 'includes' },
    { accessor: 'delete', Header: '삭제', filter: false },
  ]);

  const filterAddListener = async () => {
    try {
      await filterAPI.registerFilter({
        title: addFilterState,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setAddFilterState('');
    }
  };

  return (
    <Container>
        <Title>필터 관리</Title>
        <SubTitle>
          필터 관리 페이지입니다.
        </SubTitle>
        <LayoutWrapper>
          <KeywordWrapper>
            {loading ? (
              <>loading...</>
            ) : (
                <KeywordContainer>
                  <SearchTableWithAdd
                    data={filterAllTableData}
                    columns={filterAllTableColumn}
                    title={'필터 리스트'}
                    refetch={() => setLoading(true)}
                    tableHeight={700}
                    isSearchable={true}
                    first_keyword={'필터'}
                  />
                  <AddInputWrapper>
                    <AddTitle>필터 추가</AddTitle>
                    <AddDivider />
                    <AddForm>
                      <AddInput
                        value={addFilterState}
                        onChange={addFilterStateOnChange}
                      />
                      <KeywordAddButton
                        onClick={() => filterAddListener()}
                        refetch={() => setLoading(true)}
                      />
                    </AddForm>
                  </AddInputWrapper>
                </KeywordContainer>
            )}
          </KeywordWrapper>
        </LayoutWrapper>
      </Container>
  );
};

const KeywordWrapper = styled.div`
  display: flex;
`;

const KeywordContainer = styled.div`
  padding: 20px;
  margin-right: 20px;
  width: 20%;
  min-width: 450px;
  height: 750px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
`;

const AddInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  border: 1px solid #70707033;
  border-radius: 19px;
  opacity: 1;
`;

const AddTitle = styled.label`
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-left: 15px;
`;
const AddDivider = styled.div`
  width: 2px;
  height: 20px;
  border-left: 2px solid #707070;
  margin-left: 12px;
  margin-right: 12px;
  opacity: 0.5;
`;
const AddForm = styled.form`
  all: unset;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;
const AddInput = styled.input`
  all: unset;
  flex-grow: 1;
  font: normal normal bold 20px/26px Roboto;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.8;
`;

export default FilterManagementContainer;
