export function refreshPage() {
  window.location.reload();
}

export const getComplementaryColor = (hexColor) => {
  // hex 색상에서 '#'을 제거합니다.
  hexColor = hexColor.replace('#', '');

  // RGB 값을 계산합니다.
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // RGB 채널을 반전시킵니다.
  const rComplement = (255 - r).toString(16).padStart(2, '0');
  const gComplement = (255 - g).toString(16).padStart(2, '0');
  const bComplement = (255 - b).toString(16).padStart(2, '0');

  // 반전된 RGB 값을 hex 형식으로 변환하여 반환합니다.
  return `#${rComplement}${gComplement}${bComplement}`;
};

export function toRelativeCoordinates(lat1, lon1, lat2, lon2) {
  const meterPerDegreeLat = 111320; // 위도 1도당 거리 (111,320 m)
  const meterPerDegreeLon = 88920; // 대한민국 평균 경도 1도당 거리 (88,920 m)

  // 위도와 경도 차이
  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  // y는 위도 차이에 기반한 거리
  const y = (dLat * meterPerDegreeLat) / 5;

  // x는 경도 차이에 기반한 거리 (대한민국 내 경도 보정 거리 적용)
  const x = (dLon * meterPerDegreeLon) / 5;

  return { x, y };
}

export const radiansToDegrees = (radians) => (radians * 180) / Math.PI;

export function roundToOne(num) {
  return +(Math.round(num + 'e+1') + 'e-1');
}

export const calculateDistance = (point1, point2) => {
  return Math.sqrt(
    Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
  );
};
