import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const LinkAPI = (() => {
  class LinkAPIConstructor {
    getLink(query) {
      return httpAPI(apiPaths.getLink + query, { method: 'GET' }).call;
    }
    editLink(data) {
      return httpAPI(apiPaths.editLink, { data }).call;
    }
  }
  return LinkAPIConstructor;
})();

export const linkAPI = new LinkAPI();
