import React from 'react';
import { palette } from 'modules/defines/styles';

const Board = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={palette.iconDefault} fillRule="nonzero">
        <path d="M10.667 3.333h-.734A1.842 1.842 0 0 0 8.167 2c-.84 0-1.547.567-1.767 1.333h-.733c-.274 0-.5.227-.5.5v1c0 .64.526 1.167 1.166 1.167H10c.64 0 1.167-.527 1.167-1.167v-1c0-.273-.227-.5-.5-.5z" />
        <path d="M12 4h-.167v.833c0 1.014-.82 1.834-1.833 1.834H6.333A1.832 1.832 0 0 1 4.5 4.833V4h-.167C3.32 4 2.5 4.82 2.5 5.833v8.334C2.5 15.18 3.32 16 4.333 16h4.92l.147-.82c.067-.373.24-.707.507-.98l.533-.533H5a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h6.333c.034 0 .06 0 .094.013h.006l2.4-2.4V5.833C13.833 4.82 13.013 4 12 4zm-.667 7.5H5a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h6.333c.274 0 .5.227.5.5s-.226.5-.5.5zm0-2.167H5a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h6.333c.274 0 .5.227.5.5 0 .274-.226.5-.5.5zM10.851 18a.499.499 0 0 1-.492-.587l.354-2.004a.502.502 0 0 1 .138-.266l4.95-4.95c.608-.609 1.206-.444 1.532-.118l.825.825a1.167 1.167 0 0 1 0 1.65l-4.95 4.95a.493.493 0 0 1-.267.139l-2.003.353a.476.476 0 0 1-.087.008z" />
      </g>
    </svg>
  );
};

export default Board;
