import FaviewContainer from 'containers/faview/FaviewContainer';
import GlobalContainer from 'containers/GlobalContainer';

const Faview = () => {
  return (
    <GlobalContainer>
      <FaviewContainer />
    </GlobalContainer>
  );
};

export default Faview;
