// 유클리드 거리 계산 함수
function getDistance(pointA, pointB) {
    const dx = pointA.x - pointB.x;
    const dy = pointA.y - pointB.y;
    return Math.sqrt(dx * dx + dy * dy);
}

// 데이터에서 같은 그룹인 애들만 분리
export function groupByAdditionalInfo(data) {
    const grouped = {};

    data.forEach(item => {
        const key = item.additionalInfo.split('_')[0]; // _로 split된 [0] 값으로 그룹화
        if (!grouped[key]) {
            grouped[key] = [];
        }
        grouped[key].push(item);
    });

    return Object.values(grouped); // 그룹별 배열 반환
}

// DBSCAN(밀도 기반 클러스터링) 알고리즘
export function dbscan(points, epsilon, minPoints) {
    const clusters = [];
    const visited = new Set();
    const noise = [];

    function regionQuery(point) {
        return points.filter(p => getDistance(point, p) < epsilon);
    }

    function expandCluster(point, neighbors, cluster) {
        cluster.push(point);

        for (let i = 0; i < neighbors.length; i++) {
            const neighbor = neighbors[i];

            if (!visited.has(neighbor)) {
                visited.add(neighbor);

                const neighborNeighbors = regionQuery(neighbor);

                if (neighborNeighbors.length >= minPoints) {
                    neighbors.push(...neighborNeighbors);
                }
            }

            if (!clusters.some(c => c.includes(neighbor))) {
                cluster.push(neighbor);
            }
        }
    }

    points.forEach(point => {
        if (visited.has(point)) return;

        visited.add(point);

        const neighbors = regionQuery(point);

        if (neighbors.length >= minPoints) {
            const cluster = [];
            expandCluster(point, neighbors, cluster);
            clusters.push(cluster);
        } else {
            noise.push(point);
        }
    });

    return clusters;
}

// 군집화된 클러스터의 평균 계산 함수
function calculateMean(cluster) {
    const n = cluster.length;
    const mean = { x: 0, y: 0 };

    cluster.forEach(point => {
        mean.x += point.x;
        mean.y += point.y;
    });

    mean.x /= n;
    mean.y /= n;

    return mean;
}

// 군집 내 멀리 떨어진 점들을 제외하고 평균을 계산하는 함수
export function filterOutliersAndCalculateMean(cluster, threshold) {
    const mean = calculateMean(cluster);
    const filteredCluster = cluster.filter(point => getDistance(point, mean) <= threshold);

    if (filteredCluster.length < 2) return null; // 2개 미만이면 군집으로 취급하지 않음

    return calculateMean(filteredCluster);
}

// 가장 큰 클러스터를 선택하는 함수
export function selectLargestCluster(clusters) {
    if (clusters.length === 0) return null;

    return clusters.reduce((largest, current) => {
        return current.length > largest.length ? current : largest;
    }, clusters[0]);
}
