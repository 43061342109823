import FeedbackListContainer from 'containers/feedback/FeedbackListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const FeedbackList = () => {
  return (
    <GlobalContainer>
      <FeedbackListContainer />
    </GlobalContainer>
  );
};

export default FeedbackList;
