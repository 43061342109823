import React from 'react';
import { palette } from 'modules/defines/styles';

const Person = ({ color }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>icon/admin/_20</title>
      <g
        id="icon/admin/_20"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
        <g
          id="businessman-job-svgrepo-com"
          transform="translate(2.000000, 2.000000)"
          fill="#A0A5BA"
          fillRule="nonzero"
        >
          <path
            d="M13.3857188,9.92803125 C12.3412813,8.88359375 11.0539688,8.17065625 9.65128125,7.84071875 L8.73759375,8.75440625 L9.138,13.6024687 C9.1515625,13.7669375 9.10678125,13.9309062 9.0114375,14.065625 L8.09784375,15.3565625 C8.038875,15.439875 7.9431875,15.489375 7.84115625,15.489375 C7.739125,15.489375 7.6434375,15.439875 7.58446875,15.3565625 L6.670875,14.065625 C6.5755625,13.9309063 6.53075,13.7669062 6.5443125,13.6024687 L6.94471875,8.75440625 L6.03103125,7.84071875 C4.62834375,8.17065625 3.341,8.88359375 2.29659375,9.92803125 C0.815625,11.4090312 0,13.378125 0,15.4725625 C0,15.7638438 0.23615625,16 0.5274375,16 L15.1549375,16 C15.4462188,16 15.682375,15.7638437 15.682375,15.4725625 C15.682375,13.378125 14.8667188,11.4090312 13.3857188,9.92803125 Z"
            id="Path"
          ></path>
          <circle id="Oval" cx="7.84128125" cy="3.485625" r="3.485625"></circle>
        </g>
      </g>
    </svg>
  );
};

export default Person;
