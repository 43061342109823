import React from 'react';
import { palette } from 'modules/defines/styles';

const Person = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M14.6 11.6a1.7 1.7 0 0 1 1.698 1.617l.002.082v.301c0 1.502-.777 2.627-1.968 3.352C13.16 17.665 11.586 18 9.9 18c-1.686 0-3.26-.335-4.432-1.048-1.155-.703-1.92-1.782-1.966-3.216L3.5 13.6v-.3a1.7 1.7 0 0 1 1.618-1.698L5.2 11.6h9.4zM9.9 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8z"
          fill={palette.iconDefault}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default Person;
