import { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditorComponent = ({ convertedText, setConvertedText, width }) => {
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [
          {
            header: [1, 2, 3, false],
          },
          { color: [] },
          { background: [] },
        ],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
          { align: [] },
        ],
        ['link'],
        ['clean'],
      ],
    },
  }));
  const formats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];
  return (
    <ReactQuill
      value={convertedText}
      onChange={setConvertedText}
      style={{ width: width ?? '100%' }}
      formats={formats}
      modules={modules}
      theme="snow"
      placeholder="내용을 입력해주세요"
    />
  );
};

export default EditorComponent;
