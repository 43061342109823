import SpaceListContainer from 'containers/space/SpaceListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const ReataurantList = () => {
  return (
    <GlobalContainer>
      <SpaceListContainer />
    </GlobalContainer>
  );
};

export default ReataurantList;
