import React from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';


const CommentWrapper = ({ commentArray }) => {
    return (
        <TempComment>
        {commentArray.map((value, index) => (
            <li key={index}>
                <Comment>
                    <b>username </b> {value}
                </Comment>
            </li>
        ))}
        </TempComment>
    );
};

export default CommentWrapper;


const TempComment = styled.ul`
  width: 100%;
  font-size: 16px;
  border: 1.5px solid ${palette.borderLightGrey};
  padding: 20px;
  line-height: 1.2;
`

const Comment = styled.div`
  border-bottom: 1px solid ${palette.borderLightGrey};
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.2;
`