import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const CategoryAPI = (() => {
  class CategoryAPIConstructor {
    getCategoryAll() {
      return httpAPI(apiPaths.getCategoryAll, { method: 'GET' }).call;
    }
  }
  return CategoryAPIConstructor;
})();

export const categoryAPI = new CategoryAPI();
