import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const VersionAPI = (() => {
  class VersionAPIConstructor {
    getVersionAll() {
      return httpAPI(apiPaths.versionBase, { method: 'GET' });
    }
    getVersionSingle(id) {
      return httpAPI(apiPaths.versionBase + id, { method: 'GET' }).call;
    }
    registerVersion(data) {
      return httpAPI(apiPaths.registerVersion, { data }).call;
    }
    editVersion(data) {
      return httpAPI(apiPaths.editVersion, { data }).call;
    }
    deleteVersion(data) {
      return httpAPI(apiPaths.deleteVersion, { data }).call;
    }
  }
  return VersionAPIConstructor;
})();

export const versionAPI = new VersionAPI();
