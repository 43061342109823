import { useParams, useHistory } from 'react-router';
import { feedbackAPI } from 'modules/api';
import { pagePaths } from 'modules/defines/paths';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from 'modules/api/user';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import {
  ButtonCancel,
  ButtonDelete,
  ButtonSend,
} from 'components/common/DefaultButton';
import { Container, LayoutWrapper } from 'components/common/DataTableStyle';
import FeedbackInfoWrapper from 'components/feedback/FeedbackInfoWrapper';
import CommentWrapper from 'components/feedback/CommentWrapper';

const FeedbackDetailContainer = () => {
  const { id } = useParams();
  const history = useHistory();
  const [userContext] = useContext(UserContext);
  const [idFeedback, setIdFeedback] = useState('');
  const [name, setName] = useState();
  const [spaceId, setSpaceId] = useState();
  const [type, setType] = useState();
  const [contact, setContact] = useState();
  const [editAt, setEditAt] = useState();
  const [text, setText] = useState();

  const goBack = () => {
    history.goBack();
  };

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }

  useEffect(() => {
    console.log(id);
    feedbackAPI.getFeedbackSingle(id).then((response) => {
      setIdFeedback(response.id);
      setName(response.user_nickname);
      setSpaceId(response.r_id);
      setType(response.type);
      setContact(response.user_contact);
      setEditAt(response.editAt);
      setText(response.text);
    });
  }, []);

  const deleteFeedback = async () => {
    const postData = {
      id: id,
    };
    try {
      feedbackAPI.deleteFeedback(postData).then(() => {
        alert('피드백이 삭제되었습니다.');
      });
    } catch (e) {
      console.erroe(e);
      alert(
        '피드백을 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    history.push(pagePaths.feedback.list);
  };

  //Comment
  const [comment, setComment] = useState('');
  const onChange = (e) => setComment(e.target.value);
  const [commentArray, setCommentArray] = useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (comment === '') {
      return;
    }
    setCommentArray((commentValueList) => [comment, ...commentValueList]);
    setComment('');
  };

  function Comment({ comment }) {
    return (
      <div>
        <b>username: </b> ({comment.email})
      </div>
    );
  }

  const commentList = ({ comments }) => {
    return (
      <div>
        <ul>
          {comments.map((comment, index) => (
            <li key={index}>
              <div>
                <b>username : </b> {comment}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Container>
      <FeedbackButtonWrapper>
        <ButtonCancel text="이전" onClick={goBack} />
        <ButtonDelete text="삭제" onClick={deleteFeedback} />
      </FeedbackButtonWrapper>
      <LayoutWrapper>
        <FeedbackInfo>
          <FeedbackInfoWrapper label="ID" content={idFeedback} />
          <FeedbackInfoWrapper label="사용자" content={name} />
          <FeedbackInfoWrapper label="매장 ID" content={spaceId} />
        </FeedbackInfo>
        <FeedbackInfo>
          <FeedbackInfoWrapper label="피드백 타입" content={type} />
          <FeedbackInfoWrapper label="연락처" content={contact} />
        </FeedbackInfo>
        <FeedbackInfoWrapper label="작성일자" content={editAt} />

        <FeedbackBody>{text}</FeedbackBody>

        {commentArray.length ? (
          <CommentWrapper commentArray={commentArray} />
        ) : null}

        <AddCommentWrapper type="text" onChange={onChange} />
        <ResultButtonWrapper>
          <ButtonSend text="작성" onClick={onSubmit} />
        </ResultButtonWrapper>
      </LayoutWrapper>
    </Container>
  );
};

const FeedbackInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
`;

const AddCommentWrapper = styled.input`
  width: 100%;
  display: flex;
  font-size: 16px;
  margin-top: 10px;
  border: 1.5px solid ${palette.borderLightGrey};
  padding: 20px;
  line-height: 1.2;
`;

const ResultButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const FeedbackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FeedbackBody = styled.div`
  min-height: 250px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1.5px solid ${palette.borderLightGrey};
  padding: 20px;
  line-height: 1.2;
  strong {
    font-weight: 700;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  blockquote {
  }
  .ql-align-center {
    text-align: center;
  }
  ol {
    list-style-type: lower-aplha;
  }
  ul {
    list-style: circle;
  }
  li {
    margin-left: 40px;
  }
  a {
    text-decoration: underline;
    color: blue;
  }
`;

export default FeedbackDetailContainer;
