import { useHistory } from 'react-router-dom';
import { curationAPI } from 'modules/api';
import { UserContext } from 'modules/api/user';
import { useMemo, useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Filter } from 'components/common/Filter';
import {
  TitleWrapper,
  TitleRowWrapper,
  HeaderButtonRow,
  HeaderButtonWrapper,
  RowLink,
} from 'components/curation/CurationCommon';
import CurationPublishSwitchButton from 'components/curation/CurationPublishSwitchButton';
import CurationPaidSwitchButton from 'components/curation/CurationPaidSwitchButton';
import CurationPublishInstaSwitchButton from 'components/curation/CurationPublishInstaSwitchButton';
import { ButtonCreate } from 'components/common/DefaultButton';
import DefaultTable from 'components/table/DefaultTable';
import {
  Container,
  Title,
  SubTitle,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import OrderDropdown from 'components/curation/CurationOrderDropdownButton';
import { pagePaths } from 'modules/defines/paths';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';

const CurationListContainer = () => {
  const history = useHistory();
  const [userContext] = useContext(UserContext);
  const queryClient = useQueryClient();

  const fetchList = () => {
    const { call, cancel } = curationAPI.getCurationAll();
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['curations'],
    queryFn: () => fetchList(),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 1 * 1000 * 60,
  });

  const curationMutation = useMutation({
    mutationFn: (data) => curationAPI.registerCuration(data),
    onSuccess: (response) => {
      alert('큐레이션이 생성되었습니다.');
      history.push(
        pagePaths.curation.detail.replace(':id', response.curation.id)
      );
      queryClient.invalidateQueries(['curations']);
    },
    onError: (error) => {
      console.error(error);
      alert('큐레이션 생성 중 오류가 발생했습니다. 관리자에게 문의해주세요.');
    },
  });

  const changeUploadDate = async (id, date) => {
    let postData = {
      id: id,
      uploadedAt: date,
    };
    await curationAPI.editCurationUploadedAt(postData).then((response) => {
      alert('출시 일자가 수정되었습니다.');
      queryClient.invalidateQueries(['curations']);
    });
  };

  const onClickRow = (e, curationId) => {
    e.preventDefault();
    history.push(pagePaths.curation.detail.replace(':id', curationId));
  };

  const havePermission = userContext.user.role === 'super-admin';

  // << Table Column >>
  const tableColumns = useMemo(
    () =>
      userContext.user.role.match('admin')
        ? [
            {
              accessor: 'id',
              Header: 'ID',
              Cell: ({ row }) => (
                <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
                  {row.values.id}
                </RowLink>
              ),
              Filter: false,
              width: 70,
              align: 'center',
            },
            {
              accessor: 'title',
              Header: '큐레이션명',
              Cell: ({ row }) => (
                <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
                  {row.values.title}
                </RowLink>
              ),
              Filter: false,
              Resize: true,
              maxWidth: 450,
              minWidth: 150,
              width: 300,
              align: 'left',
            },
            {
              accessor: 'description',
              Header: '큐레이션 설명',
              Cell: ({ row }) => (
                <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
                  {row.values.description}
                </RowLink>
              ),
              Filter: false,
              Resize: true,
              maxWidth: 500,
              minWidth: 250,
              width: 400,
              align: 'left',
            },
            {
              accessor: 'uploadedAt',
              Header: '출시 일자',

              Cell: ({ row }) =>
                row.values.uploadedAt !== null ? (
                  <DatePicker
                    className="datePicker"
                    dateFormat="yyyy.MM.dd"
                    locale={ko}
                    shouldCloseOnSelect
                    showPopperArrow={false}
                    selected={moment(
                      row.values.uploadedAt,
                      'YYYY-MM-DD'
                    ).toDate()}
                    onChange={(date) => changeUploadDate(row.values.id, date)}
                    placeholderText="최초 pubish 필요"
                    disabled={!havePermission}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                ) : null,
              Filter: false,
              width: 120,
              align: 'center',
            },
            {
              accessor: 'writer',
              Header: '작성자',
              Filter: Filter,
              Sort: false,
              width: 90,
              align: 'center',
            },
            {
              accessor: 'publish',
              Header: 'PUBLISH FAV앱',
              Cell: ({ row }) => {
                return (
                  <CurationPublishSwitchButton
                    published={row.values.publish}
                    id={row.values.id}
                    belongSpace={row.original.belongSpace}
                    havePermission={havePermission}
                  />
                );
              },
              Filter: Filter,
              Sort: false,
              width: 90,
              align: 'center',
            },
            {
              accessor: 'publish_instagram',
              Header: 'PUBLISH Insta',
              Cell: ({ row }) => {
                return (
                  <CurationPublishInstaSwitchButton
                    published={row.values.publish_instagram}
                    id={row.values.id}
                    havePermission={havePermission}
                  />
                );
              },
              Filter: Filter,
              Sort: false,
              width: 90,
              align: 'center',
            },
            {
              accessor: 'paid',
              Header: '집행완료',
              Cell: ({ row }) =>
                row.original.owner !== 1 ? (
                  <CurationPaidSwitchButton
                    paid={row.values.paid}
                    id={row.values.id}
                  />
                ) : null,
              Filter: Filter,
              Sort: false,
              width: 90,
              align: 'center',
            },
            {
              accessor: 'order',
              Header: '순서',
              Cell: ({ row }) => {
                return (
                  <OrderDropdown
                    data={row.values}
                    editApi={(data) => curationAPI.editCuration(data)}
                    refetch={() => queryClient.invalidateQueries(['curations'])}
                    initialValue={row.values.order ?? 0}
                    option={Array.from(Array(16).keys())}
                  />
                );
              },
              Filter: false,
              Sort: false,
              Resize: false,
              width: 150,
              align: 'center',
            },
          ]
        : [
            {
              accessor: 'id',
              Header: 'ID',
              Cell: ({ row }) => (
                <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
                  {row.values.id}
                </RowLink>
              ),
              Filter: false,
              width: 70,
              align: 'center',
            },
            {
              accessor: 'title',
              Header: '큐레이션명',
              Cell: ({ row }) => (
                <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
                  {row.values.title}
                </RowLink>
              ),
              Filter: false,
              Resize: true,
              maxWidth: 450,
              minWidth: 150,
              width: 300,
              align: 'left',
            },
            {
              accessor: 'description',
              Header: '큐레이션 설명',
              Cell: ({ row }) => (
                <RowLink onClick={(e) => onClickRow(e, row.values.id)}>
                  {row.values.description}
                </RowLink>
              ),
              Filter: false,
              Resize: true,
              maxWidth: 500,
              minWidth: 250,
              width: 400,
              align: 'left',
            },
            {
              accessor: 'uploadedAt',
              Header: '출시 일자',
              Filter: false,
              width: 120,
              align: 'center',
            },
            {
              accessor: 'writer',
              Header: '작성자',
              Filter: Filter,
              Sort: false,
              width: 90,
              align: 'center',
            },
          ],
    [userContext.user.role]
  );

  const createSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      standard: 'true',
      category_id: 1,
      title: '-',
      description: '-',
      description2: '-',
      owner: userContext.user.user_id,
    };
    if (Object.values(postData).slice(2, 4).includes('')) {
      alert('제목과 설명을 입력하세요.');
      return;
    }
    curationMutation.mutate(postData);
  };

  // << Table Data >>
  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((item) => ({
      ...item,
    }));
  }, [data]);

  return (
    <Container>
      <TitleWrapper>
        <TitleRowWrapper>
          <Title>큐레이션 설정</Title>
        </TitleRowWrapper>
        <HeaderButtonRow>
          <HeaderButtonWrapper>
            <ButtonCreate
              text={'큐레이션 추가'}
              active={false}
              onClick={createSubmit}
            />
          </HeaderButtonWrapper>
        </HeaderButtonRow>
      </TitleWrapper>
      <LayoutWrapper>
        {isLoading
          ? null
          : tableData &&
            tableColumns && (
              <DefaultTable
                columns={tableColumns}
                data={tableData}
                from={'curation'}
              />
            )}
      </LayoutWrapper>
    </Container>
  );
};

export default CurationListContainer;
