import { dashboardAPI } from 'modules/api';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { palette } from 'modules/defines/styles';
import ErrorDashboard from 'components/dashboard/ErrorDashboard';
import {
  Container,
  LayoutWrapper,
  CommonContainer,
  CommonHeader,
  CommonTitle,
  DateCollectorWrapper,
  CommonContents,
  CommonContentsRow,
  FlexDataContainer,
  ChartContainer,
  DescContainer,
  DescContents,
  GridDataContainer,
  DataItemContainer,
  DataItemTitle,
  DataItemValue,
  LayoutNoneWrapper,
  NoneTitle,
  NoneDesc,
  DashboardDatePicker,
  DateCollectorBtn,
  DateAllCollectorBtn,
} from 'components/dashboard/DashboardCommon';
import { refreshPage } from 'utils/globalFunction';
import { formatDate, getMonthBefore } from 'utils/date';
import { pagePaths } from 'modules/defines/paths';

const DashboardDataContainer = () => {
  const [startDate, setStartDate] = useState(getMonthBefore(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const history = useHistory();
  const [dashboardData, setDashboardData] = useState(null);
  const [loadingState, setLoadingState] = useState({
    dashboardData: true,
    spaceTitleDuplicate: true,
    spaceMenuNoLineBreak: true,
    spaceNoKeyword: true,
    spaceNoMenu: true,
    spaceNoTime: true,
  });
  const [errorData, setErrorData] = useState({
    spaceTitleDuplicate: null,
    spaceMenuNoLineBreak: null,
    spaceNoKeyword: null,
    spaceNoMenu: null,
    spaceNoTime: null,
  });

  useEffect(() => {
    if (!loadingState.dashboardData) return;
    const queryString = `?time_start=${formatDate(
      startDate
    )}&time_end=${formatDate(endDate)}`;
    const { call, cancel } = dashboardAPI.getDashboardData(queryString);

    let isMounted = true;
    call
      .then((response) => {
        if (isMounted) {
          setDashboardData(response);
          setLoadingState((prev) => ({ ...prev, dashboardData: false }));
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false; // 컴포넌트 언마운트 시 마운트 상태 업데이트
      cancel(); // 요청 취소
    };
  }, [loadingState.dashboardData, startDate, endDate]);

  useEffect(() => {
    if (!loadingState.spaceTitleDuplicate) return;
    const { call, cancel } = dashboardAPI.getErrorSpaceTitleDuplicate();
    let isMounted = true;
    call
      .then((response) => {
        if (isMounted) {
          setErrorData((prevState) => ({
            ...prevState,
            spaceTitleDuplicate: response,
          }));
          setLoadingState((prev) => ({ ...prev, spaceTitleDuplicate: false }));
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
      cancel();
    };
  }, [loadingState.spaceTitleDuplicate]);

  useEffect(() => {
    if (!loadingState.spaceMenuNoLineBreak) return;
    const { call, cancel } = dashboardAPI.getErrorSpaceMenuLineBreak();
    let isMounted = true;
    call
      .then((response) => {
        if (isMounted) {
          setErrorData((prevState) => ({
            ...prevState,
            spaceMenuNoLineBreak: response,
          }));
          setLoadingState((prev) => ({ ...prev, spaceMenuNoLineBreak: false }));
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
      cancel();
    };
  }, [loadingState.spaceMenuNoLineBreak]);

  useEffect(() => {
    if (!loadingState.spaceNoKeyword) return;
    const { call, cancel } = dashboardAPI.getErrorSpaceNoKeyword();
    let isMounted = true;
    call
      .then((response) => {
        if (isMounted) {
          setErrorData((prevState) => ({
            ...prevState,
            spaceNoKeyword: response,
          }));
          setLoadingState((prev) => ({ ...prev, spaceNoKeyword: false }));
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
      cancel();
    };
  }, [loadingState.spaceNoKeyword]);

  useEffect(() => {
    if (!loadingState.spaceNoMenu) return;
    const { call, cancel } = dashboardAPI.getErrorSpaceNoMenu();
    let isMounted = true;
    call
      .then((response) => {
        if (isMounted) {
          setErrorData((prevState) => ({
            ...prevState,
            spaceNoMenu: response,
          }));
          setLoadingState((prev) => ({ ...prev, spaceNoMenu: false }));
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
      cancel();
    };
  }, [loadingState.spaceNoMenu]);

  useEffect(() => {
    if (!loadingState.spaceNoTime) return;
    const { call, cancel } = dashboardAPI.getErrorSpaceNoTime();
    let isMounted = true;
    call
      .then((response) => {
        if (isMounted) {
          setErrorData((prevState) => ({
            ...prevState,
            spaceNoTime: response,
          }));
          setLoadingState((prev) => ({ ...prev, spaceNoTime: false }));
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
      cancel();
    };
  }, [loadingState.spaceNoTime]);

  const [mode, setMode] = useState(1);

  const [closedData, setClosedData] = useState([]);
  // 0: waiting , 1: processing , 2: complete
  const [closedDataLoadingMode, setClosedDataLoadingMode] = useState(0);
  const [closedDataBtnText, setClosedDataBtnText] = useState('동기화');

  const setModeListener = (e, modeClicked) => {
    setMode(modeClicked);
  };

  const chartData = {
    labels: ['Type0', 'Type1', 'Type2', 'Type3', 'Type4', 'Type5'],
    datasets: [
      {
        label: 'Type',
        data: [
          dashboardData?.spaceType0,
          dashboardData?.spaceType1,
          dashboardData?.spaceType2,
          dashboardData?.spaceType3,
          dashboardData?.spaceType4,
          dashboardData?.spaceType5,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
      },
    ],
  };

  const changeDateListener = async (e) => {
    setLoadingState((prevState) => ({ ...prevState, dashboardData: true }));
  };

  const changeDateAllListener = async (e) => {
    // 모든 데이터를 보기 위한 버튼
    const startDateStr = new Date('2021-01-01');
    const endDateStr = new Date();
    setStartDate(startDateStr);
    setEndDate(endDateStr);
    setLoadingState((prevState) => ({ ...prevState, dashboardData: true }));
  };

  const closedDataSyncBtnListener = async () => {
    if (closedDataLoadingMode === 0) {
      setClosedData([]);
      setClosedDataLoadingMode(1);
      setClosedDataBtnText('중지');
      const { num: spaceNum } = await dashboardAPI.getSpaceNum();
      const iter = parseInt(spaceNum / 10);
      const remain = spaceNum % 10;
      var idx_start = 0;
      var idx_end = 10;
      for (let i = 0; i < iter; i++) {
        const queryString = '?idx_start=' + idx_start + '&idx_end=' + idx_end;
        const spaceData = await dashboardAPI.getClosedData(queryString);
        await Promise.all(
          spaceData?.map((item) =>
            !item.canSearch ? setClosedData((prev) => [...prev, item]) : null
          )
        );
        await sleep(1000);
        idx_start += 10;
        idx_end += 10;
      }
      idx_start = spaceNum - remain;
      idx_end = spaceNum;
      const queryStringRemain =
        '?idx_start=' + idx_start + '&idx_end=' + idx_end;
      const spaceData = await dashboardAPI.getClosedData(queryStringRemain);
      await Promise.all(
        spaceData?.map((item) =>
          !item.canSearch ? setClosedData((prev) => [...prev, item]) : null
        )
      );
      setClosedDataLoadingMode(2);
      setClosedDataBtnText('완료');
    } else if (closedDataLoadingMode === 1) {
      setClosedDataLoadingMode(0);
      setClosedDataBtnText('동기화');
      refreshPage();
    } else if (closedDataLoadingMode === 2) {
      alert('동기화가 이미 완료되었습니다.');
    }
  };

  const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const elementClickListener = (e, type, id, newWindow = false) => {
    e.preventDefault();
    if (newWindow) {
      if (type === 'curation')
        window.open(
          pagePaths.base + pagePaths.curation.detail.replace(':id', id),
          '_blank'
        );
      else if (type === 'space')
        window.open(
          pagePaths.base + pagePaths.space.detail.replace(':id', id),
          '_blank'
        );
    } else {
      if (type === 'curation')
        history.push(pagePaths.curation.detail.replace(':id', id));
      else if (type === 'space')
        history.push(pagePaths.space.detail.replace(':id', id));
    }
  };

  return (
    <Container>
      {!loadingState.dashboardData ? (
        <LayoutWrapper>
          {/* 좌측 */}
          <CommonContainer>
            {/* 공간 컨텐츠 */}
            <CommonHeader>
              <CommonTitle>공간 컨텐츠</CommonTitle>
              <DateCollectorWrapper>
                <DashboardDatePicker
                  selected={startDate}
                  onChange={(startDate) => setStartDate(startDate)}
                  dateFormat="yyyy년 MM월 dd일"
                />
                <DashboardDatePicker
                  selected={endDate}
                  onChange={(endDate) => setEndDate(endDate)}
                  dateFormat="yyyy년 MM월 dd일"
                />
                <DateCollectorBtn onClick={changeDateListener}>
                  적용
                </DateCollectorBtn>
                <DateAllCollectorBtn onClick={changeDateAllListener}>
                  All
                </DateAllCollectorBtn>
              </DateCollectorWrapper>
            </CommonHeader>
            <CommonContents>
              <FlexDataContainer>
                <DataItemContainer
                  bgColor={palette.white}
                  clicked={mode === 1}
                  onClick={(e) => setModeListener(e, 1)}
                >
                  <DataItemTitle>
                    업로드된
                    <br />총 공간 수
                  </DataItemTitle>
                  <DataItemValue>
                    {dashboardData?.spacesUploadedNum}
                  </DataItemValue>
                </DataItemContainer>
                <DataItemContainer
                  bgColor={palette.white}
                  clicked={mode === 2}
                  onClick={(e) => setModeListener(e, 2)}
                >
                  <DataItemTitle>
                    크롤링 업로드된
                    <br />
                    공간 수
                  </DataItemTitle>
                  <DataItemValue>
                    {dashboardData?.spacesCrawledNum}
                  </DataItemValue>
                </DataItemContainer>
              </FlexDataContainer>
              {mode === 1 && (
                <GridDataContainer>
                  <DataItemContainer>
                    <DataItemTitle>
                      앱 Publish
                      <br />
                      공간 수
                    </DataItemTitle>
                    <DataItemValue>
                      {dashboardData?.spacesUploadedPublishedNum}
                    </DataItemValue>
                  </DataItemContainer>
                  <DataItemContainer>
                    <DataItemTitle>
                      인스타 Publish
                      <br />
                      공간 수
                    </DataItemTitle>
                    <DataItemValue>
                      {dashboardData?.spacesUploadedInstaPublishedNum}
                    </DataItemValue>
                  </DataItemContainer>
                  <DataItemContainer>
                    <DataItemTitle>
                      FAVIEW Publish
                      <br />
                      공간 수
                    </DataItemTitle>
                    <DataItemValue>
                      {dashboardData?.spacesUploadedFaviewPublishedNum}
                    </DataItemValue>
                  </DataItemContainer>
                  <DataItemContainer>
                    <DataItemTitle>
                      앱 Publish & <br />
                      FAVIEW 공간 수
                    </DataItemTitle>
                    <DataItemValue>
                      {dashboardData?.spacesUploadedPublishedFaviewNum}
                    </DataItemValue>
                  </DataItemContainer>
                </GridDataContainer>
              )}
              {mode === 2 && (
                <GridDataContainer>
                  <DataItemContainer bgColor={palette.white}>
                    <DataItemTitle>
                      앱 Publish
                      <br />
                      공간 수
                    </DataItemTitle>
                    <DataItemValue>
                      {dashboardData?.spacesCrawledNum}
                    </DataItemValue>
                  </DataItemContainer>
                  <DataItemContainer bgColor={palette.white}>
                    <DataItemTitle>
                      FAV_1
                      <br />
                      (형주님)
                    </DataItemTitle>
                    <DataItemValue>
                      {dashboardData?.spacesCrawledFAV_1}
                    </DataItemValue>
                  </DataItemContainer>
                  <DataItemContainer bgColor={palette.white}>
                    <DataItemTitle>
                      FAV_2
                      <br />
                      (삼순님)
                    </DataItemTitle>
                    <DataItemValue>
                      {dashboardData?.spacesCrawledFAV_2}
                    </DataItemValue>
                  </DataItemContainer>
                </GridDataContainer>
              )}
            </CommonContents>
            {/* 큐레이션 컨텐츠 */}
            <CommonHeader>
              <CommonTitle>큐레이션 컨텐츠</CommonTitle>
            </CommonHeader>
            <CommonContents>
              <FlexDataContainer>
                <DataItemContainer clicked={false}>
                  <DataItemTitle>
                    업로드된
                    <br />총 큐레이션 수
                  </DataItemTitle>
                  <DataItemValue>
                    {dashboardData?.curationsUploadedNum}
                  </DataItemValue>
                </DataItemContainer>
                <DataItemContainer clicked={false}>
                  <DataItemTitle>
                    크롤링 업로드된
                    <br />
                    공간 수
                  </DataItemTitle>
                  <DataItemValue>
                    {dashboardData?.curationsUploadedInstaPublishedNum}
                  </DataItemValue>
                </DataItemContainer>
              </FlexDataContainer>
            </CommonContents>
            {/* 데이터 TYPE 그래프 */}
            <CommonHeader>
              <CommonTitle>데이터 Type</CommonTitle>
            </CommonHeader>
            <CommonContentsRow>
              <ChartContainer>
                <Chart type="doughnut" data={chartData} />
              </ChartContainer>
              <DescContainer>
                <DescContents>
                  Type 0 : {dashboardData?.spaceType0}
                </DescContents>
                <DescContents>
                  Type 1 : {dashboardData?.spaceType1}
                </DescContents>
                <DescContents>
                  Type 2 : {dashboardData?.spaceType2}
                </DescContents>
                <DescContents>
                  Type 3 : {dashboardData?.spaceType3}
                </DescContents>
                <DescContents>
                  Type 4 : {dashboardData?.spaceType4}
                </DescContents>
                <DescContents>
                  Type 5 : {dashboardData?.spaceType5}
                </DescContents>
              </DescContainer>
            </CommonContentsRow>
          </CommonContainer>
          {/* 우측 */}
          <CommonContainer>
            <CommonHeader>
              <CommonTitle>데이터 에러 Handler</CommonTitle>
            </CommonHeader>
            <CommonContents>
              <ErrorDashboard
                closedData={closedData}
                closedDataLoadingMode={closedDataLoadingMode}
                closedDataSyncBtnListener={closedDataSyncBtnListener}
                closedDataBtnText={closedDataBtnText}
                elementClickListener={elementClickListener}
                spaceTitleDuplicate={errorData.spaceTitleDuplicate}
                spaceMenuNoLineBreak={errorData.spaceMenuNoLineBreak}
                spaceNoKeyword={errorData.spaceNoKeyword}
                spaceNoMenu={errorData.spaceNoMenu}
                spaceNoTime={errorData.spaceNoTime}
                spaceTitleDuplicateLoading={loadingState.spaceTitleDuplicate}
                spaceMenuNoLineBreakLoading={loadingState.spaceMenuNoLineBreak}
                spaceNoKeywordLoading={loadingState.spaceNoKeyword}
                spaceNoMenuLoading={loadingState.spaceNoMenu}
                spaceNoTimeLoading={loadingState.spaceNoTime}
              />
            </CommonContents>
          </CommonContainer>
        </LayoutWrapper>
      ) : (
        <LayoutNoneWrapper>
          <NoneTitle>데이터 로딩중입니다...</NoneTitle>
          <NoneDesc>본 화면이 지속되면 관리자에게 문의해주세요.</NoneDesc>
        </LayoutNoneWrapper>
      )}
    </Container>
  );
};

export default DashboardDataContainer;
