import DashboardAppContainer from 'containers/dashboardApp/DashboardAppContainer';
import GlobalContainer from 'containers/GlobalContainer';

const DashboardApp = () => {
  return (
    <GlobalContainer>
      <DashboardAppContainer />
    </GlobalContainer>
  );
};

export default DashboardApp;
