import { useTable } from 'react-table';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

const AddressPopup = ({ title, data, closeModal, setAddressBasedOnNaver }) => {
  // set 'ESC' keystroke to escape
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // set Table Data
  const columns = useMemo(
    () => [
      {
        accessor: 'title',
        Header: '등록명',
      },
      {
        accessor: 'roadAddress',
        Header: '주소',
      },
      {
        accessor: 'address',
        Header: '지번주소',
      },
      {
        accessor: 'category',
        Header: '카테고리',
      },
    ],
    []
  );
  const setText = (text) => {
    return text + '_aa';
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <Wrapper>
      <HeaderContainer>
        <TitleContainer>
          <TitleWrapper>주소검색</TitleWrapper>
          <TitleSpace>{title}</TitleSpace>
        </TitleContainer>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </HeaderContainer>
      <Table {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableHeaderCell>
              ))}
            </tr>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableBodyRow
                  onClick={() => {
                    setAddressBasedOnNaver(
                      row.cells[0].value
                        .replace(/<b>/g, '')
                        .replace(/<\/b>/g, ''),
                      row.cells[1].value,
                      row.cells[2].value
                    );
                    closeModal();
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    let tableData = cell.render('Cell');
                    if (tableData.props.column.Header === '등록명') {
                      tableData = tableData.props.cell.value
                        .replace(/<b>/g, '')
                        .replace(/<\/b>/g, '');
                    }

                    return (
                      <TableBodyCell {...cell.getCellProps()}>
                        {tableData}
                      </TableBodyCell>
                    );
                  })}
                </TableBodyRow>
              );
            })
          ) : (
            <TableDataNotExist>
              검색된 공간이 없습니다. 공간 이름을 다시 확인해주세요.
            </TableDataNotExist>
          )}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-height: 100vh;
  max-width: 750px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const HeaderContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
`;
const TitleWrapper = styled.div`
  font-size: 20px;
  color: ${palette.fontBlack};
  font-weight: 700;
`;
const TitleSpace = styled.div`
  font-size: 18px;
  color: ${palette.fontBlue};
  font-weight: 700;
  margin-left: 8px;
`;
//////////////////////////////////////////////////

const Table = styled.table`
  margin-top: 16px;
  border-radius: 12px;
`;
const TableHeader = styled.thead`
  font-size: 16px;
  font-weight: 700;
  height: 30px;
  background-color: ${palette.grey_lighter};
`;
const TableHeaderCell = styled.th`
  vertical-align: middle;
  text-align: center;
  border: 1px solid ${palette.borderDefault};
`;
const TableBody = styled.tbody`
  font-size: 14px;
`;
const TableBodyRow = styled.tr`
  &:hover {
    background-color: ${palette.mainBlueLight};
  }
`;
const TableBodyCell = styled.td`
  vertical-align: middle;
  text-align: center;
  border: 1px solid ${palette.borderDefault};
  padding: 10px;
  cursor: pointer;
`;

const TableDataNotExist = styled.div`
  margin: 20px;
  font-size: 14px;
  color: ${palette.fontWarning};
`;

export default AddressPopup;
