import { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { ModalContext } from 'modules/context/ModalContext';
import { palette } from 'modules/defines/styles';
import useInputState from 'modules/customHooks/useInputState';
import { icon_imgadd } from 'assets/img/icon';
import CurationPublishSwitchButton from './CurationPublishSwitchButton';
import CurationPublishInstaSwitchButton from 'components/curation/CurationPublishInstaSwitchButton';
import MirroringCurationModal from 'components/modal/MirroringCurationModal';
import ImageDetailModal from 'components/modal/ImageDetailModal';

const CurationDetailInfo = ({
  havePermission,
  superPermission,
  editCuration,
  deleteCuration,
  detailLoading,
  detailData,
  uploadCurationImage,
  curatedData,
  refetchData,
}) => {
  const history = useHistory();
  const { openModal, closeModal } = useContext(ModalContext);
  // 우클릭 세팅
  const [show, setShow] = useState(false);
  const [imageLink, setImageLink] = useState('');
  const [choosedPicId, setChoosedPicId] = useState(0);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  // USE STATE Data Value
  const { state: id, setState: setId } = useInputState('');
  const {
    state: title,
    setState: setTitle,
    onChange: titleOnChange,
  } = useInputState('');
  const {
    state: desc,
    setState: setDesc,
    onChange: descOnChange,
  } = useInputState('');
  const {
    state: desc2,
    setState: setDesc2,
    onChange: desc2OnChange,
  } = useInputState('');
  const [belongSpace, setBelongSpace] = useState(null);

  // INITIALIZE Data
  useEffect(() => {
    setId(detailData?.id);
    setTitle(detailData?.title);
    setDesc(detailData?.description);
    setDesc2(detailData?.description2);
    setBelongSpace(curatedData);
    console.log(detailData);
  }, [curatedData, detailData, setDesc, setDesc2, setId, setTitle]);

  const ModalMirroring = async (e) => {
    e.preventDefault();
    return openModal(
      <MirroringCurationModal
        titleProps={title}
        setTitleProps={setTitle}
        descProps={desc}
        setDescProps={setDesc}
        closeModal={closeModal}
      />
    );
  };

  const saveBtnClickListener = async (e, isTempSave = false) => {
    e.preventDefault();
    const postData = {
      id: detailData.id, // NN
      title: title, // NN
      description: desc, // NN
      description2: desc2,
    };
    editCuration(postData);
    if (!isTempSave) {
      history.goBack();
    }
  };

  const ImageDetailModalOpen = async (e, url) => {
    e.preventDefault();
    return openModal(<ImageDetailModal closeModal={closeModal} url={url} />);
  };

  const deleteBtnClickListener = async (e) => {
    e.preventDefault();
    if (!window.confirm('정말로 삭제하시겠습니까?')) {
      return;
    }
    deleteCuration(detailData.id);
  };
  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle>큐레이션 정보</HeaderTitle>
        {havePermission && (
          <HeaderBtnContainer>
            <HeaderBtn
              color={palette.fontGray}
              onClick={(e) => ModalMirroring(e)}
            >
              미러링
            </HeaderBtn>
            <HeaderBtn onClick={deleteBtnClickListener}>삭제</HeaderBtn>
            <HeaderBtn onClick={(e) => saveBtnClickListener(e, true)}>
              임시저장
            </HeaderBtn>
            <HeaderBtn onClick={(e) => saveBtnClickListener(e, false)}>
              저장
            </HeaderBtn>
          </HeaderBtnContainer>
        )}
      </HeaderContainer>
      <ContentsContainer>
        {!detailLoading && (
          <ContentsWrapper>
            {/* 큐레이션 ID */}
            <ItemContainer>
              <ItemTitle>ID</ItemTitle>
              <ItemTextInput readOnly value={id} />
            </ItemContainer>
            {/* 큐레이션 제목 */}
            <ItemContainer>
              <ItemTitleContainer>
                <ItemTitle>제목</ItemTitle>
                <ItemDesc>
                  공백 포함
                  <br />
                  최대 16자
                </ItemDesc>
                {title != null && (
                  <ItemDesc2 warning={title.length > 16}>
                    {title.length} / 16
                  </ItemDesc2>
                )}
              </ItemTitleContainer>
              <ItemTextInput
                value={title}
                onChange={titleOnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            {/* 큐레이션 설명 */}
            <ItemContainer>
              <ItemTitleContainer>
                <ItemTitle>설명</ItemTitle>
                <ItemDesc>
                  공백 포함
                  <br />
                  최대 20자
                </ItemDesc>
                {desc != null && (
                  <ItemDesc2 warning={desc.length > 20}>
                    {desc.length} / 20
                  </ItemDesc2>
                )}
              </ItemTitleContainer>
              <ItemTextArea
                height="80px"
                value={desc}
                onChange={descOnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            {/* 큐레이션 추가설명 */}
            <ItemContainer>
              <ItemTitleContainer>
                <ItemTitle>추가설명</ItemTitle>
                <ItemDesc>
                  공백 포함
                  <br />
                  최소 70자
                </ItemDesc>
                {desc2 != null && (
                  <ItemDesc2 warning={desc2.length < 70}>
                    {desc2.length} 자
                  </ItemDesc2>
                )}
              </ItemTitleContainer>
              <ItemTextArea
                height="100px"
                value={desc2}
                onChange={desc2OnChange}
                disabled={!havePermission}
              />
            </ItemContainer>
            <ItemContainersRow grid={2}>
              <ItemContainer>
                <ItemTitle>작성 일자</ItemTitle>
                <ItemTextInput readOnly value={detailData.uploadedAt} />
              </ItemContainer>
              <ItemContainer>
                <ItemTitle>작성자</ItemTitle>
                <ItemTextInput readOnly value={detailData.writer} />
              </ItemContainer>
            </ItemContainersRow>
            <ItemContainersRow grid={2}>
              {/* Publish */}
              <ItemContainer>
                <ItemTitle>Publish</ItemTitle>
                <CurationPublishSwitchButton
                  published={detailData.publish}
                  id={id}
                  belongSpace={belongSpace}
                  havePermission={superPermission}
                />
              </ItemContainer>
              <ItemContainer>
                <ItemTitle>Insta</ItemTitle>
                <CurationPublishInstaSwitchButton
                  published={detailData.publish_instagram}
                  id={id}
                  havePermission={superPermission}
                />
              </ItemContainer>
            </ItemContainersRow>
            {/* 큐레이션 이미지 */}
            <ItemContainer>
              <ItemTitle themeColor="mint">이미지</ItemTitle>
              <DetailImageWrapper>
                {detailData.img !== null && detailData.img !== '' && (
                  <DetailImage
                    uploadedImg={detailData.img}
                    size="85"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorPoint({ x: e.pageX, y: e.pageY });
                      setShow(true);
                      setChoosedPicId(detailData?.id);
                      setImageLink(detailData?.img);
                    }}
                    onClick={(e) => ImageDetailModalOpen(e, detailData?.img)}
                  />
                )}

                <DetailImageUploadLabel htmlFor="upload-image" size="85" />
                {havePermission && (
                  <DetailImageInput
                    type="file"
                    id="upload-image"
                    onChange={(e) => uploadCurationImage(e)}
                  />
                )}
              </DetailImageWrapper>
            </ItemContainer>
          </ContentsWrapper>
        )}
        {/* 우클릭 메뉴 */}
        {havePermission && (
          <CustomContextMenu
            style={{
              display: show ? 'block' : 'none',
              top: anchorPoint.y,
              left: anchorPoint.x,
            }}
          >
            <CustomContextMenuItem>
              <a href={imageLink} download>
                사진 다운로드
              </a>
            </CustomContextMenuItem>
          </CustomContextMenu>
        )}
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
// HEADER
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.div`
  font-size: 18px;
  font-family: Pretendard-b;
  color: ${palette.fontDefault};
`;
const HeaderBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Pretendard-m;
`;
const HeaderBtn = styled.div`
  color: ${(props) => (props.color ? props.color : palette.mainOrange)};
  background-color: ${palette.white};
  border: 1px solid
    ${(props) => (props.color ? props.color : palette.mainOrange)};
  border-radius: 16px;
  padding: 4px 16px 4px 16px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainOrangeHover};
    color: ${palette.white};
  }
  margin-left: 16px;
  font-size: 14px;

  @media screen and (max-width: 1200px) {
    font-size: 12px;
    padding: 4px 8px;
    margin-left: 10px;
  }
`;
// CONTENTS
const ContentsContainer = styled.div`
  margin-top: 12px;
`;
const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
// Curation Items
const ItemContainersRow = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.grid ? props.grid : 2)},
    1fr
  );
  grid-column-gap: 20px;
`;
const ItemContainer = styled.div`
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
`;
const ItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemTitle = styled.div`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0'}px;
  min-width: 70px;
  text-align: left;
  font-size: 14px;
  color: ${palette.fontDefault};
`;
const ItemDesc = styled.div`
  text-size: 6px;
  display: flex;
  margin-top: 4px;
  color: ${palette.fontGray};
  line-height: 12px;
`;
const ItemDesc2 = styled.div`
  color: ${(props) => (props.warning ? palette.red : palette.black)};
`;
// Space Item Value
const ItemTextInput = styled.input`
  all: unset;
  width: 100%;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  font-size: 16px;
  color: ${(props) =>
    props.readOnly ? palette.mainOrange : palette.fontBlack};
  opacity: 1;
`;
const ItemTextArea = styled.textarea`
  all: unset;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '60px')};
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  color: ${palette.fontBlack};
  font-size: 16px;
  line-height: 1.1;
`;

const DetailImageWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const DetailImage = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: #ffffff 0% 0% no-repeat padding-box; //https://api.pkbroz.com
  background-image: url(${(props) =>
    props.uploadedImg === '' ? `${props.initialImg}` : `${props.uploadedImg}`});
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;
const DetailImageUploadLabel = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${icon_imgadd});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
  cursor: pointer;
`;

const DetailImageInput = styled.input`
  display: none;
`;

// 우클릭 메뉴
const CustomContextMenu = styled.ul`
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  width: 150px;
  height: auto;
  margin: 0;
  position: fixed;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 99;
`;

const CustomContextMenuItem = styled.li`
  padding: 10px 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;
export default CurationDetailInfo;
