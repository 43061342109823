import { palette } from 'modules/defines/styles';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { icon_reset } from 'assets/img/icon';

const SpaceDetailCategory = ({
  categoryData,
  categoryState,
  setCategoryState,
  detailData,
}) => {
  const [categoryFirstClick, setCategoryFirstClick] = useState(true);
  useEffect(() => {
    if (
      detailData?.data.category !== null &&
      detailData?.data.category !== undefined &&
      detailData?.data.category !== ''
    ) {
      const categoryStr = detailData?.data.category;
      let newArr = [0, 0, 0, 0, 0, 0, 0,0]; // FIXME 임시방편
      categoryStr.map((element, idx) => {
        if (idx === 0) {
          newArr[element] = 2;
        } else {
          newArr[element] = 1;
        }
      });
      setCategoryState(newArr);
      setCategoryFirstClick(false);
    } else {
      let newArr = [0, 0, 0, 0, 0, 0, 0,0]; // FIXME 임시방편
      setCategoryState(newArr);
      setCategoryFirstClick(true);
    }
  }, []);

  const categoryButtonListener = async (e, idx) => {
    e.preventDefault();
    const newArr = [...categoryState];
    if (categoryState[idx] === 0 && categoryFirstClick) {
      newArr[idx] = 2;
      setCategoryFirstClick(false);
      setCategoryState(newArr);
    } else if (categoryState[idx] === 0 && !categoryFirstClick) {
      newArr[idx] = 1;
      setCategoryState(newArr);
    } else if (categoryState[idx] === 1) {
      newArr[idx] = 0;
      setCategoryState(newArr);
    } else if (categoryState[idx] === 2) {
      setCategoryState([0, 0, 0, 0, 0, 0, 0,0]); // FIXME 임시방편
      setCategoryFirstClick(true);
    }
  };
  const resetCategoryListener = () => {
    let newArr = [0, 0, 0, 0, 0, 0, 0, 0];
    setCategoryState(newArr);
    setCategoryFirstClick(true);
  };
  return (
    <CategoryContainer>
      {categoryData.map((item, idx) => {
        return (
          <CategorySelectButton
            key={idx + '_'}
            state={categoryState[idx]}
            onClick={(e) => categoryButtonListener(e, idx)}
          >
            {item.title}
          </CategorySelectButton>
        );
      })}
      <CategoryResetButton onClick={resetCategoryListener} />
    </CategoryContainer>
  );
};

const CategoryContainer = styled.div`
  width: 100%;
  display: inline-block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;
`;

const CategorySelectButton = styled.div`
  display: inline-block;
  padding: 6px 8px;
  margin-right: 8px;
  font-size: 10px;
  border-radius: 8px;
  font-weight: 600;
  white-space: nowrap;

  background-color: ${(props) =>
    (props.state === 2 && palette.mainOrange) ||
    (props.state === 1 && palette.mainOrangeHover) ||
    (props.state === 0 && palette.background)};
  color: ${(props) =>
    (props.state === 2 && palette.white) ||
    (props.state === 1 && palette.white) ||
    (props.state === 0 && palette.fontDefault)};
  &:hover {
    color: ${(props) =>
      (props.state === 2 && palette.black) ||
      (props.state === 1 && palette.black) ||
      (props.state === 0 && palette.fontOrange)};
  }
`;
const CategoryResetButton = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background: url(${icon_reset}) 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  background-color: ${palette.fontDefault};
  margin-left: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainOrange};
  }
  position: absolute;
  top: 0px;
`;

export default SpaceDetailCategory;
