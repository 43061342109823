import FilterManagementContainer from 'containers/filter/FilterManagementContainer';
import GlobalContainer from 'containers/GlobalContainer';

const FilterManagement = () => {
  return (
    <GlobalContainer>
      <FilterManagementContainer />
    </GlobalContainer>
  );
};

export default FilterManagement;
