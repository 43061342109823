import { useMemo, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import { UserContext } from 'modules/api/user';
import { Filter } from 'components/common/Filter';
import {
    Container,
    Title,
    SubTitle,
    LayoutWrapper,
} from 'components/common/DataTableStyle';
import DefaultTable from 'components/table/DefaultTable';
import { orderAPI } from 'modules/api/order';

const OrderListContainer = () => {
    const history = useHistory();
    const [userContext] = useContext(UserContext);

    const fetchList = () => {
        const { call, cancel } = orderAPI.getOrderHistory();

        call.cancel = cancel;
        return call;
    };

    const { data, error, isLoading, refetch } = useQuery({
        queryKey: ['feedback'],
        queryFn: () => fetchList(),
        refetchOnWindowFocus: true,
        refetchInterval: false,
        staleTime: 5 * 1000 * 60,
    });

    // 권한 확인 후 차단
    if (!userContext.user.role.match('admin') && userContext.user.role !== 'marketer') {
        history.push(pagePaths.space.list);
    }

    const tableColumns = useMemo(() => [
        {
            accessor: 'orderIdNumber',
            Header: '주문번호',
            Filter: false,
            width: 100,
        },
        {
            accessor: 'orderNumber',
            Header: '상품번호',
            Filter: false,
            width: 80,
        },
        {
            accessor: 'orderName',
            Header: '상품이름',
            Filter: false,
            width: 200,
        },
        {
            accessor: 'nameStr',
            Header: '성함',
            Filter: false,
            width: 80,
        },
        {
            accessor: 'phoneStr',
            Header: '번호',
            Filter: false,
            width: 120,
        },
        {
            accessor: 'paidAtStr',
            Header: '구매일자',
            Filter: false,
            width: 120,
        },
        {
            accessor: 'isUsed',
            Header: '사용 여부',
            Filter: false,
            width: 60,
        },
        {
            accessor: 'optionStr',
            Header: '옵션',
            Filter: false,
            width: 60,
        },
        {
            accessor: 'priceStr',
            Header: '가격',
            Filter: false,
            width: 70,
        },
        {
            accessor: 'userStr',
            Header: '유저ID',
            Filter: false,
            width: 60,
        },
    ]);

    const tableData = useMemo(() => {
        if (!data) {
            return [];
        }
        console.log(data);
        return data.map((orderItem) => ({
            ...orderItem,
            paidAtStr: orderItem.paidAt.split('T')[0].substring(2) + ' ' + orderItem.paidAt.split('T')[1].substring(0, 5),
            nameStr: maskName(orderItem.customerName),
            phoneStr: maskPhoneNumber(orderItem.customerPhone),
            isUsed: orderItem.usedAt !== null ? 'Y' : 'N',
            optionStr: orderItem.option !== null ? orderItem.option.title : 'X',
            priceStr: orderItem.option_price.toLocaleString('ko-KR') + '원',
            userStr: orderItem.user_id !== 0 ? orderItem.user_id : '비회원',
        }));
    }, [data]);

    return (
        <Container>
            <Title>스토어 구매 목록</Title>
            <SubTitle>FAV 스토어 구매 목록 페이지입니다.</SubTitle>
            <LayoutWrapper>
                {isLoading ? <div>데이터 로딩중...</div> : (
                    <>
                        {tableData && tableColumns && (
                            <DefaultTable columns={tableColumns} data={tableData} />
                        )}
                    </>
                )}
            </LayoutWrapper>
        </Container>
    );
};

function maskName(name) {
    const length = name.length;

    if (length === 2) {
        // 두 글자 이름은 뒷글자를 *로 처리
        return name[0] + '*';
    } else if (length === 3) {
        // 세 글자 이름은 가운데 글자를 *로 처리
        return name[0] + '*' + name[2];
    } else if (length >= 4) {
        // 네 글자 이상 이름은 가운데 글자를 모두 *로 처리
        return name[0] + '*'.repeat(length - 2) + name[length - 1];
    } else {
        // 이름 길이가 0일 경우 처리 (예외 처리)
        return '';
    }
}

function maskPhoneNumber(phoneNumber) {
    // 전화번호의 형식이 올바른지 확인하는 정규 표현식
    const regex = /^(\d{3})-(\d{4})-(\d{4})$/;

    // 전화번호가 정규 표현식에 맞는지 확인
    if (regex.test(phoneNumber)) {
        // 정규 표현식에 맞는 경우, 각 부분을 추출
        return phoneNumber.replace(regex, '$1-****-$3');
    } else {
        // 형식이 맞지 않는 경우 처리 (예를 들어, 빈 문자열 반환)
        return 'Invalid phone number format';
    }
}

export default OrderListContainer;