import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { UserContext } from 'modules/api/user';
import { GlobalContext } from 'modules/context/GlobalContext';
import { curationAPI } from 'modules/api';
import DetailKeyword from 'components/DetailKeyword';
import CurationDetailInfo from 'components/curation/CurationDetailInfo';
import CurationDetailCurated from 'components/curation/CurationDetailCurated';
import { Container } from 'components/common/DataTableStyle';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  LayoutWrapper,
  CommonContainer,
  CommonWrapper,
} from 'components/curation/CurationCommon';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

const CurationDetailContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const globalState = useContext(GlobalContext);
  const spaceData = globalState.space;
  const keywordAllData = globalState.keyword;
  const refetch = () => {
    globalState.refetch('keyword');
  };
  const [havePermission, setHavePermission] = useState(false);
  const [superPermission, setSuperPermission] = useState(false);
  const [isOneColumn, setIsOneColumn] = useState(false);
  const [mode, setMode] = useState(1); // 1 : 공간정보/이미지 or 2 : 필터/키워드 
  const [userContext] = useContext(UserContext);
  const queryClient = useQueryClient();
  const {
    data: detail,
    isLoading: isLoadingDetail,
    refetch: refetchDetail,
  } = useQuery({
    queryKey: ['curationDetail', id],
    queryFn: () => curationAPI.getCurationDetail(id).call,
  });

  const {
    data: curated,
    isLoading: isLoadingCurated,
    refetch: refetchCurated,
  } = useQuery({
    queryKey: ['spaceCurated', id],
    queryFn: () => curationAPI.getSpaceCurated(id).call,
  });

  const {
    data: curationKeyword,
    isLoading: isLoadingKeyword,
    refetch: refetchKeyword,
  } = useQuery({
    queryKey: ['curationKeyword', id],
    queryFn: () => curationAPI.getKeyword(id).call,
  });


  // Pagination Resp
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsOneColumn(true);
      } else {
        setIsOneColumn(false);
      }
    };
    // 이벤트 리스너 등록
    window.addEventListener('resize', handleResize);
    // 초기 렌더링에서 한 번 실행
    handleResize();
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const saveMutation = useMutation({
    mutationFn: (data) => curationAPI.editCuration(data),
    onSuccess: () => {
      alert('큐레이션이 수정되었습니다.');
      queryClient.invalidateQueries(['curationDetail']);
      queryClient.invalidateQueries(['curations']);
    },
    onError: (error) => {
      console.error(error);
      alert(
        '큐레이션을 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => curationAPI.deleteCuration(id).call,
    onSuccess: () => {
      alert('큐레이션이 삭제되었습니다.');
      history.goBack();
      queryClient.invalidateQueries(['curations']);
    },
    onError: (error) => {
      console.error(error);
      alert(
        '큐레이션을 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    },
  });

  const uploadMutation = useMutation({
    mutationFn: (data) => {
      const headers = {
        id: id,
      };
      return curationAPI.uploadImage(data, headers);
    },
    onSuccess: (response) => {
      if (response.url) {
        queryClient.invalidateQueries(['curationDetail']);
      }
    },
    onError: (error) => {
      console.error(error);
      alert(
        '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    },
  });

  const uploadCurationImage = async (e) => {
    let file = e.target.files[0];
    let blob = file.slice(0, file.size, file.type);
    let postData = new FormData();
    postData.append(
      'img',
      new File([blob], id + '_main.' + e.target.files[0].name.split('.').pop())
    );

    uploadMutation.mutate(postData);
  };

  useEffect(() => {
    setHavePermission(
      userContext.user.role.match('admin') !== null ||
      userContext.user.user_id === detail.owner
    );
    setSuperPermission(userContext.user.role === 'super-admin');
  }, [detail, userContext.user.role, userContext.user.user_id]);

  return (
    <Container>
      {!isLoadingDetail ? (
        <>
          {isOneColumn && (
            <ButtonWrapper>
              <PageButton active={mode === 1} onClick={() => setMode(1)}>큐레이션 정보</PageButton>
              <PageButton active={mode === 2} onClick={() => setMode(2)}>포함공간 정보</PageButton>
            </ButtonWrapper>
          )}

          <LayoutWrapper fromBottom="100px">
            {(!isOneColumn || (isOneColumn && mode === 1)) && (
              <CommonContainer>
                <CommonWrapper>
                  {!isLoadingDetail && (
                    <CurationDetailInfo
                      havePermission={havePermission}
                      superPermission={superPermission}
                      detailLoading={isLoadingDetail}
                      detailData={detail}
                      editCuration={saveMutation.mutate}
                      deleteCuration={deleteMutation.mutate}
                      uploadCurationImage={uploadCurationImage}
                      curatedData={curated}
                      refetchData={refetchDetail}
                    />
                  )}
                </CommonWrapper>
                <CommonWrapper>
                  {!isLoadingKeyword && (
                    <DetailKeyword
                      havePermission={havePermission}
                      typeData="curation"
                      id={id}
                      keywordAllData={keywordAllData}
                      keywordAllRefetch={refetch}
                      keywordConnectedLoading={isLoadingKeyword}
                      keywordConnectedData={curationKeyword}
                      keywordConnectedRefetch={refetchKeyword}
                    />
                  )}
                </CommonWrapper>
              </CommonContainer>
            )}
            {(!isOneColumn || (isOneColumn && mode === 2)) && (
              <CommonContainer>
                <CommonWrapper>
                  {!isLoadingCurated && (
                    <CurationDetailCurated
                      id={id}
                      havePermission={havePermission}
                      curatedData={curated}
                      curatedRefetch={refetchCurated}
                      spaceData={spaceData}
                    />
                  )}
                </CommonWrapper>
              </CommonContainer>
            )}
          </LayoutWrapper>
        </>
      ) : null}
    </Container>
  );
};


const ButtonWrapper = styled.div`
  display:flex;
  flex-direction: row;
  padding: 0px 10px 10px 10px;
`;
const PageButton = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width: 100%;
  padding: 10px 0px;
  margin: 0px 5px;
  background-color: ${(props) => props.active ? palette.mainBlue : palette.mainDisabled};
  &:hover{
    background-color: ${palette.mainBlueHover};
  }
  cursor: pointer;
  color: ${palette.fontWhite};
  font-size: 12px;
  font-family: Pretendard-sb;
  border-radius: 8px;
`;

export default CurationDetailContainer;
