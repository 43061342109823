import { useParams, useHistory } from 'react-router';
import { UserContext } from 'modules/api/user';
import { docsAPI } from 'modules/api';
import { pagePaths } from 'modules/defines/paths';
import { useContext, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ButtonCancel, ButtonDelete } from 'components/common/DefaultButton';
import { Container, LayoutWrapper } from 'components/common/DataTableStyle';
import {
  ButtonWrapper,
  NotiTitle,
  NotiDate,
  NotiBody,
} from 'components/appManagement/AppManagementCommon';

const DocumentDetailContainer = () => {
  const { id } = useParams();
  const history = useHistory();
  const [userContext] = useContext(UserContext);
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [publishDate, setPublishDate] = useState('');

  const goBack = () => {
    history.goBack();
  };

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }

  const deleteDocs = async () => {
    try {
      docsAPI.deleteDocs(id).then(() => {
        alert('약관이 삭제되었습니다.');
      });
    } catch (e) {
      console.error(e);
      alert('약관을 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.');
      return;
    }
    history.push(pagePaths.document.list);
  };

  useEffect(() => {
    docsAPI.getDocsSingle(id).then((response) => {
      setTitle(response.title);
      setBody(response.body);
      setPublishDate(response.publishDate);
    });
  }, []);

  return (
    <Container>
      <ButtonWrapper>
        <ButtonCancel text="이전" onClick={goBack} />
        <ButtonDelete text="삭제" onClick={deleteDocs} />
      </ButtonWrapper>

      <LayoutWrapper>
        <NotiTitle>{title}</NotiTitle>
        <NotiDate>{publishDate}</NotiDate>
        <NotiBody>{ReactHtmlParser(body)}</NotiBody>
      </LayoutWrapper>
    </Container>
  );
};

export default DocumentDetailContainer;
