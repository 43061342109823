import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const ManagerAPI = (() => {
  class ManagerAPIConstructor {
    getManagerAll() {
      return httpAPI(apiPaths.getManagerAll, { method: 'GET' });
    }
    getManagerSingle(id) {
      return httpAPI(apiPaths.getManagerSingle.replace(':id', id), {
        method: 'GET',
      }).call;
    }
    editManager(data) {
      return httpAPI(apiPaths.editManager, { data }).call;
    }
    editManagerStatus(data) {
      return httpAPI(apiPaths.editManagerStatus, { data }).call;
    }
  }
  return ManagerAPIConstructor;
})();

export const managerAPI = new ManagerAPI();
