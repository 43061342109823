import { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import { pushAPI } from 'modules/api';
import { UserContext } from 'modules/api/user';
import { ModalContext } from 'modules/context/ModalContext';
import PushNotificationModal from 'components/modal/PushNotificationModal';
import {
  Container,
  Title,
  SubTitle,
  ButtonWrapper,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import { ButtonCreate } from 'components/common/DefaultButton';
import DefaultTable from 'components/table/DefaultTable';

const PushNotificationListContainer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userContext] = useContext(UserContext);
  const history = useHistory();
  const { openModal, closeModal } = useContext(ModalContext);

  // 권한 확인 후 차단
  if (!userContext.user.role.match('super-admin|admin|marketer')) {
    history.push(pagePaths.space.list);
  }

  useEffect(() => {
    if (loading) {
      pushAPI.getPushAll().then((response) => {
        setData(response);
      });
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [data]);

  const returnFunction = async () => {
    closeModal();
    setLoading(true);
  };
  const createPush = async (e) => {
    e.preventDefault();
    return openModal(
      <PushNotificationModal
        closeModal={closeModal}
        returnFunction={returnFunction}
      />
    );
  };

  const tableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      width: 50,
    },
    {
      accessor: 'title',
      Header: '제목',
      //   Cell: ({ row }) => (
      //     <Link to={`/push/detail/${row.values.id}`}>
      //       {row.values.title}
      //     </Link>
      //   ),
      Filter: false,
      width: 300,
    },
    {
      accessor: 'body',
      Header: '내용',
      //   Cell: ({ row }) => (
      //     <Link to={`/push/detail/${row.values.id}`}>
      //       {row.values.title}
      //     </Link>
      //   ),
      Filter: false,
      width: 300,
    },
    {
      accessor: 'date',
      Header: '전송 일자',
      Filter: false,
      width: 150,
    },
    {
      accessor: 'time',
      Header: '전송 시간',
      Filter: false,
      width: 150,
    },
    {
      accessor: 'data',
      Header: '정보',
      Filter: false,
      width: 200,
    },
  ]);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((push) => ({
      ...push,
    }));
  }, [data]);

  return (
    <Container>
      <Title>푸시 알람 관리</Title>
      <SubTitle>FAV 앱 푸시 알림 관리 페이지입니다.</SubTitle>
      <ButtonWrapper>
        <ButtonCreate text={'푸시 등록'} active={false} onClick={createPush} />
      </ButtonWrapper>
      <LayoutWrapper>
        {loading
          ? null
          : tableData &&
            tableColumns && (
              <DefaultTable columns={tableColumns} data={tableData} />
            )}
      </LayoutWrapper>
    </Container>
  );
};

export default PushNotificationListContainer;
