import { useMemo, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'modules/api/user';
import { pagePaths } from 'modules/defines/paths';
import { versionAPI } from 'modules/api';
import { PublishButton } from 'components/appManagement/AppManagementCommon';
import { ModalContext } from 'modules/context/ModalContext';
import {
  Container,
  Title,
  SubTitle,
  ButtonWrapper,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import VersionCreateModal from 'components/modal/VersionPopup';
import { ButtonCreate } from 'components/common/DefaultButton';
import DefaultTable from 'components/table/DefaultTable';

const DocumentListContainer = () => {
  const history = useHistory();
  const [userContext] = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);

  const fetchList = () => {
    const { call, cancel } = versionAPI.getVersionAll();
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['version'],
    queryFn: () => fetchList(),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });

  const returnFunction = async () => {
    closeModal();
    refetch();
  };

  const createPush = async (e) => {
    e.preventDefault();
    return openModal(
      <VersionCreateModal
        closeModal={closeModal}
        returnFunction={returnFunction}
      />
    );
  };

  const editMinVersion = async (data, status) => {
    try {
      if (status) {
        alert('한개의 버전은 반드시 최소 버전이어야 합니다.');
      } else {
        const postData = {
          id: data.id,
          isMinVersion: !status,
        };
        versionAPI.editVersion(postData).then((response) => {
          refetch();
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const editLatestVersion = async (data, status) => {
    try {
      if (status) {
        alert('한개의 버전은 반드시 최신 버전이어야 합니다.');
      } else {
        const postData = {
          id: data.id,
          isLatestVersion: !status,
        };
        versionAPI.editVersion(postData).then((response) => {
          refetch();
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // 권한 확인 후 차단
  if (!userContext.user.role.match('super-admin')) {
    history.push(pagePaths.space.list);
  }

  const tableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      width: 100,
    },
    {
      accessor: 'number',
      Header: '버전',
      Filter: false,
      width: 200,
    },
    {
      accessor: 'isMinVersion',
      Header: 'min_version',
      Cell: ({ row }) => {
        return (
          <PublishButton
            published={row.values.isMinVersion}
            onClick={() => editMinVersion(row.values, row.values.isMinVersion)}
          />
        );
      },
      Filter: false,
    },
    {
      accessor: 'isLatestVersion',
      Header: 'latest_version',
      Cell: ({ row }) => {
        return (
          <PublishButton
            published={row.values.isLatestVersion}
            onClick={() =>
              editLatestVersion(row.values, row.values.isLatestVersion)
            }
          />
        );
      },
      Filter: false,
    },
  ]);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((versions) => ({
      ...versions,
    }));
  }, [data]);

  return (
    <Container>
      <Title>앱 버전 관리</Title>
      <SubTitle>FAV 앱 버전 관리 페이지입니다.</SubTitle>
      <ButtonWrapper>
        <ButtonCreate text={'버전 추가'} active={false} onClick={createPush} />
      </ButtonWrapper>
      <LayoutWrapper>
        {tableData && tableColumns && (
          <DefaultTable columns={tableColumns} data={tableData} />
        )}
      </LayoutWrapper>
    </Container>
  );
};

export default DocumentListContainer;
