import styled from 'styled-components';
import {
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import { palette } from 'modules/defines/styles';
import { linkAPI } from 'modules/api';
import { useEffect, useState } from 'react';
import useInputState from 'modules/customHooks/useInputState';

const LinkManagementContainer = () => {
  const {
    state: urlFP,
    setState: setUrlFP,
    onChange: urlFPOnChange,
  } = useInputState('');
  const [title, setTitle] = useState();

  useEffect(() => {
    linkAPI.getLink('?title=FAVIEW_PROPOSAL_LINK').then((response) => {
      setUrlFP(response.url);
      setTitle(response.title);
    });
  }, []);

  const onClickSaveBtn = async (e) => {
    e.preventDefault();
    const postData = {
      title: 'FAVIEW_PROPOSAL_LINK',
      url: urlFP,
    };
    try {
      linkAPI.editLink(postData).then(() => {
        alert('링크가 수정되었습니다.');
      });
    } catch (e) {
      console.error(e);
      alert(
        '공간 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
  };

  const testClickListener = () => {
    console.log(urlFP);
  };

  return (
    <Container>
      <LayoutWrapper>
        <LinkContainer>
          <LinkTextWrapper>
            <LinkTitle onClick={testClickListener}>
              {title}
            </LinkTitle>
            <LinkDesc>FAVIEW 영업 제안서 링크입니다.</LinkDesc>
          </LinkTextWrapper>
          <LinkURLWrapper value={urlFP} onChange={urlFPOnChange} />
          <LinkSaveButton onClick={(e) => onClickSaveBtn(e)}>
            저장
          </LinkSaveButton>
        </LinkContainer>
      </LayoutWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 28px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LinkTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkTitle = styled.div`
  font-size: 16px;
  color: ${palette.fontBlack};
  font-weight: 700;
`;

const LinkDesc = styled.div`
  font-size: 11px;
  color: ${palette.fontDefault};
  font-weight: 400;
  margin-top: 8px;
`;

const LinkURLWrapper = styled.textarea`
  all: unset;
  width: 300px;
  height: 60px;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  color: ${palette.fontBlack};
  font-size: 14px;
  line-height: 1.1;
  margin-left: 20px;
`;

const LinkSaveButton = styled.div`
  height: 60px;
  width: 40px;
  background-color: ${palette.mainBlue};
  border-radius: 12px;
  display: flex;
  margin-left: 12px;

  align-items: center;
  justify-content: center;
  color: ${palette.fontWhite};
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
`;

export default LinkManagementContainer;
