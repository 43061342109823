import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import 'react-quill/dist/quill.snow.css';
import { btn_curation_off, btn_curation_on } from 'assets/img/button';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Loading = styled.div`
  font-size: 20px;
  font-weight: 700px;
  color: ${palette.mainOrange};
`;

export const NotiTitle = styled.div`
  margin-top: 10px;
  font-size: 22px;
  font-weight: 700;
  color: ${palette.fontBlack};
`;

export const NotiDate = styled.div`
  margin-top: 14px;
  font-size: 16px;
  color: ${palette.fontDefault};
`;

export const NotiBody = styled.div`
  font-size: 16px;
  margin-top: 20px;
  border: 1.5px solid ${palette.borderLightGrey};
  padding: 20px;
  line-height: 1.2;
  strong {
    font-weight: 700;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
  blockquote {
  }
  .ql-align-center {
    text-align: center;
  }
  ol {
    list-style-type: lower-aplha;
  }
  ul {
    list-style: circle;
  }
  li {
    margin-left: 40px;
  }
  a {
    text-decoration: underline;
    color: blue;
  }
`;

export const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  padding-bottom: 80px;
  .ql-editor strong {
    font-weight: bold;
  }
  .ql-editor em {
    font-style: italic;
  }
`;

export const TextComponents = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const TextLabel = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  width: 80px;
  color: ${palette.fontDefault};
  height: 100%;
  padding-left: 10px;
  align-items: center;
`;
export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
`;
export const TextTitle = styled.input`
  height: 36px;
  width: 100%;
  font-size: 14px;
`;
export const TextDate = styled.input`
  height: 36px;
  width: 180px;
  font-size: 14px;
`;

export const TypeSelect = styled.select`
  width: 180px;
  height: 36px;
`;

export const PublishButton = styled.div`
  width: 39px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  background-image: url(${(props) =>
    props.published ? btn_curation_on : btn_curation_off});
`;

export const LayoutWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
  row-gap: 10px;
  column-gap: 10px;
  width: 100%;
  height: calc(
    100vh - ${'160px'}
  );
  overflow: auto;

  .isMobile & {
    display: flex;
    padding: 0;
    flex-direction: column;
    height: 100%;
  }
`;

export const CommonContainer = styled.div`
  background-color: ${palette.white};
  width: 100%;
  
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  .isMobile & {
    padding: 0;
  }
`;