import { docsAPI } from 'modules/api';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { dateRegex, dateRegex2 } from 'utils/regex';
import { pagePaths } from 'modules/defines/paths';
import { UserContext } from 'modules/api/user';
import { ButtonCancel, ButtonConfirm } from 'components/common/DefaultButton';
import {
  Container,
  Title,
  SubTitle,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import {
  ButtonWrapper,
  EditorWrapper,
  TextComponents,
  TextLabel,
  TextWrapper,
  TypeSelect,
  TextDate,
} from 'components/appManagement/AppManagementCommon';
import EditorComponent from 'components/common/EditorComponent';

const DocumentCreateContainer = () => {
  const history = useHistory();
  const [convertedText, setConvertedText] = useState('');
  const [typeSelect, setTypeSelect] = useState('terms-of-service');
  const [dateText, setDateText] = useState('');
  const [userContext] = useContext(UserContext);

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }

  useEffect(() => {
    if (dateText.length === 7) {
      setDateText((current) =>
        current.replace(/(\d{4})(\d{2})(\d{1})/, '$1.$2.$3')
      );
    }
    if (dateText.length === 10) {
      setDateText((current) =>
        current.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3')
      );
    }
  }, [dateText]);

  const goBack = () => {
    history.goBack();
  };
  const onSelectType = (e) => {
    setTypeSelect(e.target.value);
  };
  const onInputDate = (e) => {
    if (dateRegex2.test(e.target.value)) {
      setDateText(e.target.value);
    }
  };
  const submit = async (e) => {
    e.preventDefault();
    if (typeSelect === '') {
      alert('타입을 선택해주세요');
    } else if (dateText === '') {
      alert('게시날짜를 입력해주세요');
    } else if (!dateRegex.test(dateText)) {
      alert('올바른 게시날짜를 입력해주세요\nex)2021.08.03');
    } else if (convertedText === '') {
      alert('내용을 입력해주세요');
    } else {
      let postData = {
        type: typeSelect,
        title: `${typeSelect}/${dateText}`,
        body: convertedText,
        publishDate: dateText,
      };
      try {
        docsAPI.registerDocs(postData).then((response) => {
          alert('업로드가 완료되었습니다.');
          history.goBack();
        });
      } catch (e) {
        alert('업로드 하는 도중\n오류가 발생했습니다.');
      }
    }
  };
  return (
    <Container>
      <Title>이용약관 추가</Title>
      <SubTitle>이용약관 추가 페이지입니다.</SubTitle>
      <LayoutWrapper fromBottom="220px">
        <EditorWrapper>
          <TextComponents>
            <TextLabel>제목</TextLabel>
            <TextWrapper>
              <TypeSelect onChange={onSelectType}>
                <option value="terms-of-service">서비스 이용약관</option>
                <option value="terms-location">위치기반 서비스 이용약관</option>
                <option value="privacy-policy">개인정보 처리방침</option>
                <option value="marketing-info">마케팅 정보 활용 동의</option>
              </TypeSelect>
            </TextWrapper>
          </TextComponents>
          <TextComponents>
            <TextLabel>게시날짜</TextLabel>
            <TextWrapper>
              <TextDate
                value={dateText}
                onChange={onInputDate || null}
                placeholder="ex) 2021.12.03"
              />
            </TextWrapper>
          </TextComponents>
          <EditorComponent
            convertedText={convertedText}
            setConvertedText={setConvertedText}
          />
        </EditorWrapper>
      </LayoutWrapper>
      <ButtonWrapper>
        <ButtonCancel onClick={goBack} text="취소" />
        <ButtonConfirm onClick={submit} text="업로드" />
      </ButtonWrapper>
    </Container>
  );
};
export default DocumentCreateContainer;
