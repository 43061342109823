import FaviewSfMContainer from 'containers/faview/FaviewSfMContainer';
import GlobalContainer from 'containers/GlobalContainer';

const FaviewSfM = () => {
  return (
    <GlobalContainer>
      <FaviewSfMContainer />
    </GlobalContainer>
  );
};

export default FaviewSfM;
