import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const KeywordAPI = (() => {
  class KeywordAPIConstructor {
    getKeywordAll() {
      return httpAPI(apiPaths.getKeywordAll, { method: 'GET' }).call;
    }
    getKeywordDetail(id) {
      return httpAPI(apiPaths.getKeywordAll + id, { method: 'GET' }).call;
    }
    registerKeyword(data) {
      return httpAPI(apiPaths.registerKeyword, { data: data }).call;
    }
    deleteKeyword(id) {
      return httpAPI(apiPaths.deleteKeyword + id, { method: 'DELETE' }).call;
    }
    editKeyword(data) {
      return httpAPI(apiPaths.editKeyword, { data: data }).call;
    }
    editCustomKeyword(data) {
      return httpAPI(apiPaths.editCustomKeyword, { data: data }).call;
    }
  }
  return KeywordAPIConstructor;
})();

export const keywordAPI = new KeywordAPI();
