import UserDetailContainer from 'containers/user/UserDetailContainer';
import GlobalContainer from 'containers/GlobalContainer';

const UserDetail = () => {
  return (
    <GlobalContainer>
      <UserDetailContainer />
    </GlobalContainer>
  );
};

export default UserDetail;
