import UserListContainer from 'containers/user/UserListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const UserList = () => {
  return (
    <GlobalContainer>
      <UserListContainer />
    </GlobalContainer>
  );
};

export default UserList;
