import { useMemo, useState, useContext } from 'react';
import { GlobalContext } from 'modules/context/GlobalContext';
import { useHistory } from 'react-router';
import { keywordAPI } from 'modules/api';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import {
  Container,
  Title,
  SubTitle,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import DefaultTable from 'components/table/DefaultTable';
import DeleteKeywordButton from 'components/button/KeywordDeleteButton';
import { pagePaths } from 'modules/defines/paths';

const KeywordManagementContainer = () => {
  const history = useHistory();
  const [showDetailData, setShowDetailData] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [modeEdit, setModeEdit] = useState(false);
  const [editBtnText, setEditBtnText] = useState('수정');
  const [selectedTitleEdit, setSelectedTitleEdit] = useState('');

  const onChangeSelectedTitleEdit = (e) => {
    setSelectedTitleEdit(e.target.value);
  };
  const globalState = useContext(GlobalContext);

  const refetchKeyword = () => {
    globalState.refetch('keyword');
  };

  const keywordProductTableData = useMemo(() => {
    if (globalState.keyword === null) {
      return [];
    }
    return globalState.keyword
      .filter((itemFilter) => {
        return itemFilter.std === 'product';
      })
      .map((item) => {
        return {
          ...item,
          space_count: item.space.length,
          curation_count: item.curation.length,
        };
      });
  }, [globalState.keyword]);

  const keywordVibeTableData = useMemo(() => {
    if (globalState.keyword === null) {
      return [];
    }
    return globalState.keyword
      .filter((itemFilter) => {
        return itemFilter.std === 'vibe';
      })
      .map((item) => {
        return {
          ...item,
          space_count: item.space.length,
          curation_count: item.curation.length,
        };
      });
  }, [globalState.keyword]);

  const keywordProductTableColumn = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      align: 'center',
      Filter: false,
      width: 80,
    },
    {
      accessor: 'title',
      Header: '키워드',
      align: 'center',
      Filter: false,
      width: 120,
    },
    {
      accessor: 'std',
      Header: 'Standard',
      align: 'center',
      Filter: false,
      width: 80,
    },
    {
      accessor: 'space_count',
      Header: '공간 카운트',
      align: 'center',
      Filter: false,
    },
    {
      accessor: 'curation_count',
      Header: '큐레이션 카운트',
      align: 'center',
      Filter: false,
    },
  ]);

  const keywordVibeTableColumn = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      align: 'center',
      Filter: false,
      width: 80,
    },
    {
      accessor: 'title',
      Header: '키워드',
      align: 'center',
      Filter: false,
      width: 120,
    },
    {
      accessor: 'std',
      Header: 'Standard',
      align: 'center',
      Filter: false,
      width: 60,
    },
    {
      accessor: 'category',
      Header: 'Category',
      align: 'center',
      Filter: false,
      width: 80,
    },
    {
      accessor: 'space_count',
      Header: '공간개수',
      align: 'center',
      Filter: false,
      width: 70,
    },
    {
      accessor: 'curation_count',
      Header: '큐레이션개수',
      align: 'center',
      Filter: false,
      width: 70,
    },
  ]);

  const getIncludedContents = async (e, row) => {
    e.preventDefault();
    setShowDetailData(true);
    setSelectedKeyword(row.original);
    setSelectedTitleEdit(row.original.title);
  };

  const onClickKeywordItem = (e, type, id) => {
    e.preventDefault();
    if (type === 'space') {
      history.push(pagePaths.space.detail.replace(':id', id));
    } else if (type === 'curation') {
      history.push(pagePaths.curation.detail.replace(':id', id));
    }
  };

  const editKeywordListener = async (e) => {
    try {
      if (!modeEdit) {
        setModeEdit(true);
        setEditBtnText('수정 완료');
      } else {
        const postData = {
          id: selectedKeyword.id,
          title: selectedTitleEdit,
        };
        const response = await keywordAPI.editKeyword(postData);
        if (response.success) {
          alert('키워드 제목, 수정 완료되었습니다.');
          setModeEdit(false);
          setEditBtnText('수정');
          setSelectedKeyword({ ...selectedKeyword, title: selectedTitleEdit });
          refetchKeyword();
        } else {
          alert(
            '키워드 제목 수정 중 오류가 발생했습니다. 관리자에게 문의해주세요.'
          );
          setModeEdit(false);
          setEditBtnText('수정');
        }
      }
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  return (
    <Container>
      <Title>키워드 관리</Title>
      <SubTitle>
        키워드 관리 페이지입니다. Ctrl+f 혹은 Cmd+f 를 통해 키워드를
        검색해보세요.
      </SubTitle>
      <LayoutWrapper>
        <KeywordWrapper>
          {keywordProductTableData && keywordVibeTableData && (
            <TableContainer>
              <TableSubtitle>Product Keyword</TableSubtitle>
              <TableWrapper>
                <DefaultTable
                  columns={keywordProductTableColumn}
                  data={keywordProductTableData}
                  onClickRow={getIncludedContents}
                  halfSize
                  defaultWidth={100}
                />
              </TableWrapper>
              <TableSubtitle>Vibe Keyword</TableSubtitle>
              <TableWrapper>
                <DefaultTable
                  columns={keywordVibeTableColumn}
                  data={keywordVibeTableData}
                  onClickRow={getIncludedContents}
                  halfSize
                  defaultWidth={100}
                />
              </TableWrapper>
            </TableContainer>
          )}
          {showDetailData && selectedKeyword && (
            <DetailSection>
              <TableTitleWrapper>
                {modeEdit ? (
                  <TableSubtitleEditMode
                    value={selectedTitleEdit}
                    onChange={onChangeSelectedTitleEdit}
                  />
                ) : (
                  <TableSubtitle color={palette.mainBlue}>
                    {selectedKeyword.title}
                  </TableSubtitle>
                )}
                <KeywordBtn
                  onClick={editKeywordListener}
                  color={palette.mainBlue}
                  colorHover={palette.mainBlueHover}
                >
                  {editBtnText}
                </KeywordBtn>
                <DeleteKeywordButton
                  selectedKeyword={selectedKeyword}
                  setSelectedKeyword={setSelectedKeyword}
                  refetch={refetchKeyword}
                  btnText={'삭제'}
                />
              </TableTitleWrapper>
              {selectedKeyword.space.length === 0 &&
              selectedKeyword.curation.length === 0 ? (
                <ListSection>
                  키워드에 할당된 공간이나 큐레이션이 없습니다.
                </ListSection>
              ) : (
                <ListSection>
                  <ListColumn>
                    <DetailSubtitle>공간</DetailSubtitle>
                    {selectedKeyword.space.length === 0 ? (
                      <DescTextWrapper>
                        키워드에 할당된 공간이나 큐레이션이 없습니다.
                      </DescTextWrapper>
                    ) : (
                      <ul>
                        {selectedKeyword.space.map((space, index) => (
                          <ElementWrapperRow
                            key={index + '_space_keyword'}
                            onClick={(e) =>
                              onClickKeywordItem(e, 'space', space.id)
                            }
                          >
                            <DetailLabel>{space.id}</DetailLabel>
                            <DetailValue>{space.title}</DetailValue>
                          </ElementWrapperRow>
                        ))}
                      </ul>
                    )}
                  </ListColumn>
                  <ListColumn>
                    <DetailSubtitle>큐레이션</DetailSubtitle>
                    {selectedKeyword.curation.length === 0 ? (
                      <DescTextWrapper>
                        키워드에 할당된 공간이나 큐레이션이 없습니다.
                      </DescTextWrapper>
                    ) : (
                      <ul>
                        {selectedKeyword.curation.map((curation, index) => (
                          <ElementWrapperRow
                            key={index + '_curation_keyword'}
                            onClick={(e) =>
                              onClickKeywordItem(e, 'curation', curation.id)
                            }
                          >
                            <DetailLabel>{curation.id}</DetailLabel>
                            <DetailValue>{curation.title}</DetailValue>
                          </ElementWrapperRow>
                        ))}
                      </ul>
                    )}
                  </ListColumn>
                </ListSection>
              )}
            </DetailSection>
          )}
        </KeywordWrapper>
      </LayoutWrapper>
    </Container>
  );
};

const KeywordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableSubtitle = styled.div`
  font-family: Pretendard-sb;
  font-size: 18px;
  color: ${(props) => (props.color ? props.color : palette.fontDefault)};
  margin-bottom: 16px;
  margin-right: 20px;
`;
const TableSubtitleEditMode = styled.input`
  all: unset;
  border: 1px solid ${palette.borderDefault};
  text-align: left;
  padding: 2px;
  font-size: 16px;
  color: ${palette.fontBlack};
  margin-right: 8px;
  margin-bottom: 12px;
`;

const KeywordBtn = styled.div`
  background-color: ${(props) =>
    props.color ? props.color : palette.mainBlue};
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 10px;
  height: 20px;
  margin-right: 12px;
  color: ${palette.fontWhite};
  font-family: Pretendard-m;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.colorHover ? props.colorHover : palette.mainBlueHover};
  }
`;

const TableWrapper = styled.div`
  width: 500px;
  height: 50%;
  overflow: scroll;
  margin-bottom: 20px;
`;
const DetailSection = styled.div`
  margin-left: 40px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ElementWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  &:hover {
    background-color: ${palette.mainBlueExtraLight};
  }
  cursor: pointer;
  padding: 4px 8px;
`;
const DetailSubtitle = styled.div`
  text-align: left;
  font-size: 15px;
  font-family: Pretendard-b;
  letter-spacing: 0px;
  color: ${palette.fontDefault};
  margin-bottom: 8px;
`;
const DetailLabel = styled.div`
  font-size: 11px;
  font-family: Pretendard-el;
  color: ${palette.fontDefault};
  width: 36px;
`;
const DetailValue = styled.div`
  font-size: 14px;
  font-family: Pretendard-l;
`;
const ListSection = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: row;
  height: 100%;
  border: 1px solid ${palette.borderDefault};
  padding: 12px;
  border-radius: 12px;
`;
const ListColumn = styled.div``;

const DescTextWrapper = styled.div`
  font-size: 12px;
  color: ${palette.fontDefault};
`;

export default KeywordManagementContainer;
