import { palette } from 'modules/defines/styles';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { icon_eye, icon_like } from 'assets/img/icon';
import styled from 'styled-components';
import { TitleText } from 'containers/user/UserDetailContainer';
import { pagePaths } from 'modules/defines/paths';

const UserDetailViewLike = ({ viewlike_data }) => {
  const history = useHistory();
  const [viewLikeState, setViewLikeState] = useState(true);
  const [viewCuration, setViewCuration] = useState([]);
  const [viewSpace, setViewSpace] = useState([]);
  const [likeCuration, setLikeCuration] = useState([]);
  const [likeSpace, setLikeSpace] = useState([]);

  // 조회 & 좋아요 초기화 (viewlike_data 파싱) (viewCuration, viewSpace, likeCuration, likeSpace)
  useEffect(() => {
    viewlike_data?.view_curation.map((viewCuration) => {
      setViewCuration((item) => [
        ...item,
        viewCuration.id + ' : ' + viewCuration.weight,
      ]);
    });
    viewlike_data?.view_space.map((viewSpace) => {
      setViewSpace((item) => [
        ...item,
        viewSpace.id + ' : ' + viewSpace.weight,
      ]);
    });
    viewlike_data?.like_curation.map((likeCuration) => {
      setLikeCuration((item) => [...item, likeCuration.id]);
    });
    viewlike_data?.like_space.map((likeSpace) => {
      setLikeSpace((item) => [...item, likeSpace.id]);
    });
  }, [viewlike_data]);

  // 조회 & 좋아요 전환 버튼
  const stateChangeListener = () => {
    setViewLikeState((prev) => !prev);
  };
  // 클릭 시 해당 공간 or 큐레이션 detail 페이지로 이동
  const itemClickListener = (id, type) => {
    if (type === 'space') {
      history.push(pagePaths.space.detail.replace(':id', id));
    } else if (type === 'curation') {
      history.push(pagePaths.curation.detail.replace(':id', id));
    } else {
      alert('오류');
    }
  };
  return (
    <Container>
      <ViewLikeButton onClick={stateChangeListener} state={viewLikeState} />
      <TitleText>History : {viewLikeState ? '조회' : '좋아요'}</TitleText>

      {viewLikeState ? (
        <ViewLikeContainer>
          <ViewLikeWrapper>
            <ViewTitleContainer>
              큐레이션 : {viewCuration.length}
            </ViewTitleContainer>
            {viewCuration.map((e, i) => (
              <ViewTextContainer
                onClick={() => itemClickListener(e.split(' : ')[0], 'curation')}
                key={i}
              >
                {e}
              </ViewTextContainer>
            ))}
          </ViewLikeWrapper>
          <ViewLikeWrapper>
            <ViewTitleContainer>매장 : {viewSpace.length}</ViewTitleContainer>
            {viewSpace.map((e, i) => (
              <ViewTextContainer
                onClick={() => itemClickListener(e.split(' : ')[0], 'space')}
                key={i}
              >
                {e}
              </ViewTextContainer>
            ))}
          </ViewLikeWrapper>
        </ViewLikeContainer>
      ) : (
        <ViewLikeContainer>
          <ViewLikeWrapper>
            <ViewTitleContainer>
              큐레이션 : {likeCuration.length}
            </ViewTitleContainer>
            {likeCuration.map((e, i) => (
              <ViewTextContainer
                onClick={() => itemClickListener(e, 'curation')}
                key={i}
              >
                {e}
              </ViewTextContainer>
            ))}
          </ViewLikeWrapper>
          <ViewLikeWrapper>
            <ViewTitleContainer>매장 : {likeSpace.length}</ViewTitleContainer>
            {likeSpace.map((e, i) => (
              <ViewTextContainer
                onClick={() => itemClickListener(e, 'space')}
                key={i}
              >
                {e}
              </ViewTextContainer>
            ))}
          </ViewLikeWrapper>
        </ViewLikeContainer>
      )}
    </Container>
  );
};

const Container = styled.div``;

const ViewLikeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const ViewLikeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ViewTitleContainer = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 5px;
  color: ${palette.fontBlack};
`;
const ViewTextContainer = styled.div`
  margin-bottom: 2px;
  font-size: 12px;
  color: ${palette.fontDefault};
  &:hover {
    color: ${palette.fontOrange};
  }
  cursor: pointer;
`;
const ViewLikeButton = styled.div`
  background: url(${(props) => (props.state ? icon_eye : icon_like)}) 0% 0%
    no-repeat padding-box;
  background-size: cover;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;
`;

export default UserDetailViewLike;
