import NotificationCreateContainer from 'containers/notification/NotificationCreateContainer';
import GlobalContainer from 'containers/GlobalContainer';

const NotificationCreate = () => {
  return (
    <GlobalContainer>
      <NotificationCreateContainer />
    </GlobalContainer>
  );
};

export default NotificationCreate;
