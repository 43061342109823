import AdminListContainer from 'containers/user/AdminListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const AdminList = () => {
  return (
    <GlobalContainer>
      <AdminListContainer />
    </GlobalContainer>
  );
};

export default AdminList;
