import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const FaviewRequestAPI = (() => {
  class FaviewRequestAPIConstructor {
    getFaviewRequestAll() {
      return httpAPI(apiPaths.getFaviewRequestAll, { method: 'GET' });
    }
    getFaviewRequestSingle(id) {
      return httpAPI(apiPaths.getFaviewRequestSingle.replace(':id', id), {
        method: 'GET',
      }).call;
    }
    editFaviewRequest(data) {
      return httpAPI(apiPaths.editFaviewRequest, { data }).call;
    }
  }
  return FaviewRequestAPIConstructor;
})();

export const faviewRequestAPI = new FaviewRequestAPI();
