import CurationListContainer from 'containers/curation/CurationListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const CurationList = () => {
  return (
    <GlobalContainer>
      <CurationListContainer />
    </GlobalContainer>
  );
};

export default CurationList;
