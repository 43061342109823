import React from 'react';

const Add = ({ color }) => {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="MAIN"
          transform="translate(-473.000000, -203.000000)"
          fill="#FFFFFF"
        >
          <g id="Group" transform="translate(463.000000, 194.000000)">
            <path
              d="M16,9 C16.5522847,9 17,9.44771525 17,10 L17,14 L21,14 C21.5522847,14 22,14.4477153 22,15 C22,15.5522847 21.5522847,16 21,16 L17,16 L17,20 C17,20.5522847 16.5522847,21 16,21 C15.4477153,21 15,20.5522847 15,20 L15,16 L11,16 C10.4477153,16 10,15.5522847 10,15 C10,14.4477153 10.4477153,14 11,14 L15,14 L15,10 C15,9.44771525 15.4477153,9 16,9 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Add;
