import { useState, useEffect, useMemo, useContext } from 'react';
import { UserContext } from 'modules/api/user';
import { GlobalContext } from 'modules/context/GlobalContext';
import { authAPI } from 'modules/api';
import PublishButton from 'components/common/PublishButton';
import { useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import Dropdown from 'components/common/DropdownButton';
import { Filter } from 'components/common/Filter';

import {
  Container,
  Title,
  SubTitle,
  LayoutWrapper,
} from 'components/common/DataTableStyle';
import DefaultTable from 'components/table/DefaultTable';
import { admin_values } from 'modules/defines/values';

const AdminListContainer = () => {
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const [userContext] = useContext(UserContext);
  const globalState = useContext(GlobalContext);

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }

  useEffect(() => {
    setUserData(
      globalState.user.filter(
        (user) =>
          user.role === 'super-admin' ||
          user.role === 'admin' ||
          user.role === 'curator' ||
          user.role === 'editor' ||
          user.role === 'marketer'
      )
    );
  }, [globalState.user]);

  const tableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID',
      Filter: false,
      Resize: false,
      width: 50,
    },
    {
      accessor: 'email',
      Header: 'Email',
      Filter: false,
      Sort: false,
      Resize: false,
      width: 200,
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      Cell: ({ row }) => `${row.values.phone}`,
      Filter: false,
      Sort: false,
      Resize: false,
      width: 200,
    },
    {
      accessor: 'name',
      Header: 'Name',
      Filter: false,
      Resize: false,
      width: 200,
    },
    {
      accessor: 'role',
      Header: 'Role',
      Cell: ({ row }) => {
        return (
          <Dropdown
            data={row.values}
            editApi={(data) => authAPI.editAdmin(data)}
            refetch={() => globalState.refetch('user')}
            initialValue={admin_values.role.indexOf(row.values.role)}
            option={admin_values.role}
            editField="role"
          />
        );
      },
      Filter: Filter,
      Sort: false,
      Resize: false,
      width: 150,
      align: 'center',
    },
    {
      accessor: 'createdAt',
      Cell: ({ row }) => row.values.createdAt.slice(0, 10),
      Header: '가입일자',
      Filter: false,
    },
    {
      accessor: 'statusCd',
      Header: '상태',
      Cell: ({ row }) => {
        return (
          <PublishButton
            published={row.values.statusCd === '101'}
            data={row.values}
            refetch={() => globalState.refetch('user')}
            editApi={(data) => authAPI.editAdmin(data)}
            editField="statusCd"
            editValue={row.values.statusCd === '101' ? '100' : '101'}
            blockCond={row.values.id === userContext.user.user_id}
            blockMessage="본인의 상태는 변경할 수 없습니다."
          />
        );
      },
      Filter: false,
    },
  ]);

  const tableData = useMemo(() => {
    if (!userData) {
      return [];
    }
    return userData.map((noti) => ({
      ...noti,
    }));
  }, [userData]);

  return (
    <Container>
      <Title>관리자 설정</Title>
      <SubTitle>FAV 관리자 관리 페이지입니다.</SubTitle>
      <LayoutWrapper>
        {tableData && tableColumns && (
          <DefaultTable columns={tableColumns} data={tableData} />
        )}
      </LayoutWrapper>
    </Container>
  );
};

export default AdminListContainer;
