import { userAPI } from 'modules/api';
import { TitleText } from 'containers/user/UserDetailContainer';
import useInputState from 'modules/customHooks/useInputState';
import { palette } from 'modules/defines/styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const UserDetailSetKeywordWeight = ({
  weight_data,
  keyword_refetch,
  weight_refetch,
  viewlike_refetch,
}) => {
  const [weightTitle, setWeightTitle] = useState([]);
  const {
    state: weightVC,
    setState: setWeightVC,
    onChange: weightVCOnChange,
  } = useInputState('');
  const {
    state: weightVS,
    setState: setWeightVS,
    onChange: weightVSOnChange,
  } = useInputState('');
  const {
    state: weightLC,
    setState: setWeightLC,
    onChange: weightLCOnChange,
  } = useInputState('');
  const {
    state: weightLS,
    setState: setWeightLS,
    onChange: weightLSOnChange,
  } = useInputState('');

  // GET 가중치 (weight_data 파싱) (weightVC, weightVS, weightLC, weightLS)
  useEffect(() => {
    if (weight_data) {
      setWeightVC(weight_data[0].weight);
      setWeightVS(weight_data[1].weight);
      setWeightLC(weight_data[2].weight);
      setWeightLS(weight_data[3].weight);
      weight_data.map((e) => {
        setWeightTitle((arr) => [...arr, e.title]);
      });
    }
  }, [weight_data]);

  const weightSubmitListener = async () => {
    try {
      await userAPI.editRecommendWeight({
        title: weightTitle[0],
        weight: weightVC,
      });
      await userAPI.editRecommendWeight({
        title: weightTitle[1],
        weight: weightVS,
      });
      await userAPI.editRecommendWeight({
        title: weightTitle[2],
        weight: weightLC,
      });
      await userAPI.editRecommendWeight({
        title: weightTitle[3],
        weight: weightLS,
      });
    } catch (err) {
      console.error(err);
    } finally {
      alert('가중치가 수정 되었습니다.');
      keyword_refetch();
      weight_refetch();
      viewlike_refetch();
    }
  };

  return (
    <Container>
      <TitleText>가중치 수정</TitleText>
      <WeightContainer>
        <WeightLayout>
          <WeightTextLayout>
            <WeightColumn>큐레이션 조회 ({weightTitle[0]})</WeightColumn>
            <WeightData value={weightVC} onChange={weightVCOnChange} />
          </WeightTextLayout>
          <WeightTextLayout>
            <WeightColumn>매장 조회 ({weightTitle[1]})</WeightColumn>
            <WeightData value={weightVS} onChange={weightVSOnChange} />
          </WeightTextLayout>
          <WeightTextLayout>
            <WeightColumn>큐레이션 좋아요 ({weightTitle[2]})</WeightColumn>
            <WeightData value={weightLC} onChange={weightLCOnChange} />
          </WeightTextLayout>
          <WeightTextLayout>
            <WeightColumn>매장 좋아요 ({weightTitle[3]})</WeightColumn>
            <WeightData value={weightLS} onChange={weightLSOnChange} />
          </WeightTextLayout>
        </WeightLayout>

        <WeightButton onClick={weightSubmitListener}>SUBMIT</WeightButton>
      </WeightContainer>
    </Container>
  );
};

const Container = styled.div``;

const WeightContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const WeightLayout = styled.div``;

const WeightTextLayout = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const WeightColumn = styled.div`
  color: ${palette.fontDefault};
  font-size: 14px;
  width: 300px;
  height: 24px;
  font-weight: 500;
  vertical-align: middle;
  line-height: 24px;
`;

const WeightData = styled.input`
  margin-left: 10px;
  border: 1px solid ${palette.borderDefault};
  width: 60px;
  height: 24px;
  text-align: center;
  font-size: 16px;
  color: ${palette.fontBlack};
`;

const WeightButton = styled.div`
  width: 80px;
  height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${palette.buttonCancel};
  cursor: pointer;
  color: ${palette.fontWhite};
  font-size: 14px;
  margin-left: 40px;
  &:hover {
    background-color: ${palette.buttonOrange};
  }
`;

export default UserDetailSetKeywordWeight;
