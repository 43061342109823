import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { userAPI } from 'modules/api';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import UserDetailUserInfo from 'components/user/UserDetailUserInfo';
import UserDetailViewLike from 'components/user/UserDetailViewLike';
import UserDetailKeywordChart from 'components/user/UserDetailKeywordChart';
import UserDetailSetKeywordWeight from 'components/user/UserDetailSetKeywordWeight';
import UserDetailRecommend from 'components/user/UserDetailRecommend';

const UserDetailContainer = () => {
  const { id } = useParams();
  const [data, setData] = useState({
    user: null,
    viewlike: null,
    keyword: null,
    weight: null,
  });
  const [loadingState, setLoadingState] = useState({
    user: true,
    viewlike: true,
    keyword: true,
    weight: true,
  });

  useEffect(() => {
    if (loadingState.user) {
      userAPI.getUserSingle(id).then((response) => {
        setData((prevState) => ({ ...prevState, user: response }));
      });
    }
    if (loadingState.viewlike) {
      userAPI.getUserViewLike(id).then((response) => {
        setData((prevState) => ({ ...prevState, viewlike: response }));
      });
    }
    if (loadingState.keyword) {
      userAPI.getUserKeyword(id).then((response) => {
        setData((prevState) => ({ ...prevState, keyword: response }));
      });
    }
    if (loadingState.weight) {
      userAPI.getRecommendWeight().then((response) => {
        setData((prevState) => ({ ...prevState, weight: response }));
      });
    }
  }, [loadingState]);

  useEffect(() => {
    if (loadingState.user) {
      setLoadingState((prevState) => ({ ...prevState, user: false }));
    }
    if (loadingState.viewlike) {
      setLoadingState((prevState) => ({ ...prevState, viewlike: false }));
    }
    if (loadingState.keyword) {
      setLoadingState((prevState) => ({ ...prevState, keyword: false }));
    }
    if (loadingState.weight) {
      setLoadingState((prevState) => ({ ...prevState, weight: false }));
    }
  }, [data]);

  return (
    <Container>
      <LayoutWrapper>
        {!loadingState.user &&
        !loadingState.keyword &&
        !loadingState.weight &&
        !loadingState.viewlike ? (
          <>
            <LeftContainer>
              <ProfileTopContainer>
                {data.user !== null && (
                  <UserDetailUserInfo user_data={data.user} />
                )}
              </ProfileTopContainer>
              <LeftBottomContainer>
                <UserDetailViewLike viewlike_data={data.viewlike} />
              </LeftBottomContainer>
            </LeftContainer>
            <MiddleContainer>
              <MiddleTopContainer>
                {data.keyword !== null && (
                  <UserDetailKeywordChart keyword_data={data.keyword} />
                )}
              </MiddleTopContainer>
              <MiddleBottomContainer>
                <UserDetailSetKeywordWeight
                  weight_data={data.weight}
                  keyword_refetch={() =>
                    setLoadingState((prevState) => ({
                      ...prevState,
                      keyword: true,
                    }))
                  }
                  weight_refetch={() =>
                    setLoadingState((prevState) => ({
                      ...prevState,
                      weight: true,
                    }))
                  }
                  viewlike_refetch={() =>
                    setLoadingState((prevState) => ({
                      ...prevState,
                      viewlike: true,
                    }))
                  }
                />
              </MiddleBottomContainer>
            </MiddleContainer>
            <RightContainer>
              <UserDetailRecommend user_id={id} />
            </RightContainer>
          </>
        ) : (
          <LoadingContainer>
            <TailSpin color={palette.mainBlue} width={100} height={100} />
          </LoadingContainer>
        )}
      </LayoutWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 0px;
`;

const LayoutWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  height: calc(
    100vh - ${(props) => (props.fromBottom ? props.fromBottom : '80px')}
  );
  overflow: auto;
  .isMobile & {
    padding: 0;
  }
  display: flex;
`;
// ==========================================================================================

const LeftContainer = styled.div`
  width: 20%;
  height: 100%;
  margin-right: 1%;
`;

const ProfileTopContainer = styled.div`
  background-color: ${palette.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px;
  height: 66%;
  overflow: scroll;
`;

const LeftBottomContainer = styled.div`
  position: relative;
  background-color: ${palette.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: scroll;
  padding: 20px;
  height: 32%;
  margin-top: 16px;
`;
// ==========================================================================================
const MiddleContainer = styled.div`
  width: 54%;
  height: 100%;
  margin-right: 1%;
`;
const MiddleTopContainer = styled.div`
  background-color: ${palette.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  height: 66%;
  overflow: scroll;
`;

const MiddleBottomContainer = styled.div`
  background-color: ${palette.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  height: 32%;
  margin-top: 16px;
  overflow: scroll;
`;
// ==========================================================================================
const RightContainer = styled.div`
  width: 24%;
  background-color: ${palette.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  overflow: scroll;
`;
// ==========================================================================================

const LoadingContainer = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
export const TitleText = styled.div`
  font-size: 20px;
  font-weight: 900;
  margin-top: 4px;
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: solid 0.5px ${palette.borderLightGrey};
  color: ${palette.fontBlack};
`;

export default UserDetailContainer;
