export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

// export const phoneRegex = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
export const phoneRegex = /^[0-9\b -]{0,13}$/;
export const dateSlashRegex = /^[0-9\b -]{0,8}$/;
export const dateDotRegex = /^[0-9\b .]{0,8}$/;
export const dateDot422Regex = /^[0-9\b .]{0,10}$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&+=])[A-Za-z\d!@#$%^&+=]{8,24}$/;

export const dateRegex2 = /^[0-9\b .]{0,10}$/;
export const monthDateRegex = /^[0-9\b .]{0,5}$/;

export const dateRegex = RegExp(
  /^\d{4}\.(0[1-9]|1[012])\.(0[1-9]|[12][0-9]|3[01])$/
);
