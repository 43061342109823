import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const EventAPI = (() => {
  class EventAPIConstructor {
    getEventAll() {
      return httpAPI(apiPaths.eventBase, { method: 'GET' });
    }
    getEventSingle(id) {
      return httpAPI(apiPaths.eventBase + id, { method: 'GET' }).call;
    }
    registerEvent(data) {
      return httpAPI(apiPaths.registerEvent, { data }).call;
    }
    editEvent(data) {
      return httpAPI(apiPaths.editEvent, { data }).call;
    }
    editEventAnnounced(data) {
      return httpAPI(apiPaths.editEventAnnounced, { data }).call;
    }
    getEventImage(id) {
      return httpAPI(apiPaths.getEventImage + id, { method: 'GET' }).call;
    }
    uploadImage(data, headers) {
      return httpAPI(apiPaths.uploadEventImage, { data, headers }).call;
    }
    deleteImage(data) {
      return httpAPI(apiPaths.deleteEventImage, { data }).call;
    }
    pickEventWinner(data) {
      return httpAPI(apiPaths.pickEventWinner, { data }).call;
    }
    preprocessEventAnnounce(data) {
      return httpAPI(apiPaths.preprocessEventAnnounce, { data }).call;
    }
  }
  return EventAPIConstructor;
})();

export const eventAPI = new EventAPI();
