import { useState } from 'react';
import { curationAPI } from 'modules/api';

const useAttachCurated = (refetch) => {
  const [id, setId] = useState('');
  const [elementId, setElementId] = useState('');
  const [reason, setReason] = useState('');

  const elementIdHandler = (e) => {
    setElementId(e.target.value);
  };
  const reasonHandler = (e) => {
    setReason(e.target.value);
  };
  const attachSpaceCurated = async (e) => {
    e.preventDefault();
    let postData = {};
    postData.id = id;
    postData.element_id = elementId;
    postData.reason = reason;
    let error = false;
    try {
      await curationAPI.attachSpaceCurated(postData);
    } catch (e) {
      error = true;
    } finally {
      if (error) {
        alert(
          '큐레이션될 항목을 등록하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      setElementId('');
      setReason('');
      refetch();
    }
  };

  return {
    setId,
    elementId,
    reason,
    setReason,
    elementIdHandler,
    reasonHandler,
    attachSpaceCurated,
  };
};

export default useAttachCurated;
