import ProductListContainer from 'containers/store/ProductListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const ProductList = () => {
  return (
    <GlobalContainer>
      <ProductListContainer />
    </GlobalContainer>
  );
};

export default ProductList;
