import React from 'react';
import { palette } from 'modules/defines/styles';

const Store = ({ color = palette.iconDefault, size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <g fill={color} fillRule="nonzero">
          <path d="M16.62 5.173H3.525L2.74 6.839h14.666zM13.386 7.893a2.198 2.198 0 0 0 2.13 1.67 2.198 2.198 0 0 0 2.13-1.67h-4.26zM10.073 9.563a2.198 2.198 0 0 0 2.13-1.67h-4.26a2.198 2.198 0 0 0 2.13 1.67zM6.76 7.893H2.5a2.198 2.198 0 0 0 2.13 1.67 2.198 2.198 0 0 0 2.13-1.67z" />
          <path d="M12.794 9.14a3.249 3.249 0 0 1-2.721 1.478A3.248 3.248 0 0 1 7.35 9.14a3.249 3.249 0 0 1-3.716 1.322V18h3.636v-6.02h5.603V18h3.636v-7.538a3.248 3.248 0 0 1-3.716-1.321zM5.235 2h9.676v2.118H5.235z" />
          <path d="M8.326 13.034h3.493V18H8.326z" />
        </g>
      </g>
    </svg>
  );
};

export default Store;
