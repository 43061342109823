import { curationAPI } from 'modules/api';
import { icon_trashbin } from 'assets/img/icon';
import styled from 'styled-components';

const TrashbinIcon = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(${icon_trashbin});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

const CuratedDeleteButton = ({ curatedId, curationId, curatedRefetch }) => {
  const onClick = async () => {
    const postData = {
      id: curationId,
      element_id: curatedId,
    };
    let error = false;
    try {
      await curationAPI.detachSpaceCurated(postData);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '큐레이션 항목을 삭제하는데 실패했습니다. 관리자에게 문의하세요.'
        );
      }
      curatedRefetch();
    }
  };
  return <TrashbinIcon onClick={onClick} />;
};

export default CuratedDeleteButton;
