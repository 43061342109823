import CurationDetailContainer from 'containers/curation/CurationDetailContainer';
import GlobalContainer from 'containers/GlobalContainer';

const CurationDetail = () => {
  return (
    <GlobalContainer>
      <CurationDetailContainer />
    </GlobalContainer>
  );
};

export default CurationDetail;
