const Coffee = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M7.1987 13.1191C7.58539 13.0549 7.95111 13.3152 8.01556 13.7006L9.09973 20.1831H13.8123L14.8964 13.7006C14.9609 13.3152 15.3266 13.0549 15.7133 13.1191C16.1 13.1833 16.3612 13.5478 16.2968 13.9331L15.1137 21.0067C15.0567 21.3478 14.7606 21.5978 14.4136 21.5978H8.49842C8.15144 21.5978 7.85531 21.3478 7.79827 21.0067L6.61524 13.9331C6.55079 13.5478 6.81202 13.1833 7.1987 13.1191Z"
        fill="#1E1E1E"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M7.86358 8.6558C7.98381 8.41189 8.2296 8.25781 8.49845 8.25781H14.4135C14.6824 8.25781 14.9282 8.41189 15.0484 8.6558L16.2314 11.0557C16.4067 11.4114 16.2646 11.8438 15.914 12.0216C15.5634 12.1995 15.137 12.0553 14.9617 11.6997L13.9749 9.69775H8.93714L7.95031 11.6997C7.77499 12.0553 7.34863 12.1995 6.998 12.0216C6.64737 11.8438 6.50525 11.4114 6.68056 11.0557L7.86358 8.6558Z"
        fill="#1E1E1E"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M6 11.4036C6 11.0059 6.31867 10.6836 6.71176 10.6836H16.2019C16.595 10.6836 16.9137 11.0059 16.9137 11.4036V13.8036C16.9137 14.2012 16.595 14.5236 16.2019 14.5236H6.71176C6.31867 14.5236 6 14.2012 6 13.8036V11.4036ZM7.42352 12.1236V13.0836H15.4902V12.1236H7.42352Z"
        fill="#1E1E1E"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M9.7779 2.57461C10.0757 2.31365 10.5262 2.34638 10.7842 2.64773C11.1804 3.11066 11.2957 3.61453 11.2437 4.10018C11.1997 4.51177 11.033 4.9003 10.9149 5.17572C10.9097 5.18789 10.9046 5.19984 10.8995 5.21156C10.7607 5.53609 10.6829 5.73296 10.6645 5.90469C10.6522 6.02053 10.6656 6.11817 10.7842 6.25673C11.0421 6.55808 11.0097 7.01393 10.7119 7.27489C10.4141 7.53586 9.96355 7.50313 9.70564 7.20178C9.30941 6.73884 9.19413 6.23498 9.24609 5.74932C9.29012 5.33773 9.45677 4.94921 9.5749 4.67379C9.58012 4.66162 9.58525 4.64967 9.59026 4.63795C9.72914 4.31342 9.80688 4.11654 9.82526 3.94482C9.83765 3.82897 9.82423 3.73134 9.70564 3.59277C9.44772 3.29143 9.48007 2.83558 9.7779 2.57461Z"
        fill="#1E1E1E"
      />
      <path
        d="M12.6709 4.21875C13.371 5.02758 11.9707 5.83519 12.6709 6.64401Z"
        fill="#1E1E1E"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M12.0861 3.81373C12.3601 3.5362 12.7988 3.54209 13.0658 3.82687C13.4016 4.18493 13.5171 4.60246 13.4685 5.0166C13.4306 5.34003 13.2904 5.63705 13.2068 5.81443C13.2018 5.82503 13.197 5.83521 13.1924 5.84494C13.092 6.05894 13.0571 6.14842 13.0478 6.2059C13.0519 6.2113 13.0578 6.21829 13.0658 6.22687C13.3329 6.51165 13.3272 6.9675 13.0532 7.24502C12.7791 7.52255 12.3405 7.51666 12.0734 7.23188C11.7377 6.87382 11.6222 6.45629 11.6708 6.04215C11.7087 5.71872 11.8488 5.4217 11.9325 5.24432C11.9375 5.23372 11.9423 5.22354 11.9469 5.21381C12.0473 4.99981 12.0821 4.91033 12.0915 4.85285C12.0873 4.84745 12.0815 4.84046 12.0734 4.83188C11.8064 4.5471 11.812 4.09125 12.0861 3.81373Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};
export default Coffee;
