import DocumentCreateContainer from 'containers/document/DocumentCreateContainer';
import GlobalContainer from 'containers/GlobalContainer';

const DocumentCreate = () => {
  return (
    <GlobalContainer>
      <DocumentCreateContainer />
    </GlobalContainer>
  );
};

export default DocumentCreate;
