import { dashboardAPI } from 'modules/api';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { palette } from 'modules/defines/styles';
import {
  Container,
  LayoutWrapper,
  CommonContainer,
  CommonHeader,
  CommonTitle,
  DateCollectorWrapper,
  CommonContents,
  LayoutNoneWrapper,
  NoneTitle,
  NoneDesc,
  DashboardDatePicker,
  DateCollectorBtn,
  CommonContentsSubHeader,
  ViewListContainer,
  ViewListHeader,
  ViewListContents,
  ViewListItemContainer,
  ViewListItemId,
  ViewListItemTitle,
  ViewListItemView,
  DateAllCollectorBtn,
} from 'components/dashboard/DashboardCommon';
import { formatDate, getMonthBefore } from 'utils/date';
import { pagePaths } from 'modules/defines/paths';

const DashboardAppContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(getMonthBefore(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [dashboardView, setDashboardView] = useState();
  const [dataViewLoading, setDataViewLoading] = useState(false);

  useEffect(() => {
    if (!loading) return;
    let isMounted = true;
    const queryString =
      '?time_start=' +
      formatDate(startDate) +
      '&time_end=' +
      formatDate(endDate);
    const { call, cancel } = dashboardAPI.getDashboardView(queryString);

    call
      .then((response) => {
        if (isMounted) {
          setDashboardView(response);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    return () => {
      isMounted = false;
      cancel();
    };
  }, [loading, startDate, endDate, setDashboardView, setLoading]);

  const changeDateListener = async (e) => {
    setDataViewLoading(true);
    setLoading(true);
    setDataViewLoading(false);
  };

  const changeDateAllListener = async (e) => {
    // 모든 데이터를 보기 위한 버튼
    const startDateStr = new Date('2021-01-01');
    const endDateStr = new Date();
    setStartDate(startDateStr);
    setEndDate(endDateStr);
    setDataViewLoading(true);
    setLoading(true);
    setDataViewLoading(false);
  };

  const elementClickListener = (e, type, id) => {
    e.preventDefault();
    if (type === 'curation')
      history.push(pagePaths.curation.detail.replace(':id', id));
    else if (type === 'space')
      history.push(pagePaths.space.detail.replace(':id', id));
  };
  const testClickListener = () => {
    console.log(dataViewLoading);
    console.log(dashboardView);
  };

  return (
    <Container>
      {!loading ? (
        <LayoutWrapper>
          {/* 좌측 */}
          <CommonContainer>
            {/* 컨텐츠 업로드 */}
            <CommonHeader>
              <CommonTitle>컨텐츠 View (23.04.01~)</CommonTitle>
              <DateCollectorWrapper>
                <DashboardDatePicker
                  selected={startDate}
                  onChange={(startDate) => setStartDate(startDate)}
                  dateFormat="yyyy년 MM월 dd일"
                />
                <DashboardDatePicker
                  selected={endDate}
                  onChange={(endDate) => setEndDate(endDate)}
                  dateFormat="yyyy년 MM월 dd일"
                />
                <DateCollectorBtn onClick={changeDateListener}>
                  적용
                </DateCollectorBtn>
                <DateAllCollectorBtn onClick={changeDateAllListener}>
                  All
                </DateAllCollectorBtn>
              </DateCollectorWrapper>
            </CommonHeader>
            {!dataViewLoading ? (
              <>
                <CommonContents>
                  <CommonContentsSubHeader onClick={testClickListener}>
                    많이 조회한 공간
                  </CommonContentsSubHeader>
                  <ViewListContainer>
                    <ViewListHeader>
                      <ViewListItemId>ID</ViewListItemId>
                      <ViewListItemTitle>Title</ViewListItemTitle>
                      <ViewListItemView>View</ViewListItemView>
                    </ViewListHeader>
                    <ViewListContents>
                      {dashboardView?.spaces?.map((space) => {
                        return (
                          <ViewListItemContainer
                            onClick={(e) =>
                              elementClickListener(e, 'space', space.id)
                            }
                          >
                            <ViewListItemId>{space.id}</ViewListItemId>
                            <ViewListItemTitle>{space.title}</ViewListItemTitle>
                            <ViewListItemView>{space.view}</ViewListItemView>
                          </ViewListItemContainer>
                        );
                      })}
                    </ViewListContents>
                  </ViewListContainer>
                </CommonContents>
                <CommonContents>
                  <CommonContentsSubHeader>
                    많이 조회한 큐레이션
                  </CommonContentsSubHeader>
                  <ViewListContainer>
                    <ViewListHeader>
                      <ViewListItemId>ID</ViewListItemId>
                      <ViewListItemTitle>Title</ViewListItemTitle>
                      <ViewListItemView>View</ViewListItemView>
                    </ViewListHeader>
                    <ViewListContents>
                      {dashboardView?.curations?.map((curation) => {
                        return (
                          <ViewListItemContainer
                            onClick={(e) =>
                              elementClickListener(e, 'curation', curation.id)
                            }
                          >
                            <ViewListItemId>{curation.id}</ViewListItemId>
                            <ViewListItemTitle>
                              {curation.title}
                            </ViewListItemTitle>
                            <ViewListItemView>{curation.view}</ViewListItemView>
                          </ViewListItemContainer>
                        );
                      })}
                    </ViewListContents>
                  </ViewListContainer>
                </CommonContents>
              </>
            ) : (
              <>
                <LayoutNoneWrapper>
                  <TailSpin color={palette.mainBlue} width={50} height={50} />
                  <NoneDesc>
                    본 화면이 지속되면 관리자에게 문의해주세요.
                  </NoneDesc>
                </LayoutNoneWrapper>
              </>
            )}

            {/* 많이 사용한 유저 */}
            <CommonHeader>
              <CommonTitle>많이 사용한 유저</CommonTitle>
            </CommonHeader>
          </CommonContainer>
          {/* 우측 */}
          <CommonContainer>
            <CommonHeader>
              <CommonTitle>준비중 ...</CommonTitle>
            </CommonHeader>
            <CommonContents></CommonContents>
          </CommonContainer>
        </LayoutWrapper>
      ) : (
        <LayoutNoneWrapper>
          <NoneTitle>데이터 로딩중입니다...</NoneTitle>
          <NoneDesc>본 화면이 지속되면 관리자에게 문의해주세요.</NoneDesc>
        </LayoutNoneWrapper>
      )}
    </Container>
  );
};

export default DashboardAppContainer;
