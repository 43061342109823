import PushNotificationDetailContainer from 'containers/push/PushNotificationDetailContainer';
import GlobalContainer from 'containers/GlobalContainer';

const PushNotificationDetail = () => {
  return (
    <GlobalContainer>
      <PushNotificationDetailContainer />
    </GlobalContainer>
  );
};

export default PushNotificationDetail;
