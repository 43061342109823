import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { spaceAPI } from 'api';
import { filter_values } from 'modules/defines/values';

const SpaceDetailFilter = ({
  havePermission,
  s_id,
  filterAllLoading,
  filterAllData,
  filterAllRefetch,
  filterConnectedLoading,
  filterConnectedData,
  filterConnectedRefetch,
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const [checkedExtendedList, setCheckedExtendedList] = useState([
    { id: 0, elements: filter_values.parking, checkedId: 0 },
    { id: 2, elements: filter_values.pet, checkedId: 0 },
    { id: 5, elements: filter_values.kids, checkedId: 0 },
  ]);
  // 필터 데이터 초기화
  useEffect(() => {
    setCheckedList([]);
    filterConnectedData?.data.map((item) => {
      setCheckedList((checkedList) => [...checkedList, item.id]);
      if (item.id === 0 || item.id === 2 || item.id === 5) {
        let newArr = [...checkedExtendedList];
        if (item.SpaceFilter.type === null) {
          newArr.find((data) => {
            return data.id === item.id;
          }).checkedId = 0;
        } else {
          newArr.find((data) => {
            return data.id === item.id;
          }).checkedId = newArr
            .find((data) => {
              return data.id === item.id;
            })
            .elements.indexOf(item.SpaceFilter.type);
        }
        setCheckedExtendedList(newArr);
      }
    });
  }, [filterConnectedData]);

  // Extended 리스트 불러오기 (from id)
  const getCheckedExtendedList = (id) => {
    return checkedExtendedList.find((data) => {
      return data.id === id;
    });
  };

  const onCheckedListener = useCallback(
    async (checked, item) => {
      if (checked) {
        if (item.id === 0 || item.id === 2 || item.id === 5) {
          if (havePermission) {
            await addFilter(
              item.id,
              checkedExtendedList.find((data) => data.id === item.id)
                .elements[0]
            );
          } else {
            alert('권한이 없습니다.');
          }
        } else {
          if (havePermission) {
            await addFilter(item.id);
          } else {
            alert('권한이 없습니다.');
          }
        }
      } else {
        if (havePermission) {
          await removeFilter(item.id);
        } else {
          alert('권한이 없습니다.');
        }
      }
    },
    [checkedList, havePermission]
  );

  const onCheckedExtendedListener = useCallback(
    async (checked, itemId, checkedId) => {
      if (checked) {
        if (havePermission) {
          await addFilter(
            itemId,
            checkedExtendedList.find((data) => data.id === itemId).elements[
              checkedId
            ]
          );
        } else {
          alert('권한이 없습니다.');
        }
      }
    },
    [checkedExtendedList, havePermission]
  );

  const addFilter = async (filterId, filterDetail) => {
    try {
      const postData = {
        space_id: s_id,
        filter_id: filterId,
      };
      if (filterDetail) {
        postData['filter_detail'] = filterDetail;
      }
      await spaceAPI.addFilter(postData);
    } catch (err) {
      console.error(err);
      alert('필터 업데이트 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
      return;
    } finally {
      filterConnectedRefetch();
    }
  };

  const removeFilter = async (filterId) => {
    try {
      const postData = {
        space_id: s_id,
        filter_id: filterId,
      };
      await spaceAPI.removeFilter(postData);
    } catch (err) {
      console.error(err);
      alert('필터 업데이트 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
      return;
    } finally {
      filterConnectedRefetch();
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <ContainerTitle>매장 필터 (Filter)</ContainerTitle>
      </HeaderContainer>
      <ContentsContainer>
        {!filterAllLoading &&
          !filterConnectedLoading &&
          filterAllData?.map((item) => {
            return (
              <CheckBoxWrapper key={item.id}>
                <CheckBoxItemWrapper>
                  <CheckBox
                    type="checkbox"
                    onChange={(e) => onCheckedListener(e.target.checked, item)}
                    checked={checkedList.includes(item.id) ? true : false}
                  />
                  <CheckBoxTitle>{item.title}</CheckBoxTitle>
                </CheckBoxItemWrapper>
                <CheckBoxExtendedWrapper>
                  {getCheckedExtendedList(item.id) !== undefined &&
                    checkedList.includes(item.id) &&
                    getCheckedExtendedList(item.id).elements.map(
                      (itemExtended, index) => {
                        return (
                          <CheckBoxItemWrapper key={itemExtended}>
                            <CheckBox
                              type="radio"
                              onChange={(e) =>
                                onCheckedExtendedListener(
                                  e.target.checked,
                                  item.id,
                                  index
                                )
                              }
                              checked={
                                getCheckedExtendedList(item.id).checkedId ===
                                index
                              }
                            />
                            <CheckBoxTitle>{itemExtended}</CheckBoxTitle>
                          </CheckBoxItemWrapper>
                        );
                      }
                    )}
                </CheckBoxExtendedWrapper>
              </CheckBoxWrapper>
            );
          })}
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerTitle = styled.div`
  font-size: 18px;
  font-family: Pretendard-b;
  color: ${palette.fontDefault};
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentsContainer = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;
const CheckBoxItemWrapper = styled.div`
  display: flex;
`;

const CheckBoxExtendedWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  flex-direction: column;
`;
const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const CheckBoxTitle = styled.div`
  font-size: 12px;
  line-height: 24px;
  color: ${palette.fontDefault};
`;
const SubmitButton = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${palette.fontOrange};
  background-color: ${palette.white};
  border: 1px solid ${palette.mainOrange};
  border-radius: 16px;
  padding: 4px 8px 4px 8px;
  width: 60px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainOrangeHover};
    color: ${palette.white};
  }
`;

export default SpaceDetailFilter;
