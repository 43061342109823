import styled, { css } from 'styled-components';
import { keywordAPI } from 'modules/api';
import { icon_trashbin } from 'assets/img/icon';
import { palette } from 'modules/defines/styles';

const TrashbinButton = styled.button`
  all: unset;
  ${(props) =>
    props.btnText
      ? css`
        background-color: ${palette.mainOrange};
        &:hover{
          background-color: ${palette.mainOrangeHover};
        }
        border-radius: 6px;
        height: 20px;
        color: ${palette.fontWhite};
        font-size: 10px;
        padding: 0px 10px;
        font-family: Pretendard-m;
          `
      : css`
        background-image: url(${icon_trashbin});
        width: 24px;
        height: 24px;
        object-fit: contain;

      `
  }
  cursor: pointer;
`;

const DeleteKeywordButton = ({ selectedKeyword, setSelectedKeyword, refetch, btnText = null }) => {
  const onClick = async (e) => {
    e.preventDefault();
    if (!window.confirm('키워드가 삭제되면 키워드에 속한 공간과 큐레이션이 모두 키워드와 분리됩니다. 삭제하시겠습니까?')) {
      return;
    }
    try {
      await keywordAPI.deleteKeyword(selectedKeyword.id);
      alert(
        '키워드가 삭제되었습니다.'
      );
      setSelectedKeyword(null);
    } catch {
      alert(
        '키워드를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    } finally {
      refetch();
    }
  };
  return <TrashbinButton btnText={btnText} onClick={onClick}>{btnText}</TrashbinButton>;
};

export default DeleteKeywordButton;
