import styled from 'styled-components';
import { btn_keywordlist_add } from 'assets/img/button';

const AddButton = styled.button`
  all: unset;
  justify-self: end;
  margin-right: 12px;
  width: 26px;
  height: 26px;
  object-fit: contain;
  background-image: url(${btn_keywordlist_add});
`;

const _AddButton = ({ onClick, refetch }) => {
  const submitWithRefetch = async (e) => {
    e.preventDefault();
    await onClick();
    refetch();
  };
  return <AddButton onClick={submitWithRefetch} />;
};

export default _AddButton;
