import { palette } from 'modules/defines/styles';
import { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import styled from 'styled-components';
import { TitleText } from 'containers/user/UserDetailContainer';
import { SelectRadioBtn } from 'components/button/RadioButton';

const UserDetailKeywordChart = ({ keyword_data }) => {
  const [keywordData, setKeywordData] = useState(null);
  const [keywordChartMode, setKeywordChartMode] = useState('all');
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        type: 'bar',
        label: '키워드 별 점수',
        backgroundColor: `${palette.mainBlue}`,
        borderColor: `${palette.mainBlue}`,
        borderWidth: 1,
        data: [],
      },
    ],
  });
  // GET 키워드 (keywordData)
  useEffect(() => {
    setKeywordData(keyword_data);
  }, [keyword_data]);

  useEffect(() => {
    if (keywordData != null) {
      var updatedChartData = {
        ...chartData,
        labels: [],
        datasets: [
          {
            ...chartData.datasets[0],
            data: [],
          },
        ],
      };
      keywordData.forEach((item) => {
        updatedChartData.labels.push(item.title);
        updatedChartData.datasets[0].data.push(item.weight);
      });

      setChartData(updatedChartData);
    }
  }, [keywordData]);

  useEffect(() => {
    let keywordAll = keyword_data;
    let keywordProduct = keywordAll.filter((data) => data.std === 'product');
    let keywordVibe = keywordAll.filter((data) => data.std === 'vibe');
    if (keywordChartMode === 'all') {
      setKeywordData(keywordAll);
    } else if (keywordChartMode === 'product') {
      setKeywordData(keywordProduct);
    } else if (keywordChartMode === 'vibe') {
      setKeywordData(keywordVibe);
    }
  }, [keywordChartMode]);

  return (
    <Container>
      <HeaderContainer>
        <TitleText>키워드 분석</TitleText>
        <RadioButtonContainer>
          <SelectRadioBtn
            select={keywordChartMode === 'all'}
            onClick={() => setKeywordChartMode('all')}
            label="All"
          />
          <SelectRadioBtn
            select={keywordChartMode === 'product'}
            onClick={() => setKeywordChartMode('product')}
            label="Product"
          />
          <SelectRadioBtn
            select={keywordChartMode === 'vibe'}
            onClick={() => setKeywordChartMode('vibe')}
            label="Vibe"
          />
        </RadioButtonContainer>
      </HeaderContainer>
      <ChartContainer>
        <Chart type="line" data={chartData} />
      </ChartContainer>
    </Container>
  );
};

const Container = styled.div``;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 170px;
  justify-content: space-around;
`;
const ChartContainer = styled.div`
  margin-top: 12px;
`;

export default UserDetailKeywordChart;
