import KeywordManagementContainer from 'containers/keyword/KeywordManagementContainer';
import GlobalContainer from 'containers/GlobalContainer';

const KeywordManagement = () => {
  return (
    <GlobalContainer>
      <KeywordManagementContainer />
    </GlobalContainer>
  );
};

export default KeywordManagement;
