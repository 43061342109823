import NotificationDetailContainer from 'containers/notification/NotificationDetailContainer';
import GlobalContainer from 'containers/GlobalContainer';

const NotificationDetail = () => {
  return (
    <GlobalContainer>
      <NotificationDetailContainer />
    </GlobalContainer>
  );
};

export default NotificationDetail;
