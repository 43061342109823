import { palette } from 'modules/defines/styles';
import { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useInputState from 'modules/customHooks/useInputState';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { time_values } from 'modules/defines/values';

const ColumnItems = ({
  num,
  checkedTime,
  OnClickCheckBox,
  timeStart,
  timeEnd,
  setTimeStart,
  setTimeEnd,
  numBT,
  timeBTStart,
  timeBTEnd,
  setTimeBTStart,
  setTimeBTEnd,
  numLO,
  timeLO,
  setTimeLO,
  AddBT,
  AddLO,
  DeleteBT,
  DeleteLO,
}) => {
  const TimePickerBTStart1Handler = (newValue) => {
    let newArr = [...timeBTStart];
    newArr[0] = newValue;
    setTimeBTStart(newArr);
  };
  const TimePickerBTEnd1Handler = (newValue) => {
    let newArr = [...timeBTEnd];
    newArr[0] = newValue;
    setTimeBTEnd(newArr);
  };
  const TimePickerBTStart2Handler = (newValue) => {
    let newArr = [...timeBTStart];
    newArr[1] = newValue;
    setTimeBTStart(newArr);
  };
  const TimePickerBTEnd2Handler = (newValue) => {
    let newArr = [...timeBTEnd];
    newArr[1] = newValue;
    setTimeBTEnd(newArr);
  };
  const TimePickerLO1Handler = (newValue) => {
    let newArr = [...timeLO];
    newArr[0] = newValue;
    setTimeLO(newArr);
  };
  const TimePickerLO2Handler = (newValue) => {
    let newArr = [...timeLO];
    newArr[1] = newValue;
    setTimeLO(newArr);
  };
  return (
    <ColumnContents>
      <CheckBox
        num={num}
        items={time_values.open}
        checkedTime={checkedTime}
        onChecked={OnClickCheckBox}
      />
      <Desc>영업 시작</Desc>
      <TimePicker
        value={timeStart}
        onChange={setTimeStart}
        renderInput={(params) => <TextField {...params} />}
      />
      <Desc>영업 종료</Desc>
      <TimePicker
        value={timeEnd}
        onChange={setTimeEnd}
        renderInput={(params) => <TextField {...params} />}
      />
      {numBT.map((numbt, index) => {
        return (
          <>
            {numbt === 1 && (
              <>
                <Desc>브레이크 타임{numbt} 시작</Desc>
                <TimePicker
                  value={timeBTStart[numbt - 1]}
                  onChange={TimePickerBTStart1Handler}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Desc>브레이크 타임{numbt} 종료</Desc>
                <TimePicker
                  value={timeBTEnd[numbt - 1]}
                  onChange={TimePickerBTEnd1Handler}
                  renderInput={(params) => <TextField {...params} />}
                />
              </>
            )}
            {numbt === 2 && (
              <>
                <Desc>브레이크 타임{numbt} 시작</Desc>
                <TimePicker
                  value={timeBTStart[numbt - 1]}
                  onChange={TimePickerBTStart2Handler}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Desc>브레이크 타임{numbt} 종료</Desc>
                <TimePicker
                  value={timeBTEnd[numbt - 1]}
                  onChange={TimePickerBTEnd2Handler}
                  renderInput={(params) => <TextField {...params} />}
                />
              </>
            )}
          </>
        );
      })}
      {numLO.map((numlo, index) => {
        return (
          <>
            {numlo === 1 && (
              <>
                <Desc>라스트오더 {numlo}</Desc>
                <TimePicker
                  value={timeLO[numlo - 1]}
                  onChange={TimePickerLO1Handler}
                  renderInput={(params) => <TextField {...params} />}
                />
              </>
            )}
            {numlo === 2 && (
              <>
                <Desc>라스트오더 {numlo}</Desc>
                <TimePicker
                  value={timeLO[numlo - 1]}
                  onChange={TimePickerLO2Handler}
                  renderInput={(params) => <TextField {...params} />}
                />
              </>
            )}
          </>
        );
      })}
      <BTLOButtonContainer>
        <BTLOButton
          onClick={() => AddBT(num)}
          hoverColor={palette.buttonCancel}
          fontColor={palette.fontBlack}
        >
          + 브레이크 타임
        </BTLOButton>
        <BTLOButton
          onClick={() => AddLO(num)}
          hoverColor={palette.buttonCancel}
        >
          + 라스트오더
        </BTLOButton>
      </BTLOButtonContainer>
      <BTLOButtonContainer>
        <BTLOButton
          onClick={() => DeleteBT(num)}
          hoverColor={palette.buttonDelete}
        >
          - 브레이크 타임
        </BTLOButton>

        <BTLOButton
          onClick={() => DeleteLO(num)}
          hoverColor={palette.buttonDelete}
        >
          - 라스트오더
        </BTLOButton>
      </BTLOButtonContainer>
    </ColumnContents>
  );
};

const CheckBox = ({ num, items, checkedTime, onChecked }) => {
  return (
    <CheckboxContainer>
      {items.map((item, index) => {
        return (
          <CheckboxWrapper>
            <CheckBoxTitle>{item}</CheckBoxTitle>
            <CheckBoxTime
              type="checkbox"
              onChange={() => onChecked(num, index)}
              checked={checkedTime[index]}
            />
          </CheckboxWrapper>
        );
      })}
    </CheckboxContainer>
  );
};

const TimeSelectModal = ({ closeModal, setTime }) => {
  const [typeIsSelect, setTypeIsSelect] = useState(true);
  const [columnNum, setColumnNum] = useState([1]);
  const [checkedClosed, setCheckedClosed] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [checkedTime1, setCheckedTime1] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [checkedTime2, setCheckedTime2] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [checkedTime3, setCheckedTime3] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  // Time Picker
  const [timeStart1, setTimeStart1] = useState(null);
  const [timeStart2, setTimeStart2] = useState(null);
  const [timeStart3, setTimeStart3] = useState(null);
  const [timeEnd1, setTimeEnd1] = useState(null);
  const [timeEnd2, setTimeEnd2] = useState(null);
  const [timeEnd3, setTimeEnd3] = useState(null);
  const [numBT1, setNumBT1] = useState([]);
  const [numBT2, setNumBT2] = useState([]);
  const [numBT3, setNumBT3] = useState([]);
  const [numLO1, setNumLO1] = useState([]);
  const [numLO2, setNumLO2] = useState([]);
  const [numLO3, setNumLO3] = useState([]);
  const [timeBTStart1, setTimeBTStart1] = useState([]);
  const [timeBTStart2, setTimeBTStart2] = useState([]);
  const [timeBTStart3, setTimeBTStart3] = useState([]);
  const [timeBTEnd1, setTimeBTEnd1] = useState([]);
  const [timeBTEnd2, setTimeBTEnd2] = useState([]);
  const [timeBTEnd3, setTimeBTEnd3] = useState([]);
  const [timeLO1, setTimeLO1] = useState([]);
  const [timeLO2, setTimeLO2] = useState([]);
  const [timeLO3, setTimeLO3] = useState([]);

  const {
    state: customText,
    setState: setCustomText,
    onChange: customTextOnChange,
  } = useInputState();

  // Set Default Value
  useEffect(() => {
    setTimeStart1(dayjs('2022-11-07T10:00'));
    setTimeEnd1(dayjs('2022-11-07T18:00'));
    setTimeStart2(dayjs('2022-11-07T10:00'));
    setTimeEnd2(dayjs('2022-11-07T18:00'));
    setTimeStart3(dayjs('2022-11-07T10:00'));
    setTimeEnd3(dayjs('2022-11-07T18:00'));
    setTimeBTStart1([
      dayjs('2022-11-07T15:00'),
      dayjs('2022-11-07T15:00'),
      dayjs('2022-11-07T15:00'),
    ]);
    setTimeBTStart2([
      dayjs('2022-11-07T15:00'),
      dayjs('2022-11-07T15:00'),
      dayjs('2022-11-07T15:00'),
    ]);
    setTimeBTStart3([
      dayjs('2022-11-07T15:00'),
      dayjs('2022-11-07T15:00'),
      dayjs('2022-11-07T15:00'),
    ]);
    setTimeBTEnd1([
      dayjs('2022-11-07T17:00'),
      dayjs('2022-11-07T17:00'),
      dayjs('2022-11-07T17:00'),
    ]);
    setTimeBTEnd2([
      dayjs('2022-11-07T17:00'),
      dayjs('2022-11-07T17:00'),
      dayjs('2022-11-07T17:00'),
    ]);
    setTimeBTEnd3([
      dayjs('2022-11-07T17:00'),
      dayjs('2022-11-07T17:00'),
      dayjs('2022-11-07T17:00'),
    ]);
    setTimeLO1([
      dayjs('2022-11-07T20:00'),
      dayjs('2022-11-07T20:00'),
      dayjs('2022-11-07T20:00'),
    ]);
    setTimeLO2([
      dayjs('2022-11-07T20:00'),
      dayjs('2022-11-07T20:00'),
      dayjs('2022-11-07T20:00'),
    ]);
    setTimeLO3([
      dayjs('2022-11-07T20:00'),
      dayjs('2022-11-07T20:00'),
      dayjs('2022-11-07T20:00'),
    ]);
  }, []);

  const AddTimeButtonListener = () => {
    if ([...columnNum].pop() < 3) {
      setColumnNum([...columnNum, columnNum.length + 1]);
    } else {
      alert('더이상 추가할 수 없습니다.');
    }
  };
  const DeleteTimeButtonListener = () => {
    if ([...columnNum].pop() !== 1) {
      const newArr = [...columnNum].filter(
        (num) => num !== [...columnNum].pop()
      );
      setColumnNum(newArr);
    } else {
      alert('적어도 하나의 영업시간이 필요합니다.');
    }
  };
  const AddBT = (num) => {
    if (num === 1) {
      if (numBT1.length < 2) {
        setNumBT1([...numBT1, numBT1.length + 1]);
        setTimeBTStart1([...timeBTStart1, '']);
        setTimeBTEnd1([...timeBTEnd1, '']);
      } else {
        alert('더이상 추가할 수 없습니다.');
      }
    } else if (num === 2) {
      if (numBT2.length < 2) {
        setNumBT2([...numBT2, numBT2.length + 1]);
        setTimeBTStart2([...timeBTStart2, '']);
        setTimeBTEnd2([...timeBTEnd2, '']);
      } else {
        alert('더이상 추가할 수 없습니다.');
      }
    } else if (num === 3) {
      if (numBT3.length < 2) {
        setNumBT3([...numBT3, numBT3.length + 1]);
        setTimeBTStart3([...timeBTStart3, '']);
        setTimeBTEnd3([...timeBTEnd3, '']);
      } else {
        alert('더이상 추가할 수 없습니다.');
      }
    }
  };
  const AddLO = (num) => {
    if (num === 1) {
      if (numLO1.length < 2) {
        setNumLO1([...numLO1, numLO1.length + 1]);
        setTimeLO1([...timeLO1, '']);
      } else {
        alert('더이상 추가할 수 없습니다.');
      }
    } else if (num === 2) {
      if (numLO2.length < 2) {
        setNumLO2([...numLO2, numLO2.length + 1]);
        setTimeLO2([...timeLO2, '']);
      } else {
        alert('더이상 추가할 수 없습니다.');
      }
    } else if (num === 3) {
      if (numLO3.length < 2) {
        setNumLO3([...numLO3, numLO3.length + 1]);
        setTimeLO3([...timeLO3, '']);
      } else {
        alert('더이상 추가할 수 없습니다.');
      }
    }
  };
  const DeleteBT = (num) => {
    if (num === 1) {
      if ([...numBT1].length !== 0) {
        setNumBT1([...numBT1].filter((num) => num !== [...numBT1].pop()));
        setTimeBTStart1([
          ...timeBTStart1.filter((num) => num !== [...timeBTStart1].pop()),
        ]);
      }
    } else if (num === 2) {
      if ([...numBT2].length !== 0) {
        const newArr = [...numBT2].filter((num) => num !== [...numBT2].pop());
        setNumBT2(newArr);
        setTimeBTStart2([
          ...timeBTStart2.filter((num) => num !== [...timeBTStart2].pop()),
        ]);
      }
    } else if (num === 3) {
      if ([...numBT3].length !== 0) {
        const newArr = [...numBT3].filter((num) => num !== [...numBT3].pop());
        setNumBT3(newArr);
        setTimeBTStart3([
          ...timeBTStart3.filter((num) => num !== [...timeBTStart3].pop()),
        ]);
      }
    }
  };
  const DeleteLO = (num) => {
    if (num === 1) {
      if ([...numLO1].length !== 0) {
        const newArr = [...numLO1].filter((num) => num !== [...numLO1].pop());
        setNumLO1(newArr);
      }
    } else if (num === 2) {
      if ([...numLO2].length !== 0) {
        const newArr = [...numLO2].filter((num) => num !== [...numLO2].pop());
        setNumLO2(newArr);
      }
    } else if (num === 3) {
      if ([...numLO3].length !== 0) {
        const newArr = [...numLO3].filter((num) => num !== [...numLO3].pop());
        setNumLO3(newArr);
      }
    }
  };
  const checkedClosedTimeAlg = (closedTime, index) => {
    let newArr = [...closedTime];
    newArr[index] = ![...closedTime][index];
    if (index !== 0 && [...closedTime][0] === true) {
      newArr[0] = false;
    }
    if (index === 0) {
      newArr = [true, false, false, false, false, false, false, false];
    }
    return newArr;
  };
  const checkedTimeAlg = (checkedTime, index) => {
    let newArr = [...checkedTime];
    newArr[index] = ![...checkedTime][index];
    if (index === 0 && [...checkedTime][0] === false) {
      newArr = [true, true, true, true, true, true, true, true];
    }
    if (index === 0 && [...checkedTime][0] === true) {
      newArr = [false, false, false, false, false, false, false, false];
    }
    if (index !== 0 && [...checkedTime][0] === true) {
      newArr[0] = false;
    }
    if (
      newArr[1] === true &&
      newArr[2] === true &&
      newArr[3] === true &&
      newArr[4] === true &&
      newArr[5] === true &&
      newArr[6] === true &&
      newArr[7] === true
    ) {
      newArr[0] = true;
    }
    return newArr;
  };
  const OnClickCheckBox = (num, index) => {
    if (num === 0) {
      setCheckedClosed(checkedClosedTimeAlg(checkedClosed, index));
      setCheckedTime1(checkedTimeAlg(checkedTime1, index));
    } else if (num === 1) {
      setCheckedTime1(checkedTimeAlg(checkedTime1, index));
    } else if (num === 2) {
      setCheckedTime2(checkedTimeAlg(checkedTime2, index));
    } else if (num === 3) {
      setCheckedTime3(checkedTimeAlg(checkedTime3, index));
    }
  };
  const TestClickListener = () => {
    console.log(timeStart1);
    console.log(timeEnd1);
    console.log(timeBTStart1);
    console.log(timeBTEnd1);
    console.log(timeLO1);
  };

  const AddZero = (time) => {
    if ((time *= 1) < 10) time = '0' + time;
    time += '';
    return time;
  };

  const MakeBuffer = (
    checkedTime,
    timeStart,
    timeEnd,
    numBT,
    timeBTStart,
    timeBTEnd,
    numLO,
    timeLO
  ) => {
    let buffer = '';
    // == 1. 요일 ==
    let validEmpty = 0;
    let allDay = true;
    for (var i = 0; i < checkedTime.length; i++) {
      validEmpty += checkedTime[i];
      if (i === 0 && checkedTime[0]) {
        buffer += '매일';
        break;
      } else {
        allDay = false;
        if (checkedTime[i]) {
          buffer += time_values.open[i] + ',';
        }
      }
    }
    if (!allDay) {
      buffer = buffer.slice(0, -1);
    }
    buffer += ' : ';
    if (validEmpty === 0) {
      // setValidDay(false);
    }
    // == 2. 영업시간 ==
    if (timeStart === null || timeEnd === null) {
      // setValidTime(false);
    } else {
      buffer +=
        AddZero(timeStart.$H) +
        ':' +
        AddZero(timeStart.$m) +
        '~' +
        AddZero(timeEnd.$H) +
        ':' +
        AddZero(timeEnd.$m);
    }
    // == 3. BT ==
    numBT.map((idxBT, idx) => {
      if (numBT.length === 1) {
        buffer += '\n· 브레이크타임 : ';
      } else {
        buffer += '\n· 브레이크타임' + idxBT + ' : ';
      }
      buffer +=
        AddZero(timeBTStart[idx].$H) +
        ':' +
        AddZero(timeBTStart[idx].$m) +
        '~' +
        AddZero(timeBTEnd[idx].$H) +
        ':' +
        AddZero(timeBTEnd[idx].$m);
    });

    // == 4. LO ==
    numLO.map((idxLO, idx) => {
      if (numLO.length === 1) {
        buffer += '\n· 라스트오더 : ';
      } else {
        buffer += '\n· 라스트오더' + idxLO + ' : ';
      }
      buffer += AddZero(timeLO[idx].$H) + ':' + AddZero(timeLO[idx].$m);
    });
    return buffer;
  };

  const SubmitListener = () => {
    let buffer = '';
    if (typeIsSelect) {
      columnNum.map((idx) => {
        if (idx === 1) {
          buffer += MakeBuffer(
            checkedTime1,
            timeStart1,
            timeEnd1,
            numBT1,
            timeBTStart1,
            timeBTEnd1,
            numLO1,
            timeLO1
          );
        }
        if (idx === 2) {
          buffer += '\n';
          buffer += MakeBuffer(
            checkedTime2,
            timeStart2,
            timeEnd2,
            numBT2,
            timeBTStart2,
            timeBTEnd2,
            numLO2,
            timeLO2
          );
        }
        if (idx === 3) {
          buffer += '\n';
          buffer += MakeBuffer(
            checkedTime3,
            timeStart3,
            timeEnd3,
            numBT3,
            timeBTStart3,
            timeBTEnd3,
            numLO3,
            timeLO3
          );
        }
      });
      let existClosed = false;
      for (var i = 0; i < checkedClosed.length; i++) {
        if (i === 0 && checkedClosed[0]) {
          break;
        } else {
          if (i === 0) {
            buffer += '\n휴무일 : ';
          }
          existClosed = true;
          if (checkedClosed[i]) {
            buffer += time_values.closed[i] + ',';
          }
        }
      }
      if (existClosed) {
        buffer = buffer.slice(0, -1);
      }
      setTime(buffer);
    } else {
      setTime(customText);
    }
    closeModal();
  };
  // set 'ESC' keystroke to escape
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const CancelListener = () => {
    closeModal();
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Wrapper>
        <TitleWrapper>
          <TitleContainer>
            <Title onClick={TestClickListener}>영업시간 선택</Title>
            <TypeButton
              selected={typeIsSelect}
              onClick={() => setTypeIsSelect(true)}
            >
              선택
            </TypeButton>
            <TypeButton
              selected={!typeIsSelect}
              onClick={() => setTypeIsSelect(false)}
            >
              CUSTOM
            </TypeButton>
          </TitleContainer>
          <CloseButton onClick={() => closeModal()}>✕</CloseButton>
        </TitleWrapper>
        {typeIsSelect ? (
          <TimeSelectContainer>
            <Column>
              <SubTitle>- 휴무일</SubTitle>
              <CheckBox
                num={0}
                items={time_values.closed}
                checkedTime={checkedClosed}
                onChecked={OnClickCheckBox}
              />
            </Column>
            {columnNum.map((num) => {
              return (
                <Column>
                  <SubTitle>- 영업시간 {num}</SubTitle>
                  {num === 1 && (
                    <ColumnItems
                      num={num}
                      checkedTime={checkedTime1}
                      OnClickCheckBox={OnClickCheckBox}
                      timeStart={timeStart1}
                      setTimeStart={setTimeStart1}
                      timeEnd={timeEnd1}
                      setTimeEnd={setTimeEnd1}
                      numBT={numBT1}
                      timeBTStart={timeBTStart1}
                      setTimeBTStart={setTimeBTStart1}
                      timeBTEnd={timeBTEnd1}
                      setTimeBTEnd={setTimeBTEnd1}
                      numLO={numLO1}
                      timeLO={timeLO1}
                      setTimeLO={setTimeLO1}
                      AddBT={AddBT}
                      AddLO={AddLO}
                      DeleteBT={DeleteBT}
                      DeleteLO={DeleteLO}
                    />
                  )}
                  {num === 2 && (
                    <ColumnItems
                      num={num}
                      checkedTime={checkedTime2}
                      OnClickCheckBox={OnClickCheckBox}
                      timeStart={timeStart2}
                      setTimeStart={setTimeStart2}
                      timeEnd={timeEnd2}
                      setTimeEnd={setTimeEnd2}
                      numBT={numBT2}
                      timeBTStart={timeBTStart2}
                      setTimeBTStart={setTimeBTStart2}
                      timeBTEnd={timeBTEnd2}
                      setTimeBTEnd={setTimeBTEnd2}
                      numLO={numLO2}
                      timeLO={timeLO2}
                      setTimeLO={setTimeLO2}
                      AddBT={AddBT}
                      AddLO={AddLO}
                      DeleteBT={DeleteBT}
                      DeleteLO={DeleteLO}
                    />
                  )}
                  {num === 3 && (
                    <ColumnItems
                      num={num}
                      checkedTime={checkedTime3}
                      OnClickCheckBox={OnClickCheckBox}
                      timeStart={timeStart3}
                      setTimeStart={setTimeStart3}
                      timeEnd={timeEnd3}
                      setTimeEnd={setTimeEnd3}
                      numBT={numBT3}
                      timeBTStart={timeBTStart3}
                      setTimeBTStart={setTimeBTStart3}
                      timeBTEnd={timeBTEnd3}
                      setTimeBTEnd={setTimeBTEnd3}
                      numLO={numLO3}
                      timeLO={timeLO3}
                      setTimeLO={setTimeLO3}
                      AddBT={AddBT}
                      AddLO={AddLO}
                      DeleteBT={DeleteBT}
                      DeleteLO={DeleteLO}
                    />
                  )}
                </Column>
              );
            })}
            <ButtonContainer>
              <TimeButton onClick={AddTimeButtonListener}>+</TimeButton>
              <TimeButton onClick={DeleteTimeButtonListener}>-</TimeButton>
            </ButtonContainer>
          </TimeSelectContainer>
        ) : (
          <CustomTextArea value={customText} onChange={customTextOnChange} />
        )}
        <SubmitWrapper>
          <ButtonCancel onClick={CancelListener}>닫기</ButtonCancel>
          <ButtonSubmit onClick={SubmitListener}>적용</ButtonSubmit>
        </SubmitWrapper>
      </Wrapper>
    </LocalizationProvider>
  );
};
export default TimeSelectModal;

const Wrapper = styled.div`
  max-width: 80wh;
  overflow: scroll;
`;
// =====  T I T L E  =====
const TitleWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  color: ${palette.fontBlack};
  font-size: 20px;
  font-weight: 700;
`;
const TypeButton = styled.div`
  width: 80px;
  color: ${palette.fontBlack};
  color: ${(props) => (props.selected ? palette.fontWhite : palette.fontBlack)};
  background: ${palette.background};
  background-color: ${(props) =>
    props.selected ? palette.buttonOrange : palette.background};

  font-size: 14px;
  font-weight: 800;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 20px;
  border-radius: 10px;
  cursor: pointer;
`;
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
`;
// =====  C O N T E N T S  =====
const TimeSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;
// ==  C O L U M N  ==
const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
`;
const ColumnContents = styled.div`
  height: 420px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;
const SubTitle = styled.div`
  color: ${palette.fontOrange};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`;
// ==  C H E C K B O X  ==
const CheckboxContainer = styled.div`
  margin-top: 4px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(40px, auto);
`;
const CheckboxWrapper = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
`;
const CheckBoxTitle = styled.div`
  background-color: ${palette.borderLightGrey};
  text-align: center;
  color: ${palette.fontBlack};
  font-size: 14px;
  font-weight: 500;
`;
const CheckBoxTime = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
// ==  E L E M E N T S  ==
const Desc = styled.div`
  color: ${palette.fontBlack};
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 4px;
`;
const BTLOButtonContainer = styled.div`
  display: flex;
  flex-direcdtion: row;
  margin-top: 10px;
`;
const BTLOButton = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${palette.background};
  border-radius: 12px;
  text-align: center;
  margin-right: 10px;
  font-weight: 600;
  font-size: 12px;
  &:hover {
    background-color: ${(props) => props.hoverColor};
    color: ${palette.fontWhite};
  }
  color: ${palette.fontBlack}
  cursor: pointer;
`;

// =====  A D D & D E L E T E  =====
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeButton = styled.div`
  width: 24px;
  height: 100%;
  background-color: ${palette.borderLightGrey};
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 20px;
  color: ${palette.buttonOrange};
  border-radius: 20px;
  text-align: center;
  font-weight: 800;
  vertical-align: bottom;
  padding-top: 12px;
`;

const SubmitWrapper = styled.div`
  width: 100%;
`;
const ButtonSubmit = styled.div`
  margin-top: 12px;
  float: right;
  background-color: ${palette.buttonOrange};
  color: ${palette.fontWhite};
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 80px;
  cursor: pointer;
`;

const CustomTextArea = styled.textarea`
  width: 400px;
  height: 500px;
  border: solid 1px ${palette.borderDefault};
  border-radius: 8px;
  margin-top: 12px;
  padding: 10px;
`;

const ButtonCancel = styled.div`
  margin-top: 12px;
  margin-left: 10px;
  float: right;
  background-color: ${palette.buttonCancel};
  color: ${palette.fontWhite};
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 80px;
  cursor: pointer;
`;
