import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const NotiAPI = (() => {
  class NotiAPIConstructor {
    getNotiAll() {
      return httpAPI(apiPaths.getNotificationAll, { method: 'GET' });
    }
    getNotiDetail(id) {
      return httpAPI(apiPaths.getNotificationSingle + id, { method: 'GET' })
        .call;
    }
    notiRegister(data) {
      return httpAPI(apiPaths.registerNotification, { data }).call;
    }
    editNoti(data) {
      return httpAPI(apiPaths.editNotification, { data }).call;
    }
    deleteNoti(id) {
      return httpAPI(apiPaths.deleteNotification + id, { method: 'DELETE' })
        .call;
    }
  }
  return NotiAPIConstructor;
})();

export const notiAPI = new NotiAPI();
