import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const fontkoKR = 'Roboto';
export const fontEn = 'Montserrat';

export const palette = {
  naver: 'rgba(8, 175, 123, 1)',
  google: 'rgba(115, 182, 228, 1)',
  mint: '#8BCEC6',
  mint_lighter: '#C3E6E2',
  pink: '#ED8EA1',
  pink_lighter: '#FFA6AE',
  grey_darker: '#323232',
  grey: '#707070',
  grey_lighter: '#DDDDDD',
  //
  red: '#EB5E00',
  white: '#ffffff',
  black: '#000000',
  mainOrange: '#FA4616',
  mainOrangeHover: 'rgba(250, 70, 22, 0.7)',
  mainBlueExtraLight: '#D6EEFF',
  mainBlueLight: '#B3E0FF',
  mainBlue: '#0097FF',
  mainBlueHover: '#0067AD',
  mainDisabled: '#C1C0C9',
  mainRed: '#EA4D5D',

  borderDefault: '#CCCCCC',
  borderLightGrey: '#E6E6E6',
  borderOrange: 'rgba(250, 70, 22, 0.2)',

  // button color
  buttonCancel: '#BBBBBB',
  buttonCancelHover: '#777777',
  buttonDelete: '#DA3030',
  buttonDeleteHover: 'rgba(218, 48, 48, 0.5)',
  buttonOrange: '#FA4616',

  // font color
  fontDefault: '#74798C',
  fontGray: '#BBBBBB',
  fontBlack: '#444444',
  fontOrange: '#FA4616',
  fontBlue: '#0097FF',
  fontBlueHover: '#0067AD',
  fontWhite: '#FFFFFF',
  fontWarning: '#cc0000',
  fontWarningHover: '#8D1010',

  // icon color (left header)
  iconDefault: '#A0A5BA',

  // background color
  background: '#F5F6FA',
};

export const theme = {
  mint: {
    titleColor: palette.mint,
    borderColor: palette.mint,
    tableColor: palette.mint,
  },
  pink: {
    titleColor: palette.pink_lighter,
    borderColor: palette.pink,
    tableColor: palette.pink_lighter,
  },
  grey: {
    titleColor: palette.borderDefault,
    borderColor: palette.borderDefault,
    tableColor: palette.borderDefault,
  },
};

export const zIndex = {
  floatingMenu: 2,
  calendar: 100,
  modal: 200,
};

const GlobalStyling = createGlobalStyle`
    ${reset};

    html {
      height: 100%;
      overflow-x: auto;
    }
    a{
        text-decoration: none;
        color:inherit;
    }
    *{
        box-sizing: border-box;
    }
    body{
        font-family: ${fontkoKR};
        font-size: 12px;
        background-color: rgba(255, 255, 255, 1);
        color: black;
        min-height: 100%;
    }
    
    :root {

/* Colors: */
--bg_mint: #707070;
--bg_pink: #FFA6AE;
--white: #FFFFFF;
--pink: #FFA6AE;
--keyword: #EDA88E;
--gray: #707070;
--mint: #8BCEC6;

/* Font/text values */
--unnamed-font-family-roboto: ${fontkoKR};
--unnamed-font-style-normal: normal;
--unnamed-font-weight-bold: bold;
--unnamed-font-weight-medium: medium;
--unnamed-font-weight-normal: normal;
--unnamed-font-size-15: 15px;
--unnamed-font-size-18: 18px;
--unnamed-font-size-19: 19px;
--unnamed-font-size-20: 20px;
--unnamed-font-size-22: 22px;
--unnamed-font-size-23: 23px;
--unnamed-font-size-24: 24px;
--unnamed-font-size-35: 35px;
--unnamed-font-size-36: 36px;
--unnamed-character-spacing-0: 0px;
--unnamed-line-spacing-20: 20px;
--unnamed-line-spacing-24: 24px;
--unnamed-line-spacing-25: 25px;
--unnamed-line-spacing-26: 26px;
--unnamed-line-spacing-29: 29px;
--unnamed-line-spacing-30: 30px;
--unnamed-line-spacing-32: 32px;
--unnamed-line-spacing-46: 46px;
--unnamed-line-spacing-48: 48px;
}

/* Character Styles */
.nav {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-24);
line-height: var(--unnamed-line-spacing-32);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--keyword);
}
.nav {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-24);
line-height: var(--unnamed-line-spacing-32);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--pink);
}
.sub4 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-20);
line-height: var(--unnamed-line-spacing-26);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.text {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-medium);
font-size: var(--unnamed-font-size-20);
line-height: var(--unnamed-line-spacing-26);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.sub2 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-23);
line-height: var(--unnamed-line-spacing-30);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--pink);
}
.sub4 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-19);
line-height: var(--unnamed-line-spacing-25);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--white);
}
.text {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-35);
line-height: var(--unnamed-line-spacing-46);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.sub2 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-23);
line-height: var(--unnamed-line-spacing-30);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.sub3 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-20);
line-height: var(--unnamed-line-spacing-26);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--mint);
}
.sub3 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-20);
line-height: var(--unnamed-line-spacing-26);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--white);
}
.imglink_name {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-medium);
font-size: var(--unnamed-font-size-15);
line-height: var(--unnamed-line-spacing-20);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.edit_text2 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-20);
line-height: var(--unnamed-line-spacing-26);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.edit_text1 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-22);
line-height: var(--unnamed-line-spacing-29);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.sub2 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-23);
line-height: var(--unnamed-line-spacing-30);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--mint);
}
.text1 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-22);
line-height: var(--unnamed-line-spacing-29);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--mint);
}
.nav_off {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-24);
line-height: var(--unnamed-line-spacing-32);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.text2 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-medium);
font-size: var(--unnamed-font-size-18);
line-height: var(--unnamed-line-spacing-24);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.sub3 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-22);
line-height: var(--unnamed-line-spacing-29);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.btn_on {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-22);
line-height: var(--unnamed-line-spacing-29);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--white);
}
.text1 {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-medium);
font-size: var(--unnamed-font-size-22);
line-height: var(--unnamed-line-spacing-29);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.sub {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-36);
line-height: var(--unnamed-line-spacing-48);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--gray);
}
.nav {
font-family: var(--unnamed-font-family-roboto);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-24);
line-height: var(--unnamed-line-spacing-32);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--mint);
}
`;

export default GlobalStyling;
