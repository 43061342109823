import styled from 'styled-components';
import RadioButton from 'assets/svg/RadioButton';
import { palette } from 'modules/defines/styles';

export const SelectRadioBtn = ({ select, label, onClick }) => {
  return (
    <SelectWrapper>
      <RadioButton onClick={onClick} select={select} />
      <SelectTitle onClick={onClick}>{label}</SelectTitle>
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
`;
const SelectTitle = styled.div`
  margin-left: 5px;
  color: ${palette.fontDefault};
  font-size: 12px;
`;