import { useContext } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import useInputState from 'modules/customHooks/useInputState';
import { GlobalContext } from 'modules/context/GlobalContext';
import { versionAPI } from 'modules/api';

const ModalWrapper = styled.div`
  max-height: 100vh;
  max-width: 80wh;
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const LeftWrapper = styled.div`
  margin: 20px 25px 0 15px;
  // background-color: #000000;
`;

const TitleWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  color: ${palette.fontBlack};
  font-size: 20px;
  font-weight: 700;
`;
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;
const ItemContainer = styled.div`
  margin: 10px 0;
  position: relative;
  display: flex;
  flex-direction: row;
`;
const ItemTitle = styled.div`
  width: 150px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: ${palette.fontDefault};
  line-height: 16px;
`;
const ItemTextInput = styled.input`
  all: unset;
  width: 100%;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  font-size: 16px;
  color: ${(props) =>
    props.readOnly ? palette.mainOrange : palette.fontBlack};
  opacity: 1;
`;
const SubmitButton = styled.div`
  float: right;
  height: 30px;
  background-color: ${palette.mainOrange};
  border-radius: 8px;
  color: ${palette.fontWhite};
  line-height: 30px;
  font-size: 15px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
`;

const VersionCreateModal = ({ closeModal, returnFunction }) => {
  const globalState = useContext(GlobalContext);

  const {
    state: number,
    setState: setNumber,
    onChange: numberOnChange,
  } = useInputState('');

  const createPush = async () => {
    if (number === null || number.length === 0) {
      alert('버전 넘버는 필수입니다.');
    } else {
      try {
        const postData = {
          number: number,
        };
        versionAPI.registerVersion(postData).then((response) => {
          console.log(response);
          alert('버전 생성이 완료되었습니다.');
          returnFunction();
        });
      } catch (err) {
        alert('버전 생성 중 오류가 발생했습니다.');
      }
    }
  };
  return (
    <ModalWrapper>
      <TitleWrapper>
        <TitleContainer>
          <Title>버전 등록</Title>
        </TitleContainer>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </TitleWrapper>
      <Wrapper>
        <LeftWrapper>
          <ItemContainer>
            <ItemTitle>버전 넘버</ItemTitle>
          </ItemContainer>
          <ItemTextInput value={number} onChange={numberOnChange} />
        </LeftWrapper>
      </Wrapper>
      <ButtonContainer>
        <SubmitButton onClick={createPush}>버전 생성</SubmitButton>
      </ButtonContainer>
    </ModalWrapper>
  );
};

export default VersionCreateModal;
