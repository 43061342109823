import { palette } from 'modules/defines/styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const UserDetailUserInfo = ({ user_data }) => {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    setUserData(user_data);
  }, [user_data]);

  return (
    <Container>
      {userData !== null && (
        <>
          <ProfileImageContainer>
            {userData.img ? (
              <ProfileImage url={userData.img} />
            ) : (
              <ProfileDefaultImage>
                <ProfileDefaultImageText>
                  사진이 없습니다.
                </ProfileDefaultImageText>
              </ProfileDefaultImage>
            )}

            <ProfileName>{userData.name}</ProfileName>
          </ProfileImageContainer>
          <ProfileLayout>
            <ProfileColumn>ID : </ProfileColumn>
            <ProfileData>{userData.id}</ProfileData>
          </ProfileLayout>
          <ProfileLayout>
            <ProfileColumn>uid : </ProfileColumn>
            <ProfileData>{userData.uid}</ProfileData>
          </ProfileLayout>
          <ProfileLayout>
            <ProfileColumn>이름 : </ProfileColumn>
            <ProfileData>{userData.name}</ProfileData>
          </ProfileLayout>
          <ProfileLayout>
            <ProfileColumn>Phone : </ProfileColumn>
            <ProfileData>{userData.phone}</ProfileData>
          </ProfileLayout>
          <ProfileLayout>
            <ProfileColumn>E-mail : </ProfileColumn>
            <ProfileData>{userData.email}</ProfileData>
          </ProfileLayout>
          <ProfileLayout>
            <ProfileColumn>성별 : </ProfileColumn>
            <ProfileData>{userData.gender}</ProfileData>
          </ProfileLayout>
          <ProfileLayout>
            <ProfileColumn>생년월일 : </ProfileColumn>
            <ProfileData>{userData.birth}</ProfileData>
          </ProfileLayout>
          <ProfileLayout>
            <ProfileColumn>가입날짜 : </ProfileColumn>
            <ProfileData>{userData.createdAt.split('T')[0]}</ProfileData>
          </ProfileLayout>
        </>
      )}
    </Container>
  );
};

const Container = styled.div``;

const ProfileImageContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;
  border-bottom: solid 0.5px ${palette.borderLightGrey};
  margin-bottom: 24px;
`;
const ProfileImage = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  left: 50%;
  margin-left: -60px;
  border-radius: 50%;
  background-image: url(${(props) => props.url});
  background-size: cover;
`;
const ProfileDefaultImage = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  left: 50%;
  margin-left: -60px;
  border-radius: 50%;
  background-color: ${palette.background};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.grey_lighter};
`;
const ProfileDefaultImageText = styled.div`
  font-size: 14px;
`;
const ProfileName = styled.div`
  margin-top: 14px;
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  color: ${palette.fontBlack};
`;
const ProfileLayout = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  overflow: hidden;
`;
const ProfileColumn = styled.div`
  font-size: 14px;
  min-width: 56px;
  color: ${palette.fontGray};
`;
const ProfileData = styled.div`
  font-size: 14px;
  color: ${palette.fontDefault};
  overflow: scroll;
  width: 100%;
  margin-left: 4px;
`;

export default UserDetailUserInfo;
