import { useContext, useMemo, useState, useEffect } from "react";
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import useInputState from 'modules/customHooks/useInputState';
import { curationAPI } from 'modules/api';
import { pushAPI } from 'modules/api';
import { GlobalContext } from 'modules/context/GlobalContext';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SmallTable from 'components/table/SmallTable';

const ModalWrapper = styled.div`
  max-height: 100vh;
  max-width: 80wh;
  display:flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display:flex;
  flex-direction: row;
`;
const LeftWrapper = styled.div`
  margin: 20px 25px 0 15px;
  // background-color: #000000;
`;
const CenterWrapper = styled.div`
  margin: 20px 15px 0 25px;
`;
const RightWrapper = styled.div`
  margin: 20px 15px 0 25px;
`;
const TableWrapper = styled.div`
  height: 300px;
  overflow: scroll;
`;
const CustomWrapper = styled.div``;

const TitleWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  color: ${palette.fontBlack};
  font-size: 20px;
  font-weight: 700;
`;
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;
const ItemContainer = styled.div`
  margin: 10px 0;
  position: relative;
  display: flex;
  flex-direction: row;
`;
const ItemTitle = styled.div`
  width: 150px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: ${palette.fontDefault};
  line-height: 16px;
`;
const ItemTextInput = styled.input`
  all: unset;
  width: 100%;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  font-size: 16px;
  color: ${(props) =>
    props.readOnly ? palette.mainOrange : palette.fontBlack};
  opacity: 1;
`;
const SubmitButton = styled.div`
  float: right;
  height: 30px;
  background-color: ${palette.mainOrange};
  border-radius: 8px;
  color: ${palette.fontWhite};
  line-height: 30px;
  font-size: 15px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
`;
const TypeButton = styled.div`
  width: 80px;
  color: ${palette.fontBlack};
  color: ${(props) => (props.selected ? palette.fontWhite : palette.fontBlack)};
  background: ${palette.background};
  background-color: ${(props) =>
    props.selected ? palette.buttonOrange : palette.background};

  font-size: 14px;
  font-weight: 800;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 20px;
  border-radius: 10px;
  cursor: pointer;
`;

const CellImage = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${(props) => props.url});
  background-size: cover;
`;

const PushNotificationModal = ({ closeModal, returnFunction }) => {
  const globalState = useContext(GlobalContext);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(dayjs('2022-04-17T15:30'));
  const [pushType, setPushType] = useState('CURATION');
  const [spaces, setSpaces] = useState(null);
  const [selectedCuration, setSelectedCuration] = useState("0");
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [img, setImg] = useState('');
  const [curation, setCuration] = useState(null);
  const [loading, setLoading] = useState(true);

  const {
    state: body,
    setState: setBody,
    onChange: bodyOnChange,
  } = useInputState('');
    
  const {
    state: title,
    setState: setTitle,
    onChange: titleOnChange,
  } = useInputState('');

  const defaultState = async () => {
    setTitle('');
    setBody('');
    setSelectedCuration('0');
    setSelectedSpaces([]);
    setImg('');
  };
  const typeButtonTapped = async (e) => {
    e.preventDefault();
    setPushType(e.target.id);
    defaultState();
  };

  const handleCurationChange = (e, img) => {
    e.preventDefault();
    setSelectedCuration(e.target.id);
    setTitle("새로운 큐레이션이 등록되었습니다.")
    setBody(e.target.value);
    setImg(img)
  };

  const handleSpaceChange = (e, img) => {
    e.preventDefault();
    setSelectedSpaces((prev) => {
      if (prev.includes(e.target.id)) {
        return prev.filter((el) => el !== e.target.id);
      } else {
        return [...prev, e.target.id];
      }
    });
  };

  useEffect(() => {
    if (selectedSpaces.length > 0) {
      setTitle(selectedSpaces.length + "곳의 새로운 공간이 등록되었습니다.");
      setBody("FAV 앱에서 바로 확인하실수 있습니다.");
      setImg(spaces.filter((el) => el.id === Number(selectedSpaces[0]))[0].img);
    } else {
      setTitle('');
      setBody('');
    }
  }, [selectedSpaces]);

  useEffect(() => {
    setSpaces(globalState.space.filter(space => space.publish && !space.pushed));
  }, [globalState.space]);

  useEffect(() => {
    if (loading) {
      curationAPI.getCurationAllForPush().then((response) => {
        setCuration(response);
      });
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [curation]);

  const curationTableColumns = useMemo(
    () => [
      {
        accesor: 'selected',
        Header: '선택',
        Filter: false,
        width: 40,
        Cell: ({ row }) => <input
          id={row.values.id}
          value={row.values.title.replace('\\n', ' ')}
          type="radio"
          checked={selectedCuration === String(row.values.id)}
          onChange={(e) => handleCurationChange(e, row.values.img)}
        /> 
      },
      {
        accessor: 'img',
        Header: "이미지",
        Filter: false,
        width: 100,
        align: 'center',
        Cell: ({ row }) => <CellImage url={row.values.img} />
      },
      {
        accessor: 'id',
        Header: 'ID',
        Filter: false,
        width: 70,
        align: 'center',
      },
      {
        accessor: 'title',
        Header: 'Title',
        Filter: false,
        align: 'center',
      },
      {
        accessor: 'description',
        Header: '큐레이션 설명',
        Filter: false,
        align: 'center',
      },
    ],
  );
  const spaceTableColumns = useMemo(
    () => [
      {
        accesor: 'selected',
        Header: '선택',
        Filter: false,
        width: 40,
        Cell: ({ row }) => <input
          id={row.values.id}
          value={row.values.id}
          type="checkbox"
          checked={selectedSpaces.includes(String(row.values.id))}
          onChange={(e) => handleSpaceChange(e, row.original.img)}
        />
      },
      {
        accessor: 'id',
        Header: 'ID',
        Filter: false,
        width: 70,
        align: 'center',
      },
      {
        accessor: 'title',
        Header: 'Title',
        Filter: false,
        align: 'center',
      },
      {
        accessor: 'description',
        Header: '공간 설명',
        Filter: false,
        align: 'center',
      },
    ], [selectedSpaces]
  );
  const curationTableData = useMemo(
    () => {
      if (!curation) {
        return [];
      }
      return curation.map((item) => ({
        ...item,
        title: item.title.replace("\\n", ' '),
      }));
    }, [curation]
  );
  const spaceTableData = useMemo(
    () => {
      if (!spaces) {
        return [];
      }
      return spaces.map((item) => ({
        ...item,
      }));
    }, [spaces]
  );
  
  const createPush = async () => {
    if ((title === null || title.length === 0) && (body ===null || body.length === 0)) {
      alert('푸시 알람의 제목과 내용은 필수입니다.');
    } else {
      try {
        if (pushType === 'CURATION') {
          const postData = {
            title: title,
            body: body,
            time: time.hour() + ':' + time.minute(),
            date: date.toLocaleDateString(),
            img: img,
            data: JSON.stringify(`{"curation": ${selectedCuration}}`),
          }
          await pushAPI.registerPush(postData);
        } else if (pushType === 'SPACE') {
          const postData = {
            title: title,
            body: body,
            time: time.hour() + ':' + time.minute(),
            date: date.toLocaleDateString(),
            img: img,
            data: JSON.stringify(`{"space": [${selectedSpaces}]}`),
          }
          await pushAPI.registerPush(postData);
        } else {
        }
        returnFunction();
      } catch (err) {
        console.error(err);
      }
    }
  };
  return (
    <ModalWrapper>
      <TitleWrapper>
        <TitleContainer>
          <Title>푸시 알림 등록</Title>  
        </TitleContainer>
        <TypeButton
          id='CURATION'
          selected={pushType === 'CURATION'}
          onClick={(e) => typeButtonTapped(e)}
        >
          CURATION
        </TypeButton>
        <TypeButton
          id='SPACE'
          selected={pushType === 'SPACE'}
          onClick={(e) => typeButtonTapped(e)}
        >
          SPACE
        </TypeButton>
        <TypeButton
          value='CUSTOM'
          selected={pushType === 'CUSTOM'}
          onClick={(e) => typeButtonTapped(e)}
        >
          CUSTOM
        </TypeButton>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </TitleWrapper>
      <Wrapper>
        <LeftWrapper>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ItemContainer>
              <ItemTitle>푸시 알림 제목</ItemTitle>
            </ItemContainer>
            <ItemTextInput value={title} onChange={titleOnChange} />
            <ItemContainer>
              <ItemTitle>푸시 알림 내용</ItemTitle>
            </ItemContainer>
            <ItemTextInput value={body} onChange={bodyOnChange} />
            <ItemContainer>
              <ItemTitle>푸시 전송 날짜</ItemTitle>
            </ItemContainer>
            <DatePicker selected={date} onChange={(date) => setDate(date)} />
            <ItemContainer>
              <ItemTitle>푸시 전송 시간</ItemTitle>
            </ItemContainer>
            <TimePicker
              value={time}
              onChange={setTime}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </LeftWrapper>
        <CenterWrapper>
          {
            pushType === 'CURATION' && (
              <TableWrapper>
                {curationTableColumns && curationTableData && 
                  <SmallTable
                    columns={curationTableColumns}
                    data={curationTableData}
                  />
                }
              </TableWrapper>
            )
          }
          {
            pushType === 'SPACE' && (
              <TableWrapper>
                {spaceTableColumns && spaceTableData && 
                  <SmallTable
                    columns={spaceTableColumns}
                    data={spaceTableData}
                  />
                }
              </TableWrapper>
            )
          }
          {
            pushType === 'CUSTOM' && (
              <CustomWrapper>
                custom
              </CustomWrapper>
            )
          }
        </CenterWrapper>
        <RightWrapper>
          <ItemContainer>
              <ItemTitle>미리보기</ItemTitle>
            </ItemContainer>
          {
            img !== '' &&
            <CellImage url={img} />
          }
        </RightWrapper>
      </Wrapper>
      <ButtonContainer>
        <SubmitButton onClick={createPush}>푸시 알림 생성</SubmitButton>
      </ButtonContainer>
    </ModalWrapper>
  );
};

export default PushNotificationModal;
