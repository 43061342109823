import { useTable, useFilters, useGlobalFilter } from 'react-table';
import styled from 'styled-components';
import { SearchFilter } from 'components/common/Filter';

const TableWrapper = styled.div`
  height: ${(props) => props.tableHeight}px;
  overflow-y: auto;
`;
const Styles = styled.div`
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead th:nth-child(1) {
      width: 70px;
    }
    thead th:nth-child(2) {
      width: 160px;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      text-align: left;
      vertical-align: middle;
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
      background-color: #707070;
      opacity: 1;
      height: 30px;
      border-bottom: 1px solid #707070;
      border-left: 1px solid rgb(112, 112, 112, 0.1);
      border-right: 1px solid rgb(112, 112, 112, 0.1);
      padding-left: 20px;
      :first-child {
        text-align: center;
        padding: 0px;
      }
      :last-child {
        text-align: center;
        padding: 0px;
      }
    }
    td {
      text-align: left;
      vertical-align: middle;
      font-size: 14px;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
      height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid rgb(112, 112, 112, 0.1);
      border-right: 1px solid rgb(112, 112, 112, 0.1);
      :first-child {
        text-align: center;
        padding: 0px;
      }
    }

    tbody tr:nth-child(odd) {
      background-color: #fbfbfb;
    }
    tbody tr:nth-child(even) {
      background-color: #ffffff;
    }
  }
`;

const SearchTable = ({ columns, data, tableHeight = 600 }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <Styles>
      <SearchFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        placeholder="매장 검색"
      />
      <TableWrapper tableHeight={tableHeight}>
        {data ? (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </TableWrapper>
    </Styles>
  );
};

export default SearchTable;
