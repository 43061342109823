import React from 'react';
import  styled  from 'styled-components';

const Label = styled.div`
    color: gray;
    display: grid;
    flex-direction: row;
    margin: 10px;
    width: 100px;
    text-align: center;
`;

const Content = styled.div`
    display: grid;
    flex-direction: row;
    margin: 10px;
    width: 100px;
    text-align: left;
`;

const FeedbackInfo = styled.div`
    display: flex;
`


const FeedbackInfoWrapper = ({label, content}) => {
    return (
        <FeedbackInfo>
            <Label>{label}</Label>
            <Content>{content}</Content>
        </FeedbackInfo>
    );
};

export default FeedbackInfoWrapper;