import { useParams, useHistory } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { UserContext } from 'modules/api/user';
import { notiAPI } from 'modules/api';
import { pagePaths } from 'modules/defines/paths';
import { ButtonCancel, ButtonDelete } from 'components/common/DefaultButton';
import { Container, LayoutWrapper } from 'components/common/DataTableStyle';
import {
  ButtonWrapper,
  Loading,
  NotiTitle,
  NotiDate,
  NotiBody,
} from 'components/appManagement/AppManagementCommon';

const NotificationDetailContainer = () => {
  const { id } = useParams();
  const history = useHistory();
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const goBack = () => {
    history.goBack();
  };

  // 권한 확인 후 차단
  if (!userContext.user.role.match('admin')) {
    history.push(pagePaths.space.list);
  }
  useEffect(() => {
    if (loading) {
      notiAPI.getNotiDetail(id).then((response) => {
        setData(response);
      });
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [data]);

  const deleteNoti = async () => {
    try {
      notiAPI.deleteNoti(id).then((response) => {
        alert('공지사항이 삭제되었습니다.');
      });
    } catch (e) {
      console.error(e);
      alert(
        '공지사항을 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    history.push(pagePaths.notification.list);
  };

  return loading ? (
    <Loading>Loading...</Loading>
  ) : (
    <Container>
      <ButtonWrapper>
        <ButtonCancel text="이전" onClick={goBack} />
        <ButtonDelete text="삭제" onClick={deleteNoti} />
      </ButtonWrapper>
      {data && (
        <LayoutWrapper>
          <NotiTitle>{data.title}</NotiTitle>
          <NotiDate>{data.publishDate}</NotiDate>
          <NotiBody>{ReactHtmlParser(data.body)}</NotiBody>
        </LayoutWrapper>
      )}
    </Container>
  );
};

export default NotificationDetailContainer;
