import { useEffect, useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { spaceAPI } from 'api';
import { curationAPI, keywordAPI } from 'modules/api';
import KeywordVibeSelectModal from 'components/modal/KeywordVibeSelectModal';
import { ModalContext } from 'modules/context/ModalContext';

const defaultState = {
  product: {
    selectedKeyword: [],
    totalKeyword: [],
    isFocus: false,
    searchText: '',
    searchDuplicate: false,
  },
  vibe: {
    selectedKeyword: [],
    totalKeyword: [],
    isFocus: false,
    searchText: '',
    searchDuplicate: false,
  },
};

const DetailKeyword = ({
  havePermission,
  typeData = 'space', // space or curation
  id,
  keywordAllLoading,
  keywordAllData,
  keywordAllRefetch,
  keywordConnectedLoading,
  keywordConnectedData,
  keywordConnectedRefetch,
}) => {
  const { openModal, closeModal } = useContext(ModalContext);

  // KEYWORD DATA
  const [keywordData, setKeywordData] = useState(defaultState);

  // INITIALIZE
  useEffect(() => {
    let keywordProduct = keywordAllData.filter(
      (data) => data.std === 'product'
    );
    let keywordVibe = keywordAllData.filter((data) => data.std === 'vibe');
    setKeywordData((prev) => ({
      product: { ...prev.product, totalKeyword: keywordProduct },
      vibe: { ...prev.vibe, totalKeyword: keywordVibe },
    }));
  }, [keywordAllData]);

  useEffect(() => {
    let keywordProduct = keywordConnectedData.filter(
      (data) => data.std === 'product'
    );
    let keywordVibe = keywordConnectedData.filter(
      (data) => data.std === 'vibe'
    );
    setKeywordData((prev) => ({
      product: {
        ...prev.product,
        searchText: '',
        isFocus: false,
        selectedKeyword: keywordProduct,
      },
      vibe: {
        ...prev.vibe,
        searchText: '',
        isFocus: false,
        selectedKeyword: keywordVibe,
      },
    }));
  }, [keywordConnectedData]);
  // Search
  const searchList = useMemo(() => {
    let keywordDataBuff = null;
    if (keywordData.product.isFocus) {
      keywordDataBuff = keywordData.product;
    } else {
      keywordDataBuff = keywordData.vibe;
    }
    const keywordDataBuffItems = keywordDataBuff.totalKeyword;
    if (keywordDataBuffItems === null) {
      return [];
    }
    if (keywordDataBuff.searchText === '') {
      return [];
    } else {
      const resultData = keywordDataBuffItems.filter((item) => {
        return item.title.includes(keywordDataBuff.searchText);
      });
      resultData.sort((a, b) => {
        if (a.space.length < b.space.length) {
          return 1;
        } else if (a.space.length > b.space.length) {
          return -1;
        }
        return 0;
      });
      return resultData;
    }
  }, [keywordData]);

  const removeKeyword = async (e, keywordId) => {
    e.preventDefault();
    if (havePermission) {
      try {
        if (typeData === 'space') {
          const postData = {
            space_id: id,
            keyword_id: keywordId,
          };
          await spaceAPI.removeKeyword(postData);
        } else if (typeData === 'curation') {
          const postData = {
            curation_id: id,
            keyword_id: keywordId,
          };
          await curationAPI.removeKeyword(postData);
        }
      } catch (err) {
        alert(
          '키워드 업데이트 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.'
        );
        return;
      } finally {
        keywordConnectedRefetch();
      }
    } else {
      alert('권한이 없습니다.');
    }
  };

  const addKeyword = async (e, type, keywordId) => {
    e.preventDefault();
    if (havePermission) {
      if (type === 'vibe') {
        // 겹치는지 확인
        let isDuplicate = false;
        keywordData.vibe.selectedKeyword.map((item) => {
          if (item.id === keywordId) {
            isDuplicate = true;
          }
        });
        if (!isDuplicate) {
          try {
            if (typeData === 'space') {
              const postData = {
                space_id: id,
                keyword_id: keywordId,
              };
              await spaceAPI.addKeyword(postData);
            } else if (typeData === 'curation') {
              const postData = {
                curation_id: id,
                keyword_id: keywordId,
              };
              await curationAPI.addKeyword(postData);
            }
          } catch (err) {
            alert(
              '키워드 업데이트 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.'
            );
            return;
          } finally {
            keywordConnectedRefetch();
          }
        }
      } else {
        // 겹치는지 확인
        let isDuplicate = false;
        keywordData.product.selectedKeyword.map((item) => {
          if (item.id === keywordId) {
            isDuplicate = true;
          }
        });
        if (!isDuplicate) {
          try {
            if (typeData === 'space') {
              const postData = {
                space_id: id,
                keyword_id: keywordId,
              };
              await spaceAPI.addKeyword(postData);
            } else if (typeData === 'curation') {
              const postData = {
                curation_id: id,
                keyword_id: keywordId,
              };
              await curationAPI.addKeyword(postData);
            }
          } catch (err) {
            alert(
              '키워드 업데이트 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.'
            );
            return;
          } finally {
            keywordConnectedRefetch();
          }
        }
      }
    } else {
      alert('권한이 없습니다.');
    }
  };

  // Search Listener
  const searchFocusListener = (e, isFocus, type) => {
    if (type === 'vibe') {
      setKeywordData((prev) => ({
        product: { ...prev.product },
        vibe: { ...prev.vibe, isFocus: isFocus },
      }));
    } else {
      setKeywordData((prev) => ({
        product: { ...prev.product, isFocus: isFocus },
        vibe: { ...prev.vibe },
      }));
    }
  };
  const searchTextOnChange = (e, type) => {
    let text = e.target.value.replace(' ', '');
    if (type === 'vibe') {
      // 겹치는 키워드 제외
      let isDuplicate = false;
      keywordData.vibe.totalKeyword.map((item) => {
        if (item.title === text) {
          isDuplicate = true;
        }
      });
      setKeywordData((prev) => ({
        product: { ...prev.product },
        vibe: { ...prev.vibe, searchText: text, searchDuplicate: isDuplicate },
      }));
    } else {
      // 겹치는 키워드 제외
      let isDuplicate = false;
      keywordData.product.totalKeyword.map((item) => {
        if (item.title === text) {
          isDuplicate = true;
        }
      });
      setKeywordData((prev) => ({
        product: {
          ...prev.product,
          searchText: text,
          searchDuplicate: isDuplicate,
        },
        vibe: { ...prev.vibe },
      }));
    }
  };

  const searchItemAddClickListener = async (e, type, keywordText) => {
    if (havePermission) {
      try {
        if (keywordText.length <= 20) {
          const res = await keywordAPI.registerKeyword({
            title: keywordText,
            std: type,
          });
          if (!res.success) {
            alert(res.data.message);
          } else {
            keywordAllRefetch();
            await addKeyword(e, type, res.data.id);
          }
        } else {
          alert('키워드 글자수는 20자 내로 입력해주세요.');
        }
      } catch (err) {
        console.error(err);
        alert('키워드 추가 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
      }
    } else {
      alert('권한이 없습니다.');
    }
  };

  // Open Vibe Modal
  const OpenVibeModal = (e) => {
    e.preventDefault();
    return openModal(
      <KeywordVibeSelectModal
        id={id}
        typeData={typeData}
        keywordData={keywordData}
        setKeywordData={setKeywordData}
        keywordAllRefetch={keywordAllRefetch}
        keywordConnectedRefetch={keywordConnectedRefetch}
        closeModal={closeModal}
      />
    );
  };

  return (
    <Container>
      <HeaderContainer>
        {typeData === 'space' && (
          <HeaderTitle>공간 키워드 (Keyword)</HeaderTitle>
        )}
        {typeData === 'curation' && (
          <HeaderTitle>큐레이션 키워드 (Keyword)</HeaderTitle>
        )}
      </HeaderContainer>
      <ContentsContainer>
        {!keywordAllLoading &&
          !keywordConnectedLoading &&
          Object.entries(keywordData).map(([key, value]) => {
            return (
              <KeywordStdWrapper>
                <KeywordStdTitleWrapper>
                  <KeywordStdTitle>{key}</KeywordStdTitle>
                  {key === 'vibe' && (
                    <KeywordVibeModalButton onClick={OpenVibeModal}>
                      더보기
                    </KeywordVibeModalButton>
                  )}
                </KeywordStdTitleWrapper>

                <KeywordStdContainer>
                  {/* 1) 선택된 키워드 */}
                  <SelectedContainer>
                    {value.selectedKeyword?.map((item, idx) => {
                      return (
                        <KeywordItemWrapper
                          onClick={(e) => removeKeyword(e, item.id)}
                          color={palette.mainOrangeHover}
                          color_hover={palette.mainOrange}
                          fontcolor={palette.white}
                        >
                          <SelectedKeywordItemContents>
                            <KeywordItem>#{item.title}</KeywordItem>
                            <CloseIcon>X</CloseIcon>
                          </SelectedKeywordItemContents>
                        </KeywordItemWrapper>
                      );
                    })}
                  </SelectedContainer>
                  {/* 2) 키워드 검색 및 추가 */}
                  <SearchContainer>
                    <SearchKeyword
                      value={value.searchText}
                      onChange={(e) => searchTextOnChange(e, key)}
                      onFocus={(e) => searchFocusListener(e, true, key)}
                      onBlur={(e) => searchFocusListener(e, false, key)}
                    />
                    {value.isFocus && (
                      <SearchDropDownContainer>
                        {searchList.map((item) => {
                          return (
                            <SearchResultItem
                              onMouseDown={(e) => addKeyword(e, key, item.id)}
                            >
                              #{item.title}
                              {typeData === 'space' && (
                                <SearchResultItemDetail>
                                  공간 {item.space.length}곳
                                </SearchResultItemDetail>
                              )}
                              {typeData === 'curation' && (
                                <SearchResultItemDetail>
                                  큐레이션 {item.curation.length}개
                                </SearchResultItemDetail>
                              )}
                            </SearchResultItem>
                          );
                        })}
                        {value.searchText !== '' && !value.searchDuplicate && (
                          <SearchResultItem
                            onMouseDown={(e) =>
                              searchItemAddClickListener(
                                e,
                                key,
                                value.searchText
                              )
                            }
                            fontcolor={palette.fontGray}
                          >
                            #{value.searchText} 추가하기
                          </SearchResultItem>
                        )}
                      </SearchDropDownContainer>
                    )}
                  </SearchContainer>
                  {/* 3) 키워드 선택 및 추가 */}
                  <SelectContainer>
                    {value.totalKeyword?.map((item, idx) => {
                      return (
                        <KeywordItemWrapper
                          onClick={(e) => addKeyword(e, key, item.id)}
                          color={palette.grey_lighter}
                          color_hover={palette.mainOrangeHover}
                          fontcolor={palette.fontDefault}
                          fontcolor_hover={palette.white}
                        >
                          <KeywordItem>#{item.title}</KeywordItem>
                        </KeywordItemWrapper>
                      );
                    })}
                  </SelectContainer>
                </KeywordStdContainer>
              </KeywordStdWrapper>
            );
          })}
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const HeaderTitle = styled.div`
  font-size: 18px;
  font-family: Pretendard-b;
  color: ${palette.fontDefault};
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ContentsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  height: 100%;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;
const KeywordStdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-right: 10px;
  @media screen and (max-width: 1100px) {
    :last-child {
      margin-top: 40px;
    }
  }
`;
const KeywordStdTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
const KeywordStdTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${palette.fontDefault};
`;
const KeywordVibeModalButton = styled.div`
  font-size: 12px;
  position: absolute;
  right: 0px;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 4px;
  color: ${palette.fontWhite};
  background-color: ${palette.buttonCancel};
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainOrangeHover};
    color: ${palette.white};
  }
`;
const KeywordStdContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
// 1. 선택된 키워드
const SelectedContainer = styled.div`
  width: 100%;
  margin-bottom: 5px;
  padding: 6px 6px 0 6px;
`;
const SelectedKeywordItemContents = styled.div`
  display: flex;
  flex-direction: row;
`;
const CloseIcon = styled.div`
  color: ${palette.fontGray};
  line-height: 18px;
  font-size: 8px;
  font-weight: 400;
  margin-left: 4px;
`;
// 2. 키워드 검색 및 추가
const SearchContainer = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const SearchKeyword = styled.input`
  width: 100%;
  border: 1px solid ${palette.mint};
  border-radius: 5px;
  padding-left: 10px;
  height: 30px;
`;
const SearchDropDownContainer = styled.div`
  border: 1px solid rgb(223, 225, 229);
  font-size: 14px;
  background-color: ${palette.white};
  border-radius: 2px;
  width: 100%;
  position: absolute;
  box-shadow: 0 0 20px 0 #ccc;
  z-index: 99;
  opacity: 1;
  transition: opacity 0.5s linear;
  top: 30px;
  overflow: scroll;
`;

const SearchResultItem = styled.div`
  padding: 10px 8px;
  color: ${(props) =>
    props.fontcolor ? props.fontcolor : palette.fontDefault};
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: ${palette.mainOrangeHover};
    background-color: ${palette.background};
  }
`;
const SearchResultItemDetail = styled.div`
  color: ${palette.fontGray};
  font-size: 10px;
  float: right;
`;

// 3. 기존 키워드 중 선택하기
const SelectContainer = styled.div`
  margin-top: 8px;
  overflow-y: scroll;
  height: 100%;
`;
// 키워드 아이템
const KeywordItemWrapper = styled.div`
  background-color: ${(props) =>
    props.color ? props.color : palette.mainOrangeHover};
  color: ${(props) => (props.fontcolor ? props.fontcolor : palette.white)};
  display: inline-block;
  padding: 3px 8px 3px 6px;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 6px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.color_hover ? props.color_hover : palette.mainOrange};
    color: ${(props) =>
      props.fontcolor_hover ? props.fontcolor_hover : palette.white};
  }
`;
const KeywordItem = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
  height: 100%;
`;

export default DetailKeyword;
