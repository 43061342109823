import { useContext } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { ModalContext } from 'modules/context/ModalContext';

export const ModalOpener = styled.div`
  cursor: pointer;
`;

const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #00000080;
  // border: 1px solid #707070;
  border-radius: 12px;
  opacity: 1;
  padding: 20px;
  max-height: 90vh;
  overflow: scroll;
`;

const ModalPortal = (props) => {
  const el = document.getElementById('app-modal');
  let { modal, modalContent } = useContext(ModalContext);
  if (modal) {
    return createPortal(
      <>
        <ModalBackground>
          <ModalContent>{modalContent}</ModalContent>
        </ModalBackground>
      </>,
      el
    );
  } else return null;
};

export default ModalPortal;
