import EventListContainer from 'containers/event/EventListContainer';
import GlobalContainer from 'containers/GlobalContainer';

const EventList = () => {
  return (
    <GlobalContainer>
      <EventListContainer />
    </GlobalContainer>
  );
};

export default EventList;
