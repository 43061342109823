import { useEffect, useState, useContext } from 'react';
import { GlobalContext } from 'modules/context/GlobalContext';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import useInputState from 'modules/customHooks/useInputState';
import { keywordAPI } from 'modules/api';
import { UserContext } from 'modules/api/user';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { Container, Title, SubTitle } from 'components/common/DataTableStyle';
import { icon_trashbin, icon_imgadd } from 'assets/img/icon';
import { getComplementaryColor } from 'utils/globalFunction';
import DetailKeyword from 'components/DetailKeyword';
import { LayoutWrapper, CommonContainer } from 'components/space/SpaceCommon';
import { ButtonCancel, ButtonConfirm } from 'components/common/DefaultButton';
import { monthDateRegex } from 'utils/regex';
import DefaultTable from 'components/table/DefaultTable';
import DeleteKeywordButton from 'components/button/KeywordDeleteButton';
import { pagePaths } from 'modules/defines/paths';
import { SketchPicker } from 'react-color';

const CustomKeywordDetailContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const globalState = useContext(GlobalContext);
  const keywordAllData = globalState.keyword;
  const refetch = () => {
    globalState.refetch('keyword');
  };
  const [havePermission, setHavePermission] = useState(false);
  const [userContext] = useContext(UserContext);
  const [phrases, setPhrases] = useState(['']);
  const [connectedKeyword, setConnectedKeyword] = useState(null);
  const addPhrase = () => {
    if (phrases[phrases.length - 1].trim() !== '') {
      setPhrases([...phrases, '']);
    }
  };
  const updatePhrase = (index, newPhrase) => {
    const newPhrases = [...phrases];
    newPhrases[index] = newPhrase;
    setPhrases(newPhrases);
  };
  const deletePhrase = (index) => {
    const newPhrases = phrases.filter((_, i) => i !== index);
    setPhrases(newPhrases);
  };
  const {
    state: title,
    setState: setTitle,
    onChange: titleOnChange,
  } = useInputState('');
  const {
    state: std,
    setState: setStd,
    onChange: stdOnChange,
  } = useInputState('');
  const {
    state: category,
    setState: setCategory,
    onChange: categoryOnChange,
  } = useInputState('');
  const { state: startDate, setState: setStartDate } = useInputState('');
  const { state: endDate, setState: setEndDate } = useInputState('');
  const startDateOnChange = (e) => {
    if (monthDateRegex.test(e.target.value)) {
      setStartDate(e.target.value);
    }
  };
  const endDateOnChange = (e) => {
    if (monthDateRegex.test(e.target.value)) {
      setEndDate(e.target.value);
    }
  };
  const goBack = () => {
    history.goBack();
  };
  useEffect(() => {
    if (startDate?.length === 5) {
      setStartDate((current) =>
        current.replace(/\./g, '').replace(/(\d{2})(\d{2})/, '$1.$2')
      );
    }
    if (startDate?.length === 4) {
      setStartDate((current) => current.replace(/(\d{2})(\d{2})/, '$1.$2'));
    }
    if (startDate?.length === 3) {
      setStartDate((current) => current.replace(/(\d{1})(\d{2})/, '$1.$2'));
    }
    if (endDate?.length === 5) {
      setEndDate((current) =>
        current.replace(/\./g, '').replace(/(\d{2})(\d{2})/, '$1.$2')
      );
    }
    if (endDate?.length === 4) {
      setEndDate((current) => current.replace(/(\d{2})(\d{2})/, '$1.$2'));
    }
    if (endDate?.length === 3) {
      setEndDate((current) => current.replace(/(\d{1})(\d{2})/, '$1.$2'));
    }
  }, [endDate, setEndDate, setStartDate, startDate]);
  const [color, setColor] = useState('#000');
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setHavePermission(userContext.user.role.match('admin') !== null);
  }, [userContext.user.role]);

  useEffect(() => {
    keywordAPI.getKeywordDetail(id).then((response) => {
      setTitle(response.keyword.title);
      setStd(response.keyword.std);
      setCategory(response.keyword.category);
      setColor(response.keyword.color);
      setStartDate(response.keyword.start_date);
      setEndDate(response.keyword.end_date);
      const initialPhrases = response.keyword.text.split('/');
      setPhrases(initialPhrases);
    });
  }, [id]);

  const editCustomKeyword = async (e, isTempSave = false) => {
    e.preventDefault();
    try {
      const postData = {
        id: id,
        title: title,
        std: std,
        category: category,
        text: phrases.join('/'),
        start_date: startDate,
        end_date: endDate,
        color: color,
      };
      await keywordAPI
        .editCustomKeyword(postData)
        .then((response) => {
          alert(`커스텀 키워드 ${id} ${title} 수정이 완료되었습니다.`);
          refetch();
          if (!isTempSave) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  return (
    <Container>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>커스텀 키워드</Title>
          <SubTitle>커스텀 키워드 상세 페이지입니다.</SubTitle>
        </TitleWrapper>
        <ButtonWrapper>
          <ButtonCancel onClick={goBack} text="취소" />
          <ButtonConfirm
            onClick={(e) => {
              editCustomKeyword(e);
            }}
            color={palette.mainBlue}
            text="임시 저장"
          />
          <ButtonConfirm
            onClick={(e) => {
              editCustomKeyword(e);
            }}
            text="키워드 저장"
          />
        </ButtonWrapper>
      </HeaderWrapper>
      <LayoutWrapper fromBottom="100px">
        <CommonContainer>
          <TextComponents>
            <TextLabel>제목</TextLabel>
            <TextInput value={title} onChange={titleOnChange} />
          </TextComponents>
          <TextComponents>
            <TextLabel>대분류</TextLabel>
            <TextInput value={std} onChange={stdOnChange} />
          </TextComponents>
          <TextComponents>
            <TextLabel>소분류</TextLabel>
            <TextInput value={category} onChange={categoryOnChange} />
          </TextComponents>
          <TextComponents>
            <TextLabel>시작날짜</TextLabel>
            <TextInput value={startDate} onChange={startDateOnChange} />
          </TextComponents>
          <TextComponents>
            <TextLabel>종료날짜</TextLabel>
            <TextInput value={endDate} onChange={endDateOnChange} />
          </TextComponents>
          <TextComponents>
            <TextLabel>키워드 색상</TextLabel>
            <PickerOffsetContainer>
              <StyledButton
                color={color}
                onClick={() => setShowPicker((show) => !show)}
              >
                색상 선택
              </StyledButton>
              {showPicker ? (
                <PickerContainer onClick={(e) => e.stopPropagation()}>
                  <SketchPicker
                    color={color}
                    onChangeComplete={handleChangeComplete}
                  />
                </PickerContainer>
              ) : null}
            </PickerOffsetContainer>
          </TextComponents>
          <TextComponents>
            <TextLabel>문장</TextLabel>
            <div>
              {phrases.map((phrase, index) => (
                <PhraseInputContainer key={index}>
                  <TextInput
                    value={phrase}
                    onChange={(e) => updatePhrase(index, e.target.value)}
                    placeholder="키워드 문구를 입력하세요"
                  />
                  {phrases.length > 1 && (
                    <DeleteButton onClick={() => deletePhrase(index)} />
                  )}
                </PhraseInputContainer>
              ))}
              {phrases[phrases.length - 1].trim() !== '' && (
                <AddButton onClick={addPhrase}>+</AddButton>
              )}
            </div>
          </TextComponents>
        </CommonContainer>
      </LayoutWrapper>
      <LayoutWrapper>
        <CommonContainer>
          {/* <DetailKeyword
            havePermission={havePermission}
            typeData="curation"
            id={id}
            keywordAllData={keywordAllData}
            keywordAllRefetch={refetch}
            // keywordConnectedLoading={isLoadingKeyword}
            // keywordConnectedData={curationKeyword}
            // keywordConnectedRefetch={refetchKeyword}
          /> */}
        </CommonContainer>
      </LayoutWrapper>
    </Container>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleWrapper = styled.div``;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 350px;
  justify-content: space-around;

  .downloadbtn {
    width: 80px;
    height: 35px;
    padding: 9px 10px;
    border-radius: 5px;
    background-color: ${palette.mint};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${palette.white};
    font-size: 12px;
    font-weight: 700;
`;
const TextComponents = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;

  .react-datepicker-wrapper {
    width: 200px;
  }
  .react-datepicker__input-container {
    height: 36px;
    input {
      height: 100%;
      text-align: center;
    }
  }
`;
const TextLabel = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  width: 80px;
  color: ${palette.fontDefault};
  height: 100%;
  align-items: center;
`;
const TextInput = styled.input`
  height: 36px;
  width: 400px;
  font-size: 14px;
  padding: 0 10px;
`;
const BodyWrapper = styled.div`
  .ql-editor {
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .ql-editor strong {
    font-weight: bold;
  }
  .ql-editor em {
    font-style: italic;
  }
`;
const ContentsContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;
const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;
const ContentsTitle = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: ${palette.fontDefault};
`;

// 우클릭 메뉴
const CustomContextMenu = styled.ul`
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  width: 150px;
  height: auto;
  margin: 0;
  position: fixed;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 99;
`;

const CustomContextMenuItem = styled.li`
  padding: 10px 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.color || '#007bff'};
  color: ${(props) => getComplementaryColor(props.color || '#007bff')};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: ${(props) => (props.color ? props.color : '#0056b3')};
  }
`;

const PickerOffsetContainer = styled.div`
  position: relative;
  height: 36px;
`;

const PickerContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: calc(100% + 10px);
`;

const PhraseInputContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const DeleteButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-image: url(${icon_trashbin});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #555;
`;
const AddButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px; // 아이콘의 실제 크기에 따라 조정
  cursor: pointer;
  margin-left: 5px;
  border-radius: 55px; // 둥근 모서리
  box-sizing: border-box;
  border: 1px solid #555; // 테두리
  text-align: center;

  &:hover {
    background-color: #9acfea;
  }

  font-size: 24px;
  color: #555;
`;

export default CustomKeywordDetailContainer;
