import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import useInputState from 'modules/customHooks/useInputState';
import { feedbackAPI } from 'modules/api';
import { useEffect } from 'react';

const ModalWrapper = styled.div`
  max-height: 100vh;
  max-width: 80wh;
  overflow: scroll;
`;

const TitleWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  color: ${palette.fontBlack};
  font-size: 20px;
  font-weight: 700;
`;
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
`;
const DeveloperMessage = styled.div`
  color: ${palette.fontBlack};
  font-size: 15px;
  font-weight: 500;
  margin: 20px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;
const FeedbackTextarea = styled.textarea`
  all: unset;
  height: 200px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  letter-spacing: 0px;
  font-size: 16px;
  opacity: 1;
  margin-bottom: 12px;
  padding: 8px;
  white-space: pre-line;
`;
const SubmitButton = styled.div`
  float: right;
  height: 30px;
  background-color: ${palette.mainOrange};
  border-radius: 8px;
  color: ${palette.fontWhite};
  line-height: 30px;
  font-size: 15px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
`;

const FeedbackModal = ({ user, closeModal }) => {
  const {
    state: feedback,
    setState: setFeedback,
    onChange: feedbackOnChange,
  } = useInputState('');

  const sendFeedback = async () => {
    if (feedback === null || feedback.length === 0) {
      alert('피드백 내용을 입력해주세요!');
    } else {
      try {
        const postData = {
          type: 'admin',
          text: feedback,
          user_contact: user.phone,
          user_nickname: user.name,
        }
        feedbackAPI.sendFeedback(postData).then((response) => {
          alert('피드백 감사합니다. 노력해볼게요...!');
          closeModal();
        });
      } catch (err) {
        console.error(err);
      }
    }
  };
  // set 'ESC' keystroke to escape
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <ModalWrapper>
      <TitleWrapper>
        <TitleContainer>
          <Title>피드백 보내기</Title>
        </TitleContainer>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </TitleWrapper>
      <DeveloperMessage>
        안녕하세요 큐레이터/에디터 여러분!
        <br />
        어드민 사이트 사용시 불편한 점이나 요구사항이 있다면 피드백 남겨주세요.
        <br />
        최대한 빨리 처리해보겠습니다...! (from. Developers)
      </DeveloperMessage>
      <ContentWrapper>
        <FeedbackTextarea value={feedback} onChange={feedbackOnChange} />
        <ButtonContainer>
          <SubmitButton onClick={sendFeedback}>피드백 전송</SubmitButton>
        </ButtonContainer>
      </ContentWrapper>
    </ModalWrapper>
  );
};

export default FeedbackModal;
