import { TitleText } from 'containers/user/UserDetailContainer';
import { palette } from 'modules/defines/styles';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import StoreIcon from 'assets/svg/Store';
import CurationIcon from 'assets/svg/Curation';
import styled from 'styled-components';
import { userAPI } from 'modules/api';
import { TailSpin } from 'react-loader-spinner';
import { pagePaths } from 'modules/defines/paths';

const UserDetailRecommend = ({ user_id }) => {
  const history = useHistory();
  const [recData, setRecData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSpaceMode, setIsSpaceMode] = useState(true);

  useEffect(() => {
    if (loading) {
      userAPI.getUserRecommend(user_id).then((response) => {
        setRecData(response);
      });
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [recData]);

  const recItemClickListener = (id, type) => {
    if (type === 'space') {
      history.push(pagePaths.space.detail.replace(':id', id));
    } else if (type === 'curation') {
      history.push(pagePaths.curation.detail.replace(':id', id));
    }
  };
  return (
    <Container>
      <TitleText>추천 {isSpaceMode ? '공간' : '큐레이션'}</TitleText>
      <SwitchSpaceCurationBtn onClick={() => setIsSpaceMode(!isSpaceMode)}>
        {isSpaceMode ? (
          <StoreIcon size={25} color={palette.iconDefault} />
        ) : (
          <CurationIcon size={25} color={palette.iconDefault} />
        )}
      </SwitchSpaceCurationBtn>
      {recData !== null && recData.length !== 0 ? (
        <RecContainer>
          {isSpaceMode
            ? recData?.spaces.map((item, idx) => (
                <RecommendElement
                  key={idx + '_space'}
                  onClick={() => recItemClickListener(item.id, 'space')}
                >
                  <RecommendTextLayout>
                    <RecommendTextId>{item.id}</RecommendTextId>
                    <RecommendTextTitle>{item.title}</RecommendTextTitle>
                  </RecommendTextLayout>
                  <RecommendTextWeight>{item.score}</RecommendTextWeight>
                </RecommendElement>
              ))
            : recData?.curations.map((item, idx) => (
                <RecommendElement
                  key={idx + '_curation'}
                  onClick={() => recItemClickListener(item.id, 'curation')}
                >
                  <RecommendTextLayout>
                    <RecommendTextId>{item.id}</RecommendTextId>
                    <RecommendTextTitle>{item.title}</RecommendTextTitle>
                  </RecommendTextLayout>
                  <RecommendTextWeight>{item.score}</RecommendTextWeight>
                </RecommendElement>
              ))}
        </RecContainer>
      ) : (
        <LoadingContainer>
          <TailSpin color={palette.mainBlue} width={100} height={100} />
        </LoadingContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const SwitchSpaceCurationBtn = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
`;

const RecContainer = styled.div``;

const RecommendElement = styled.div`
  margin-bottom: 10px;
  width: 240px;
  height: auto;
  background-color: ${palette.background};
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
`;
const RecommendTextLayout = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
`;
const RecommendTextId = styled.div`
  font-size: 12px;
  color: ${palette.fontDefault};
  font-weight: 500;
`;
const RecommendTextTitle = styled.div`
  margin-top: 8px;
  font-size: 15px;
  color: ${palette.fontDefault};
  font-weight: 300;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
`;
const RecommendTextWeight = styled.div`
  font-size: 18px;
  color: ${palette.fontDefault};
  font-weight: 600;
  position: absolute;
  top: 20px;
  right: 16px;
`;
const LoadingContainer = styled.div``;
export default UserDetailRecommend;
