import { useTable, useFilters } from 'react-table';
import styled from 'styled-components';
import CurationDeleteButton from 'components/curation/CuratedDeleteButton';
import { palette } from 'modules/defines/styles';

const Styles = styled.div`
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead th:nth-child(1) {
      width: 60px;
    }
    thead th:nth-child(2) {
      width: 80px;
    }
    thead th:nth-child(3) {
    }
    thead th:nth-child(4) {
      width: 50px;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      text-align: left;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 600;
      color: ${palette.white};
      padding-top: 10px;
      padding-bottom: 10px;
      letter-spacing: 0px;
      background-color: ${palette.grey_lighter};
      opacity: 1;
      border-bottom: 1px solid #8bcec6;
      border-left: 1px solid rgb(112, 112, 112, 0.1);
      border-right: 1px solid rgb(112, 112, 112, 0.1);
      padding-left: 20px;
      :first-child {
        text-align: center;
        padding: 0px;
      }
      :last-child {
        text-align: center;
        padding: 0px;
      }
    }

    td {
      text-align: left;
      vertical-align: middle;
      font-size: 13px;
      color: ${palette.fontDefault};
      padding: 6px;
      border-left: 1px solid rgb(112, 112, 112, 0.1);
      border-right: 1px solid rgb(112, 112, 112, 0.1);

      :first-child {
        text-align: center;
        padding: 0px;
      }
      :last-child {
        text-align: center;
        padding: 0px;
      }
      :nth-child(2) {
        font-size: 13px;
        line-height: 16px;

        @media screen and (max-width: 1200px) {
          font-size: 12px;
        }
      }
      :nth-child(3) {
        font-size: 12px;
        line-height: 16px;

        @media screen and (max-width: 1200px) {
          font-size: 11px;
          line-height: 14px;
        }
      }
    }

    tbody tr:nth-child(odd) {
      background-color: #fbfbfb;
    }
    tbody tr:nth-child(even) {
      background-color: #ffffff;
    }
  }
`;

const CuratedTable = ({ columns, data, havePermission }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters
    );
  return (
    <Styles>
      {data ? (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.column.Header === '삭제' ? (
                          havePermission ? (
                            <CurationDeleteButton
                              curationId={row.original.curationId}
                              curatedRefetch={row.original.curatedRefetch}
                              curatedId={cell.value}
                            />
                          ) : null
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </Styles>
  );
};

export default CuratedTable;
