import { useEffect, useState, useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { palette } from 'modules/defines/styles';
import useAttachCurated from 'modules/customHooks/useAttachCurated';
import CuratedTable from 'components/table/CuratedTable';
import SearchTable from 'components/table/SearchTable';
import { ModalContext } from 'modules/context/ModalContext';
import styled from 'styled-components';
import SetOrderModal from 'components/modal/SetOrderModal';
import MirroringCuratedModal from 'components/modal/MirroringCuratedModal';
import { pagePaths } from 'modules/defines/paths';

const CurationDetailCurated = ({
  id,
  havePermission,
  curatedData,
  curatedRefetch,
  spaceData,
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const [needOrder, setNeedOrder] = useState(false);

  const {
    setId: a_setId,
    elementId,
    reason,
    setReason,
    elementIdHandler,
    reasonHandler,
    attachSpaceCurated,
  } = useAttachCurated(curatedRefetch);
  useEffect(() => {
    a_setId(id);
  }, [a_setId, id]);

  /////////// CURATED /////////////////////////////////////////////////////
  const curatedTableColumns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: '매장 ID',
      },
      {
        accessor: 'title',
        Header: '매장 이름',
        Cell: ({ row }) => (
          <Link to={pagePaths.space.detail.replace(':id', row.values.id)}>
            {row.values.title}
          </Link>
        ),
      },
      { accessor: 'reason', Header: '큐레이션 이유' },
      { accessor: 'deleteId', Header: '삭제' },
    ],
    [closeModal, openModal]
  );
  const curatedTableData = useMemo(() => {
    if (curatedData === null) {
      return [];
    }
    return curatedData.map((item) => ({
      ...item,
      reason: item.CurationSpace?.reason,
      deleteId: item.id,
      curationId: id,
      curatedRefetch: curatedRefetch,
    }));
  }, [curatedData, curatedRefetch, id]);
  const searchTableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: '매장 ID',
      filter: 'includes',
    },
    {
      accessor: 'title',
      Header: '매장 이름',
      filter: 'includes',
    },
  ]);
  const searchTableData = spaceData ? spaceData : [];

  const saveCurationOrder = () => {
    closeModal();
    curatedRefetch();
  };

  const OrderModalListener = async (e) => {
    e.preventDefault();
    if (curatedTableData.length === 0) {
      alert('우선 공간을 추가해주세요 :)');
    } else {
      return openModal(
        <SetOrderModal
          closeModal={saveCurationOrder}
          curatedData={curatedData}
        />
      );
    }
  };

  useEffect(() => {
    curatedData?.map((space) => {
      if (space.CurationSpace.order === null) {
        setNeedOrder(true);
      }
    });
  }, [curatedData]);

  // Modal Mirroring
  const ModalMirroring = async (e) => {
    e.preventDefault();
    return openModal(
      <MirroringCuratedModal
        reasonProps={reason}
        setReasonProps={setReason}
        closeModal={closeModal}
      />
    );
  };
  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle>포함된 공간 정보</HeaderTitle>
        {havePermission && (
          <HeaderBtnContainer>
            {needOrder && (
              <NeedOrderWrapper>순서가 필요해요! ➡️</NeedOrderWrapper>
            )}
            <HeaderBtn
              color={palette.buttonCancel}
              onClick={(e) => OrderModalListener(e)}
            >
              순서
            </HeaderBtn>
          </HeaderBtnContainer>
        )}
      </HeaderContainer>
      <ContentsContainer>
        <ContentsWrapper>
          <CuratedTableWrapper>
            {curatedTableColumns && curatedTableData && (
              <CuratedTable
                columns={curatedTableColumns}
                data={curatedTableData}
                havePermission={havePermission}
              />
            )}
          </CuratedTableWrapper>
          {curatedTableData.length === 0 && (
            <NoSpaceWrapper>
              포함된 공간이 없습니다. 추가해주세요!
            </NoSpaceWrapper>
          )}
          {havePermission && (
            <>
              <ItemWrapper>
                <ItemContainerWrapper>
                  <ItemContainer>
                    <ItemTitle>큐레이션 ID</ItemTitle>
                    <ItemTextInput
                      value={elementId}
                      onChange={elementIdHandler}
                      disabled={!havePermission}
                    />
                  </ItemContainer>
                  {/* 큐레이션 설명 */}
                  <ItemContainer>
                    <ItemTitleContainer>
                      <ItemTitle>이유</ItemTitle>
                      <ItemDesc>
                        공백 포함
                        <br />
                        최대 70자
                      </ItemDesc>
                      {reason != null && (
                        <ItemDesc2 warning={reason.length > 70}>
                          {reason.length} / 70
                        </ItemDesc2>
                      )}
                    </ItemTitleContainer>
                    <ItemTextArea
                      height="60px"
                      value={reason}
                      onChange={reasonHandler}
                      disabled={!havePermission}
                    />
                  </ItemContainer>
                </ItemContainerWrapper>
                <AddCuratedButton onClick={attachSpaceCurated}>
                  등록
                </AddCuratedButton>
              </ItemWrapper>
              <MirrorButton
                color={palette.fontGray}
                onClick={(e) => ModalMirroring(e)}
              >
                미러링
              </MirrorButton>
            </>
          )}
          {/* 매장 ID 확인 용 */}
          {havePermission && (
            <SearchTableWrapper>
              <HeaderTitle margin_top="30px" margin_bottom="12px">
                공간 정보 찾기
              </HeaderTitle>
              <SearchTable
                columns={searchTableColumns}
                data={searchTableData}
                tableHeight={300}
              />
            </SearchTableWrapper>
          )}
        </ContentsWrapper>
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
// HEADER
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.div`
  font-size: 18px;
  font-family: Pretendard-b;
  color: ${palette.fontDefault};
  margin-top: ${(props) => (props.margin_top ? props.margin_top : '0px')};
  margin-bottom: ${(props) =>
    props.margin_bottom ? props.margin_bottom : '0px'};
`;
const HeaderBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const HeaderBtn = styled.div`
  font-family: Pretendard-m;
  font-size: 14px;
  color: ${palette.grey_darker};
  border: 1px solid ${palette.grey_darker};
  border-radius: 16px;
  padding: 4px 16px 4px 16px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${palette.grey_darker};
    color: ${palette.white};
  }
  margin-left: 16px;
`;

const MirrorButton = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : palette.mainOrange)};
  background-color: ${palette.white};
  border: 1px solid
    ${(props) => (props.color ? props.color : palette.mainOrange)};
  border-radius: 16px;
  padding: 4px 16px 4px 16px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainOrangeHover};
    color: ${palette.white};
  }
  width: 80px;
`;
// CONTENTS
const ContentsContainer = styled.div`
  margin-top: 12px;
`;
const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const ItemContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ItemContainer = styled.div`
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
`;
const ItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemTitle = styled.div`
  font-family: Pretendard-m;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0'}px;
  min-width: 70px;
  text-align: left;
  font-size: 12px;
  color: ${palette.fontDefault};
`;
const ItemDesc = styled.div`
  text-size: 6px;
  display: flex;
  margin-top: 4px;
  color: ${palette.fontGray};
  line-height: 12px;
`;
const ItemDesc2 = styled.div`
  color: ${(props) => (props.warning ? palette.red : palette.black)};
`;
const ItemTextArea = styled.textarea`
  all: unset;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '60px')};
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  color: ${palette.fontBlack};
  font-size: 16px;
  line-height: 1.1;
  overflow: scroll;
`;

const AddCuratedButton = styled.div`
  display: flex;
  height: 100px;
  width: 50px;
  border-radius: 10px;
  color: ${palette.white};
  background-color: ${palette.mainOrangeHover};
  &:hover {
    background-color: ${palette.mainOrange};
  }
  font-size: 14px;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
`;

const CuratedTableWrapper = styled.div`
  margin-bottom: 20px;
  max-height: 350px;
  overflow-y: auto;
`;

const ItemTextInput = styled.input`
  all: unset;
  width: 100%;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  font-size: 16px;
  color: ${(props) =>
    props.readOnly ? palette.mainOrange : palette.fontBlack};
  opacity: 1;
`;

const SearchTableWrapper = styled.div`
  margin-bottom: 30px;
  flex-grow: 0;
`;

const NoSpaceWrapper = styled.div`
  width: 100%;
  height: 40px;
  color: ${palette.mainOrange};
  font-size: 14px;
  margin-left: 8px;
  display: flex;
  margin-bottom: 40px;
  align-items: center;
`;

const NeedOrderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${palette.mainOrange};
  font-size: 14px;
`;

export default CurationDetailCurated;
