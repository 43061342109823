import { httpAPI } from 'modules/api/http';
import { apiPaths } from 'modules/defines/paths';

const PanoramaAPI = (() => {
  class PanoramaAPIConstructor {
    getPanorama(id) {
      return httpAPI(apiPaths.getPanorama + id, { method: 'GET' });
    }
    registerPanorama(data) {
      return httpAPI(apiPaths.registerPanorama, { data }).call;
    }
    deletePanoramaImage(id) {
      return httpAPI(apiPaths.deletePanoramaImage + id, { method: 'DELETE' })
        .call;
    }
    deletePanoramaImageAll(id) {
      return httpAPI(apiPaths.deletePanoramaImageAll + id, { method: 'DELETE' })
        .call;
    }
    refreshPanoramaImage(id) {
      return httpAPI(apiPaths.refreshPanoramaImage + id, { method: 'DELETE' })
        .call;
    }
    uploadPanoramaImage(data, headers) {
      return httpAPI(apiPaths.uploadPanoramaImage, { data, headers }).call;
    }
    formatPanoramaMode(data) {
      return httpAPI(apiPaths.formatPanoramaMode, { data }).call;
    }
    editPanoramaInfo(data) {
      return httpAPI(apiPaths.editPanorama, { data }).call;
    }
    editPanoramaRoomNameAll(data) {
      return httpAPI(apiPaths.editPanoramaRoomNameAll, { data }).call;
    }
    loadPanoramaFromDefaultMode(data) {
      return httpAPI(apiPaths.loadPanoramaFromDefaultMode, { data }).call;
    }
    changePanoramaMode(data) {
      return httpAPI(apiPaths.changePanoramaMode, { data }).call;
    }
    attachPanoramaRelationBlock(data) {
      return httpAPI(apiPaths.attachPanoramaRelationBlock, { data }).call;
    }
    attachPanoramaRelationConnect(data) {
      return httpAPI(apiPaths.attachPanoramaRelationConnect, { data }).call;
    }
    attachPanoramaRelationExternal(data) {
      return httpAPI(apiPaths.attachPanoramaRelationExternal, { data }).call;
    }
    detachPanoramaRelation(data) {
      return httpAPI(apiPaths.detachPanoramaRelation, { data }).call;
    }
    detachPanoramaRelationExternal(data) {
      return httpAPI(apiPaths.detachPanoramaRelationExternal, { data }).call;
    }
  }
  return PanoramaAPIConstructor;
})();

export const panoramaAPI = new PanoramaAPI();
