import CustomKeywordDetailContainer from 'containers/keyword/CustomKeywordDetailContainer';
import GlobalContainer from 'containers/GlobalContainer';

const CustomKeywordDetail = () => {
  return (
    <GlobalContainer>
      <CustomKeywordDetailContainer />
    </GlobalContainer>
  );
};

export default CustomKeywordDetail;
