import { useState } from 'react';
import { useTable, useFilters, useGlobalFilter } from 'react-table';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { SearchFilter } from 'components/common/Filter';
import KeywordDeleteButton from 'components/button/KeywordDeleteButton';

const TableWrapper = styled.div`
  height: 100%;
  // height: ${(props) => props.tableHeight}px;
  overflow-y: scroll;
`;
const CheckBox = styled.input`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`;
const TableWithSearch = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Styles = styled.div`
  table {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead th:nth-child(1) {
      width: 60%;
    }
    thead th:nth-child(2) {
      width: 25%;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      text-align: center;
      vertical-align: middle;
      font-size: 13px;
      letter-spacing: 0px;
      color: ${palette.fontWhite};
      background-color: #707070;
      opacity: 1;
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid #707070;
      border-left: 1px solid rgb(112, 112, 112, 0.1);
      border-right: 1px solid rgb(112, 112, 112, 0.1);
      :first-child {
        text-align: center;
      }
      :last-child {
        padding: 0px;
      }
    }
    td {
      text-align: center;
      vertical-align: middle;
      font-size: 12px;
      letter-spacing: 0px;
      color: ${palette.fontDefault};
      opacity: 1;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid rgb(112, 112, 112, 0.1);
      border-right: 1px solid rgb(112, 112, 112, 0.1);
      :first-child {
        padding-left: 20px;
        text-align: left;
      }
    }

    tbody tr:nth-child(odd) {
      background-color: #fbfbfb;
    }
    tbody tr:nth-child(even) {
      background-color: #ffffff;
    }
  }
`;
const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
`;
const SubmitButton = styled.button`
  all: unset;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
`;
const RegisterButtonUp = (props) => (
  <svg
    version="1.1"
    width="40px"
    height="40px"
    viewBox="0 0 40.0 40.0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="i0">
        <path d="M40,0 L40,40 L0,40 L0,0 L40,0 Z"></path>
      </clipPath>
      <clipPath id="i1">
        <path d="M19.9995136,0 C31.0274222,0 39.9987031,8.97257776 39.9987031,19.9998379 C39.9987031,31.028395 31.0274222,40 19.9995136,40 C8.97160505,40 0,31.028395 0,19.9998379 C0,8.97257776 8.97160505,0 19.9995136,0 Z M19.9931776,14 C19.8723942,13.998795 19.7662417,14.0409657 19.6871721,14.1185353 L11.1159067,22.5942436 C10.959324,22.7481515 10.9599466,23.0224154 11.1159067,23.1766312 L11.8147696,23.8661386 C11.8916601,23.944016 11.9956334,23.9868024 12.1083231,23.9868024 C12.2194563,23.9868024 12.3240523,23.944016 12.402188,23.8661386 L19.778071,16.5736742 C19.8957415,16.4576277 20.0850103,16.4576277 20.2020582,16.5736742 L27.5910157,23.8790668 C27.7528904,24.0403623 28.0181159,24.0403623 28.1784341,23.878759 L28.877297,23.188636 C28.9554327,23.1122977 28.9987031,23.0073325 28.9987031,22.89775 C28.9987031,22.7872442 28.9554327,22.6844337 28.877297,22.6071719 L20.2929571,14.1182275 C20.2132649,14.0409657 20.1080464,13.998795 19.9931776,14 Z"></path>
      </clipPath>
      <clipPath id="i2">
        <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z"></path>
      </clipPath>
      <clipPath id="i3">
        <path d="M9,0 C9.55228475,-1.01453063e-16 10,0.44771525 10,1 C10,1.55228475 9.55228475,2 9,2 L1,2 C0.44771525,2 -1.5440923e-16,1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L9,0 Z"></path>
      </clipPath>
    </defs>
    <g clipPath="url(#i0)">
      <g transform="translate(0.0012969432668299419 0.0)">
        <g clipPath="url(#i1)">
          <polygon
            points="0,0 39.9987031,0 39.9987031,40 0,40 0,0"
            stroke="none"
            fill="#BBBBBB"
          ></polygon>
        </g>
      </g>
      <g transform="translate(24.0 0.0)">
        <g clipPath="url(#i2)">
          <polygon
            points="0,0 16,0 16,16 0,16 0,0"
            stroke="none"
            fill="#FA4615"
          ></polygon>
        </g>
        <g transform="translate(3.0 7.0)">
          <g clipPath="url(#i3)">
            <polygon
              points="0,0 10,0 10,2 0,2 0,0"
              stroke="none"
              fill="#FFFFFF"
            ></polygon>
          </g>
        </g>
        <g transform="translate(9.0 3.0) rotate(90.0)">
          <g clipPath="url(#i3)">
            <polygon
              points="0,0 10,0 10,2 0,2 0,0"
              stroke="none"
              fill="#FFFFFF"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const RegisterButtonLeft = (props) => (
  <svg
    version="1.1"
    width="40px"
    height="40px"
    viewBox="0 0 40.0 40.0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="i0">
        <path d="M40,0 L40,40 L0,40 L0,0 L40,0 Z"></path>
      </clipPath>
      <clipPath id="i1">
        <path d="M19.9995136,0 C31.0274222,0 39.9987031,8.97257776 39.9987031,19.9998379 C39.9987031,31.028395 31.0274222,40 19.9995136,40 C8.97160505,40 0,31.028395 0,19.9998379 C0,8.97257776 8.97160505,0 19.9995136,0 Z M21.8964531,11 C21.7859472,11 21.6831367,11.0432704 21.605875,11.121406 L13.1169305,19.705746 C13.0396688,19.7854381 12.997498,19.8906567 12.9987031,20.0055255 C12.997498,20.1263089 13.0396688,20.2324614 13.1172384,20.3115309 L21.5929467,28.8827964 C21.7468546,29.039379 22.0211185,29.0387564 22.1753342,28.8827964 L22.8648416,28.1839335 C22.942719,28.107043 22.9855054,28.0030696 22.9855054,27.8903799 C22.9855054,27.7792467 22.942719,27.6746508 22.8648416,27.5965151 L15.5723773,20.220632 C15.4563307,20.1029616 15.4563307,19.9136927 15.5723773,19.7966448 L22.8777699,12.4076873 C23.0390654,12.2458126 23.0390654,11.9805871 22.8774621,11.8202689 L22.187339,11.121406 C22.1110007,11.0432704 22.0060355,11 21.8964531,11 Z"></path>
      </clipPath>
      <clipPath id="i2">
        <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z"></path>
      </clipPath>
      <clipPath id="i3">
        <path d="M9,0 C9.55228475,-1.01453063e-16 10,0.44771525 10,1 C10,1.55228475 9.55228475,2 9,2 L1,2 C0.44771525,2 -1.5440923e-16,1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L9,0 Z"></path>
      </clipPath>
    </defs>
    <g clipPath="url(#i0)">
      <g transform="translate(0.0012969432668299419 0.0)">
        <g clipPath="url(#i1)">
          <polygon
            points="0,0 39.9987031,0 39.9987031,40 0,40 0,0"
            stroke="none"
            fill="#BBBBBB"
          ></polygon>
        </g>
      </g>
      <g transform="translate(24.0 0.0)">
        <g clipPath="url(#i2)">
          <polygon
            points="0,0 16,0 16,16 0,16 0,0"
            stroke="none"
            fill="#FA4615"
          ></polygon>
        </g>
        <g transform="translate(3.0 7.0)">
          <g clipPath="url(#i3)">
            <polygon
              points="0,0 10,0 10,2 0,2 0,0"
              stroke="none"
              fill="#FFFFFF"
            ></polygon>
          </g>
        </g>
        <g transform="translate(9.0 3.0) rotate(90.0)">
          <g clipPath="url(#i3)">
            <polygon
              points="1.42108547e-14,0 10,0 10,2 1.42108547e-14,2 1.42108547e-14,0"
              stroke="none"
              fill="#FFFFFF"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const SearchTable = ({
  columns,
  data,
  title,
  refetch,
  isSubmit,
  submitDir = 'up',
  onSubmit,
  tableHeight,
  isSearchable,
  first_keyword,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  const [checkedValueState, setCheckedValueState] = useState([]);
  const onClicked = (e) => {
    let checked = [...checkedValueState];
    if (checked.includes(+e.target.value)) {
      checked = checked.filter((el) => el !== +e.target.value);
    } else {
      checked.push(+e.target.value);
    }
    checked.sort();
    setCheckedValueState(checked);
  };
  const submit = (e) => {
    e.preventDefault();
    onSubmit(checkedValueState);
    setCheckedValueState([]);
  };

  return (
    <>
      {isSubmit ? (
        <SubmitButtonWrapper>
          <SubmitButton onClick={submit}>
            {submitDir === 'up' ? <RegisterButtonUp /> : <RegisterButtonLeft />}
          </SubmitButton>
        </SubmitButtonWrapper>
      ) : null}
      <TableWithSearch>
        {isSearchable && (
          <SearchFilter
            placeholder={title}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <TableWrapper tableHeight={tableHeight}>
          <Styles>
            {data ? (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.column.Header === '삭제' ? (
                                <KeywordDeleteButton
                                  id={cell.value}
                                  refetch={refetch}
                                />
                              ) : cell.column.Header === '선택' ? (
                                <CheckBox
                                  type="checkbox"
                                  value={cell.value}
                                  onChange={onClicked}
                                  checked={checkedValueState.includes(
                                    cell.value
                                  )}
                                />
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
          </Styles>
        </TableWrapper>
      </TableWithSearch>
    </>
  );
};

export default SearchTable;
