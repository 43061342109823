import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

const Select = styled.select`
  all: unset;
  width: ${(props) => props.width}px;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 10px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${palette.fontBlack};
  opacity: 1;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

const OrderDropdown = ({ data, editApi, refetch, initialValue, option }) => {
  const [order, setOrder] = useState(initialValue);
  useEffect(() => {
    setOrder(initialValue);
  }, [initialValue]);

  const handleChange = async (e) => {
    const postData = {
      ...data,
      order: e.target.value,
    };
    editApi(postData)
      .then((response) => {
        alert('큐레이션 순서가 변경 되었습니다.');
        refetch();
      })
      .catch((e) => {
        console.error(e);
        alert('에러가 발생했습니다.\n관리자에게 문의하세요.');
      });
  };

  return (
    <div style={{ display: 'flex' }}>
      <Select type="select" value={order} width={100} onChange={handleChange}>
        {option.map((order) => (
          <option value={order} key={order}>
            {order}
          </option>
        ))}
      </Select>
    </div>
  );
};
export default OrderDropdown;
