import { faviewTestAPI } from 'api';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'modules/api/user';
import { palette } from 'modules/defines/styles';
import { Scatter } from 'react-chartjs-2';
import styled, { css } from 'styled-components';
import { dbscan, filterOutliersAndCalculateMean, groupByAdditionalInfo, selectLargestCluster } from 'utils/sfmClusterAlg';

const FaviewSfMContainer = () => {
  const [userContext] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState(0);  // 0: 업로드 전, 1 : 업로드 중, 2 : 업로드 완료, 프로세스 진행 중, 3: 프로세스 완료 , 4: 오류
  const [uploadTotal, setUploadTotal] = useState(0);
  const [uploadIdx, setUploadIdx] = useState(0);
  const [uploadId, setUploadId] = useState('');
  const [chartData, setChartData] = useState([]);
  const [inputData, setInputData] = useState([]);

  const [mode, setMode] = useState(1);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const { x, y } = context.raw;
            const additionalInfo = context.raw.additionalInfo;
            return `x: ${x}, y: ${y}, Info: ${additionalInfo}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
      },
    },
    elements: {
      point: {
        radius: function (context) {
          return context.raw.r; // 각 포인트의 반지름(r)을 반영
        },
      },
    },
  };

  const getStatusAPI = async (uid) => {
    await faviewTestAPI.getStatus({ uid }).then((response) => {
      console.log('-------------------')
      console.log(`status : ${response.data.status}\nvid : ${response.data.vid}`);
      setState(response.data.status);
      setUploadId(response.data.vid);
      setIsLoading(false);
    });
  }
  const loadResult = async (uid) => {
    await faviewTestAPI.loadResult({ uid }).then((res) => {
      var datas = [];
      console.log(res.data);
      res.data.result.frames.map((frame) => {
        var dataFrame = { x: parseFloat(frame.transform_matrix[0][3].toFixed(4)), y: parseFloat(frame.transform_matrix[1][3].toFixed(4)), additionalInfo: frame.file_path, r: 5 };
        datas.push(dataFrame);
      });

      setChartData({
        datasets: [
          {
            label: 'SfM Result',
            data: datas,
            backgroundColor: 'rgba(75, 192, 192, 1)',
          },
        ],
      });
      setInputData(res.data.inputFiles);
    });
  }

  const downloadData = async () => {
    if (chartData && inputData) {
      var data = chartData.datasets[0].data;

      const epsilon = 0.5; // 클러스터링 거리 기준
      const minPoints = 2; // 최소 포인트 수
      const outlierThreshold = 1.0; // 멀리 떨어진 점들 제거 기준
      const groupedData = groupByAdditionalInfo(data);
      const results = [];

      groupedData.forEach(group => {
        if (group.length < 2) return; // 2개 미만이면 군집화 필요 없음
        const clusterId = parseInt(group[0].additionalInfo.split('/')[1].split('_')[0], 10); // 파일명에서 클러스터 ID 추출
        const originalName = inputData[clusterId];

        const clusters = dbscan(group, epsilon, minPoints);
        const largestCluster = selectLargestCluster(clusters); // 가장 큰 클러스터 선택

        if (largestCluster) {
          const mean = filterOutliersAndCalculateMean(largestCluster, outlierThreshold);
          if (mean) {
            // originalName.split('_') [1] 이후 모두 합침 (join)
            mean.additionalInfo = originalName.split('_').slice(1).join('_');
            mean.r = 10;
            mean.x = mean.x.toFixed(3);
            mean.y = mean.y.toFixed(3);
            results.push(mean);
          }
        }
      });
      if (results.length !== 0) {
        setChartData({
          datasets: [
            {
              label: 'SfM Result',
              data: data,
              backgroundColor: 'rgba(75, 192, 192, 1)',
            },
            {
              label: 'Clustered Result',
              data: results,
              backgroundColor: 'rgba(255, 99, 132, 1)',
            },
          ],
        });

        // results에서 'r' 값만 제거하고, 'x', 'y', 'additionalInfo'만 남겨서 txt 파일로 다운로드 받을 수 있게 처리
        const downloadData = results.map(({ x, y, additionalInfo }) => ({ x, y, additionalInfo }));
        const downloadDataString = JSON.stringify(downloadData, null, 2);
        const blob = new Blob([downloadDataString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${uploadId}_coord.txt`;
        a.click();
        URL.revokeObjectURL(url);
      } else {
        alert('SfM 결과에 오류가 있습니다.\n다른 Method로 시도해보세요.')
      }
    } else {
      alert('데이터가 없습니다.');
    }
  }

  useEffect(() => {
    getStatusAPI(userContext.user.user_id);
  }, []);

  // 10초마다 상태 체크
  useEffect(() => {
    if (state === 2) {
      const interval = setInterval(() => {
        getStatusAPI(userContext.user.user_id);
      }, 10000);
      return () => clearInterval(interval);
    }
    if (state === 3) {
      // 불러오기
      loadResult(userContext.user.user_id);
    }
  }, [state]);

  const uploadImgPromise = (formData, headers) => {
    return new Promise((resolve, reject) => {
      faviewTestAPI
        .uploadPano(formData, headers)
        .then((response) => resolve(response))
        .catch((e) => {
          reject(e);
        });
    });
  };

  const handleFileUpload = async (e) => {
    const file_list = e.target.files;
    setUploadTotal(file_list.length);
    setState(1);
    const uploadId = new Date().getTime();  // Unique ID for the upload
    setUploadId(uploadId);

    for (let i = 0; i < file_list.length; i++) {
      setUploadIdx(i + 1);
      let file = file_list[i];
      console.log(`idx : ${i}, filename : ${file.name}`);
      let blob = file.slice(0, file.size, file.type);
      let postData = new FormData();
      // postData.append('img', new File([blob], i + '_' + file.name + '.' + file.name.split('.').pop()));
      postData.append('img', new File([blob], i + '_' + file.name));

      await uploadImgPromise(postData, { id: uploadId, uid: userContext.user.user_id });
    };
    await faviewTestAPI.processSfm({ id: uploadId, uid: userContext.user.user_id, mode: mode }).then((response) => {
      if (response.data.message === 'OK') {
        setState(2);
      } else {
        setState(4);
      }
    });
  };

  const resetProcess = async (status) => {
    await faviewTestAPI.setStatus({ uid: userContext.user.user_id, status: status }).then((response) => {
      if (response.data.status === 'STATUS_UPDATED') {
        console.log(response.data);
        setState(0);
      } else {
        alert('오류가 발생했습니다.');
        // 새로고침
        window.location.reload();
      }
    });
  }

  const setModelMode = (mode) => {
    setMode(mode);

  }

  return (<>
    {!isLoading ? (
      <Wrapper>
        {state === 0 && (
          <>
            <ModeContainer>
              <ModeButton onClick={() => setModelMode(1)} isActivate={mode === 1}>MODE 1 (RADIAL)</ModeButton>
              <ModeButton onClick={() => setModelMode(2)} isActivate={mode === 2}>MODE 2 (PINHOLE)</ModeButton>
            </ModeContainer>
            <FileInputLabel htmlFor="file-upload">파일 선택</FileInputLabel>
            <HiddenFileInput id="file-upload" onChange={handleFileUpload} multiple />
            <UploadDesc>SfM을 수행할 파노라마 이미지 여러 장을 선택해주세요.</UploadDesc></>
        )}
        {state === 1 && (
          <>
            이미지 {uploadTotal}개 중 {uploadIdx}개 업로드 중...
          </>
        )}
        {state === 2 && (
          <>
            이미지 업로드 완료, 프로세스 진행 중... (id: {uploadId})
          </>
        )}
        {state === 3 && (
          <>
            {chartData.length !== 0 ? (
              <>
                <TextStatus>프로세스 완료</TextStatus>
                <ChartContainer>
                  <Scatter data={chartData} options={options} />
                </ChartContainer>
                <ButtonContainer>
                  <Button onClick={() => resetProcess(0)}>다시 시작</Button>
                  <Button onClick={() => downloadData()}>데이터 다운로드</Button>
                </ButtonContainer>
              </>
            ) : <>
              <TextStatus>프로세스 완료, 데이터 불러오는 중...</TextStatus>
            </>}

          </>
        )}
        {state === 4 && (
          <>
            <TextStatus>프로세스 오류</TextStatus>
            <Button onClick={() => resetProcess(0)}>다시 시작</Button>
          </>
        )}
      </Wrapper>
    ) : (
      <Wrapper>
        <UploadDesc>상태 불러오는 중...</UploadDesc>
      </Wrapper>
    )}

  </>);
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-size: 1.2rem;
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #0056b3;
  }
`;

const UploadDesc = styled.div`
  font-size: 16px;
  margin-top: 20px;
  color: ${palette.fontGray};
`;

const TextStatus = styled.div`
  font-size: 20px;
  color:${palette.fontBlack};
`;

const Button = styled.div`
  background-color: ${palette.mainBlue};
  color: ${palette.white};
  font-size: 16px;
  font-weight: 800;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 24px;
  cursor: pointer;
  &:hover{
    background-color: ${palette.mainBlueHover};
  }
`;
const ChartContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 24px;
`;
const ModeContainer = styled.div`
  display:flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;
const ModeButton = styled.div`
  background-color: ${palette.mainDisabled};
  &:hover{
      background-color: ${palette.mainOrangeHover};
  }
  color: ${palette.white};
  font-size: 14px;
  font-weight: 800;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  margin-left: 12px;

  ${(props) => props.isActivate && css`
    background-color: ${palette.mainOrange};
    &:hover{
      background-color: ${palette.mainOrangeHover};
    }
  `}
`;


export default FaviewSfMContainer;
