import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { btn_curation_off, btn_curation_on } from 'assets/img/button';
import { spaceAPI } from 'api';

const PublishButton = styled.div`
  width: 39px;
  height: 24px;
  object-fit: contain;
  background-image: url(${(props) =>
    props.published ? btn_curation_on : btn_curation_off});
`;
const SpacePublishSwitchButton = (props) => {
  const [published, setPublished] = useState(props.published);
  const publishMode = props.publishMode;
  const id = props.id;

  useEffect(() => {
    setPublished(props.published);
  }, [props.published]);

  const onClick = async () => {
    const postData = {
      id,
      publishMode: publishMode,
    };
    try {
      await spaceAPI.switchPublish(postData);
      setPublished(!published);
    } catch (e) {
      console.error(e);
      if (e.response.status === 406) {
        alert(
          '공간 필수 필드 [매장명, 주소, 설명 2개 모두, 대표+상세이미지] 중 비어있는 필드가 존재합니다.\nPublish를 위해서 공간 정보를 다시 한번 확인해주세요.'
        );
      } else if (e.response.status === 409) {
        alert(
          '공간이 발행 중인 큐레이션에 소속되어있어 Off할수 없습니다.\n큐레이션의 publish를 먼저 Off해주세요.'
        );
      } else {
        alert(
          '공간을 Publish하는 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      // props.refetchList();
    }
  };

  const onClickNoPermission = async () => {
    alert('Super-Admin만 가능한 기능입니다.\n 관리자에게 문의해주세요.');
  }

  return published === undefined
    ? null
    : props.havePermission === undefined ? (
      <PublishButton published={published} onClick={onClick} />
    ) : (
      <PublishButton published={published} onClick={props.havePermission ? onClick : onClickNoPermission} />
    );
};

export default SpacePublishSwitchButton;
