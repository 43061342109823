import { palette } from 'modules/defines/styles';
import styled, { createGlobalStyle } from 'styled-components';
import { useState, useEffect } from 'react';
import PretendardWoffBold from 'assets/fonts/pretendard/Pretendard-Bold.woff';
import PretendardWoffRegular from 'assets/fonts/pretendard/Pretendard-Regular.woff';
import PretendardWoffLight from 'assets/fonts/pretendard/Pretendard-Light.woff';
import GmarketWoffMedium from 'assets/fonts/gmarket/GmarketSansMedium.woff';
import GmarketWoffBold from 'assets/fonts/gmarket/GmarketSansBold.woff';
import SampleImgInsta from 'assets/img/insta-space-sample.png';
import SampleImgApp from 'assets/img/app-space-sample.png';
import IconInstaPerson from 'assets/img/insta-icon-person.png';
import IconInstaArrow from 'assets/img/insta-icon-arrow.png';

import CoffeeIcon from 'assets/svg/Coffee';

const FontStyle = createGlobalStyle`
  @font-face {
    font-family: "Pretendard-bold";
    font-style: normal;
    src: url(${PretendardWoffBold}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-regular";
    font-style: normal;
    src: url(${PretendardWoffRegular}) format("woff");
  }
  @font-face {
    font-family: "Pretendard-light";
    font-style: normal;
    src: url(${PretendardWoffLight}) format("woff");
  }
  @font-face {
    font-family: "Gmarket-bold";
    font-style: normal;
    src: url(${GmarketWoffBold}) format("truetype");
  }
  @font-face {
    font-family: "Gmarket-medium";
    font-style: normal;
    src: url(${GmarketWoffMedium}) format("truetype");
  }
`;
const MirroringSpaceModal = ({
  titleProps,
  setTitleProps,
  descProps,
  setDescProps,
  imgRep,
  closeModal,
}) => {
  const [title, setTitle] = useState(titleProps);
  const [desc, setDesc] = useState(descProps);

  const titleOnChange = (e) => {
    setTitle(e.target.value);
  };
  const descOnChange = (e) => {
    setDesc(e.target.value);
  };
  // set 'ESC' keystroke to escape ========================
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const submitBtnListener = () => {
    setTitleProps(title);
    setDescProps(desc);
    closeModal();
  };

  const exportBtnListener = () => {
    // domtoimage
    //   .toBlob(document.querySelector('.insta_real_size'))
    //   .then((blob) => {
    //     saveAs(blob, 'card.png');
    //   });
  };

  return (
    <ModalContainer>
      <HeaderContainer>
        <HeaderTitle>공간 컨텐츠 미러링</HeaderTitle>
        <CloseButton onClick={() => closeModal()}>✕</CloseButton>
      </HeaderContainer>
      <ContentsContainer>
        <FontStyle />
        <TextContainer>
          <ItemTextInput value={title} onChange={(e) => titleOnChange(e)} />
          <ItemTextArea
            height="100px"
            value={desc}
            onChange={(e) => descOnChange(e)}
          />
          <SubmitButton onClick={submitBtnListener}>적용</SubmitButton>
          <SubmitButton onClick={exportBtnListener}>EXPORT</SubmitButton>
        </TextContainer>
        <MirroringContents>
          <MirroringContentsWrapper>
            <InstaContents>
              <IconWrapperPerson />
              <IconWrapperArrow />
              <InstaTitle>{title}</InstaTitle>
              <InstaDesc>{desc}</InstaDesc>
            </InstaContents>

            <AppContents>
              <AppImageWrapper />
              <AppTextWrapper>
                <AppTitleWrapper>
                  <CoffeeIcon />
                  <AppTitle>{title}</AppTitle>
                </AppTitleWrapper>
                <AppDesc>{desc}</AppDesc>
                <AppDesc2>
                  인사동 작은 골목 속 숨어있는 카페로, 시그니처 메뉴인
                  가나다사는 말차라테와 바닐라 크림, 비주얼부터 얹어진 크림까지
                  한 모금도 남길 수 없는 메뉴이다.인사동 작은 골목 속 숨어있는
                  카페로, 시그니처 메뉴인 가나다사는 말차라테와 바닐라 크림,
                  비주얼부터 얹어진 크림까지 한 모금도 남길 수 없는 메뉴이다. 동
                  작은 골목 속 숨어있는 카페로, 시그니처 메뉴인 ...
                </AppDesc2>
              </AppTextWrapper>
            </AppContents>
          </MirroringContentsWrapper>
          {/* <InstaContentsRealSize
            className="insta_real_size"
            uploadedImg={imgRep}
          >
            <IconWrapperArrow />
            <InstaTitle>{title}</InstaTitle>
            <InstaDesc>{desc}</InstaDesc>
          </InstaContentsRealSize> */}
        </MirroringContents>
      </ContentsContainer>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  max-height: 720px;
  max-width: calc(100vw - 120px);
`;
const HeaderContainer = styled.div`
  display: flex;
  position: relative;
`;
const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${palette.fontDefault};
`;
const CloseButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 0px;
`;
const SubmitButton = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  background-color: ${palette.mainOrangeHover};
  color: ${palette.white};
  &:hover {
    background-color: ${palette.mainOrange};
  }
  border-radius: 8px;
  cursor: pointer;
`;
const MirroringContents = styled.div`
  display: flex;
  flex-direction: column;
  width: 1508px;
  height: 100%;
`;
const MirroringContentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`;
const AppContents = styled.div`
  width: 428px;
  height: 675px;
  position: relative;
  display: flex;
  flex-direction: column;
`;
const InstaContents = styled.div`
  width: 1080px;
  height: 675px;
  background-image: url(${SampleImgInsta});
  position: relative;
`;
const InstaContentsRealSize = styled.div`
  width: 1080px;
  height: 1350px;
  // background-image: url(${SampleImgInsta});
  background-image: url(${(props) =>
    props.uploadedImg ? props.uploadedImg : SampleImgInsta});
  background-size: contain;
  background-position: center;
  position: relative;
`;

const AppImageWrapper = styled.div`
  width: 100%;
  height: 387px;
  background-image: url(${SampleImgApp});
`;
const AppTextWrapper = styled.div`
  padding: 0 25px;
  width: 100%;
  height: 284px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const AppTitleWrapper = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const AppTitle = styled.div`
  font-family: Pretendard-bold;
  font-size: 18px;
  height: 24px;
  bottom: 260px;
  line-height: 1.3;
  margin-left: 5px;
`;
const AppDesc = styled.div`
  font-family: Pretendard-light;
  font-size: 32px;
  margin-top: 10px;
  width: 100%;
  line-height: 1.25;
  text-align: left;
  color: #1e1e1e;
  align-self: stretch;
  word-break: keep-all;
`;

const AppDesc2 = styled.div`
  font-family: Pretendard-regular;
  width: 100%;
  height: 160px;
  margin-top: 10px;
  font-size: 18px;
  font-stretch: normal;
  line-height: 1.56;
  text-align: left;
  color: #1e1e1e;
`;

const InstaTitle = styled.div`
  font-family: Gmarket-medium;
  font-size: 120px;
  color: ${palette.fontWhite};
  position: absolute;
  left: 56px;
  bottom: 213px;
  flex-grow: 0;
  // -webkit-text-stroke: 2px #fff;
  // text-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: -1.2px;
  text-align: left;
  word-break: keep-all;
`;
const InstaDesc = styled.div`
  font-family: Gmarket-medium;
  font-size: 50px;
  color: ${palette.fontWhite};
  position: absolute;
  width: 914px;
  right: 110px;
  bottom: 83px;
  flex-grow: 0;
  text-align: right;
  letter-spacing: -2px;
  text-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
`;

const ContentsContainer = styled.div`
  padding: 10px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const TextContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const ItemTextInput = styled.input`
  all: unset;
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 4px;
  text-align: left;
  margin-bottom: 16px;
  font-size: 16px;
  color: ${(props) =>
    props.readOnly ? palette.mainOrange : palette.fontBlack};
  opacity: 1;
`;
const ItemTextArea = styled.textarea`
  all: unset;
  height: ${(props) => (props.height ? props.height : '60px')};
  border-bottom: 1px solid ${palette.borderDefault};
  border-left: 1px solid ${palette.borderDefault};
  padding-left: 8px;
  text-align: left;
  color: ${palette.fontBlack};
  font-size: 16px;
  line-height: 1.1;
  overflow: scroll;
`;
const IconWrapperPerson = styled.div`
  position: absolute;
  left: 39px;
  bottom: 39px;
  z-index: 99;
  width: 72px;
  height: 72px;
  background-image: url(${IconInstaPerson});
`;
const IconWrapperArrow = styled.div`
  position: absolute;
  right: 60px;
  bottom: 78px;
  z-index: 99;
  width: 47px;
  height: 52px;
  background-image: url(${IconInstaArrow});
`;

export default MirroringSpaceModal;
