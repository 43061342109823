import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import DatePicker from 'react-datepicker';

export const Container = styled.div`
  padding-top: 20px;
`;
export const LayoutWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  // @media screen and (max-width: 1000px) {
  //   grid-template-columns: repeat(1, 1fr);
  // }
  row-gap: 20px;
  column-gap: 10px;
  width: 100%;
  height: calc(
    100vh - ${(props) => (props.fromBottom ? props.fromBottom : '80px')}
  );
  overflow: auto;

  .isMobile & {
    display: flex;
    padding: 0;
    flex-direction: column;
    height: 100%;
  }
`;
export const CommonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${palette.white};
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  overflow: scroll;
  .isMobile & {
    width: 100%;
  }
  padding-bottom: 120px;
`;
export const CommonHeader = styled.div`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
`;
export const CommonTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
  color: ${palette.fontBlack};
`;
export const DateCollectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const CommonContents = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;
export const CommonContentsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`;
export const FlexDataContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ChartContainer = styled.div`
  width: 25%;
`;
export const DescContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;
export const DescContents = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${palette.fontDefault};
  padding: 8px 0;
`;
export const GridDataContainer = styled.div``;
export const DataItemContainer = styled.div`
  display: inline-block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: ${(props) =>
    props.clicked ? palette.mainBlue : palette.white};

  align-items: left;
  justify-content: center;
  padding: 16px 36px 16px 16px;
  margin-bottom: 12px;
  margin-right: 8px;
  border-radius: 12px;
  color: ${(props) => (props.clicked ? palette.fontWhite : palette.fontGray)};

  &:hover {
    color: ${palette.fontBlueHover};
  }
  cursor: pointer;
`;
export const DataItemTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
export const DataItemValue = styled.div`
  font-size: 32px;
  font-weight: 500;
  margin-top: 8px;
  color: ${palette.fontBlack};
`;
export const LayoutNoneWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 20px;
`;
export const NoneTitle = styled.div`
  font-size: 22px;
  color: ${palette.fontDefault};
`;
export const NoneDesc = styled.div`
  margin-top: 12px;
  font-size: 13px;
  color: ${palette.fontDefault};
`;
export const DashboardDatePicker = styled(DatePicker)`
  width: 128px;
  padding-left: 10px;
  height: 30px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid ${palette.borderDefault};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.grey_lighter};
  }
`;
export const DateCollectorBtn = styled.div`
  height: 30px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.mainBlue};
  border-radius: 8px;
  color: ${palette.fontWhite};
  margin-left: 4px;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
`;

export const DateAllCollectorBtn = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.mainOrange};
  &:hover {
    background-color: ${palette.mainOrangeHover};
  }
  border-radius: 4px;
  color: ${palette.fontWhite};
  margin-left: 4px;
  cursor: pointer;
  font-weight: 600;
`;

export const CommonContentsSubHeader = styled.div`
  font-size: 14px;
  font-weight: 800;
  color: ${palette.fontDefault};
  margin-bottom: 8px;
`;
export const ViewListContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.borderDefault};
  border-radius: 4px;
`;
export const ViewListHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  background-color: ${palette.mainBlueLight};
`;
export const ViewListContents = styled.div`
  display: flex;
  overflow: scroll;
  flex-direction: column;
`;
export const ViewListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: ${palette.fontDefault};
  &:hover {
    background-color: ${palette.mainBlue};
    color: ${palette.white};
  }
  cursor: pointer;
`;
export const ViewListItemId = styled.div`
  width: 60px;
  display: flex;
  height: 32px;
  justify-content: left;
  align-items: center;
  margin-left: 16px;
`;
export const ViewListItemTitle = styled.div`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ViewListItemView = styled.div`
  position: absolute;
  height: 32px;
  right: 0px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
